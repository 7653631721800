import React, { useState, useEffect } from 'react';
import { PageHeader, Button } from 'antd';
import { useParams } from 'react-router';
import CreateAircraftPublicLinkDrawer from './CreateAircraftPublicLinkDrawer';
import { getInstance } from 'common/api/spidertracks-sdk';

export const SubTitle = () => {
  const publicPageUrl = window.location.href.replace('publicaircraft', 'publicpage');

  return (
    <div>
      <p>
        This page allows you to create a unique URL to share the latest location of one or more
        specific aircraft with external parties. Before generating a link please make sure that the
        aircraft that you wish to share are marked as ‘Public’ in the{' '}
        <a href={publicPageUrl}>Public Page settings</a>.{' '}
      </p>{' '}
      <p>
        Clicking on ‘Create Link’ will open a form for you to select the aircraft and an optional
        expiry date.
      </p>
      <p>
        Note that the URL generated will not be saved in the Spidertracks platform so you may choose
        to save this elsewhere if needed.
      </p>
    </div>
  );
};

SubTitle.displayName = 'SubTitle';

const PublicAircraft = () => {
  const SpidertracksSDK = getInstance();
  const { organisationId } = useParams();

  const [drawerVisibility, setDrawerVisibility] = useState(false);
  const [publicAircraftData, setPublicAircraftData] = useState([]);

  useEffect(() => {
    const fetchAircraftData = async () => {
      const aircraftData = await SpidertracksSDK.getOrgAircraft(organisationId);
      const publicAircraft = aircraftData
        .filter(aircraft => aircraft.isPublic === true)
        .map(aircraft => ({ id: aircraft.id, registration: aircraft.registration }));

      setPublicAircraftData(publicAircraft);
    };
    fetchAircraftData();
  }, [organisationId]);

  const showDrawer = () => {
    setDrawerVisibility(true);
  };

  const onDrawerClose = () => {
    setDrawerVisibility(false);
  };

  return (
    <React.Fragment>
      <div
        className="px-5 py-4"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <PageHeader
          className="site-page-header"
          style={{ paddingLeft: '0px' }}
          title="Public Aircraft"
          subTitle={<SubTitle />}
        />
        <Button
          size="large"
          type="primary"
          ghost
          style={{ padding: '0 50px' }}
          onClick={showDrawer}
        >
          Create Link
        </Button>
      </div>
      {drawerVisibility && (
        <CreateAircraftPublicLinkDrawer
          visibility={drawerVisibility}
          onClose={onDrawerClose}
          publicAircraftData={publicAircraftData}
        />
      )}
    </React.Fragment>
  );
};

export default PublicAircraft;
