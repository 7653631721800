import {
  USER_DATA,
  SOS_NOTIFICATIONS,
  UNREAD_SPIDERTXT,
  EXCLUSIONS,
  FAVOURITES,
  INCREMENT_PENDING_NOTIFICATIONS,
  DECREMENT_PENDING_NOTIFICATIONS
} from './actions';
import deepEqual from 'deep-equal';
import { createReducer } from '@reduxjs/toolkit';

export const initialState = {
  exclusions: ['history', 'spidertxt', 'journeylog', 'schedule', 'admin'],
  spidertxtCount: 0,
  pendingSpidertxtNotificationCount: 0,
  sosNotifications: [],
  userData: { timezone: Intl.DateTimeFormat().resolvedOptions().timeZone },
  favourites: []
};

export default createReducer(initialState, builder => {
  builder
    .addCase(EXCLUSIONS, (state, { payload }) => {
      if (!deepEqual(state.exclusions, payload)) {
        state.exclusions = payload;
      }
    })
    .addCase(UNREAD_SPIDERTXT, (state, { payload }) => {
      if (!deepEqual(state.spidertxtCount, payload)) {
        state.spidertxtCount = payload;
      }
    })
    .addCase(SOS_NOTIFICATIONS, (state, { payload }) => {
      if (!deepEqual(state.sosNotifications, payload)) {
        state.sosNotifications = payload;
      }
    })
    .addCase(USER_DATA, (state, { payload }) => {
      if (!deepEqual(state.userData, payload)) {
        state.userData = payload;
      }
    })
    .addCase(FAVOURITES, (state, { payload }) => {
      if (!deepEqual(state.favourites, payload)) {
        state.favourites = payload;
      }
    })
    .addCase(INCREMENT_PENDING_NOTIFICATIONS, (state, { payload }) => {
      state.pendingSpidertxtNotificationCount = state.pendingSpidertxtNotificationCount + payload;
    })
    .addCase(DECREMENT_PENDING_NOTIFICATIONS, (state, { payload }) => {
      if (state.pendingSpidertxtNotificationCount > 0) {
        state.pendingSpidertxtNotificationCount = state.pendingSpidertxtNotificationCount - payload;
      }
    });
});
