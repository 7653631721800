import React from 'react';
import PropTypes from 'prop-types';

export const EtaInfoWindow = ({ etaTime, etaDate, etaDateUtc, distance }) => (
  <div>
    <p>Time: {etaTime}</p>
    <p>ETA: {etaDate}</p>
    <p>{etaDateUtc}</p>
    <p>Distance: {distance}</p>
  </div>
);

EtaInfoWindow.propTypes = {
  etaTime: PropTypes.string,
  etaDate: PropTypes.string,
  distance: PropTypes.string,
  etaDateUtc: PropTypes.string
};
