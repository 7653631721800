import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useGoogleMap } from '@react-google-maps/api';
import { renderKmlLayers } from '../../utils/kmlLayers/kmlLayer';
import { getKmlData, getActiveKmls } from '../../../../../redux/selectors/mapData';

export const KmlLayersContainer = ({ kmlData, activeKmls }) => {
  const map = useGoogleMap();
  const [kmlLayersOnMap, setKmlLayersOnMap] = useState([]);

  useEffect(() => {
    setKmlLayersOnMap(
      renderKmlLayers({
        kmlData: kmlData.filter(layer => layer.type === 'kml'),
        activeKmls: activeKmls.filter(layerId => layerId !== 'geofence'),
        kmlLayersOnMap,
        map
      })
    );
  }, [map, activeKmls]);

  return null;
};

const mapStateToProps = state => ({
  kmlData: getKmlData(state),
  activeKmls: getActiveKmls(state)
});

export default connect(mapStateToProps)(KmlLayersContainer);
