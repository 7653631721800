import React, { useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { notification } from 'antd';
import { NavigationContainer, isAuthenticated } from '@spidertracks/common';
import {
  populateUnreadSpidertxt,
  populateSosNotifications
} from '../../redux/reducers/navigationReducer/actions/thunk';
import { setUnreadSpidertxt } from '../../redux/reducers/navigationReducer/actions/index';
import {
  getUserData,
  getExclusions,
  getSpiderTxtCount,
  getSosNotifications,
  isOrgRoleOwnerOrAdmin
} from '../../redux/selectors/userData';
import { MAP_CONTAINER } from '../../components/Flying/Map/GoogleMap/constants';
import { useFeatureFlag } from '../../hooks';

export const isSideBarCollapsed = () => {
  return (
    sessionStorage.sidebarCollapsed === undefined || sessionStorage.sidebarCollapsed === 'true'
  );
};

const getSidebarDisabledItems = userData => {
  return userData.orgs && userData.orgs.findIndex(org => org.org.spiderTxtEnabled) > -1
    ? []
    : ['spidertxt'];
};

const getSidebarCallback = history => {
  const publicPath = () => `${window.location.pathname}`;

  return menuEvent => {
    const openInNewTab = menuEvent.item.props.target === '_blank';
    if (openInNewTab) {
      return;
    }

    notification.destroy();
    isAuthenticated() ? history.push(menuEvent.item.props['data-url']) : history.push(publicPath());
  };
};

const getTopbarCallback = history => {
  return ({ item }) => {
    const url = item.props['data-url'];

    notification.destroy();

    if (item.props.target === '_blank') {
      window.open(url, '_blank');
    } else {
      history.push(url);
    }
  };
};

const getSidebarCollapsedCallback = () => {
  return sidebarCollapseState => {
    sessionStorage.setItem('sidebarCollapsed', sidebarCollapseState);
  };
};

const getContentProps = () => {
  return {
    id: MAP_CONTAINER,
    className: 'map-wrapper'
  };
};

export const NavigationFrame = ({
  children,
  exclusions,
  spidertxtCount,
  sosNotifications,
  history,
  userData
}) => {
  const { orgs = [] } = userData;
  const adminUserOrgIds = orgs
    .filter(isOrgRoleOwnerOrAdmin)
    .reduce((acc, o) => acc.concat(o.org.id), []);

  const isInsightsFeatureEnabled = useFeatureFlag('insights', adminUserOrgIds);
  const isSafetyInsightsFeatureEnabled = useFeatureFlag('insights-safety', adminUserOrgIds);
  const isEventsExplorerFeatureEnabled = useFeatureFlag('enable-events-explorer', adminUserOrgIds);

  const sidebarExcludedItems = useMemo(() => {
    const excludedItems = [...exclusions];
    if (!isInsightsFeatureEnabled) {
      excludedItems.push('insights');
    }

    if (!isSafetyInsightsFeatureEnabled) {
      excludedItems.push('safetyInsights');
    }

    if (!isEventsExplorerFeatureEnabled) {
      excludedItems.push('eventsExplorer');
    }

    return excludedItems;
  }, [
    exclusions,
    adminUserOrgIds,
    isInsightsFeatureEnabled,
    isSafetyInsightsFeatureEnabled,
    isEventsExplorerFeatureEnabled
  ]);

  return (
    <NavigationContainer
      sosNotifications={sosNotifications}
      sidebarDisabledItems={getSidebarDisabledItems(userData)}
      sidebarExcludedItems={sidebarExcludedItems}
      sidebarCollapsed={isSideBarCollapsed()}
      sidebarCollapseCallback={getSidebarCollapsedCallback()}
      spidertxtCount={spidertxtCount}
      contentProps={getContentProps()}
      sidebarCallback={getSidebarCallback(history)}
      topbarCallback={getTopbarCallback(history)}
      authenticatedUserData={userData}
    >
      {children}
    </NavigationContainer>
  );
};

NavigationFrame.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.object.isRequired,
  userData: PropTypes.object,
  exclusions: PropTypes.array,
  spidertxtCount: PropTypes.number,
  sosNotifications: PropTypes.array
};

const mapStateToProps = state => ({
  userData: getUserData(state),
  exclusions: getExclusions(state),
  spidertxtCount: getSpiderTxtCount(state),
  sosNotifications: getSosNotifications(state)
});

const mapDispatchToProps = {
  setUnreadSpidertxt,
  populateUnreadSpidertxt,
  populateSosNotifications
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavigationFrame));
