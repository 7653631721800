import React, { FC, Fragment } from 'react';
import { DisplayPoint } from '../../../../common/api/spidertracks-sdk/types/TrackData';
import { POINT_INFO_PANEL } from '../GoogleMap/constants';
import RowInfo from './RowInfo';
import { EventClass } from '../../../../constants';

interface PanelBodyProps {
  selectedDisplayPoint: DisplayPoint;
}

const hasProperty = (selectedMarkerPoint: DisplayPoint, key: string) => {
  if (selectedMarkerPoint === undefined) {
    return false;
  }
  const p = selectedMarkerPoint[key as keyof DisplayPoint];
  return !!(p || typeof p === 'string' || p === 0);
};

const isNumeric = (selectedMarkerPoint: DisplayPoint, key: string) =>
  hasProperty(selectedMarkerPoint, key) && !isNaN(selectedMarkerPoint[key as keyof DisplayPoint]);

const PanelBody: FC<PanelBodyProps> = ({ selectedDisplayPoint }) => {
  // TODO: arguably could dump most of this in a getPanelBody selector
  const hasCoordinates =
    hasProperty(selectedDisplayPoint, 'coordinates') &&
    !!selectedDisplayPoint.coordinates.value.length;
  const hasAltitude = isNumeric(selectedDisplayPoint, 'altitude');
  const hasSpeed = isNumeric(selectedDisplayPoint, 'speed');
  const hasHeading = isNumeric(selectedDisplayPoint, 'heading');
  const hasHeightAgl = isNumeric(selectedDisplayPoint, 'heightAgl');
  const isFSIEvent =
    hasProperty(selectedDisplayPoint, 'type') && selectedDisplayPoint.type === EventClass.FSI;
  const hasThresholdsAndValues =
    isFSIEvent &&
    hasProperty(selectedDisplayPoint, 'thresholdDisplayString') &&
    hasProperty(selectedDisplayPoint, 'valueDisplayString');
  const hasDescription =
    hasProperty(selectedDisplayPoint, 'description') &&
    selectedDisplayPoint.description &&
    selectedDisplayPoint.description.length > 0;
  const descriptionKey = isFSIEvent ? POINT_INFO_PANEL.fsiEventName : POINT_INFO_PANEL.description;
  const descriptionValue = hasProperty(selectedDisplayPoint, 'displayName')
    ? selectedDisplayPoint.displayName
    : selectedDisplayPoint.description;
  const isCASEvent =
    hasProperty(selectedDisplayPoint, 'type') && selectedDisplayPoint.type === EventClass.CAS;
  const hasAlert = isCASEvent && hasProperty(selectedDisplayPoint, 'alert');

  const eventData = selectedDisplayPoint.eventData;

  return (
    <Fragment>
      {hasCoordinates && (
        <RowInfo
          name={POINT_INFO_PANEL.position}
          value={selectedDisplayPoint.coordinates.value.join(' ')}
        />
      )}
      {hasAltitude && (
        <RowInfo
          name={POINT_INFO_PANEL.altitude}
          value={`${selectedDisplayPoint.altitude} ${selectedDisplayPoint.altitudeUnit}`}
          tooltipText="Altitude above mean sea level (AMSL)"
        />
      )}
      {hasHeightAgl && (
        <RowInfo
          name={POINT_INFO_PANEL.heightAgl}
          value={`${selectedDisplayPoint.heightAgl} ${selectedDisplayPoint.altitudeUnit}`}
          tooltipText="Altitude above ground level (AGL)"
        />
      )}
      {hasSpeed && (
        <RowInfo
          name={POINT_INFO_PANEL.speed}
          value={`${selectedDisplayPoint.speed} ${selectedDisplayPoint.speedUnit}`}
          tooltipText="GPS speed over ground"
        />
      )}
      {hasHeading && (
        <RowInfo
          name={POINT_INFO_PANEL.direction}
          value={`${selectedDisplayPoint.heading}°${POINT_INFO_PANEL.degree}`}
        />
      )}
      {hasAlert && (
        <RowInfo
          name={POINT_INFO_PANEL.alert}
          value={selectedDisplayPoint.alert}
          colorClassName={selectedDisplayPoint.alertDisplayColorClass}
        />
      )}
      {hasDescription && <RowInfo name={descriptionKey} value={descriptionValue} />}
      {eventData?.map((e: { key: string; value: string }) => {
        return <RowInfo key={e.key} name={e.key} value={e.value} />;
      })}
      {hasThresholdsAndValues && (
        <RowInfo
          name={POINT_INFO_PANEL.fsiEventThreshold}
          value={selectedDisplayPoint.thresholdDisplayString}
          tooltipText="The threshold for this Event at the time it was triggered"
        />
      )}
      {hasThresholdsAndValues && (
        <RowInfo
          name={POINT_INFO_PANEL.fsiEventValue}
          value={selectedDisplayPoint.valueDisplayString}
          tooltipText="The value of the flight parameter when the Event was triggered"
        />
      )}
    </Fragment>
  );
};

export default PanelBody;
