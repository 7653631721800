export const roundToTenth = (value: number): number => Math.round(value * 10) / 10;

/**
 * Format speed from meters/second to the requirement format
 *
 * @param  {Number}    speed        speed in meters/sec (double)
 * @param  {String}    speedSetting target format
 * @return {Number}    speed in the target format limited to 1 decimal place
 */

export const formatSpeedFromMetersPerSeconds = (speed: number, speedSetting: string): number => {
  switch (speedSetting) {
    case 'knots':
      return speed * 1.94384449;
    case 'km/h':
      return speed * 3.6;
    case 'mi/h':
      return speed * 2.23693629;
    case 'ft/min':
      return speed * 196.85039;
    default:
      return speed;
  }
};

/**
 * Format speed to meters/second from the requirement format
 *
 * @param  {Number}    speed        speed in meters/sec (double)
 * @param  {String}    speedSetting provided format
 * @return {Number}    speed in meters/sec
 */

export const formatSpeedToMetersPerSeconds = (speed: number, speedSetting: string): number => {
  switch (speedSetting) {
    case 'knots':
      return speed / 1.94384449;
    case 'km/h':
      return speed / 3.6;
    case 'mi/h':
      return speed / 2.23693629;
    case 'ft/min':
      return speed / 196.85039;
    default:
      return speed;
  }
};
