import React, { useMemo, useState, useCallback, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { gaEventHelper } from '@spidertracks/common';
import { Table, Spin } from 'antd';
import { getInstance } from 'common/api/spidertracks-sdk';
import { populateFavourites } from '../../../../../../redux/reducers/navigationReducer/actions/thunk';
import DeleteIcon from '@material-ui/icons/Delete';

const gaEventSender = gaEventHelper('MapControls - Favourite Menu');

export const FavouritesList = ({ favourites, populateFavourites }) => {
  const [deletingRecordIds, setDeleteingRecordIds] = useState([]);

  const sendGAEvent = eventAction => {
    let eventLabel = 'Favourites ' + eventAction;
    gaEventSender(eventAction, eventLabel);
  };

  const loadFavourite = favourite => {
    const location = { lat: favourite.location.lat, lng: favourite.location.lng };
    window.gmap.panTo(location);
    window.gmap.setZoom(favourite.zoom_level);
    sendGAEvent('Load');
  };

  const deleteFavourites = useCallback(
    favouriteId => async e => {
      e.stopPropagation();
      !deletingRecordIds.includes(favouriteId) &&
        setDeleteingRecordIds([...deletingRecordIds, favouriteId]);
      await getInstance()
        .getFavouritesService()
        .delete(favouriteId);
      sendGAEvent('Delete');
      populateFavourites();
    },
    [deletingRecordIds]
  );

  const columns = useMemo(() => {
    return [
      {
        title: 'Name',
        dataIndex: 'name',
        width: '82%',
        className: 'pr-0'
      },
      {
        title: '',
        dataIndex: 'action',
        render: function deleteColumn(text, favourite) {
          const deleting = deletingRecordIds.includes(favourite.id);
          return (
            <Fragment>
              {!deleting && (
                <DeleteIcon
                  style={{
                    height: 25,
                    width: 25,
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer'
                  }}
                  onClick={deleteFavourites(favourite.id)}
                />
              )}
              {deleting && <Spin />}
            </Fragment>
          );
        }
      }
    ];
  }, [favourites, deletingRecordIds]);

  return (
    <Table
      columns={columns}
      dataSource={favourites}
      pagination={false}
      rowKey="id"
      onRow={favourite => ({ onClick: () => loadFavourite(favourite) })}
    />
  );
};

FavouritesList.propTypes = {
  favourites: PropTypes.array,
  populateFavourites: PropTypes.func
};

const mapDispatchToProps = {
  populateFavourites
};

export default connect(null, mapDispatchToProps)(FavouritesList);
