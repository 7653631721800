import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Select } from 'antd';
import { getEventTypeOptions } from './getEventTypeOptions';

export const dropdownColumnSpans = {
  full: 24,
  icon: 3,
  title: 21
};

export const EventTypeDropdown = ({
  eventTypes,
  selectedEventType,
  setSelectedEventType,
  getFieldDecorator,
  isEditForm = false
}) => (
  <Form.Item
    label={'Event Trigger'}
    name={'eventType'}
    style={{ textAlign: 'left' }}
    className="mt-3"
  >
    <Row>
      {getFieldDecorator('eventType', {
        ...(selectedEventType && { initialValue: selectedEventType }),
        rules: [
          {
            required: true,
            message: 'Please specify an event type'
          }
        ]
      })(
        <Select
          labelInValue={true}
          onSelect={setSelectedEventType}
          disabled={isEditForm}
          showSearch
          style={{ width: '300px' }}
          placeholder={'Select an event trigger'}
          optionLabelProp="label"
        >
          {eventTypes.map(getEventTypeOptions)}
        </Select>
      )}
    </Row>
  </Form.Item>
);

EventTypeDropdown.propTypes = {
  eventTypes: PropTypes.array,
  selectedEventType: PropTypes.any,
  getFieldDecorator: PropTypes.func,
  setSelectedEventType: PropTypes.func,
  isEditForm: PropTypes.bool
};

EventTypeDropdown.displayName = 'EventTypeDropdown';
