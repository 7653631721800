import 'cesium/Source/Widgets/widgets.css';

import React, { useEffect, useRef, useState } from 'react';
import * as Cesium from 'cesium';
import { Cartographic, sampleTerrainMostDetailed, ScreenSpaceEventType } from 'cesium';
import { Clock, CzmlDataSource, Viewer } from 'resium';
import { LoadingBackground } from '../../LoadingBackground';
import config from './cesium.config';
import useCesiumPlayerControls from './useCesiumPlayerControls';
import { useDispatch, useSelector } from 'react-redux';
import {
  setElapsedTime,
  setTotalTime,
  togglePlaybackStatus as togglePlaybackStatusAction
} from '../../../redux/slice/flightExplorer';
import { czmlWall } from './czmlWall';
import { czmlPath } from './czmlPath';
import {
  get3dReplayFeatures,
  getPanelVisibility,
  getSelectedTrackFlightDataSmoothed
} from '../../../redux/selectors/flightExplorer';
import { PanelVisibility } from '../../../redux/types';
import {
  DisplayPoint,
  PrivateTrackData
} from '../../../common/api/spidertracks-sdk/types/TrackData';
import Overlay from './overlay';
import { czmlLabels } from './czmlLabels';
import { dateFormatter } from '../../../helpers/formatTime';
import { getGroundEvents, getTimelineEvents, TimelineEvent } from '../utilities/pointClassifier';
import { SpeechBubbleSVG } from './speechBubbleSVG';
import { getEncodedIconUrl } from '../../Flying/Map/utils/drawing/marker';
import { FlightDataSample } from '../../../types/FlightData';
import { lockToGround } from './lockToGround';
import { aircraftMarkerColors } from '../../Flying/Map/GoogleMap/markers/markerColorCodes';
import SourceOverlay from './sourceOverlay';
import DisplaySourceOverlay from './displaySourceOverlay';
import { useFeatureFlag, useLocalStorage } from '../../../hooks';
import { getUserData } from '../../../redux/selectors/userData';

Cesium.Ion.defaultAccessToken = config.cesiumAccessToken;

interface Flight3DPlayerProps {
  selectedTrack: PrivateTrackData;
  displayPoints: DisplayPoint[];
  aircraftId: string;
  className: string;
  timezone: string;
}

const VIEWER_PROPS = {
  homeButton: false,
  geocoder: false,
  sceneModePicker: false,
  fullscreenButton: false,
  baseLayerPicker: false,
  imageryProvider: false,
  infoBox: false,
  navigationHelpButton: false,
  shouldAnimate: true
};

const BING_IMAGERY_PROVIDER_ID = 2;
const SENITINAL_IMAGERY_PROVIDER_ID = 3954;

const Flight3DPlayer: React.FC<Flight3DPlayerProps> = ({
  selectedTrack,
  displayPoints,
  aircraftId,
  className,
  timezone
}) => {
  const dispatch = useDispatch();
  const [iAmDeveloper] = useLocalStorage('iAmDeveloper', false);
  const selectedTrackFlightData = useSelector(getSelectedTrackFlightDataSmoothed);
  const [timeOffset, setTimeOffset] = useState<number>(0);
  const [baseCZML, setBaseCZML] = useState<any>(undefined);
  const [terrainProvider, setTerrainProvider] = useState<Cesium.CesiumTerrainProvider>();
  const [imageryLayer, setImageryLayer] = useState<Cesium.ImageryLayer>();
  const [entireTrackFlightData, setEntireTrackFlightData] = useState<FlightDataSample[]>([]);

  const panelVisibility = useSelector(getPanelVisibility);
  const replayFeatures = useSelector(get3dReplayFeatures);
  const { isPlaying, playbackSpeed, elapsedTime, totalTime } = useSelector(
    // @ts-ignore
    state => state.flightExplorer
  );

  const { iconColour: aircraftColor, type: aircraftType, model: aircraftModel } = useSelector(
    // @ts-ignore
    state => state.aircraft[aircraftId] || { iconColour: null, type: null, model: null }
  );

  const dataSourceRef = useRef<Cesium.CzmlDataSource | null>(null);
  const dataSource: Cesium.CzmlDataSource | null = dataSourceRef.current;
  const viewerRef = useRef(null);
  // @ts-ignore
  const cesiumElement = viewerRef.current?.cesiumElement;

  const userData = useSelector(getUserData);
  const userOrgIds = userData.orgs.reduce<string[]>((acc, o) => acc.concat(o.org.id), []);
  const useLegacyLockToGround = useFeatureFlag('force-legacy-lock-to-ground', userOrgIds);
  const useGpsHeading = useFeatureFlag('gps-heading-3dfr', userOrgIds);

  const formatTimeFn = dateFormatter('HH:mm:ss');
  const timelineEvents = getTimelineEvents(displayPoints, formatTimeFn, timezone);
  const groundEvents = getGroundEvents(selectedTrack.points);

  const {
    togglePlaybackStatus,
    handlePlaybackSpeedChange,
    updatePlaybackTimeline
  } = useCesiumPlayerControls(viewerRef.current);

  let displayClassName = className;
  if (panelVisibility['3dfr'] !== PanelVisibility.VISIBLE) {
    displayClassName += ' hidden';
  }
  const aircraft = {
    type: aircraftType,
    model: aircraftModel,
    color: aircraftColor ? aircraftColor.toLowerCase() : 'default',
    isFixedWing: aircraftType ? aircraftType.toLowerCase().startsWith('fixed') : false
  };
  const markerColor = aircraftMarkerColors[aircraft.color as keyof typeof aircraftMarkerColors];

  // This keeps the aircraft in view
  function trackPath(force: boolean = false) {
    if (
      viewerRef.current &&
      // @ts-ignore
      viewerRef.current.cesiumElement &&
      dataSourceRef.current &&
      dataSourceRef.current.entities &&
      // @ts-ignore
      (force || !viewerRef.current.cesiumElement.trackedEntity)
    ) {
      const entity = dataSourceRef.current.entities.getById('path');
      // @ts-ignore
      viewerRef.current.cesiumElement.trackedEntity = entity;
    }
  }

  // Nothing can be done without a terrain provider, do this first.
  useEffect(() => {
    const setTerrain = async () => {
      const terrain = await Cesium.createWorldTerrainAsync();
      setTerrainProvider(terrain);
    };
    const createImageryLayer = async () => {
      // This is the magic asset number for sentinel one imagery
      const imageryProviders = {
        development: SENITINAL_IMAGERY_PROVIDER_ID,
        local: BING_IMAGERY_PROVIDER_ID,
        test: BING_IMAGERY_PROVIDER_ID,
        production: BING_IMAGERY_PROVIDER_ID
      };
      const id =
        imageryProviders[window.env.ENV as keyof typeof imageryProviders] ||
        SENITINAL_IMAGERY_PROVIDER_ID;
      const imageryLayer = Cesium.ImageryLayer.fromProviderAsync(
        Cesium.IonImageryProvider.fromAssetId(id),
        {}
      );
      setImageryLayer(imageryLayer);
    };

    setTerrain();
    createImageryLayer();
  }, []);

  // Builds the additional features on the 3d View
  useEffect(() => {
    const buildFeatures = async () => {
      if (entireTrackFlightData.length != 0 && terrainProvider && aircraftColor && aircraftType) {
        // @ts-ignore
        const buildWall = (): Promise<any> => {
          return czmlWall(
            selectedTrack,
            entireTrackFlightData,
            aircraft,
            markerColor,
            terrainProvider,
            replayFeatures.showWall
          );
        };
        // @ts-ignore
        const buildLabels = (): Promise<any> => {
          return czmlLabels(timelineEvents, entireTrackFlightData, markerColor, {
            visible: replayFeatures.showEvents,
            showName: panelVisibility.flightEventsTimeline != PanelVisibility.VISIBLE,
            eventVisibleDuration: 30
          });
        };
        // @ts-ignore
        const buildPath = (): Promise<any> => {
          return czmlPath(selectedTrack, entireTrackFlightData, aircraft, markerColor);
        };
        buildPath().then(c => setBaseCZML(c));
        buildWall().then(c => dataSourceRef?.current?.process(c));
        buildLabels().then(c => dataSourceRef?.current?.process(c));
      }
    };

    buildFeatures();
  }, [
    terrainProvider,
    entireTrackFlightData,
    aircraftColor,
    aircraftType,
    aircraftModel,
    replayFeatures,
    panelVisibility
  ]);

  useEffect(() => {
    const processAndLockTrackToGround = async () => {
      if (selectedTrackFlightData.length > 0 && terrainProvider) {
        const entireTrackFlightData = selectedTrackFlightData;

        const isFixedWing = aircraft.type ? aircraft.type.toLowerCase().startsWith('fixed') : true;
        const carts = entireTrackFlightData.map(pt =>
          Cartographic.fromDegrees(pt.longitude, pt.latitude)
        );
        const terrain = (await sampleTerrainMostDetailed(terrainProvider, carts)).map(
          c => c.height
        );
        const entireTrackFlightDataGroundLocked = await lockToGround(
          groundEvents,
          entireTrackFlightData,
          terrain,
          isFixedWing,
          true,
          useLegacyLockToGround
        );

        if (useGpsHeading) {
          for (let sample of entireTrackFlightDataGroundLocked) {
            sample.yawRadians = sample.gpsHeadingRadians;
            sample.pitchRadians = 0;
            sample.rollRadians = 0;
          }
        }

        setEntireTrackFlightData(entireTrackFlightDataGroundLocked);
      }
    };

    processAndLockTrackToGround();
  }, [selectedTrackFlightData, selectedTrack]);

  // These 3 useEffect allow to update the 3DFlight Player from outside
  useEffect(() => {
    togglePlaybackStatus(isPlaying);
  }, [isPlaying]);

  useEffect(() => {
    handlePlaybackSpeedChange(playbackSpeed);
  }, [playbackSpeed]);

  useEffect(() => {
    updatePlaybackTimeline(elapsedTime);
  }, [elapsedTime]);

  // This useEffect is to enable clustering on the 3D View
  useEffect(() => {
    if (dataSource) {
      dataSource.clustering.enabled = true;
      dataSource.clustering.pixelRange = 50;
      dataSource.clustering.minimumClusterSize = 2;
      dataSource.clustering.clusterEvent.addEventListener(function(clusteredEntities, cluster) {
        const clusteredEvents = clusteredEntities.reduce<TimelineEvent[]>((acc, e) => {
          if (e.properties?.hasProperty('event')) {
            return [...acc, e.properties?.getValue(elapsedTime).event as TimelineEvent];
          }
          return acc;
        }, []);
        const text = clusteredEvents.map(e => e.name).join('\n');
        const speechBubble = new SpeechBubbleSVG(text, true);
        cluster.label.show = false;
        cluster.billboard.image = getEncodedIconUrl(speechBubble.generateSVG());
        cluster.billboard.show = true;
        cluster.billboard.id = cluster.label.id;
        cluster.billboard.verticalOrigin = Cesium.VerticalOrigin.BOTTOM;
        cluster.billboard.horizontalOrigin = Cesium.HorizontalOrigin.LEFT;
        cluster.billboard.heightReference = Cesium.HeightReference.NONE;
        cluster.billboard.translucencyByDistance = new Cesium.NearFarScalar(1.0, 1.0, 2000.0, 0.5);
      });
    }
  }, [dataSource]);

  useEffect(() => {
    if (cesiumElement) {
      cesiumElement.scene.debugShowFramesPerSecond = iAmDeveloper;
      cesiumElement.scene.globe.depthTestAgainstTerrain = true;
      cesiumElement.screenSpaceEventHandler.setInputAction(function onLeftClick(
        //@ts-ignore
        movement: ScreenSpaceEventHandler.PositionedEvent
      ) {
        // Pick a new feature
        const pickedFeature = cesiumElement.scene.pick(movement.position);
        if (!Cesium.defined(pickedFeature)) {
          return;
        }
        let entity: Cesium.Entity | undefined = undefined;
        if (
          Array.isArray(pickedFeature.id) &&
          // @ts-ignore
          pickedFeature.id.every(item => item instanceof Cesium.Entity)
        ) {
          entity = pickedFeature.id[0] as Cesium.Entity;
        } else if (pickedFeature.id instanceof Cesium.Entity) {
          entity = pickedFeature.id as Cesium.Entity;
        }
        if (entity) {
          const event = entity.properties?.getValue(elapsedTime).event as TimelineEvent;
          const newPointTime = Math.round(
            (event.point.timestamp - selectedTrack.departedTime) / 1000
          );
          dispatch(setElapsedTime({ elapsedTime: newPointTime }));
        }
      }, ScreenSpaceEventType.LEFT_CLICK);

      cesiumElement.scene.light = new Cesium.DirectionalLight({
        direction: cesiumElement.scene.camera.directionWC
      });
      cesiumElement.scene.preRender.addEventListener(function(scene: any, time: any) {
        cesiumElement.scene.light.direction = Cesium.Cartesian3.clone(
          cesiumElement.scene.camera.directionWC,
          cesiumElement.scene.light.direction
        );
      });
    }
  }, [cesiumElement]);

  const handleSetElapsedTime = (eTime: number) => {
    if (eTime != elapsedTime) {
      dispatch(setElapsedTime({ elapsedTime: eTime }));
    }
  };

  const onDataSourceLoad = (dataSource: Cesium.CzmlDataSource) => {
    dataSourceRef.current = dataSource;
    if (imageryLayer) {
      // @ts-ignore
      viewerRef.current.cesiumElement?.imageryLayers.add(imageryLayer);
    }
  };

  const onClockTick = (clock: Cesium.Clock) => {
    trackPath(true);
    const offset = Math.floor(
      Cesium.JulianDate.secondsDifference(clock.currentTime, clock.startTime)
    );

    // This is needed as otherwise it'd overwrite the value of the timeline slider in the UI when dragging
    if (isPlaying) {
      handleSetElapsedTime(offset);
    }

    if (totalTime < 0) {
      const value = Math.floor(
        Cesium.JulianDate.secondsDifference(clock.stopTime, clock.startTime)
      );
      dispatch(setTotalTime({ totalTime: value }));
    }
    if (timeOffset !== offset) {
      setTimeOffset(offset);
    }
  };

  return (
    <div className={`${displayClassName}`}>
      {terrainProvider && baseCZML ? (
        <Viewer
          ref={viewerRef}
          {...VIEWER_PROPS}
          terrainProvider={terrainProvider}
          style={{ position: 'relative', height: '100%', width: '100%' }}
          timeline={false}
          animation={false}
        >
          <Clock
            onStop={() => {
              if (isPlaying) {
                dispatch(togglePlaybackStatusAction());
              }
            }}
            clockRange={Cesium.ClockRange.LOOP_STOP}
            onTick={onClockTick}
            shouldAnimate={false}
          />
          <CzmlDataSource data={baseCZML} onLoad={onDataSourceLoad} />
        </Viewer>
      ) : (
        <LoadingBackground />
      )}
      <Overlay className={'3dfr-overlay'} />
      <DisplaySourceOverlay className={'3dfr-overlay'} />
      {iAmDeveloper ? <SourceOverlay className={'3dfr-overlay'} /> : undefined}
    </div>
  );
};

export default Flight3DPlayer;
