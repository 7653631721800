import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { DropdownTooltip } from './DropdownTooltip';
import { dropdownColumnSpans } from './EventTypeDropdown';
import { SolidTooltip } from '@spidertracks/components';

export const DisabledSelectOption = ({ name }) => {
  return (
    <Row>
      <Col
        span={dropdownColumnSpans.title}
        style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
      >
        <SolidTooltip
          title={
            <DropdownTooltip
              title={name}
              description="This event type already has rules configured for all aircraft"
            />
          }
          trigger="click"
        >
          {name}
        </SolidTooltip>
      </Col>
    </Row>
  );
};

DisabledSelectOption.propTypes = {
  name: PropTypes.string
};
