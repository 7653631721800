import React, { useState, useEffect, useCallback } from 'react';
import { PageHeader } from 'antd';
import { useParams } from 'react-router';
import TagTable from './TagTable';
import { getInstance } from 'common/api/spidertracks-sdk';

export const Tag = () => {
  const { organisationId } = useParams();
  const SpidertracksSDK = getInstance();
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchTags = useCallback(async () => {
    setIsLoading(true);

    try {
      const personsData = await SpidertracksSDK.getPersons(organisationId);
      const personsAssociatedTagsData = await SpidertracksSDK.getPersonAssociatedTags(
        organisationId
      );

      const tagData = personsData.map(person => {
        const tag = personsAssociatedTagsData.find(tag => tag.personId === person.id);
        return {
          key: person.id,
          personId: person.id,
          name: `${person.firstName} ${person.lastName}`,
          tagId: tag && tag.id ? tag.id : null,
          macAddress:
            tag && tag.macAddress
              ? tag.macAddress
                  .replaceAll(':', '')
                  .replaceAll('-', '')
                  .replace(/(.{2})/g, '$1:')
                  .replace(/\:$/, '')
              : '-'
        };
      });
      setTags(tagData);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [SpidertracksSDK, organisationId]);
  const saveTag = useCallback(
    async tag => {
      await SpidertracksSDK.savePersonAssociatedTag(tag);
      fetchTags();
    },
    [SpidertracksSDK, fetchTags, organisationId]
  );

  const deleteTag = useCallback(
    async (tagId, shouldFetchTags = true) => {
      await SpidertracksSDK.deletePersonAssociatedTag(tagId);
      if (shouldFetchTags) {
        fetchTags();
      }
    },
    [SpidertracksSDK, fetchTags, organisationId]
  );

  useEffect(() => {
    if (organisationId && fetchTags) {
      fetchTags();
    }
  }, [organisationId, fetchTags]);

  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <PageHeader
          className="site-page-header"
          style={{ paddingLeft: '0px' }}
          title="Tags"
          subTitle="Click the row to edit"
        />
      </div>
      <TagTable tags={tags} isLoading={isLoading} saveTag={saveTag} deleteTag={deleteTag} />
    </React.Fragment>
  );
};

Tag.displayName = 'Tag';

export default Tag;
