import { memo, useEffect } from 'react';
import { useGoogleMap } from '@react-google-maps/api';
import { buildImageMapTypeFor } from '../utils/weather/weatherOverlay';
import { offlineError, weatherUnknownError } from '../../../../helpers';

export const GraphicalOverlayMapView = ({ layerProductCode }) => {
  const mapInstance = useGoogleMap();

  useEffect(() => {
    let layerIndex;
    const addLayer = async () => {
      try {
        if (layerIndex !== undefined) {
          mapInstance.overlayMapTypes.removeAt(layerIndex);
        }
        const overlayLayer = await buildImageMapTypeFor(layerProductCode);
        const overlayLength = mapInstance.overlayMapTypes.push(overlayLayer);
        layerIndex = overlayLength - 1;
      } catch (weatherError) {
        if (!window.navigator.onLine) {
          offlineError();
        } else {
          weatherUnknownError();
        }
      }
    };
    addLayer();
    const intervalId = setInterval(addLayer, 300000);

    return () => {
      mapInstance.overlayMapTypes.removeAt(layerIndex);
      clearInterval(intervalId);
    };
  }, [layerProductCode]);

  return null;
};

GraphicalOverlayMapView.displayName = 'GraphicalOverlayMapView';

export default memo(GraphicalOverlayMapView);
