import React, { Fragment, useMemo } from 'react';
import AircraftTrackPoint from './AircraftTrackPoint';
import PropTypes from 'prop-types';
import { getSelectedPointMarker } from '../../../utils/drawing/marker';
import { activeMarkerIcon as activeMarkerIconBuilder } from '../../../utils/drawing/marker';
import { getLatLng } from '../../../../../../redux/selectors/aircraftData';

const SelectedTrackPoint = ({ point, aircraftProperties }) => {
  const activeMarkerIcon = useMemo(() => activeMarkerIconBuilder(), []);
  const selectedTrackPointLatLng = getLatLng(point);

  return (
    <Fragment>
      <AircraftTrackPoint
        icon={activeMarkerIcon}
        position={selectedTrackPointLatLng}
        zIndex={999}
      />
      <AircraftTrackPoint
        icon={getSelectedPointMarker(point, aircraftProperties)}
        position={selectedTrackPointLatLng}
        zIndex={999}
      />
    </Fragment>
  );
};

SelectedTrackPoint.propTypes = {
  point: PropTypes.object.isRequired,
  aircraftProperties: PropTypes.object.isRequired
};

export default SelectedTrackPoint;
