import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Button, Spin, Tooltip } from 'antd';
import TagFormDropdown from './TagFormDropdown';
import TagFormInput from './TagFormInput';
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';
import {
  tagAddSuccess,
  tagAddError,
  tagDeleteSuccess,
  tagDeleteError
} from '../../../helpers/globalNotifications';

const TagForm = ({
  form,
  tagData,
  isEdit,
  saveTag,
  deleteTag,
  onClose,
  selectedRecordTag = undefined
}) => {
  const { getFieldDecorator, validateFields } = form;
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [requstError, setRequstError] = useState(undefined);

  const handleMacAddressFieldChange = e => {
    setRequstError(undefined);
  };

  const onSave = async e => {
    e.preventDefault();
    setRequstError(undefined);
    try {
      await validateFields(async (err, values) => {
        if (!err) {
          setIsSaving(true);
          const tagFormData = values; // Example {"personId":"3095c440-0083-11ec-9288-0a582fa3adcb","macAddress":"18:FD:74:00:00:00"}
          tagFormData.macAddress = tagFormData.macAddress
            .replaceAll(':', '')
            .replaceAll('-', '')
            .replaceAll('.', '')
            .replaceAll(' ', '');
          try {
            if (isEdit) {
              await deleteTag(selectedRecordTag.tagId, false);
            }
            await saveTag(tagFormData);
            tagAddSuccess();
            onClose();
          } catch (e) {
            if (e.httpResponse) {
              const response = await e.httpResponse.json();
              if (
                e.httpResponse.status === 400 &&
                response.message.includes('Mac Address already assigned to a user in organization')
              ) {
                setRequstError('MAC Address already assigned to a user in organization');
              }
              if (e.httpResponse.status === 403) {
                setRequstError('Authorization failed');
              }
              if (e.httpResponse.status === 404) {
                setRequstError('Person not found');
              }
            }
            tagAddError();
            setIsSaving(false);
          }
        }
      });
    } catch (e) {
      // Catch intentionally left empty
    }
  };

  const onDelete = async () => {
    setRequstError(undefined);
    setIsDeleting(true);
    try {
      await deleteTag(selectedRecordTag.tagId);
      tagDeleteSuccess();
      onClose();
    } catch (e) {
      if (e.httpResponse) {
        if (e.httpResponse.status === 403) {
          setRequstError('Authorization failed');
        }
        if (e.httpResponse.status === 404) {
          setRequstError('Tag not found');
        }
      }
      tagDeleteError();
      setIsDeleting(false);
    }
  };

  const macAddressLabel = (
    <span>
      MAC Address
      <Tooltip
        placement="right"
        title={
          <span>
            MAC Address from your Bluetooth tag. Please refer this{' '}
            <a target={'_blank'} href={'https://spidertrackshelp.zendesk.com/hc/en-nz'}>
              wiki page
            </a>{' '}
            for more info. Example valid MAC Address formats : 18:FD:74:00:00:00, 18FD74000000
          </span>
        }
      >
        <ExclamationCircleFilled className="ml-2" style={{ color: 'rgba(0,0,0,0.25)' }} />
      </Tooltip>
    </span>
  );

  const validMacAddressRegex = /((18([:|\-|.| ]){0,1}FD([:|\-|.| ]){0,1}74)|(48([:|\-|.| ]){0,1}A9([:|\-|.| ]){0,1}8A)|(DC([:|\-|.| ]){0,1}2C([:|\-|.| ]){0,1}6E)|(2C([:|\-|.| ]){0,1}C8([:|\-|.| ]){0,1}1B)|(CC([:|\-|.| ]){0,1}2D([:|\-|.| ]){0,1}E0)|(74([:|\-|.| ]){0,1}4D([:|\-|.| ]){0,1}28)|(D4([:|\-|.| ]){0,1}CA([:|\-|.| ]){0,1}6D)|(4C([:|\-|.| ]){0,1}5E([:|\-|.| ]){0,1}0C)|(E4([:|\-|.| ]){0,1}8D([:|\-|.| ]){0,1}8C)|(64([:|\-|.| ]){0,1}D1([:|\-|.| ]){0,1}54)|(48([:|\-|.| ]){0,1}8F([:|\-|.| ]){0,1}5A)|(C4([:|\-|.| ]){0,1}AD([:|\-|.| ]){0,1}34)|(6C([:|\-|.| ]){0,1}3B([:|\-|.| ]){0,1}6B)|(00([:|\-|.| ]){0,1}0C([:|\-|.| ]){0,1}42)|(B8([:|\-|.| ]){0,1}69([:|\-|.| ]){0,1}F4)|(08([:|\-|.| ]){0,1}55([:|\-|.| ]){0,1}31))(([:|\-|.| ]){0,1}([0-9A-F]){2}){3}$/;
  const macAddressValidationRules = [
    {
      required: true,
      message: 'MAC Address is required'
    },
    {
      pattern: validMacAddressRegex,
      message: 'MAC Address is not identified'
    },
    {
      validator: (rule, value, callback) => {
        const formattedMacAddress = value
          .replaceAll(':', '')
          .replaceAll('-', '')
          .replaceAll('.', '')
          .replaceAll(' ', '');
        const duplicateTag = tagData.find(
          tag => tag.macAddress.replaceAll(':', '') === formattedMacAddress
        );
        if (duplicateTag) {
          callback(new Error('Duplicate Tags'));
        }
        callback();
      },
      message: 'MAC Address already assigned to a user in organization'
    }
  ];

  return (
    <Form
      onSubmit={onSave}
      layout="vertical"
      hideRequiredMark={false}
      style={{ width: '308px', margin: '30px 0 0 32px' }}
    >
      <TagFormDropdown
        name="personId"
        label="Person"
        getFieldDecorator={getFieldDecorator}
        dropdownOptions={tagData.map(tag => {
          return {
            actualValue: tag.personId,
            displayedValue: tag.name
          };
        })}
        placeholder="Select person to assign"
        validationRules={[
          {
            required: true,
            message: 'Person is required'
          }
        ]}
        disabled={true}
        initialValue={selectedRecordTag ? selectedRecordTag.personId : undefined}
      />
      <TagFormInput
        name="macAddress"
        label={macAddressLabel}
        getFieldDecorator={getFieldDecorator}
        validationRules={macAddressValidationRules}
        handleFieldChange={handleMacAddressFieldChange}
        initialValue={selectedRecordTag ? selectedRecordTag.macAddress : undefined}
      />
      {requstError && <Form.Item validateStatus="error" help={requstError}></Form.Item>}
      <Form.Item style={{ textAlign: 'right' }} className="mt-3">
        <Row>
          {isEdit && (
            <Button
              disabled={isDeleting}
              className="mx-2 px-3"
              type="danger"
              htmlType="submit"
              size="large"
              onClick={onDelete}
              style={{ width: '90px', float: 'left', marginLeft: '0 !important' }}
            >
              {isDeleting ? (
                <span className="px-2">
                  <Spin size="small" />
                </span>
              ) : (
                'Remove'
              )}
            </Button>
          )}
          <Button
            className="mx-2 px-3"
            type="secondary"
            size="large"
            onClick={onClose}
            style={{ width: '85px' }}
          >
            Cancel
          </Button>
          <Button
            disabled={isSaving}
            className="mx-2 px-4"
            type="primary"
            htmlType="submit"
            size="large"
            style={{ width: '85px' }}
          >
            {isSaving ? (
              <span className="px-2">
                <Spin size="small" />
              </span>
            ) : (
              'Save'
            )}
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
};

TagForm.propTypes = {
  form: PropTypes.object.isRequired,
  tagData: PropTypes.array.isRequired,
  isEdit: PropTypes.bool.isRequired,
  saveTag: PropTypes.func.isRequired,
  deleteTag: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedRecordTag: PropTypes.object
};

export default Form.create()(TagForm);
