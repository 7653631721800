import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import Icon from '../Icon';
import FullScreenIcon from './FullScreenIcon';

import { GOOGLE_MAP_CONTAINER } from '../../GoogleMap/constants';
import { MenuIcon } from '../MenuIcon';

const FullScreen = ({ toggleFullScreen }) => (
  <Tooltip
    placement="left"
    title="Fullscreen"
    onClick={toggleFullScreen}
    getPopupContainer={() => document.getElementById(GOOGLE_MAP_CONTAINER)}
  >
    <MenuIcon>
      <Icon height={18} width={18} type={FullScreenIcon} />
    </MenuIcon>
  </Tooltip>
);

FullScreen.propTypes = {
  toggleFullScreen: PropTypes.func
};

export default FullScreen;
