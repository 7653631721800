import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React, { FC } from 'react';
import CheckboxGroup from 'antd/lib/checkbox/Group';

interface NotificationMethodSelectorProps extends FormComponentProps {
  notifyMethodOptions: { label: string; value: string; disabled: boolean }[];
}

export const NotificationMethodSelector: FC<NotificationMethodSelectorProps> = ({
  notifyMethodOptions,
  form
}) => {
  return (
    <Form.Item label={'Type'} style={{ textAlign: 'left' }} className="mt-3">
      {form.getFieldDecorator('notifyMethod', {
        initialValue: [],
        rules: [
          {
            required: true,
            message: `Please select a notification type`
          }
        ]
      })(<CheckboxGroup options={notifyMethodOptions} />)}
    </Form.Item>
  );
};

export default NotificationMethodSelector;
