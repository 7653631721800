import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Typography } from 'antd';

const { Text } = Typography;

export const InfoWindowRow = ({ title, content }) => (
  <Row>
    <Col span={8} className="align-right py-1">
      <Text strong={true}>{title}</Text>
    </Col>
    <Col span={16} className="align-left py-1 pl-3">
      {content}
    </Col>
  </Row>
);

InfoWindowRow.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string
};

InfoWindowRow.displayName = 'InfoWindowRow';

export default InfoWindowRow;
