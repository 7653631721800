import { createSlice } from '@reduxjs/toolkit';
import { getInstance } from '../../common/api/spidertracks-sdk';
import { throwIfRequestError } from '../../helpers';

const initialState = {
  aircraft: {},
  flightStatus: {},
  selectedDisplayPoint: undefined,
  types: {}
};

export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    clearSelectedDisplayPoint: state => {
      state.selectedDisplayPoint = undefined;
    },
    setEvents: (state, { payload }) => {
      if (payload.items.length) {
        const aircraftId = payload.items[0].aircraftId;
        if (!state.aircraft[aircraftId]) {
          state.aircraft[aircraftId] = {};
        }
        for (const event of payload.items) {
          state.aircraft[aircraftId][event.eventId] = event;
        }
      }
    },
    // TODO: would it be better to associate this with organisation rather than track?
    setEventTypes: (state, { payload: { trackId, eventTypes } }) => {
      state.types[trackId] = eventTypes;
    },
    // TODO: it'd be easy to argue this belongs under the tracks slice...
    setFlightStatus: (state, { payload: { trackId, flightStatus } }) => {
      state.flightStatus[trackId] = flightStatus;
    },
    setSelectedDisplayPoint: (state, { payload }) => {
      state.selectedDisplayPoint = payload;
    }
  }
});

export const {
  clearSelectedDisplayPoint,
  setEvents,
  setEventTypes,
  setFlightStatus,
  setSelectedDisplayPoint
} = eventsSlice.actions;

export const fetchEvents = (aircraftId, startTimestamp, endTimestamp) => async dispatch => {
  try {
    const SpidertracksSDK = getInstance();
    const events = await SpidertracksSDK.getEventService().getEventsByAircraftAndTime(
      aircraftId,
      startTimestamp,
      endTimestamp
    );
    throwIfRequestError(
      events,
      `Failed to fetch events for aircraft ${aircraftId} (start: ${startTimestamp}, end: ${endTimestamp}).`
    );
    dispatch(setEvents(events));
  } catch (e) {
    // TODO: fix (dispatch error state?)
    console.error(e);
  }
};

export default eventsSlice;
