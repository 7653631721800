import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getInstance } from '../../common/api/spidertracks-sdk';
import { LoadingBackground } from '../../components/LoadingBackground';
import { insightsError } from '../../helpers/globalNotifications';

export const InsightsRedirect = () => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  const authenticateAndRedirect = async () => {
    const search = new URLSearchParams(location.search);
    const returnTo = search.get('return_to');

    if (!returnTo) {
      setIsLoading(false);
      insightsError();
      return;
    }

    const decoded = decodeURIComponent(returnTo);

    // NOTE: This is a backstop, designed to catch any instances where the Sisense session has expired but
    // we are trying to return to a Spidertracks route and not a Sisense one. In a perfect world,
    // this would never happen. Possible reasons might include a user leaving a Flight Report page
    // open for long periods of time, then trying to use it again without a reload.
    //
    // In case it's not apparent, using `return_to` with a Spidertracks URL doesn't work: Sisense
    // just can't find the route, and we get `api not found` on a blank white screen.
    if (decoded.startsWith(window.location.origin)) {
      // NOTE: This could use some explaining. If we just push the `return_to` path back onto
      // browser history, it does work... but if the user happens to hit their back button instead
      // of using the provided links on the page, they end up in the same place instead of going
      // back. Even `history.replace` doesn't work, because we're essentially dealing with a fresh
      // copy of the React application that doesn't have anything in its state.
      //
      // Likewise, setting `window.location.href` also adds another state push, which mucks up the
      // back button.
      //
      // Instead, we can just manipulate the browser history back by one page. Unfortunately,
      // there's no way for JS to remove the /sisense/sso-login route entirely, so the 'forward'
      // button will still be available.
      window.history.go(-1);
      return;
    }

    try {
      const sisenseService = getInstance().getSisenseService();
      const result = await sisenseService.getSisenseToken();
      const { hash } = window.location;

      window.location.href = `${result.url}&return_to=${returnTo}${hash}`;
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      insightsError();
    }
  };

  useEffect(() => {
    authenticateAndRedirect();
  }, []);

  return isLoading ? <LoadingBackground /> : null;
};
