import React, { memo, useCallback, useContext } from 'react';
import { Tooltip } from 'antd';
import { gaEventHelper } from '@spidertracks/common';
import { MenuIcon } from '../MenuIcon';
import Star from '@material-ui/icons/Star';
import Icon from '../Icon';
import { FavouritesContext } from '../../../../../context/FavouritesContext';
import { GOOGLE_MAP_CONTAINER } from '../../GoogleMap/constants';

const gaEventSender = gaEventHelper('MapControls - Favourite Menu');

const FavourtiesButton = () => {
  const favouritesDrawerContext = useContext(FavouritesContext);

  const sendGAEvent = () => {
    let eventAction = 'Open Drawer';
    let eventLabel = 'Favourites ' + eventAction;
    gaEventSender(eventAction, eventLabel);
  };

  const openFavouritesMenu = useCallback(() => {
    sendGAEvent();
    favouritesDrawerContext.open();
  });

  return (
    <div className="map-control">
      <Tooltip
        placement="left"
        title="Favourites"
        onClick={openFavouritesMenu}
        getPopupContainer={() => document.getElementById(GOOGLE_MAP_CONTAINER)}
      >
        <MenuIcon>
          <Icon height={18} width={18} type={Star} />
        </MenuIcon>
      </Tooltip>
    </div>
  );
};

export default memo(FavourtiesButton);
