import React, { FC, useEffect } from 'react';
import { Form, Row, Select, Tooltip } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';
import { Severity } from '../../../common/api/spidertracks-sdk/private/services/NotificationConfigurationService';

interface ExcludedSeveritiesDropdownProps extends FormComponentProps {
  initialValue: SendNotificationForSeverity;
}

export const ExcludedSeveritiesDropdown: FC<ExcludedSeveritiesDropdownProps> = ({
  form,
  initialValue
}) => {
  useEffect(() => {
    form.resetFields();
  }, [initialValue]);

  return (
    <Form.Item
      label={
        <span>
          Notify for:
          <Tooltip
            placement="right"
            title={
              <span>
                For events that don’t have severity configured, notifications will always be sent.
              </span>
            }
          >
            <ExclamationCircleFilled className="ml-2" style={{ color: 'rgba(0,0,0,0.25)' }} />
          </Tooltip>
        </span>
      }
      style={{ textAlign: 'left' }}
      className="mt-3"
    >
      <Row>
        {form.getFieldDecorator('sendNotificationForSeverity', {
          initialValue,
          rules: [
            {
              required: false
            }
          ]
        })(
          <Select
            key="sendNotificationForSeverity"
            style={{ width: '300px' }}
            placeholder={'Select severity'}
            optionLabelProp="label"
          >
            <Select.Option
              key={SendNotificationForSeverity.HIGH}
              style={{ width: '100%' }}
              value={SendNotificationForSeverity.HIGH}
              label={getSendNotifcationForSeverityLabel(SendNotificationForSeverity.HIGH)}
            >
              {getSendNotifcationForSeverityLabel(SendNotificationForSeverity.HIGH)}
            </Select.Option>
            <Select.Option
              key={SendNotificationForSeverity.MEDIUM_AND_HIGH}
              style={{ width: '100%' }}
              value={SendNotificationForSeverity.MEDIUM_AND_HIGH}
              label={getSendNotifcationForSeverityLabel(
                SendNotificationForSeverity.MEDIUM_AND_HIGH
              )}
            >
              {getSendNotifcationForSeverityLabel(SendNotificationForSeverity.MEDIUM_AND_HIGH)}
            </Select.Option>
            <Select.Option
              key={SendNotificationForSeverity.ALL}
              style={{ width: '100%' }}
              value={SendNotificationForSeverity.ALL}
              label={getSendNotifcationForSeverityLabel(SendNotificationForSeverity.ALL)}
            >
              {getSendNotifcationForSeverityLabel(SendNotificationForSeverity.ALL)}
            </Select.Option>
          </Select>
        )}
      </Row>
    </Form.Item>
  );
};

export enum SendNotificationForSeverity {
  HIGH = 'high',
  MEDIUM_AND_HIGH = 'medium_and_high',
  ALL = 'all'
}

export function mapSendNotificationForSeverityToExludedSeverities(
  sendNotificationForSeverity: SendNotificationForSeverity
): Severity[] {
  if (sendNotificationForSeverity == undefined) {
    return [];
  }
  switch (sendNotificationForSeverity) {
    case SendNotificationForSeverity.HIGH:
      return [Severity.MEDIUM, Severity.LOW];
    case SendNotificationForSeverity.MEDIUM_AND_HIGH:
      return [Severity.LOW];
    case SendNotificationForSeverity.ALL:
      return [];
  }
}

export function mapExcludedSeveritiesToSendNotificationForSeverity(
  severities: Severity[]
): SendNotificationForSeverity {
  if (
    !severities.includes(Severity.HIGH) &&
    severities.includes(Severity.MEDIUM) &&
    severities.includes(Severity.LOW) &&
    !severities.includes(Severity.NOT_SPECIFIED)
  ) {
    return SendNotificationForSeverity.HIGH;
  }
  if (
    !severities.includes(Severity.HIGH) &&
    !severities.includes(Severity.MEDIUM) &&
    severities.includes(Severity.LOW) &&
    !severities.includes(Severity.NOT_SPECIFIED)
  ) {
    return SendNotificationForSeverity.MEDIUM_AND_HIGH;
  }
  return SendNotificationForSeverity.ALL;
}

export function getSendNotifcationForSeverityLabel(
  sendNotificationForSeverity: SendNotificationForSeverity
): string {
  switch (sendNotificationForSeverity) {
    case SendNotificationForSeverity.HIGH:
      return 'High severity';
    case SendNotificationForSeverity.MEDIUM_AND_HIGH:
      return 'Medium and High severity';
    case SendNotificationForSeverity.ALL:
      return 'All events';
  }
}
