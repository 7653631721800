export const LAT_BOUNDS = {
  min: -85,
  max: 85
};

export const SIGAIRMET_TYPES = ['SIGMET', 'AIRMET', 'CONVECTIVE SIGMET'];

interface HazardDisplayProps {
  [key: string]: {
    polygon: {
      color: string;
    };
    marker: {
      label: string;
      color: string;
    };
  };
}

export const HAZARD_DISPLAY_PROPS: HazardDisplayProps = {
  TURB: {
    polygon: {
      color: '#FF0000'
    },
    marker: {
      label: 'TURB',
      color: '#FF0000'
    }
  },
  CONVECTIVE: {
    polygon: {
      color: '#FFF133'
    },
    marker: {
      label: 'CONVECTIVE',
      color: '#FFF133'
    }
  },
  'EMBED TS': {
    polygon: {
      color: '#FFF133'
    },
    marker: {
      label: 'TS',
      color: '#FFF133'
    }
  },
  ICE: {
    polygon: {
      color: '#0096FF'
    },
    marker: {
      label: 'ICE',
      color: '#0096FF'
    }
  },
  'ICE (FZRA)': {
    polygon: {
      color: '#0096FF'
    },
    marker: {
      label: 'ICE (FZRA)',
      color: '#0096FF'
    }
  },
  IFR: {
    polygon: {
      color: '#FF7433'
    },
    marker: {
      label: 'IFR',
      color: '#FF7433'
    }
  },
  'MTN WAVES': {
    polygon: {
      color: '#00E099'
    },
    marker: {
      label: 'MTW',
      color: '#00E099'
    }
  },
  'MTN OBSC': {
    polygon: {
      color: '#248000'
    },
    marker: {
      label: 'MTN OBSCN',
      color: '#248000'
    }
  },
  TS: {
    polygon: {
      color: '#FFF133'
    },
    marker: {
      label: 'TS',
      color: '#FFF133'
    }
  },
  'VA ERUPTION': {
    polygon: {
      color: '#CC00FF'
    },
    marker: {
      label: 'VA',
      color: '#CC00FF'
    }
  }
};
