const MIN_LAT = -90;
const MAX_LAT = 90;
const MIN_LON = -180;
const MAX_LON = 180;

interface CodesDict {
  [key: string]: number;
}

interface DecodedGeoHash {
  latitude: number;
  longitude: number;
  error: DecodedGeoHashError;
}

interface DecodedGeoHashError {
  latitude: number;
  longitude: number;
}

const BASE32_CODES_DICT: CodesDict = {
  '0': 0,
  '1': 1,
  '2': 2,
  '3': 3,
  '4': 4,
  '5': 5,
  '6': 6,
  '7': 7,
  '8': 8,
  '9': 9,
  b: 10,
  c: 11,
  d: 12,
  e: 13,
  f: 14,
  g: 15,
  h: 16,
  j: 17,
  k: 18,
  m: 19,
  n: 20,
  p: 21,
  q: 22,
  r: 23,
  s: 24,
  t: 25,
  u: 26,
  v: 27,
  w: 28,
  x: 29,
  y: 30,
  z: 31
};

const decode_bbox = (hash_string: string) => {
  var isLon = true,
    maxLat = MAX_LAT,
    minLat = MIN_LAT,
    maxLon = MAX_LON,
    minLon = MIN_LON,
    mid;

  var hashValue = 0;
  for (var i = 0, l = hash_string.length; i < l; i++) {
    var code = hash_string[i].toLowerCase();
    hashValue = BASE32_CODES_DICT[code];

    for (var bits = 4; bits >= 0; bits--) {
      var bit = (hashValue >> bits) & 1;
      if (isLon) {
        mid = (maxLon + minLon) / 2;
        if (bit === 1) {
          minLon = mid;
        } else {
          maxLon = mid;
        }
      } else {
        mid = (maxLat + minLat) / 2;
        if (bit === 1) {
          minLat = mid;
        } else {
          maxLat = mid;
        }
      }
      isLon = !isLon;
    }
  }
  return [minLat, minLon, maxLat, maxLon];
};

export const decodeGeoHash = (hashString: string): DecodedGeoHash => {
  var bbox = decode_bbox(hashString);
  var lat = (bbox[0] + bbox[2]) / 2;
  var lon = (bbox[1] + bbox[3]) / 2;
  var latErr = bbox[2] - lat;
  var lonErr = bbox[3] - lon;
  return { latitude: lat, longitude: lon, error: { latitude: latErr, longitude: lonErr } };
};
