import { Checkbox } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { set3dReplayFeatures } from '../../../redux/slice/flightExplorer';
import { get3dReplayFeatures } from '../../../redux/selectors/flightExplorer';
import styled from 'styled-components';

interface OverlayProps {
  className: string;
}

const OverlayControls = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  background-color: rgba(255, 255, 255);
  border-radius: 10px;
`;

const Overlay: React.FC<OverlayProps> = ({ className }) => {
  const dispatch = useDispatch();
  const replayFeatures = useSelector(get3dReplayFeatures);
  const handleFeatureClick = (panelName: string, value: boolean) => {
    dispatch(set3dReplayFeatures({ [panelName]: value }));
  };

  return (
    <div className={`${className}`}>
      <OverlayControls>
        <Checkbox
          data-testid="showEvents"
          value={'showEvents'}
          checked={replayFeatures['showEvents']}
          onChange={() => handleFeatureClick('showEvents', !replayFeatures['showEvents'])}
        >
          Events
        </Checkbox>
        <Checkbox
          data-testid="showWall"
          value={'showWall'}
          checked={replayFeatures['showWall']}
          onChange={() => handleFeatureClick('showWall', !replayFeatures['showWall'])}
        >
          Ribbon
        </Checkbox>
      </OverlayControls>
    </div>
  );
};

export default Overlay;
