/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useEffect, useState } from 'react';
import useScript from '../hooks/useScript';
import { LoadingBackground } from '../components/LoadingBackground';
import { ScriptLoadStatus as Status } from '../types/ScriptLoadStatus';
import { getInstance } from '../common/api/spidertracks-sdk';

export const queryReducer = (acc, aircraft) => [...acc, { value: aircraft[0], label: aircraft[0] }];

/**
 * getSisense()
 * Description:
 * - Creates an instance of SisenseFrame with custom confirguration
 * - Calling render() will apply the above configuration to the existing iFrame element
 * - Appends to existing iframe DOM element
 * Note: Sisense is working on adding type definitions 04/20.
 */

export const getSisense = () => {
  try {
    const { SisenseFrame } = window['sisense.embed'];
    const insightsDashboardId = window.env.sisense.dashboards.find(
      dashboard => dashboard.name === 'insights'
    ).id;
    const sisense = new SisenseFrame({
      url: window.env.sisense.endpoint,
      dashboard: insightsDashboardId,
      settings: {
        showHeader: false,
        showToolbar: false,
        showLeftPane: false,
        showRightPane: false
      },
      element: document.getElementById('sisense_iframe'),
      id: 'sisense-iframe'
    });

    return sisense;
  } catch (e) {
    console.log(e);
  }
};

/**
 * Todo: Add Sisense types when its available
 */

export const initialContext = {
  sisense: {},
  aircraftFilters: [{ value: '...Loading', label: '...Loading' }],
  status: Status.idle,
  sisenseDashboardReady: false,
  sisenseDashboardBeingExported: false,
  setSisenseDashboardBeingExported: () => {}
};

export const Context = createContext(initialContext);

export const Provider = ({ children }) => {
  const status = useScript(`${window.env.sisense.endpoint}/js/frame.js`, 'sisense_script');
  const [sisense, setSisense] = useState();
  const [sisenseDashboardReady, setSisenseDashboardReady] = useState(false);
  const [sisenseDashboardBeingExported, setSisenseDashboardBeingExported] = useState(false);
  const [aircraftFilters, setAircraftFilters] = useState([
    { value: '...Loading', label: '...Loading' }
  ]);
  const [eventFilterValues, setEventFilterValues] = useState([
    { value: '...Loading', label: '...Loading' }
  ]);
  const sisenseService = getInstance().getSisenseService();

  useEffect(() => {
    if (status === 'ready' && !sisense) {
      setSisense(getSisense());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    const renderSisense = async sisense => {
      await sisense.render();
    };

    if (sisense) {
      renderSisense(sisense);
      // Define an event handler

      // Subscribe to the dashboard loaded event
      const { enums } = window['sisense.embed'];
      sisense.dashboard.on(enums.DashboardEventType.LOADED, args => {
        setSisenseDashboardReady(true);
        console.log('Dashboard ' + args.dashboard.oid + ' loaded!');
      });
    }
  }, [sisense]);

  useEffect(() => {
    const fetchAircrafts = async () => {
      try {
        const query = await sisenseService.querySisenseElasticube({
          datasource: 'safety_events_production_data_model',
          metadata: [
            {
              dim: '[joined_safety_events.vehicle_registration_number]'
            }
          ]
        });

        setAircraftFilters(query.reduce(queryReducer, []));
      } catch (e) {
        console.log(e);
      }
    };

    const fetchEvents = async () => {
      try {
        const query = await sisenseService.querySisenseElasticube({
          datasource: 'safety_events_production_data_model',
          metadata: [
            {
              dim: '[joined_safety_events.event_name]'
            }
          ]
        });
        const values = query.map(e => {
          return { value: e[0], label: e[0] };
        });
        setEventFilterValues(values);
      } catch (e) {
        console.log(e);
      }
    };

    if (sisenseDashboardReady) {
      fetchAircrafts();
      fetchEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sisenseDashboardReady]);

  return (
    <Context.Provider
      value={{
        sisense,
        aircraftFilters,
        eventFilterValues,
        status,
        sisenseDashboardReady,
        sisenseDashboardBeingExported,
        setSisenseDashboardBeingExported
      }}
    >
      {status === 'ready' ? <React.Fragment>{children}</React.Fragment> : <LoadingBackground />}
    </Context.Provider>
  );
};

export const Consumer = Context.Consumer;
