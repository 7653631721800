import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import AircraftTrackPoint from './AircraftTrackPoint';
import { getLatLng } from '../../../../../../redux/selectors/aircraftData';
import { getMarkerIcon } from '../../../utils/drawing/marker';

const TrackWithEvents = ({ points, onMarkerClick, aircraftProperties, zIndex }) => {
  const getOtherMarkers = useCallback(
    () =>
      points.map(
        keyPoint =>
          !!keyPoint && (
            <AircraftTrackPoint
              key={`clustered-${keyPoint.id}`}
              onClick={onMarkerClick(keyPoint)}
              position={getLatLng(keyPoint)}
              icon={getMarkerIcon(keyPoint)}
              clusterer={false}
              trackStatus={aircraftProperties.trackStatus}
              pointStatus={keyPoint.trackStatus}
              radius={keyPoint.radius}
              zIndex={zIndex}
            />
          )
      ),
    [aircraftProperties.trackStatus, points, onMarkerClick, zIndex]
  );

  return <Fragment>{!!points && getOtherMarkers()}</Fragment>;
};

TrackWithEvents.propTypes = {
  points: PropTypes.array,
  onMarkerClick: PropTypes.func,
  aircraftProperties: PropTypes.object,
  zIndex: PropTypes.number
};

export default TrackWithEvents;
