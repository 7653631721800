import React from 'react';
import { notification, Spin, Modal } from 'antd';

const noticeDuration = 2.0;
const delayToCloseNotification = 2000;
const delayToOpenNotification = 3000;

notification.config({
  duration: noticeDuration,
  top: 55
});

export const eventsExplorerApi500 = () =>
  notification.error({
    message: 'Error',
    description: (
      <>
        An error has occurred while processing your request. Contact{' '}
        <a href="mailto:suppor@spidertracks.com">suppor@spidertracks.com</a> if this problem
        persists.
      </>
    ),
    key: 'updatable',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });

export const historyTableDeleteError404 = () =>
  notification.error({
    message: 'Error',
    description: `The track(s) you are trying to delete cannot be found`,
    key: 'updatable',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });

export const historyTableDeleteErrorAuth = () =>
  notification.error({
    message: 'Error',
    description: `You do not have permission to delete this track`,
    key: 'updatable',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });

export const historyTableDeleteError = () =>
  notification.error({
    message: 'Error',
    description: `Unable to delete track(s) - please try again`,
    key: 'updatable',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });

export const historyTableDataError = () =>
  notification.error({
    message: 'Error',
    description: `Unable to retrieve Flight History data`,
    key: 'updatable',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });

export const flightReplayStatusError = () =>
  notification.error({
    message: 'Error',
    description: `Unable to retrieve 3D Flight Replay status`,
    key: 'updatable',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });

export const insightsDashboardUnavailableError = () =>
  notification.error({
    message: 'Error',
    description: `Insights dashboard still loading`,
    key: 'updatable',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });

export const duplicateRegistrationError = (registration: string) =>
  notification.error({
    message: 'Error',
    description: `${registration} is already registered. Please enter a unique aircraft registration.`,
    key: 'updatable',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });

export const partialRegistrationError = () =>
  notification.error({
    message: 'Error',
    description:
      'The Spider didn’t complete registration. Please contact support to complete the process',
    key: 'updatable',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });

export const cannotChangeSpiderError = () =>
  Modal.warning({
    title: 'Change Spider',
    content: 'We cannot move the Spider without an aircraft to assign to',
    style: { backgroundColor: '#FFFFF' }
  });

export const aircraftAddFormSuccess = (): void =>
  notification.success({
    message: 'Success!',
    description: 'Aircraft was added successfully',
    key: 'updatable',
    style: { backgroundColor: '#FFFFF' }
  });

export const aircraftEditFormSuccess = (): void =>
  notification.success({
    message: 'Success!',
    description: 'Aircraft was edited successfully',
    key: 'updatable',
    style: { backgroundColor: '#FFFFF' }
  });

export const aircraftAddFormError = (): void =>
  notification.error({
    message: 'Error',
    description: 'Unable to add an aircraft, please contact customer support',
    key: 'updatable',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });

export const aircraftDeleteSuccess = (): void =>
  notification.success({
    message: 'Success!',
    description: 'Aircraft was deleted successfully.',
    key: 'updatable',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });

export const aircraftDeleteError = (): void =>
  notification.error({
    message: 'Error',
    description: 'Unable to delete the aircraft.',
    key: 'updatable',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });

export const unableToSaveError = (): void =>
  notification.error({
    message: 'Error',
    description: 'Unable to save changes.',
    key: 'updatable',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });

export const spiderAddedSuccess = (): void =>
  notification.success({
    message: 'Success!',
    description: 'Spider was added successfully',
    key: 'updatable',
    style: { backgroundColor: '#FFFFF' }
  });

export const spiderAssignedSuccess = (registration: string): void =>
  notification.success({
    message: 'Success!',
    description: `Your Spider was assigned to aircraft: ${registration}`,
    key: 'updatable',
    style: { backgroundColor: '#FFFFF' }
  });

export const serialFormError = (): void =>
  notification.error({
    message: 'Error',
    description: 'That serial number is not available. Please try typing again',
    key: 'updatable',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });

export const serialFormValidationInfo = (): void =>
  notification.info({
    message: 'Info',
    description: 'That serial number is already available in your list',
    key: 'updatable',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });

export const getSpiderSettingsError = (): void =>
  notification.error({
    message: 'Error',
    description: 'Unable to retrieve Spider settings data.',
    style: { backgroundColor: '#FFFFF' }
  });

export const saveSpiderSettingsError = (): void =>
  notification.error({
    message: 'Error',
    description: 'Unable to save Spider settings.',
    style: { backgroundColor: '#FFFFF' }
  });

export const offlineError = (): void => {
  const key = 'offline';
  notification.error({
    key,
    message: 'You appear to be offline',
    description: 'Please try again later',
    style: { backgroundColor: '#FFFFF' }
  });
};

export const weatherUnknownError = (): void => {
  notification.error({
    message: 'Weather cannot be loaded',
    description: 'Please try again later',
    style: { backgroundColor: '#FFFFF' }
  });
};

export const replayError = (): void =>
  notification.error({
    message: 'Error',
    description: 'Unable to replay flight, please try again later.',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });

export const insightsError = (): void =>
  notification.error({
    message: 'Error',
    description: 'Unable to show insights. Try reloading the page.',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });

export const flightReportChartError = (): void =>
  notification.error({
    message: 'Error',
    description: 'Unable to display chart. Try reloading the page.',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });

export const HDFlightTrackingSupportNoticeInfo = (): void =>
  notification.info({
    message: 'Info',
    description: 'Please validate that your plan supports HD Flight Tracking',
    key: 'updatable',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });

export const updateFlightEventConfigurationError = (): void =>
  notification.error({
    message: 'Something went wrong! ',
    description: 'Please try again later',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });

const openLoadingMessage = (key: string, thing: string) => {
  notification.info({
    key,
    duration: 0,
    message: `Loading ${thing}`,
    description: 'Please Wait',
    icon: React.createElement(Spin)
  });
};

export const linkCopied = (): void => {
  const key = 'linkCopied';
  notification.close(key);
  notification.info({
    key,
    message: 'Link copied.',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });
};
export const geofenceAddFormSuccess = (): void =>
  notification.success({
    message: 'Success!',
    description: 'You have successfully created a geofence',
    key: 'geofenceAddSuccess',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });

export const geofenceAddFormError = (): void =>
  notification.error({
    message: 'Something went wrong!',
    description: 'We could not save the geofence',
    key: 'geofenceAddError',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });

export const geofenceDeleteSuccess = (): void =>
  notification.success({
    message: 'Success!',
    description: 'The geofence has been deleted',
    key: 'geofenceDeleteSuccess',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });

export const geofenceDeleteError = (): void =>
  notification.error({
    message: 'Something went wrong!',
    description: 'We could not delete the geofence',
    key: 'geofenceDeleteError',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });

export const eventRuleCreateSuccess = (): void =>
  notification.success({
    message: 'Success!',
    description: 'The event has been created',
    key: 'eventRuleCreateSuccess',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });

export const eventRuleCreateError = (): void =>
  notification.error({
    message: 'Something went wrong!',
    description: 'We could not create the event',
    key: 'eventRuleCreateError',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });

export const eventRuleUpdateSuccess = (): void =>
  notification.success({
    message: 'Success!',
    description: 'The event has been updated',
    key: 'eventRuleUpdateSuccess',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });

export const eventRuleUpdateError = (): void =>
  notification.error({
    message: 'Something went wrong!',
    description: 'We could not update the event',
    key: 'eventRuleUpdateError',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });

export const eventRuleDeleteSuccess = (): void =>
  notification.success({
    message: 'Success!',
    description: 'The events have been deleted',
    key: 'eventRuleDeleteSuccess',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });

export const eventRuleDeleteError = (): void =>
  notification.error({
    message: 'Something went wrong!',
    description: 'We could not delete the event',
    key: 'eventRuleDeleteError',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });

export const trackFetchError = () =>
  notification.error({
    message: 'Error',
    description: 'Unable to load flight data. Try reloading the page.',
    duration: 10,
    style: { backgroundColor: '#FFFFF' }
  });

export const trackNotFound = () =>
  notification.error({
    message: 'Error',
    description:
      'Unable to load flight data. Please contact your administrator and confirm you have access to this flight information.',
    duration: 10,
    style: { backgroundColor: '#FFFFF' }
  });

export const showErrorNotification = (description: string, duration: number = 10) =>
  notification.error({
    message: 'Error',
    description,
    duration,
    key: `error${Date.now()}`,
    style: { backgroundColor: '#FFFFF' }
  });

export const showSuccessNotification = (description: string) =>
  notification.success({
    message: 'Success!',
    description,
    key: `success${Date.now()}`,
    style: { backgroundColor: '#FFFFF' }
  });

// close the loading message after a duration - to ensure the message is always visible
export const closeLoadingMessage = (key: string) =>
  setTimeout(() => {
    notification.close(key);
  }, delayToCloseNotification);

/**
 * Display a Loading Message on screen
 * @param key a string to use to identify the loading box if it is called more than once
 * @param thing the string to display after 'Loading'
 */
export const loadingMessage = (key: string, thing: string): Function => {
  openLoadingMessage(key, thing);
  return () => {
    closeLoadingMessage(key);
  };
};

/**
 * Display a Loading Message on screen after a delay of 3 seconds
 * @param key a string to use to identify the loading box if it is called more than once
 * @param thing the string to display after 'Loading'
 */
export const loadingMessageWithDelay = (key: string, thing: string): Function => {
  const timerId = setTimeout(() => openLoadingMessage(key, thing), delayToOpenNotification);
  return () => {
    clearTimeout(timerId);
    closeLoadingMessage(key);
  };
};

export const tagAddSuccess = (): void =>
  notification.success({
    message: 'Success!',
    description: 'You have successfully added a tag',
    key: 'tagAddSuccess',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });

export const tagAddError = (): void =>
  notification.error({
    message: 'Something went wrong!',
    description: 'We could not save the tag',
    key: 'tagAddError',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });

export const tagDeleteSuccess = (): void =>
  notification.success({
    message: 'Success!',
    description: 'The tag has been deleted',
    key: 'tagDeleteSuccess',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });

export const tagDeleteError = (): void =>
  notification.error({
    message: 'Something went wrong!',
    description: 'We could not delete the tag',
    key: 'geofenceDeleteError',
    duration: 6,
    style: { backgroundColor: '#FFFFF' }
  });
