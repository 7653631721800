/* eslint-disable @typescript-eslint/no-explicit-any */
import { fetchWrapper, checkAuth } from '@spidertracks/common';

const ENDPOINTS = {
  BETA_FEATURES: 'users',
  SPIDERTXT: 'messages/search',
  SOS: 'sos/latest',
  CONTACTS: 'tracks?contacts=true=&trackIds='
};

/**
 * Todo: add types for data
 */

export const fetchUnreadSpidertxt = () =>
  fetchWrapper({
    url: `${ENDPOINTS.SPIDERTXT}?unread=true&body=false`,
    handleBody: (data: any, resolve: Function, reject: Function) => {
      if (data && checkAuth(data)) {
        return resolve(data);
      }
      return reject(data);
    },
    handleError: (error: Error, reject: Function) => reject(error)
  });

export const fetchSosNotifications = () =>
  fetchWrapper({
    url: ENDPOINTS.SOS,
    handleBody: (data: any, resolve: Function, reject: Function) => {
      if (data && checkAuth(data)) {
        return resolve(data);
      }
      return reject(data);
    },
    handleError: (error: Error, reject: Function) => reject(error)
  });

export const fetchContactDetails = (trackIdentifier: string) =>
  fetchWrapper({
    url: `${ENDPOINTS.CONTACTS}${trackIdentifier}`,
    handleBody: (data: any, resolve: Function, reject: Function) => {
      if (data && checkAuth(data)) {
        return resolve(data);
      }
      return reject(data);
    },
    handleError: (error: Error, reject: Function) => reject(error)
  });

export default {
  fetchUnreadSpidertxt,
  fetchSosNotifications,
  fetchContactDetails
};
