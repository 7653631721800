import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { fetchLatestPublicTracksByAircraftIds } from '../../redux/slice/tracks';
import { setIsLoading } from '../../redux/slice/loading';
import PublicMapContainer from './PublicMapContainer';
import { getSelectedTrack, isLastPointInTrack } from '../../redux/selectors/aircraftData';
import { getSelectedMarkerPoint } from '../../redux/selectors/mapData';
import { selectLatestPointInSelectedTrack } from '../../redux/reducers/aircraftReducer/thunk';
import { THIRTY_SECONDS } from '../../common/constants';

export const SharedTracks = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const ref = useRef();
  const selectedTrack = useSelector(getSelectedTrack);
  const selectedMarkerPoint = useSelector(getSelectedMarkerPoint);

  const [expiry, aircraftIds] = atob(token).split(/,(.+)/);

  useEffect(() => {
    const init = async () => {
      dispatch(setIsLoading(true));
      await dispatch(fetchLatestPublicTracksByAircraftIds(aircraftIds));
      dispatch(setIsLoading(false));
    };
    init();

    const intervalId = setInterval(() => {
      Promise.all([dispatch(fetchLatestPublicTracksByAircraftIds(aircraftIds))]);
    }, THIRTY_SECONDS);

    return () => clearInterval(intervalId);
  }, [aircraftIds, dispatch, fetchLatestPublicTracksByAircraftIds]);

  useEffect(() => {
    if (
      selectedTrack &&
      selectedMarkerPoint &&
      ref.current &&
      ref.current.selectedTrack &&
      ref.current.selectedTrack.trackId === selectedTrack.trackId &&
      isLastPointInTrack(ref.current.selectedTrack, selectedMarkerPoint)
    ) {
      dispatch(selectLatestPointInSelectedTrack());
    }
    ref.current = {
      selectedTrack
    };
  }, [selectedTrack]);

  return expiry > Date.now() && <PublicMapContainer />;
};

export default SharedTracks;
