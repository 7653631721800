import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { EventClass } from '../constants';

const mapContextDefault = {
  events: { displayEventClasses: [EventClass.CAS] },
  strictPointGrouping: { displayFullTrack: false }
};

const MapContext = createContext(mapContextDefault);
const { Provider } = MapContext;

const MapProvider = ({ children, context = mapContextDefault }) => {
  return <Provider value={context}>{children}</Provider>;
};

MapProvider.propTypes = {
  children: PropTypes.node,
  context: PropTypes.object
};

export { MapContext, MapProvider };
