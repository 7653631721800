import { lockAircraftToGround as lockAircraftToGroundEvents } from './lockAircraftToGroundEvents';
import { lockAircraftToGround as lockAircraftToGroundLegacy } from './lockAircraftToGroundLegacy';
import { GroundEvent } from './lockToGroundTypes';
import { Ahrs, Position } from '../../../common/api/spidertracks-sdk/types/FlightData';
import { FlightDataSample } from '../../../types/FlightData';

export interface OutputData {
  gps: Position[];
  ahrs: Ahrs[];
}

export async function lockToGround(
  takeOffAndLandings: GroundEvent[],
  flightData: FlightDataSample[],
  terrain: number[],
  isFixedWing: boolean,
  firstBlock: boolean,
  forceUseLegacy: boolean
): Promise<FlightDataSample[]> {
  // Copy the ground events otherwise when we pop off the front, we affect other things
  // sharing the array
  const groundEvents = [...takeOffAndLandings];
  if (
    forceUseLegacy ||
    (groundEvents.length === 0 && flightData.some(p => p.aglMetres && p.aglMetres > 100))
  ) {
    const { positions, ahrs } = flightData.reduce(
      (acc, sample) => {
        acc.positions.push({
          timestamp: sample.sampleTimeEpochMillis,
          latitude: sample.latitude,
          longitude: sample.longitude,
          altitude: sample.altitudeMetres
        });
        acc.ahrs.push({
          timestamp: sample.sampleTimeEpochMillis,
          roll: sample.rollRadians,
          pitch: sample.pitchRadians,
          yaw: sample.yawRadians
        });
        return acc;
      },
      { positions: [] as Position[], ahrs: [] as Ahrs[] }
    );

    const { gps: lockedPositions, ahrs: lockedAHRS } = await lockAircraftToGroundLegacy(
      positions,
      ahrs,
      terrain,
      isFixedWing,
      firstBlock
    );

    // Ensure all arrays have the same length
    const length = Math.min(flightData.length, lockedPositions.length, lockedAHRS.length);
    /**
     * Returns a new array following the structure of ChunkData where
     * - each obj contains all data from flightData
     * - each obj gets the lockedPositions and lockedAHRS data
     * - All data gets updated simultaneaously
     */
    return Array.from({ length }, (_, index) => ({
      ...flightData[index],
      latitude: lockedPositions[index].latitude,
      longitude: lockedPositions[index].longitude,
      altitudeMetres: lockedPositions[index].altitude,
      rollRadians: lockedAHRS[index].roll,
      pitchRadians: lockedAHRS[index].pitch,
      yawRadians: lockedAHRS[index].yaw,
      sampleTimeEpochMillis: Math.max(lockedPositions[index].timestamp, lockedAHRS[index].timestamp)
    }));
  }

  return await lockAircraftToGroundEvents({
    flightData,
    terrainAltitudes: terrain,
    groundEvents
  });
}
