import { HistoryTableTrack } from '../types/Track';

export const getAircraftSosStatus = (track: HistoryTableTrack): string => {
  if (track.sosStatus == 'NONE') {
    return '';
  } else {
    return `${track.sosStatus[0]}${track.sosStatus.slice(1).toLowerCase()}`;
  }
}

