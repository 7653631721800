import React, { Fragment, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { getRowClassName } from '../../common';
import TagDrawer from './TagDrawer';

export const TagTable = ({ isLoading, tags, saveTag, deleteTag }) => {
  const [tagDrawerVisibility, setEditTagDrawerVisibility] = useState(false);
  const [selectedRecordTag, setSelectedRecordTag] = useState('');
  const [tagDrawerIsEdit, setTagDrawerIsEdit] = useState(false);

  const openEditTagDrawer = useCallback(tag => e => {
    e.stopPropagation();
    setSelectedRecordTag(tag);
    if (tag.macAddress === '-') {
      setTagDrawerIsEdit(false);
    } else {
      setTagDrawerIsEdit(true);
    }

    setEditTagDrawerVisibility(true);
  });

  const onEditTagDrawerClose = () => {
    setEditTagDrawerVisibility(false);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        return nameA.localeCompare(nameB);
      }
    },
    {
      title: 'Tag ID',
      dataIndex: 'macAddress',
      key: 'macAddress',
      sorter: (a, b) => {
        const macAddressA = a.macAddress.toUpperCase();
        const macAddressB = b.macAddress.toUpperCase();

        return macAddressA.localeCompare(macAddressB);
      },
      defaultSortOrder: 'descend'
    }
  ];

  return (
    <Fragment>
      <Table
        id="tags-list"
        loading={isLoading}
        size="middle"
        columns={columns}
        dataSource={tags}
        bordered
        rowClassName={getRowClassName}
        onRow={record => ({
          onClick: openEditTagDrawer(record)
        })}
      />
      {tagDrawerVisibility && (
        <TagDrawer
          visibility={tagDrawerVisibility}
          drawerTitle={tagDrawerIsEdit ? 'Edit Tag' : 'Add Tag'}
          tagData={tags}
          isEdit={tagDrawerIsEdit}
          saveTag={saveTag}
          deleteTag={deleteTag}
          onClose={onEditTagDrawerClose}
          selectedRecordTag={selectedRecordTag}
        />
      )}
    </Fragment>
  );
};

TagTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  tags: PropTypes.array.isRequired,
  saveTag: PropTypes.func.isRequired,
  deleteTag: PropTypes.func.isRequired
};

export default TagTable;
