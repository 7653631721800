import { Point } from '../../../redux/types';

export const getLatestPointBefore = (
  trackPoints: Point[],
  trackStartTime: number,
  elapsedTimeSeconds: number
) => {
  const pointTime = trackStartTime + elapsedTimeSeconds * 1000;

  const pointsBefore = trackPoints
    .filter((point: Point) => point.timestamp <= pointTime)
    .sort((a, b) => a.timestamp - b.timestamp);

  return pointsBefore[pointsBefore.length - 1];
};
