import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getSelectedTrackFlightDataDataSource } from '../../../redux/selectors/flightExplorer';

interface OverlayProps {
  className: string;
}

const DisplayOverlay = styled.div`
  position: absolute;
  bottom: 3px;
  right: 3px;
  color: white;
  font-size: 9px;
`;

const getDisplayStringForFlightDataSource = (dataSource: string) => {
  switch (dataSource) {
    case 'spider':
      return 'SX AHRS';
    case 'platform':
      return 'SX IMU';
    case 'migration':
      return 'SX IMU';
    case 'garminG1000':
      return 'GARMIN G1000';
    default:
      return dataSource;
  }
};

const DisplaySourceOverlay: React.FC<OverlayProps> = ({ className }) => {
  const selectedTrackFlightDataDataSource = useSelector(getSelectedTrackFlightDataDataSource);
  return (
    <div className={`${className}`}>
      <DisplayOverlay>
        <span> {getDisplayStringForFlightDataSource(selectedTrackFlightDataDataSource)} </span>
      </DisplayOverlay>
    </div>
  );
};

export default DisplaySourceOverlay;
