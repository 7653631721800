import React from 'react';
import { Modal } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;
export default deleteTracks => {
  confirm({
    width: '26vw',
    centered: true,
    title: (
      <strong
        style={{
          fontSize: 17,
          color: (0, 0, 0, 0.85),
          lineHeight: 1.4
        }}
      >
        {'Are you sure you want to delete these flights?'}
      </strong>
    ),
    icon: <QuestionCircleOutlined />,
    content: (
      <p style={{ width: '80%', fontSize: 14, margintop: 8, color: (0, 0, 0, 0.65) }}>
        {'You will not be able to view these flights anywhere in the app'}
      </p>
    ),
    okText: 'Yes',
    async onOk() {
      await deleteTracks();
    }
  });
};
