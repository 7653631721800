import React from 'react';
import PropTypes from 'prop-types';
import { ViewFlightIcon, FlightIcon, ViewFlightLabel } from './styled/ViewFlightIconStyle';

export const ViewFlights = ({ handleClick }) => {
  return (
    <ViewFlightIcon onClick={handleClick}>
      <FlightIcon />
      <ViewFlightLabel>View Flights</ViewFlightLabel>
    </ViewFlightIcon>
  );
};

ViewFlights.propTypes = {
  handleClick: PropTypes.func
};

export default ViewFlights;
