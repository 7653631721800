import React, { MouseEventHandler } from 'react';
import { Button } from 'antd';

export const Cancel = ({ onClose }: { onClose: MouseEventHandler<Element> }) => (
  <Button className="mx-2 px-3" size="large" onClick={onClose} style={{ width: '85px' }}>
    Cancel
  </Button>
);

Cancel.displayName = 'Cancel';
