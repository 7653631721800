import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import MapContainer from '../../components/Flying/Map/MapContainer';
import { getInstance } from '../../common/api/spidertracks-sdk';
import { selectLatestPointInSelectedTrack } from '../../redux/reducers/aircraftReducer/thunk';

import { AZ } from '../../components/Flying/AircraftList/constants';
import { savedFilter } from '../../redux/reducers/mapReducer/actions/map/index';
import { getSelectedTrack, isLastPointInTrack } from '../../redux/selectors/aircraftData';
import { getSelectedMarkerPoint } from '../../redux/selectors/mapData';
import { setLatestTracks, clearAllTracks } from '../../redux/slice/tracks';
import { fetchAircraft } from '../../redux/slice/aircraft';
import { setIsLoading } from '../../redux/slice/loading';

import { selectAircraftFromList } from '../../redux/reducers/aircraftReducer/thunk';
import MapNavigation from '../../components/Flying/Map/MapNavigation';
import MapControls from '../../components/Flying/Map/MapControls';
import PointInfoPanelContainer from '../../components/Flying/Map/PointInfoPanel/PointInfoPanelContainer';
import { MapProvider } from '../../context/MapContext';
import { setAircraftListVisibility } from '../../redux/reducers/mapReducer/actions/map';
import { UserPreferenceKey } from '../../common/api/spidertracks-sdk/types/UserData';

const REFRESH_INTERVAL = 15 * 1000; // In miliseconds

export const FlightPage = () => {
  const dispatch = useDispatch();
  const ref = useRef();
  const params = useParams();
  const SpidertracksSDK = getInstance();

  const selectedTrack = useSelector(getSelectedTrack);
  const selectedMarkerPoint = useSelector(getSelectedMarkerPoint);

  useEffect(() => {
    const getTrackDataInput = 'trackId' in params ? { trackIds: params.trackId } : params;
    const init = async () => {
      dispatch(setIsLoading(true));
      await SpidertracksSDK.saveUserPreference(UserPreferenceKey.AIRCRAFT_SORT, {
        sidebarTab: AZ
      });
      dispatch(savedFilter(AZ));
      dispatch(clearAllTracks());
      dispatch(fetchAircraft());
      const trackData = await SpidertracksSDK.getTrackHistory(getTrackDataInput);
      dispatch(setLatestTracks(trackData.items));
      dispatch(setAircraftListVisibility(false));
      dispatch(selectAircraftFromList(trackData.items[0].aircraft.id));
      dispatch(setIsLoading(false));
    };

    init();

    const getLatestTrackData = async () => {
      const trackData = await SpidertracksSDK.getTrackHistory(getTrackDataInput);
      dispatch(setLatestTracks(trackData.items));
    };

    const intervalId = setInterval(getLatestTrackData, REFRESH_INTERVAL);

    return () => {
      clearInterval(intervalId);
      dispatch(clearAllTracks());
      dispatch(setAircraftListVisibility(true));
    };
  }, [dispatch, fetchAircraft, SpidertracksSDK]);

  useEffect(() => {
    // if there is a selected track that is equal to the previous selected track
    // and has the latest point selected, then update the selected marker point.
    if (
      selectedTrack &&
      selectedMarkerPoint &&
      ref.current?.selectedTrack?.trackId === selectedTrack.trackId &&
      isLastPointInTrack(ref.current.selectedTrack, selectedMarkerPoint)
    ) {
      dispatch(selectLatestPointInSelectedTrack());
    }

    ref.current = {
      selectedTrack
    };
  }, [selectedTrack, selectedMarkerPoint]);

  const mapContext = {
    events: { displayEventClasses: [] },
    strictPointGrouping: { displayFullTrack: true }
  };

  return (
    <MapProvider context={mapContext}>
      <MapContainer>
        <MapNavigation aircraftListVisible={true} />
        <PointInfoPanelContainer />
        <MapControls weatherEnabled={false} favoritesEnabled={false} />
      </MapContainer>
    </MapProvider>
  );
};

export default FlightPage;
