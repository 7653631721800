import React, { useState, Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import { Marker, InfoWindow } from '@react-google-maps/api';
import Forecasts from './Forecasts';
import { Row, Typography } from 'antd';

const { Title } = Typography;

export function getSortedForecastsByLatest(taf) {
  const sortedForecasts = [...taf.forecasts].sort((forecast1, forecast2) => {
    if (forecast1.valid_begin !== forecast2.valid_begin) {
      return new Date(forecast2.valid_begin) - new Date(forecast1.valid_begin);
    } else {
      return new Date(forecast2.valid_end) - new Date(forecast1.valid_end);
    }
  });

  for (let i = 0; i < sortedForecasts.length; i++) {
    sortedForecasts[i].id = i;
  }

  return sortedForecasts;
}

export const TafMarker = memo(({ taf }) => {
  const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false);
  const [markerInstance, setMarkerInstance] = useState();

  const onMarkerClick = () => {
    setIsInfoWindowOpen(true);
  };

  const forecasts = getSortedForecastsByLatest(taf);

  return (
    <Fragment>
      <Marker
        icon={{
          scale: 5,
          fillColor: '#B779FF',
          fillOpacity: 1,
          path: window.google.maps.SymbolPath.CIRCLE,
          strokeColor: '#B779FF',
          strokeOpacity: 0.4,
          strokeWeight: 6
        }}
        onClick={onMarkerClick}
        onLoad={setMarkerInstance}
        position={{ lat: taf.station.coordinates[1], lng: taf.station.coordinates[0] }}
      />
      {markerInstance && isInfoWindowOpen && taf.forecasts && (
        <InfoWindow
          anchor={markerInstance}
          onCloseClick={() => setIsInfoWindowOpen(false)}
          zIndex={0}
          options={{ maxWidth: '450' }}
        >
          <Fragment>
            <Title level={4}>{taf.station.id}</Title>
            <Forecasts forecasts={forecasts} />
            <hr />
            <Row>{taf.raw_text}</Row>
          </Fragment>
        </InfoWindow>
      )}
    </Fragment>
  );
});

TafMarker.propTypes = {
  taf: PropTypes.object
};

export default TafMarker;
