import { bindActionCreators } from 'redux';
import { FullState, StandardDispatch } from '../../store';
import { connect } from 'react-redux';

import { getPendingNotifications, getSpiderTxtCount } from '../../redux/selectors/userData';
import { decrementPendingSpiderTxtNotifications } from '../../redux/reducers/navigationReducer/actions';
import { FunctionComponent, useEffect, useState } from 'react';
import { playTune, Tune } from '../../helpers/audio';

const mapStateToProps = (state: FullState) => {
  return {
    pendingNotificationCount: getPendingNotifications(state),
    spidertxtCount: getSpiderTxtCount(state)
  };
};

const mapDispatchToProps = (dispatch: StandardDispatch) => ({
  ...bindActionCreators(
    {
      decrementPendingNotifications: decrementPendingSpiderTxtNotifications
    },
    dispatch
  )
});

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IComponentProps = IStateProps & IDispatchProps;

const SpiderTxtNotificationAudio: FunctionComponent<IComponentProps> = ({
  pendingNotificationCount,
  spidertxtCount,
  decrementPendingNotifications
}) => {
  const [notifyAfterInitialRender, setNotifyAfterInitialRender] = useState(false);

  useEffect(() => {
    setNotifyAfterInitialRender(true);
  }, []);

  useEffect(() => {
    //The first run after login, only one chime regardless of the number of unread messages
    if (notifyAfterInitialRender === true && spidertxtCount > 0) {
      playTune(Tune.CHIME, 1);
      setNotifyAfterInitialRender(false);
      return;
    }
  }, [spidertxtCount]);

  useEffect(() => {
    if (pendingNotificationCount > 0) {
      decrementPendingNotifications(pendingNotificationCount);
      playTune(Tune.CHIME, pendingNotificationCount);
    }
  }, [pendingNotificationCount]);

  return null;
};

export default connect(mapStateToProps, mapDispatchToProps)(SpiderTxtNotificationAudio);
