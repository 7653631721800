/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';
import TagForm from './TagForm';

export const TagDrawer = ({
  visibility,
  drawerTitle,
  tagData,
  isEdit,
  saveTag,
  deleteTag,
  onClose,
  selectedRecordTag = undefined
}) => {
  return (
    <Drawer
      title={drawerTitle}
      placement="right"
      closable={true}
      visible={visibility}
      onClose={onClose}
      getContainer={false}
      style={{ position: 'absolute' }}
      width={400}
    >
      <TagForm
        tagData={tagData}
        isEdit={isEdit}
        saveTag={saveTag}
        deleteTag={deleteTag}
        onClose={onClose}
        selectedRecordTag={selectedRecordTag}
      />
    </Drawer>
  );
};

TagDrawer.propTypes = {
  visibility: PropTypes.bool.isRequired,
  drawerTitle: PropTypes.string.isRequired,
  tagData: PropTypes.array.isRequired,
  isEdit: PropTypes.bool.isRequired,
  saveTag: PropTypes.func.isRequired,
  deleteTag: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedRecordTag: PropTypes.object
};

export default TagDrawer;
