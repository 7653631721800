import { baronWeatherFetchSignature } from '../../../../../common/api/fetchBaronWeatherSignature';
import { baronUrlHelper, imageUrlHelper, baronReportUrlHelper } from './helper';
import weatherdata from '../../../../../components/Flying/Map/MapControls/weather/weatherdata';

export const tileUrl = (time, weatherLayerData) => {
  const tile = {
    getTileUrl: (coord, zoom) => {
      const numTiles = 1 << zoom; // eslint-disable-line no-bitwise
      const coords = {
        x: ((coord.x % numTiles) + numTiles) % numTiles,
        y: numTiles - coord.y - 1
      };
      if (coord.y < 0 || coord.y >= numTiles) {
        return undefined;
      }

      return baronUrlHelper(weatherLayerData.productCode, time, zoom, coords.x, coords.y);
    },
    tileSize: new window.google.maps.Size(256, 256),
    name: weatherLayerData.name,
    alt: weatherLayerData.productCode,
    opacity: 0.7
  };
  return tile;
};

export const buildImageMapTypeFor = async productCode => {
  const mapLayer = weatherdata.options
    .filter(mapLayer => mapLayer.type === 'graphical')
    .find(mapLayer => mapLayer.productCode === productCode);

  const time = await baronWeatherFetchSignature(imageUrlHelper(mapLayer.productCode));
  const tileUrlPath = tileUrl(time, mapLayer);

  return new window.google.maps.ImageMapType(tileUrlPath);
};

export const getTextualDataForWeatherLayer = async (weatherLayer, mapBounds) => {
  async function loadTextualWeather(resultAccumulator = [], page = 1) {
    const textualDataResponse = await fetch(baronReportUrlHelper(weatherLayer, mapBounds, page));
    if (!textualDataResponse.ok) {
      throw new Error(textualDataResponse.statusText);
    }

    const parsedTextualData = await textualDataResponse.json();
    const payloadKey = Object.keys(parsedTextualData)[0];
    const payload = parsedTextualData[payloadKey];

    const resultSet = resultAccumulator.concat(payload.data);

    try {
      if (payload.meta && payload.meta.page < payload.meta.pages) {
        return await loadTextualWeather(resultSet, page + 1);
      }
    } catch (e) {
      console.log(e);
    }
    return resultSet;
  }

  return await loadTextualWeather([], 1);
};
