import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Bold = styled.div`
  font-weight: bold;
`;

export const Header = ({ children }) => {
  return <Bold>{children}</Bold>;
};

Header.propTypes = {
  children: PropTypes.node
};

export default Header;
