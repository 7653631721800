export class SpeechBubbleSVG {
  private text: string;
  public readonly boundingBoxWidth: number;
  public readonly boundingBoxHeight: number;
  private bubbleColor: string;
  private textColor: string;
  private fontSize: number;
  private padding: number;
  private strokeWidth: number;
  private spikeWidth: number;
  public readonly spikeHeight: number;
  private textWidth: number;
  private textHeight: number;
  private radius: number;

  public constructor(text: string, hasSpike = false) {
    this.text = text;
    this.bubbleColor = '#FFFFFF';
    this.textColor = '#000000';
    this.fontSize = 16;
    this.padding = 0;
    this.spikeWidth = hasSpike ? 20 : 0;
    this.spikeHeight = hasSpike ? 20 : 0;
    this.strokeWidth = 2;
    const s = this.calculateTextSize(this.text, this.fontSize); // Add paddin
    this.textHeight = s.height;
    this.textWidth = s.width;
    this.radius = 5;
    this.boundingBoxWidth =
      this.spikeWidth + this.radius * 2 + s.width + +this.strokeWidth + this.padding * 2;
    this.boundingBoxHeight =
      this.spikeHeight + this.radius * 2 + s.height + +this.strokeWidth + this.padding * 2;
  }

  private calculateTextSize(text: string, fontSize: number): { width: number; height: number } {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    const textElement = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    textElement.setAttribute('font-size', fontSize.toString());
    textElement.setAttribute('font-family', 'Arial');
    svg.appendChild(textElement);
    text.split('\n').forEach((line, index) => {
      const e = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
      e.setAttribute('font-size', fontSize.toString());
      e.setAttribute('x', '0');
      if (index == 0) {
        e.setAttribute('y', `1.2em`);
      } else {
        e.setAttribute('dy', '1.2em');
      }
      e.textContent = line;
      textElement.appendChild(e);
    });

    document.body.appendChild(svg);
    const width = textElement.getBBox().width;
    const height = textElement.getBBox().height;
    document.body.removeChild(svg);
    return { width, height };
  }

  public generateSVG(): string {
    const radius = this.radius;
    const bubblePath = `
      M${radius},0
      h${this.boundingBoxWidth - radius * 2}
      a${radius},${radius} 0 0 1 ${radius},${radius}
      v${this.boundingBoxHeight - radius * 2}
      a${radius},${radius} 0 0 1 -${radius},${radius}
      h-${this.boundingBoxWidth - radius * 2}
      a${radius},${radius} 0 0 1 -${radius},-${radius}
      v-${this.boundingBoxHeight - radius * 2}
      a${radius},${radius} 0 0 1 ${radius},-${radius}
      z
    `;
    const bubblePathWithSpike = `
      M${this.spikeWidth + radius},${this.strokeWidth}
      h${this.textWidth}
      a${radius},${radius} 0 0 1 ${radius},${radius}
      v${this.textHeight}
      a${radius},${radius} 0 0 1 -${radius},${radius}
      h-${this.textWidth + radius}
      l-${this.spikeWidth + radius},${this.spikeHeight}
      l${this.spikeWidth},-${this.spikeHeight + radius}
      v-${this.textHeight}
      a${radius},${radius} 0 0 1 ${radius},-${radius}
      z
    `;
    let bubble = bubblePath;
    if (this.spikeHeight !== 0 && this.spikeWidth !== 0) {
      bubble = bubblePathWithSpike;
    }

    const spans = this.text.split('\n').map((line, index) => {
      if (index == 0) {
        return `<tspan x='${this.spikeWidth + this.radius}' y='1.2em'>${line}</tspan>`;
      } else {
        return `<tspan x='${this.spikeWidth + this.radius}' dy='1.2em'>${line}</tspan>`;
      }
    });

    return `
      <svg width="${this.boundingBoxWidth}" height="${
      this.boundingBoxHeight
    }" xmlns="http://www.w3.org/2000/svg">
        <path d="${bubble}" fill="${this.bubbleColor}" stroke="black" stroke-width="2"/>
        <text font-family="Arial" font-size="${this.fontSize}" fill="${this.textColor}">
          ${spans.join('')}
        </text>
      </svg>
    `;
  }
}
