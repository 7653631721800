export enum EventClass {
  GEOFENCE = 'geofence',
  FSI = 'fsi',
  CAS = 'cas'
}

export enum GeofenceEventType {
  BreachIn = 'breach_in',
  BreachOut = 'breach_out'
}
