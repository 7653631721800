import React, { Fragment, useEffect, useState } from 'react';
import { SigAirmetData, getFilteredSigAirmetData } from './utils';
import { SigAirmetPolygon } from './SigAirmetPolygon';

interface ComponentProps {
  sigAirmetData: SigAirmetData[];
}

export const SigAirmetPolygons: React.FC<ComponentProps> = ({ sigAirmetData = [] }) => {
  const [filteredSigAirmets, setFilteredSigAirmets] = useState<SigAirmetData[]>([]);

  useEffect(() => {
    if (sigAirmetData.length > 0) {
      const sigAirmetsFiltered = getFilteredSigAirmetData(sigAirmetData);
      setFilteredSigAirmets(sigAirmetsFiltered);
    }
  }, [sigAirmetData]);

  return (
    <Fragment>
      {filteredSigAirmets.map((sigAirmet, index) => (
        <SigAirmetPolygon key={index} sigAirmet={sigAirmet} />
      ))}
    </Fragment>
  );
};
