import React, { useEffect } from 'react';
import { getSelectedDisplayPoint } from '../../redux/selectors/eventData';
import { useGoogleMap } from '@react-google-maps/api';
import { panMapToPoint } from '../../helpers';
import { useSelector } from 'react-redux';

export const EventsMapWrapper = ({ children }) => {
  const map = useGoogleMap();
  const selectedDisplayPoint = useSelector(getSelectedDisplayPoint);

  useEffect(() => {
    if (selectedDisplayPoint && map !== null) {
      panMapToPoint(selectedDisplayPoint, map);
    }
  }, [selectedDisplayPoint, map]);

  return <React.Fragment>{children}</React.Fragment>;
};

export default EventsMapWrapper;
