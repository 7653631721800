import React from 'react';
import { MoveSpiderModalContent } from './SpiderModalContent';
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';
import { Modal } from 'antd';
import { assignAircraftToSpiderFlow } from './addAircraftSpiderFlows';

const { confirm } = Modal;

export const showMoveSpiderModal = ({
  serialNumber,
  associatedAircraft,
  registration,
  aircraftId,
  callback
}) =>
  confirm({
    title: 'Change Spider',
    icon: <ExclamationCircleFilled />,
    content: MoveSpiderModalContent(serialNumber, associatedAircraft, registration),
    okText: 'Change Spider',
    onOk: async () => {
      await assignAircraftToSpiderFlow({ serialNumber, aircraftId, registration, callback });
    }
  });

export default showMoveSpiderModal;
