export enum Tune {
  CHIME = 'chime',
  SOS = 'sos'
}

const TUNE_URLS = {
  [Tune.CHIME]: 'https://s3-us-west-2.amazonaws.com/web-objects/arpeggio.mp3',
  [Tune.SOS]: 'https://s3-us-west-2.amazonaws.com/web-objects/sos.mp3'
};

type AudioFactory = (url: string) => HTMLAudioElement;

const playSound = (url: string, fac: AudioFactory) => {
  return new Promise<void>(resolve => {
    // Sometimes the browser doesn't allow repeated playing of the same audio object. To work around this, we create a new audio object each time.
    const audio = fac(url);
    document.documentElement.appendChild(audio);
    audio.preload = 'auto';
    audio.onended = () => {
      document.documentElement.removeChild(audio);
      resolve();
    };
    // if the user doesn't interact with the browser first, this can fail on some browsers
    audio.play().catch(error => {
      console.error(error);
      resolve;
    });
  });
};

export const playTune = async (
  tune: Tune,
  count: number = 1,
  factory: AudioFactory = (url: string): HTMLAudioElement => new Audio(url)
) => {
  for (let i = 0; i < count; i++) {
    await playSound(TUNE_URLS[tune], factory);
  }
};
