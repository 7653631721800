export enum TakeoffLanding {
  Takeoff,
  Landing,
  TouchAndGo
}

export interface GroundEvent {
  timestamp: number;
  type: TakeoffLanding;
}
