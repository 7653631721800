import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import MapContainer from '../../components/Flying/Map/MapContainer';
import { getInstance } from '../../common/api/spidertracks-sdk';
import { AZ } from '../../components/Flying/AircraftList/constants';
import { savedFilter } from '../../redux/reducers/mapReducer/actions/map/index';
import { getSelectedFilter } from '../../redux/selectors/mapData';
import { setLatestTracks, clearAllTracks } from '../../redux/slice/tracks';
import { fetchAircraft } from '../../redux/slice/aircraft';
import { selectAircraftFromList } from '../../redux/reducers/aircraftReducer/thunk';
import MapNavigation from '../../components/Flying/Map/MapNavigation';
import MapControls from '../../components/Flying/Map/MapControls';
import PointInfoPanelContainer from '../../components/Flying/Map/PointInfoPanel/PointInfoPanelContainer';
import { MapProvider } from '../../context/MapContext';

export const HistoryFlyingPage = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const selectedAircraftFilterState = useSelector(getSelectedFilter);

  useEffect(() => {
    const getTrackDetails = async () => {
      dispatch(savedFilter(AZ));
      dispatch(clearAllTracks());
      const SpidertracksSDK = getInstance();
      const trackData = await SpidertracksSDK.getTrackHistory({ trackIds: params.trackId });

      dispatch(fetchAircraft());
      dispatch(setLatestTracks(trackData.items));
      dispatch(selectAircraftFromList(trackData.items[0].aircraft.id));
    };

    getTrackDetails();

    return () => {
      dispatch(setLatestTracks([]));
      dispatch(savedFilter(selectedAircraftFilterState));
    };
  }, []);

  const mapContext = {
    events: { displayEventClasses: [] },
    strictPointGrouping: { displayFullTrack: true }
  };

  return (
    <MapProvider context={mapContext}>
      <MapContainer>
        <MapNavigation />
        <PointInfoPanelContainer />
        <MapControls weatherEnabled={false} />
      </MapContainer>
    </MapProvider>
  );
};

export default HistoryFlyingPage;
