import { Amplify } from '@spidertracks/common';
import { ServiceAbstract } from '../../ServiceAbstract';

export class FeatureConfigService extends ServiceAbstract {
  public async getFeatureFlags(orgIds: string[]): Promise<any> {
    try {
      const params = orgIds.reduce((acc, id) => `orgIds=${id.toLowerCase()}&${acc}`, '');
      const url = `features?${params}`;
      const result = await Amplify.API.get('authenticated', url);
      return result;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
}
