import InfoCircleFilled from '@ant-design/icons/InfoCircleFilled';

import { Col, Row, Tooltip } from 'antd';
import React, { FC } from 'react';
import Header from './Header';

const columnOne = 8;
const columnTwo = 16;

interface RowInfoProps {
  name?: string;
  tooltipText?: string;
  value?: string;
  colorClassName?: string;
}

const RowInfo: FC<RowInfoProps> = ({ name, value, tooltipText, colorClassName }) => {
  return (
    <Row>
      <Col span={columnOne}>
        <Header>
          {tooltipText ? (
            <Tooltip placement="right" title={tooltipText} trigger="click">
              {name} <InfoCircleFilled style={{ fontSize: 10, color: 'gray' }} />
            </Tooltip>
          ) : (
            <div>{name}</div>
          )}
        </Header>
      </Col>
      <Col className={colorClassName || ''} span={columnTwo}>
        {value}
      </Col>
    </Row>
  );
};

export default RowInfo;
