import { EventParameterItem } from '../common/api/spidertracks-sdk/private/services/EventRulesService';

export enum EventRuleType {
  EXCESSIVE_PITCH_DOWN = 'excessive_pitch_down',
  EXCESSIVE_PITCH_DOWN_AT_LOW_ALTITUDE_AGL = 'excessive_pitch_down_at_low_altitude_agl',
  EXCESSIVE_PITCH_UP = 'excessive_pitch_up',
  EXCESSIVE_PITCH_UP_AT_LOW_SPEED = 'excessive_pitch_up_at_low_speed',
  EXCESSIVE_PITCH_UP_AT_LOW_ALTITUDE_AGL = 'excessive_pitch_up_at_low_altitude_agl',
  EXCESSIVE_RATE_OF_CLIMB = 'excessive_rate_of_climb',
  EXCESSIVE_ROLL = 'excessive_roll',
  EXCESSIVE_ROLL_AT_LOW_ALTITUDE_AMSL = 'excessive_roll_at_low_altitude',
  EXCESSIVE_ROLL_AT_LOW_ALTITUDE_AGL = 'excessive_roll_at_low_altitude_agl',
  HIGH_RATE_OF_DESCENT = 'high_rate_of_descent',
  HIGH_RATE_OF_DESCENT_AT_LOW_ALTITUDE_AMSL = 'high_rate_of_descent_at_low_altitude',
  HIGH_RATE_OF_DESCENT_AT_LOW_ALTITUDE_AGL = 'high_rate_of_descent_at_low_altitude_agl',
  MAXIMUM_ALTITUDE_AGL = 'maximum_altitude_agl',
  MAXIMUM_ALTITUDE_AMSL = 'maximum_altitude_amsl',
  EXCESSIVE_G_FORCE = 'excessive_g_force',
  EXCESSIVE_TEMPERATURE = 'excessive_temperature',
  EXCESSIVE_RPM = 'excessive_rpm',
  EXCESSIVE_SPEED = 'excessive_speed'
}

export enum InsightRuleCondition {
  pitchDown = 'pitchDown',
  pitchUp = 'pitchUp',
  lowAltitudeAgl = 'lowAltitudeAgl',
  lowAltitudeAmsl = 'lowAltitudeAmsl',
  lowSpeed = 'lowSpeed',
  highSpeed = 'highSpeed',
  rateOfClimb = 'rateOfClimb',
  roll = 'roll',
  rateOfDescent = 'rateOfDescent',
  highAltitudeAgl = 'highAltitudeAgl',
  highAltitudeAmsl = 'highAltitudeAmsl',
  gForce = 'gForce',
  temperature = 'temperature',
  rpm = 'rpm'
}

export enum InsightRuleThresholdProperty {
  aglMetres = 'aglMetres',
  amslMetres = 'amslMetres',
  aircraftRollRadians = 'aircraftRollRadians',
  aircraftPitchRadians = 'aircraftPitchRadians',
  aircraftYawRadians = 'aircraftYawRadians',
  groundSpeed = 'groundSpeed',
  /**
   * Vertical speed DOWN
   */
  verticalSpeed = 'verticalSpeed',
  gForce = 'gForce',
  /** Used to mean that the property is determined by the value primary condition + the primary condition source
   * e.g. primaryCondition is temperature, then we look at primaryConditionSource. Say it's engine1Egt1, then we look at engine1Egt1DegreesC
   */
  primaryConditionValue = 'primaryConditionValue'
}

export enum ConditionSource {
  engine1Cht1DegreesC = 'engine1Cht1DegreesC',
  engine1Egt1DegreesC = 'engine1Egt1DegreesC',
  engine1Rpm = 'engine1Rpm',
  indicatedAirSpeedMetresPerSecond = 'indicatedAirSpeedMetresPerSecond',
  groundSpeedMetresPerSecond = 'groundSpeedMetresPerSecond'
}

export interface InsightEventRuleThreshold {
  thresholdProperty: InsightRuleThresholdProperty;
  thresholdValue: number;
  severityEnabled: boolean;
  thresholdValueLow: number;
  thresholdValueMedium: number;
  thresholdValueHigh: number;
}

export interface InsightRule {
  id: string;
  name: string;
  enabled: boolean;
  organisationId: string;
  /// AKA trigger. We can have other conditions too.
  primaryCondition: InsightRuleCondition;
  secondaryCondition?: InsightRuleCondition;
  primaryConditionSource?: ConditionSource;
  aircraftIds: string[];
  allAircraft: boolean;
  thresholds: InsightEventRuleThreshold[];
}

export interface OrganisationInsightRules {
  [key: string]: InsightRule[];
}

export interface InsightRulesState {
  /** Map of orgId -> rules */
  organisationRules: OrganisationInsightRules;
  eventParameters: EventParameterItem[];
}
