import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Tooltip, Popover } from 'antd';
import { GOOGLE_MAP_CONTAINER } from '../../GoogleMap/constants';
import MapLayerViewOptions from './MapLayerViewOptionsContainer';
import Icon from '../Icon';
import MapLayersIcon from '../icons/MapLayers';
import {
  populateKmlData,
  updateKmlData
} from '../../../../../redux/reducers/mapReducer/actions/map/thunk';
import './styles/scss/styles.scss';
import { MenuIcon } from '../MenuIcon';
import { getKmlData, getActiveKmls } from '../../../../../redux/selectors/mapData';
import { getUserData } from '../../../../../redux/selectors/userData';
import KmlLayersContainer from './KmlLayersContainer';
import GeofencesContainer from './GeofencesContainer';
import { useFeatureFlag } from '../../../../../hooks';
import { useParams } from 'react-router-dom';

const MapLayersContainer = () => {
  let organisationSlug = useParams().organisationSlug;
  const userData = useSelector(getUserData);
  const { orgs = [] } = userData;
  const isGeofenceFeatureEnabled = useFeatureFlag(
    'geofence',
    orgs.reduce((acc, o) => acc.concat(o.org.id), []) // make a list of org Ids from the userData
  );
  const kmlData_ = useSelector(getKmlData);
  let kmlData = kmlData_.concat([]);
  if (isGeofenceFeatureEnabled && !kmlData.some(element => element.name === 'Geofences')) {
    kmlData = [{ id: 'geofence', name: 'Geofences' }].concat(kmlData);
  }
  const activeKmls = useSelector(getActiveKmls);
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    dispatch(populateKmlData(organisationSlug));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePopover = () => {
    setVisible(!visible);
  };

  if (kmlData && kmlData.length === 0) {
    return null;
  }

  return (
    <div className="map-layers-wrapper">
      <KmlLayersContainer />
      <GeofencesContainer />
      <Tooltip
        placement="left"
        title="Map Layers"
        getPopupContainer={() => document.getElementById(GOOGLE_MAP_CONTAINER)}
      >
        <Popover
          placement="leftTop"
          getPopupContainer={() => document.getElementById(GOOGLE_MAP_CONTAINER)}
          content={
            <MapLayerViewOptions
              mapLayers={kmlData}
              activeKmls={activeKmls}
              updateKmlData={updateKmlData}
            />
          }
          title=""
          role="button"
          trigger="click"
          visible={visible}
          onVisibleChange={handlePopover}
        >
          <MenuIcon>
            <Icon height={18} width={18} type={MapLayersIcon} />
          </MenuIcon>
        </Popover>
      </Tooltip>
    </div>
  );
};

export default MapLayersContainer;
