import React, { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import InfoWindowRow from '../../InfoWindowRow';

const skyConditionDescMap = new Map([
  ['NSC', 'No significant cloud'],
  ['SKC', 'Clear'],
  ['CLR', 'Clear below 12,000 feet'],
  ['FEW', '1-2 oktas'],
  ['SCT', '3-4 oktas'],
  ['BKN', '5-7 oktas'],
  ['OVC', 'Overcast'],
  ['OVX', 'No reported cloud information, obscured due to poor vertical visibility']
]);

export function getSkyConditionDetails(skyCondition) {
  let skyConditionDetails =
    skyConditionDescMap.get(skyCondition.sky_cover) || skyCondition.sky_cover;

  if (skyCondition.cloud_base) {
    skyConditionDetails +=
      ', cloud base: ' + skyCondition.cloud_base.value + skyCondition.cloud_base.units;
  }

  return skyConditionDetails;
}

export function getSortedSkyConditionsByAltitude(skyConditions) {
  const sortedSkyConditions = [...skyConditions];

  sortedSkyConditions.sort((condition1, condition2) => {
    if (
      condition1.cloud_base &&
      condition2.cloud_base &&
      typeof condition1.cloud_base.value === 'number' &&
      typeof condition2.cloud_base.value === 'number'
    ) {
      return condition1.cloud_base.value - condition2.cloud_base.value;
    }

    return 0;
  });

  return sortedSkyConditions;
}

export const SkyConditionDetails = ({ skyConditions }) => {
  const sortedSkyConditions = getSortedSkyConditionsByAltitude(skyConditions);

  return (
    <Fragment>
      {sortedSkyConditions.map(
        (skyCondition, i) =>
          skyCondition.cloud_base &&
          typeof skyCondition.cloud_base.value === 'number' && (
            <InfoWindowRow key={i} title="Sky" content={getSkyConditionDetails(skyCondition)} />
          )
      )}
    </Fragment>
  );
};

SkyConditionDetails.propTypes = {
  skyConditions: PropTypes.array.isRequired
};

SkyConditionDetails.displayName = 'SkyConditionDetails';

export default memo(SkyConditionDetails);
