import { vectorTile } from './vectorTile';

const SKY_VECTOR_MAP_PROPS = {
  vfr: {
    name: 'VFR',
    mapTypeId: 'sectional',
    minZoom: 1,
    maxZoom: 11
  },
  hi: {
    name: 'highMap',
    mapTypeId: 'high',
    minZoom: 1,
    maxZoom: 9
  },
  lo: {
    name: 'lowMap',
    mapTypeId: 'low',
    minZoom: 1,
    maxZoom: 10
  },
  basic: {
    name: 'basicMap',
    mapTypeId: 'basic',
    minZoom: 1,
    maxZoom: 8
  }
};

export const setMapProperties = ({ map, getNormalisedCoordinates, skyVectorView }) => {
  if (
    window.google === undefined ||
    map === undefined ||
    getNormalisedCoordinates === undefined ||
    skyVectorView === undefined
  ) {
    return;
  }

  const mapProps = SKY_VECTOR_MAP_PROPS[skyVectorView];
  if (mapProps) {
    const vectorMapType = new window.google.maps.ImageMapType({
      getTileUrl: function tileUrl(coord, zoom) {
        const normalizedCoord = getNormalisedCoordinates(coord, zoom);
        const viewUrl = vectorTile(normalizedCoord, skyVectorView, zoom);
        return viewUrl;
      },
      minZoom: mapProps.minZoom,
      maxZoom: mapProps.maxZoom,
      name: mapProps.name,
      tileSize: new window.google.maps.Size(256, 256)
    });
    map.mapTypes.set(mapProps.mapTypeId, vectorMapType);
    map.setMapTypeId(mapProps.mapTypeId);
  }
};
