import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { EtaInfoWindow } from './EtaInfoWindow';
import humanizeDuration from 'humanize-duration';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { convertMetresTo } from '../../utils/helper';
import {
  getSelectedMarkerPoint,
  getSelectedMarkerPointTimestamp
} from '../../../../../redux/selectors/mapData';
import { getDistanceUnit, getDateTimeFormat } from '../../../../../redux/selectors/userData';
import {
  formatStandardDateStrings,
  fromTimestamp,
  dateFormatter,
  addSeconds
} from '../../../../../helpers/formatTime';
import { getTimezone } from '../../../../../redux/selectors/userData';

export class EtaInfoWindowContainer extends Component {
  static propTypes = {
    destinationLatLng: PropTypes.object,
    sourceLatLng: PropTypes.object,
    pointTime: PropTypes.number,
    rawSpeed: PropTypes.number,
    userDistanceUnit: PropTypes.string,
    dateFormat: PropTypes.string,
    timezone: PropTypes.string
  };

  calculateDistance(latLng1, latLng2) {
    return Math.round(
      window.google.maps.geometry.spherical.computeDistanceBetween(latLng1, latLng2)
    );
  }

  render() {
    const {
      destinationLatLng,
      sourceLatLng,
      pointTime,
      rawSpeed,
      userDistanceUnit,
      dateFormat,
      timezone
    } = this.props;

    if (!destinationLatLng) {
      return null;
    }

    const distance = this.calculateDistance(sourceLatLng, destinationLatLng);
    const distanceInUserUnit = convertMetresTo(distance, userDistanceUnit).join(' ');
    const timeDiff = distance / rawSpeed;
    const etaTime =
      rawSpeed === 0
        ? 'unknown'
        : `${humanizeDuration(moment.duration(timeDiff, 'seconds').asMilliseconds(), {
            largest: 2,
            maxDecimalPoints: 0
          })}`;

    const localMoment = fromTimestamp(pointTime);
    const formatFn = dateFormatter(dateFormat);
    const [localTime, utcTime] = formatStandardDateStrings(
      formatFn,
      addSeconds(localMoment, timeDiff),
      timezone
    );

    const etaDateInUserFormat = rawSpeed === 0 ? 'unknown' : localTime;
    const etaDateInUtcFormat = rawSpeed === 0 ? 'unknown' : utcTime;

    return (
      <EtaInfoWindow
        etaTime={etaTime}
        etaDate={etaDateInUserFormat}
        etaDateUtc={etaDateInUtcFormat}
        distance={distanceInUserUnit}
      />
    );
  }
}

const mapStateToProps = state => {
  const markerPoint = getSelectedMarkerPoint(state);
  const rawSpeed = markerPoint && markerPoint.speed_raw ? markerPoint.speed_raw.value : 0;

  return {
    rawSpeed,
    pointTime: getSelectedMarkerPointTimestamp(state),
    userDistanceUnit: getDistanceUnit(state),
    dateFormat: getDateTimeFormat(state),
    timezone: getTimezone(state)
  };
};

export default connect(mapStateToProps)(EtaInfoWindowContainer);
