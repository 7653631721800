import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Steps } from 'antd';
import AircraftForm from './AircraftForm';
import SpiderSettingsForm from './SpiderSettingsForm';
import SpiderForm from './SpiderForm';
import { getInstance } from 'common/api/spidertracks-sdk';
import { useParams } from 'react-router';
import {
  aircraftAddFormSuccess,
  aircraftAddFormError,
  spiderAssignedSuccess,
  cannotChangeSpiderError,
  duplicateRegistrationError
} from '../../../helpers/globalNotifications';
import {
  registerThenAssignSpiderFlow,
  assignAircraftToSpiderFlow,
  registerSpiderFlow
} from './addAircraftSpiderFlows';
import { showMoveSpiderModal } from './showConfirmModal';

const { Step } = Steps;

const AircraftSpiderWizard = ({
  visibility,
  onClose,
  refreshDeviceTable,
  spidersAircraftsData
}) => {
  const SpidertracksSDK = getInstance();
  const [currentStep, setCurrentStep] = useState(0);
  const [newSerialNumber, setNewSerialNumber] = useState('');
  const [createdAircraft, setCreatedAircraft] = useState({});
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [aircraftStepStatus, setAircraftStepStatus] = useState('process');
  const { organisationId } = useParams();

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
  const [submitButtonValue, setSubmitButtonValue] = useState('Skip');

  const registerThenAssignSpiderFlowCB = serialNumber => {
    refreshDeviceTable();
    setNewSerialNumber(serialNumber);
    setCurrentStep(2);
  };

  const assignAircraftToSpiderFlowCB = (serialNumber, registration) => {
    spiderAssignedSuccess(registration);
    refreshDeviceTable();
    setNewSerialNumber(serialNumber);
    setCurrentStep(2);
  };

  const registerSpiderFlowCB = serialNumber => {
    refreshDeviceTable();
    setNewSerialNumber(serialNumber);
    setCurrentStep(2);
  };

  const addAircraft = async aircraft => {
    try {
      const { aircraftId } = await SpidertracksSDK.getAircraftService().addAircraft(aircraft);
      aircraftAddFormSuccess();
      setCurrentStep(1);
      setAircraftStepStatus('finish');
      setCreatedAircraft({ ...aircraft, aircraftId });
      refreshDeviceTable();
    } catch (e) {
      if (e.response && e.response.status === 409) {
        duplicateRegistrationError(aircraft.registration);
      } else {
        aircraftAddFormError();
      }
      console.error(e);
    }
  };

  const addSpider = async params => {
    const serialNumberInSpiderAircraftData = spiderAircraftRecord =>
      spiderAircraftRecord.serialNumber === params.serialNumber;
    const isSpiderNewlyCreated = !spidersAircraftsData.some(serialNumberInSpiderAircraftData);

    // user has just created a new aircraft
    if (createdAircraft.aircraftId) {
      // if a new spider was created (filter existing spider data we have in the table to know if the spider is new or not)
      if (isSpiderNewlyCreated) {
        // Flow Code: 1
        await registerThenAssignSpiderFlow({
          serialNumber: params.serialNumber,
          aircraftId: params.aircraftId,
          registration: createdAircraft.registration,
          organisationId,
          callback: registerThenAssignSpiderFlowCB
        });
      } else {
        // assign the aircraft to the spider
        if (params.associatedAircraft === 'Unassigned') {
          // Flow Code: 2
          await assignAircraftToSpiderFlow({
            serialNumber: params.serialNumber,
            aircraftId: params.aircraftId,
            registration: createdAircraft.registration,
            callback: assignAircraftToSpiderFlowCB
          });
        } else {
          // Flow Code: 3
          showMoveSpiderModal({
            serialNumber: params.serialNumber,
            associatedAircraft: params.associatedAircraft,
            registration: createdAircraft.registration,
            aircraftId: params.aircraftId,
            callback: assignAircraftToSpiderFlowCB
          });
        }
      }
    }
    // user skipped the creation of the aircraft
    else {
      // if the spider was newly added to the list, register it without assigning it to an aircraft as no aircraft got created
      if (isSpiderNewlyCreated) {
        // Flow Code: 4
        await registerSpiderFlow({
          serialNumber: params.serialNumber,
          organisationId,
          callback: registerSpiderFlowCB
        });
      }
      // the spider was selected from the list
      else {
        if (params.associatedAircraft === 'Unassigned') {
          // Flow Code: 5
          // Navigate to the second form
          setNewSerialNumber(params.serialNumber);
          setCurrentStep(2);
        } else {
          // Flow Code: 6
          cannotChangeSpiderError();
        }
      }
    }
  };

  const goToStepOne = () => {
    setAircraftStepStatus('wait');
    setCurrentStep(1);
  };

  return (
    <Drawer title="Add" placement="right" onClose={onClose} visible={visibility} width={400}>
      <Steps current={currentStep} size="medium" labelPlacement="vertical">
        <Step key="Aircraft" title="Aircraft" status={aircraftStepStatus} />
        <Step key="Spider" title="Spider" />
        <Step key="Reporting" title="Reporting" />
      </Steps>
      {currentStep === 0 && (
        <AircraftForm
          onClose={onClose}
          skipped={goToStepOne}
          save={addAircraft}
          submitButtonValue={submitButtonValue}
          setSubmitButtonValue={setSubmitButtonValue}
          isSubmitButtonDisabled={isSubmitButtonDisabled}
          setIsSubmitButtonDisabled={setIsSubmitButtonDisabled}
          handleFieldChange={() => setSubmitButtonValue('Next')}
        />
      )}
      {currentStep === 1 && (
        <SpiderForm
          onClose={onClose}
          setNewSerialNumber={setNewSerialNumber}
          currentAircraftSpider={createdAircraft}
          spidersAircraftsData={spidersAircraftsData}
          save={addSpider}
          submitButtonValue="Next"
        />
      )}
      {currentStep === 2 && (
        <SpiderSettingsForm
          onClose={onClose}
          serialNumber={newSerialNumber}
          aircraftRegistration={createdAircraft.registration}
          saveButtonDisabled={saveButtonDisabled}
          setSaveButtonDisabled={setSaveButtonDisabled}
        />
      )}
    </Drawer>
  );
};

AircraftSpiderWizard.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshDeviceTable: PropTypes.func.isRequired,
  spidersAircraftsData: PropTypes.array.isRequired
};
export default AircraftSpiderWizard;
