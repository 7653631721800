import { useLocation, useParams } from 'react-router-dom';
import { TrackHistoryRequest } from '../common/api/spidertracks-sdk/types/TrackHistoryRequest';

interface TrackIdParams {
  trackId: string;
}

interface SNBootcountParams {
  serialNumber: string;
  bootcount: string;
}

type PreselectMultipleTracksParams = TrackIdParams | SNBootcountParams;

interface TrackRouteParams {
  params: PreselectMultipleTracksParams;
  trackHistoryRequest: TrackHistoryRequest;
  trackIds: string[];
}

export const useTrackRouteParams = (): TrackRouteParams => {
  const params = useParams<PreselectMultipleTracksParams>();
  const queryParams = new URLSearchParams(useLocation().search);
  const trackIds = queryParams.get('trackIds');

  if ('trackId' in params) {
    return {
      params,
      trackHistoryRequest: { trackIds: params.trackId },
      trackIds: [params.trackId]
    };
  }

  if (trackIds) {
    return {
      params,
      trackHistoryRequest: { trackIds },
      trackIds: trackIds.split(',')
    };
  }

  return {
    params,
    trackHistoryRequest: { ...params, bootcount: Number(params.bootcount) },
    trackIds: []
  };
};
