import React, { JSXElementConstructor, ReactElement, useEffect } from 'react';
import { Form, Select } from 'antd';
import { SelectValue } from 'antd/lib/select';

interface AltitudeContextOptionsProps {
  getFieldDecorator: Function;
  setFieldsValue: Function;
  onAltitudeChange: (
    value: SelectValue,
    option: // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | ReactElement<any, string | JSXElementConstructor<any>>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      | ReactElement<any, string | JSXElementConstructor<any>>[]
  ) => void;
  disabled: boolean;
  options: Function;
  altitudeContext: string;
  label: string;
}

export const AltitudeContextOptions = ({
  getFieldDecorator,
  onAltitudeChange,
  disabled,
  options,
  altitudeContext,
  setFieldsValue,
  label
}: AltitudeContextOptionsProps) => {
  useEffect(() => {
    if (disabled) {
      setFieldsValue({ altitudeContext });
    }
  }, [setFieldsValue, disabled, altitudeContext]);

  return (
    <Form.Item label={label} style={{ width: '100%', minWidth: '90px' }}>
      {getFieldDecorator('altitudeContext', {
        rules: [{ required: true, message: 'Please enter a context' }]
      })(
        <Select placeholder="Any" onChange={onAltitudeChange} disabled={disabled}>
          {options([
            { actualValue: 'any', displayedValue: 'Any' },
            { actualValue: 'above', displayedValue: 'Above' },
            { actualValue: 'below', displayedValue: 'Below' }
          ])}
        </Select>
      )}
    </Form.Item>
  );
};

export default AltitudeContextOptions;
