import React from 'react';
import { AirplanemodeActive, ExpandMore, ExpandLess } from '@material-ui/icons';
import styled from 'styled-components';
const BluePlane = styled(AirplanemodeActive)`
  &&&&&& {
    color: #40a9ff;
    margin-right: 10px;
  }
`;

const FlightReportPanelWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  color: #000000a6;
`;

const FlightReportPanelMetricsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  flex-grow: 2;
`;

const FlightReportPanelTitle = styled.h4`
  font-size: 16px;
  margin: 0;
  font-weight: normal;
  color: #000000a6;
`;

const FlightReportPanelSubTitle = styled.span`
  font-size: 12px;
`;

const FlightReportPanelMainContent = styled.span`
  font-size: 12px;
`;

const FlightReportPanelIconWrapper = styled.div`
  text-align: right;
  margin-left: 10px;
`;

interface FlightReportPanelProps {
  registration: string;
  departedTime: string;
  distance: string;
  duration: string;
  active: boolean;
}

const FlightReportPanel = ({
  registration,
  departedTime,
  distance,
  duration,
  active
}: FlightReportPanelProps) => {
  return (
    <FlightReportPanelWrapper>
      <BluePlane />
      <div className="d-flex flex-direction-col">
        <FlightReportPanelTitle>{registration}</FlightReportPanelTitle>
        <FlightReportPanelSubTitle>{departedTime}</FlightReportPanelSubTitle>
      </div>
      <FlightReportPanelMetricsWrapper>
        <FlightReportPanelMainContent>{distance}</FlightReportPanelMainContent>
        <FlightReportPanelSubTitle>{duration}</FlightReportPanelSubTitle>
      </FlightReportPanelMetricsWrapper>
      <FlightReportPanelIconWrapper>
        {active ? <ExpandLess /> : <ExpandMore />}
      </FlightReportPanelIconWrapper>
    </FlightReportPanelWrapper>
  );
};

export default FlightReportPanel;
