import React from 'react';
import PropTypes from 'prop-types';
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';
import { Modal } from 'antd';

const { confirm } = Modal;

export const showDeleteAircraftModal = (aircraftId, deleteAircraft, onClose) =>
  confirm({
    title: 'Delete Aircraft',
    icon: <ExclamationCircleFilled />,
    content: (
      <>
        <p>Are you sure you want to delete this Aircraft?</p>
        <p>
          {' '}
          <b>Note</b>: If a Spider is registered against this aircraft, it will remain active and
          become available to pair with another aircraft. You will continue to be billed for this
          active spider.
        </p>
        <p>
          {' '}
          <b>Note</b>: Deleting an aircraft does not delete tracks created by this aircraft - they
          will remain available via History.
        </p>
      </>
    ),
    okText: 'Delete Aircraft',
    okButtonProps: { type: 'danger' },
    onOk() {
      deleteAircraft(aircraftId);
      onClose();
    }
  });

showDeleteAircraftModal.propTypes = {
  aircraftId: PropTypes.string.isRequired,
  deleteAircraft: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};
export default showDeleteAircraftModal;
