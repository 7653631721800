import React, { Fragment, FC } from 'react';
import { SolidTooltip } from '@spidertracks/components';
import { TableData } from './utils';

interface AircraftCellProps {
  aircraftRegistrations: Array<string>;
  row: TableData;
}

export const AircraftCell: FC<AircraftCellProps> = ({ aircraftRegistrations = [], row = {} }) => {
  if ('allAircraft' in row) {
    return <Fragment>All</Fragment>;
  }

  if (aircraftRegistrations.length === 1) {
    return <div>{aircraftRegistrations[0]}</div>;
  }

  return (
    <SolidTooltip placement="bottom" title={aircraftRegistrations.join(', ')}>
      <div>
        {aircraftRegistrations.length && aircraftRegistrations[0]}
        {aircraftRegistrations.length > 1 && ` and ${aircraftRegistrations.length - 1} more`}
      </div>
    </SolidTooltip>
  );
};

export default AircraftCell;
