import React from 'react';
import { FilterDropdown } from './FilterDropdown';
import { FilterIconSearch } from './FilterIcon';

export const getFilterDropdown = ({
  clearSelection,
  updateOnConfirm = false,
  displayName,
  onFilter,
  showSearch = false,
  style
}) => {
  return () => {
    const filterDropdown = args => {
      return (
        <FilterDropdown
          clearFilters={args.clearFilters}
          clearSelection={clearSelection}
          confirm={args.confirm}
          updateOnConfirm={updateOnConfirm}
          displayName={displayName}
          filters={args.filters}
          onFilter={onFilter}
          selectedKeys={args.selectedKeys}
          setSelectedKeys={args.setSelectedKeys}
          showSearch={showSearch}
          style={style}
          visible={args.visible}
        />
      );
    };
    return {
      filterDropdown,
      filterIcon: FilterIconSearch
    };
  };
};

export default getFilterDropdown;
