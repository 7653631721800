import React from 'react';

export function ReplaceSpiderModalContent(serialNumber, registration) {
  return (
    <p>
      Are you sure you want to replace <span style={{ fontWeight: 'bold' }}>{serialNumber}</span>{' '}
      assigned to <span style={{ fontWeight: 'bold' }}>{registration}</span>. Replaced Spiders will
      still show in your list and are still active on your account.
    </p>
  );
}

export function MoveSpiderModalContent(serialNumber, associatedAircraft, targetAircraft) {
  return (
    <p>
      Are you sure you want to move <span style={{ fontWeight: 'bold' }}>{serialNumber}</span> from{' '}
      <span style={{ fontWeight: 'bold' }}>{associatedAircraft}</span> to{' '}
      <span style={{ fontWeight: 'bold' }}>{targetAircraft}</span>?
    </p>
  );
}
