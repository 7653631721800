import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';

const MapLayers = ({ item, onChange }) => {
  return (
    <Checkbox value={item.id} onChange={onChange}>
      {item.name}
    </Checkbox>
  );
};

MapLayers.defaultProps = {
  onChange: () => {}
};

MapLayers.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  onChange: PropTypes.func
};

export default MapLayers;
