import React from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';
import WrappedCreateAircraftPublicLinkForm from './CreateAircraftPublicLinkForm';

const CreateAircraftPublicLinkDrawer = ({ visibility, onClose, publicAircraftData }) => {
  return (
    <Drawer
      title="Create Link"
      placement="right"
      visible={visibility}
      onClose={onClose}
      width={400}
      style={{ display: 'flex', justifyContent: 'center' }}
    >
      <WrappedCreateAircraftPublicLinkForm
        publicAircraftData={publicAircraftData}
        closeDrawer={onClose}
      />
    </Drawer>
  );
};

CreateAircraftPublicLinkDrawer.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  publicAircraftData: PropTypes.array.isRequired
};

export default CreateAircraftPublicLinkDrawer;
