import React from 'react';
import PropTypes from 'prop-types';
import { getEncodedIconUrl, getVehicleMarkerIcon } from '../../Flying/Map/utils/drawing/marker';

export const AircraftIcon = ({ aircraft, dimensions }) => {
  const vehicleMarker = getVehicleMarkerIcon(aircraft.aircraftIcon, aircraft.aircraftIconColor);
  return <img src={getEncodedIconUrl(vehicleMarker)} width={dimensions} height={dimensions} />;
};

AircraftIcon.propTypes = {
  aircraft: PropTypes.object,
  dimensions: PropTypes.number
};
