import { ServiceAbstract } from '../../ServiceAbstract';
import { Amplify } from '@spidertracks/common';

export type Thresholds = Record<string, string | number>;
export type SeverityThresholds = Record<
  string,
  | {
      low: number;
      medium: number;
      high: number;
    }
  | number
>;
export type SeverityThresholdLevel = 'low' | 'medium' | 'high';

export type EventRuleBase = {
  id: string;
  eventClass: string;
  eventType: string;
  ruleName: string;
  organisationId: string;
  eventTypeDisplayName: string;
  ruleAlreadyConfigured?: boolean;
} & (
  | {
      thresholds: Thresholds;
      displayThresholds: Thresholds;
      severityThresholds?: never;
      severityDisplayThresholds?: never;
    }
  | {
      thresholds?: never;
      displayThresholds?: never;
      severityThresholds: SeverityThresholds;
      severityDisplayThresholds: SeverityThresholds;
    }
);

type AllAircraftEventRule = EventRuleBase & {
  allAircraft: true;
  aircraftIds?: never;
};

type SomeAircraftEventRule = EventRuleBase & {
  aircraftIds: string[];
  allAircraft?: never;
};

export type EventRule = AllAircraftEventRule | SomeAircraftEventRule;

export interface APIResponse<T> {
  items: T[];
  errors?: { code: number; message: string }[];
  meta: { count: number; total: number };
}

export interface EventParameter {
  op: string;
  unit: string;
  displayName: string;
  name: string;
  maxDisplayValue: number;
  displayUnit: string;
  minDisplayValue: number;
  type: string;
}
export interface EventParameterItem {
  eventClass: string;
  eventType: string;
  parameters: EventParameter[];
}
export interface EventType {
  id: string;
  eventType: string;
  ruleName: string;
  eventTypeDisplayName: string;
  eventClass: string;
  eventClassLabel: string;
  ruleAlreadyConfigured?: boolean;
  createdAt: string;
  newEventType?: boolean;
  description?: string;
}

export class EventRulesService extends ServiceAbstract {
  public async getAllEventTypes(organisationId: string): Promise<EventType[]> {
    try {
      const { items } = await Amplify.API.get(
        'authenticated',
        `events/types?eventClass=fsi&organisationId=${organisationId}`
      );
      return items;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  public getEventParametersByType(
    eventClass: string,
    eventType: string
  ): Promise<EventParameterItem[]> {
    return this.getEventParameters(eventClass, eventType);
  }

  public getAllEventParameters(eventClass: string): Promise<EventParameterItem[]> {
    return this.getEventParameters(eventClass);
  }

  private async getEventParameters(...pathParts: string[]): Promise<EventParameterItem[]> {
    try {
      const path = pathParts.join('/');

      const { items } = (await Amplify.API.get(
        'authenticated',
        `events/parameters/${path}`
      )) as APIResponse<EventParameterItem>;
      return items;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  public async getAllEventRules(organisationId: string): Promise<EventRule[]> {
    try {
      const { items } = await Amplify.API.get(
        'authenticated',
        `events/rules?organisationId=${organisationId}`
      );
      return items;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  public async createEventRule(organisationId: string, eventRule: any): Promise<void> {
    try {
      await Amplify.API.post('authenticated', `events/rules?organisationId=${organisationId}`, {
        body: eventRule
      });
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  public async updateEventRule(
    organisationId: string,
    eventRuleId: string,
    eventRule: any
  ): Promise<void> {
    try {
      await Amplify.API.put(
        'authenticated',
        `events/rules/${eventRuleId}?organisationId=${organisationId}`,
        {
          body: eventRule
        }
      );
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  public async deleteEventRule(organisationId: string, eventRuleId: string): Promise<void> {
    try {
      await Amplify.API.del(
        'authenticated',
        `events/rules/${eventRuleId}?organisationId=${organisationId}`
      );
    } catch (e) {
      console.log(e);
      throw e;
    }
  }
}
