import React, { MouseEventHandler } from 'react';
import { Button, PageHeader } from 'antd';

export const InsightRulesHeader = (props: {
  disabledAddButton: boolean;
  openDrawer: MouseEventHandler<HTMLElement>;
}) => {
  const { disabledAddButton, openDrawer } = props;
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <PageHeader
        className="site-page-header"
        style={{ paddingLeft: '0px' }}
        title="Insight Rules"
        subTitle="Click the row to edit"
      />
      <Button
        disabled={disabledAddButton}
        size="large"
        onClick={openDrawer}
        type="primary"
        style={{ padding: '0 50px' }}
      >
        Add
      </Button>
    </div>
  );
};

InsightRulesHeader.displayName = 'InsightRulesHeader';

export default InsightRulesHeader;
