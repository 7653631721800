import { Point } from '../redux/types';
import { getLatLng } from '../redux/selectors/aircraftData';

export function panMapToPoint(point: Point, map: google.maps.Map) {
  const pointLatLng = getLatLng(point);
  const currentBounds = map.getBounds();
  if (currentBounds && !currentBounds.contains(pointLatLng)) {
    map.panTo(pointLatLng);
  }
}

export default panMapToPoint;
