// @ts-ignore
import { SeriesClickEventObject, SeriesOptionsType, YAxisOptions } from 'highcharts';
import { CompleteFlightDataSamples } from '../../../types/FlightData';
import {
  getDisplayNameForParameter,
  getGroupNameForParameter,
  getParameterByName,
  getParameterGroupByName
} from './ChartParameters/parameterGroups';
import { UserData } from '../../../redux/types';
import { DisplayPoint } from '../../../common/api/spidertracks-sdk/types/TrackData';

const DP = 2;
const LEFT_COLOURS = ['#0050B3', '#40A9FF', '#BAE7FF'];
// lighter shades of red
const RIGHT_COLOURS = ['#A8071A', '#FF4D4F', '#FFCCC7'];

interface SeriesSample {
  timestamp: number;
  value: number;
}

export interface FlightReportDataSeries {
  name: string;
  axis: 'LEFT' | 'RIGHT';
  navigator?: boolean;
  data: SeriesSample[];
}

export interface FlightReportDataAxis {
  name: string;
  alignment: 'LEFT' | 'RIGHT';
}

export function toSeries(
  series: FlightReportDataSeries,
  callback: (category: string) => void,
  seriesIndex: number
): SeriesOptionsType {
  const isTerrain = series.name === 'Terrain';
  // Lighter shades of blue
  const color = series.axis === 'LEFT' ? LEFT_COLOURS[seriesIndex] : RIGHT_COLOURS[seriesIndex];

  return {
    visible: true,
    color,
    name: series.name,
    fillColor: 'rgba(225,225,225,0.47)',
    lineWidth: 1,
    data: series.data.map(v => [v.timestamp, v.value]),
    type: series.name === 'Terrain' ? 'area' : 'line',
    yAxis: series.axis === 'LEFT' ? 0 : 1,
    allowPointSelect: false,
    showInNavigator: !!series.navigator,
    navigatorOptions: {
      visible: !!series.navigator
    },
    events: {
      click: (sc: SeriesClickEventObject) => {
        callback(String(sc.point.category));
      }
    },
    marker: {
      enabled: false
    }
  };
}

export function toAircraftSeries(series: FlightReportDataSeries[]): SeriesOptionsType {
  const timestamps = Array.from(
    new Set(
      series.reduce<number[]>((acc, val) => {
        const t = val.data.map(v => v.timestamp);
        acc.push(...t);
        return acc;
      }, [])
    )
  );
  timestamps.sort();

  return {
    visible: true,
    name: 'AIRCRAFT_POSITION',
    data: timestamps.map(v => [v, 0]),
    type: 'line',
    color: '#00000000',
    yAxis: 0,
    allowPointSelect: false,
    showInNavigator: false,
    showInLegend: false,
    navigatorOptions: {
      visible: false
    },
    enableMouseTracking: false,
    marker: {
      enabled: false
    },
    states: {
      hover: {
        enabled: false
      }
    }
  };
}

export function toAxis(axis: FlightReportDataAxis): YAxisOptions {
  const color = axis.alignment === 'LEFT' ? LEFT_COLOURS[0] : RIGHT_COLOURS[0];
  return {
    title: {
      text: axis.name,
      style: {
        color
      }
    },
    labels: {
      align: axis.alignment === 'RIGHT' ? 'left' : 'right',
      style: {
        color
      }
    },

    opposite: axis.alignment === 'RIGHT',
    gridLineWidth: 1,
    lineWidth: 1,
    lineColor: color
  };
}

function createAxis(
  seriesGroupName: string,
  alignment: 'LEFT' | 'RIGHT',
  userData: UserData
): FlightReportDataAxis | null {
  if (seriesGroupName) {
    const pg = getParameterGroupByName(seriesGroupName);
    const unitStr = pg ? ` (${pg.unit(userData)})` : '';
    return {
      name: `${seriesGroupName}${unitStr}`,
      alignment
    };
  }
  return null;
}

function displayPointsToFlightReportSeries(
  samples: DisplayPoint[],
  seriesName: string,
  seriesInNavigator: boolean,
  axis: 'LEFT' | 'RIGHT',
  userData: UserData
): FlightReportDataSeries {
  const parameter = getParameterByName(seriesName);
  let data: SeriesSample[] = [];
  if (parameter?.accessors?.displayPoint) {
    const transform = parameter.accessors.displayPoint;
    data = samples.map(sample => {
      return {
        timestamp: sample.timestamp,
        value: transform(sample, userData)
      };
    });
  } else {
    data = [];
  }
  const flightReportDataSeries: FlightReportDataSeries = {
    name: getDisplayNameForParameter(seriesName),
    axis,
    data
  };

  if (seriesInNavigator) {
    flightReportDataSeries.navigator = true;
  }

  return flightReportDataSeries;
}

function flightDataToFlightReportSeries(
  flightDataSamples: CompleteFlightDataSamples,
  seriesName: string,
  seriesInNavigator: boolean,
  axis: 'LEFT' | 'RIGHT',
  userData: UserData
): FlightReportDataSeries {
  let data: SeriesSample[] = [];
  const parameter = getParameterByName(seriesName);
  if (parameter?.accessors?.flightDataSample) {
    const transform = parameter.accessors.flightDataSample;
    data = flightDataSamples.raw.map(sample => {
      return {
        timestamp: sample.sampleTimeEpochMillis,
        value: transform(sample, userData)
      };
    });
  } else {
    data = [];
  }
  const flightReportDataSeries: FlightReportDataSeries = {
    name: getDisplayNameForParameter(seriesName),
    axis,
    data
  };

  if (seriesInNavigator) {
    flightReportDataSeries.navigator = true;
  }

  return flightReportDataSeries;
}

interface ChartSeries {
  left: string[];
  right: string[];
}

function getSeriesInNavigator(chartSeries: ChartSeries): string {
  const leftSeries = chartSeries.left;
  if (leftSeries.includes('AMSL')) {
    return 'AMSL';
  }

  return leftSeries[0];
}

export function transformFlightDataSamplesToSeries(
  flightDataSamples: CompleteFlightDataSamples,
  userData: UserData,
  chartSeries: ChartSeries
) {
  const series: FlightReportDataSeries[] = [];

  const navigatorSeries = getSeriesInNavigator(chartSeries);
  for (const seriesValue of chartSeries.left) {
    const seriesNameInNavigator = seriesValue === navigatorSeries;
    series.push(
      flightDataToFlightReportSeries(
        flightDataSamples,
        seriesValue,
        seriesNameInNavigator,
        'LEFT',
        userData
      )
    );
  }

  for (const seriesValue of chartSeries.right) {
    series.push(
      flightDataToFlightReportSeries(flightDataSamples, seriesValue, false, 'RIGHT', userData)
    );
  }

  return series;
}

export function transformTrackDataToSeries(
  displayPoints: DisplayPoint[],
  userData: UserData,
  chartSeries: ChartSeries
) {
  const series: FlightReportDataSeries[] = [];
  const navigatorSeries = getSeriesInNavigator(chartSeries);
  for (const seriesValue of chartSeries.left) {
    const seriesNameInNavigator = seriesValue === navigatorSeries;
    series.push(
      displayPointsToFlightReportSeries(
        displayPoints,
        seriesValue,
        seriesNameInNavigator,
        'LEFT',
        userData
      )
    );
  }

  for (const seriesValue of chartSeries.right) {
    series.push(
      displayPointsToFlightReportSeries(displayPoints, seriesValue, false, 'RIGHT', userData)
    );
  }

  return series;
}

export function getFlightReportDataAxes(chartSeries: ChartSeries, userData: UserData) {
  const axes: FlightReportDataAxis[] = [];

  if (chartSeries.left.length > 0) {
    const leftSeriesGroup = getGroupNameForParameter(chartSeries.left[0]);
    if (leftSeriesGroup) {
      const leftAxis = createAxis(leftSeriesGroup, 'LEFT', userData);
      if (leftAxis) {
        axes.push(leftAxis);
      }
    }
  }

  if (chartSeries.right.length > 0) {
    const rightSeriesGroup = getGroupNameForParameter(chartSeries.right[0]);
    if (rightSeriesGroup) {
      const rightAxis = createAxis(rightSeriesGroup, 'RIGHT', userData);
      if (rightAxis) {
        axes.push(rightAxis);
      }
    }
  }

  return axes;
}
