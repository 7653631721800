import { EventExplorerEvent } from '../../common/api/spidertracks-sdk/types/EventExplorerEvent';
import { EventsQueryParams } from '../../common/api/spidertracks-sdk/private/services/EventsExplorerService';
import { getInstance } from '../../common/api/spidertracks-sdk';

export interface EventsDataResponse {
  events: EventExplorerEvent[];
  total?: number;
  error?: Error;
}

export const getEventsData = async (params: EventsQueryParams): Promise<EventsDataResponse> => {
  if (!(params.organisationIds.length && params.aircraft.length)) {
    return { events: [], total: 0 };
  }

  const apiResponse = await getInstance()
    .getEventsExplorerService()
    .getEvents(params);

  if (apiResponse.error) {
    return { events: [], error: apiResponse.error };
  }

  return apiResponse;
};
