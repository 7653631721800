import { useEffect } from 'react';
import { useParams } from 'react-router';
import useLocalStorage from './useLocalStorage';

export function useMostRecentOrganisation() {
  const { organisationId } = useParams();

  // Sometimes Userdata hasnt yet loaded
  const [orgId, setOrgId] = useLocalStorage('mostRecentOrganisation');

  // update org id in local storage if it has changed in the route
  useEffect(() => {
    if (organisationId !== undefined) {
      setOrgId(organisationId);
    }
  }, [organisationId]);

  return [orgId, setOrgId];
}
