import React from 'react';
import SpiderSettings from './spiderSettings/SpiderSettings';

const style = { overflow: 'scroll', height: '100%' };

const SettingsContainer = () => (
  <div className="px-5 py-4" style={style}>
    <SpiderSettings />
  </div>
);

export default SettingsContainer;
