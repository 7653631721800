import React, { Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from '@spidertracks/common';

export function PublicRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => (
        <Fragment>
          {!isAuthenticated() && <Component {...props} {...rest} />}
          {isAuthenticated() && (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location }
              }}
            />
          )}
        </Fragment>
      )}
    />
  );
}

export default PublicRoute;
