export const aircraftMarkerColors: Record<string, string> = {
  default: '#BAD928',
  magenta: '#F759AB',
  purple: '#9254DE',
  darkblue: '#2F54EB',
  blue: '#4096FF',
  cyan: '#13C2C2',
  darkgreen: '#006400',
  green: '#389E0D',
  yellow: '#FADB14',
  amber: '#FFA940',
  orange: '#FA541C',
  red: '#BA2321'
};
