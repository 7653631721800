import {
  activeMarker,
  blocksoff,
  blockson,
  casAdvisoryAssertedIcon,
  casAdvisoryClearedIcon,
  casCautionAssertedIcon,
  casWarningAssertedIcon,
  casWarningOrCautionClearedIcon,
  defaultEventsTimelineMarker,
  defaultMarker,
  emptyIcon,
  fireDropEndIcon,
  fireDropStartIcon,
  fireFightingIcon,
  fireFillEndIcon,
  fireFillStartIcon,
  firePumpOffIcon,
  firePumpOnIcon,
  fixedWingSingleEventsTimelineMarker,
  fixedWingSingleMarker,
  fixedWingTwinEventsTimelineMarker,
  fixedWingTwinMarker,
  greyRoundIcon,
  insightSeverityHighIcon,
  insightSeverityLowIcon,
  insightSeverityMediumIcon,
  insightSeverityNotSpecifiedIcon,
  landing,
  mark1,
  mark2,
  mark3,
  mark4,
  normalRocRod,
  radius,
  rocExceeded,
  rodExceeded,
  rotaryWingEventsTimelineMarker,
  rotaryWingMarker,
  smallColouredDotIcon,
  smallGreyDotIcon,
  sosOn,
  speeddown,
  speedup,
  startFinishTrackIcon,
  takeoff,
  touchAndGoLandingIcon,
  watchon
} from '../../GoogleMap/markers/Markers';
import {
  ACTIVE_GMAP_CONTROLLED_ICON,
  EVENT_CONTROLLED_ICON,
  GMAP,
  GMAP_CONTROLLED_ICON,
  GOOGLE,
  STANDARD_POINT_GMAP_CONTROLLED_ICON
} from '../../../../../common/constants';
import {
  AircraftTrackState,
  TrackPoint
} from '../../../../../common/api/spidertracks-sdk/types/TrackData';
import { AircraftTrackProperties } from '../../../../../types/aircraft';
import { EventSeverity } from '../../../../../redux/types';

export const checkPointTrackStatus = (
  pointTrackStatus: string | undefined,
  aircraftHeading: number | null
) => {
  if (pointTrackStatus === 'SPIDERWATCH') {
    return defaultMarker('blue', aircraftHeading);
  }
  if (pointTrackStatus === 'SOS') {
    return defaultMarker('red', aircraftHeading);
  }

  return false;
};

interface PolylineOptions {
  path: number[];
  geodesic?: boolean;
  strokeColor?: string;
  strokeOpacity?: number;
  strokeWeight?: number;
  fillColor?: string;
  fillOpacity?: number;
}

export const polyline = (polylineOptions: PolylineOptions) => {
  const google = GOOGLE();
  window.mapData = {
    polylines: [],
    ...window.mapData
  };
  const line = new google.maps.Polyline({
    path: polylineOptions.path,
    geodesic: polylineOptions.geodesic || false,
    strokeColor: polylineOptions.strokeColor || 'red',
    strokeOpacity: polylineOptions.strokeOpacity || 1.0,
    strokeWeight: polylineOptions.strokeWeight || 2,
    fillColor: polylineOptions.fillColor || 'red',
    fillOpacity: polylineOptions.fillOpacity || 0.35
  });

  window.mapData.polylines.push(line);
  return line;
};

// For now assign a number to start/finish of track from fe.
export const StartFinishIconNumber = 201;

export const BUTTON_MODES = {
  POINTS: {
    NORMAL: 0,
    MARK1: 61,
    MARK2: 62,
    MARK3: 63,
    MARK4: 64,
    WATCH_ON: 50,
    WATCH_OFF: 51,
    WATCH_ON_RESEND: 52,
    WATCH_OFF_RESEND: 53,
    WATCH_ON_AUTOMATED: 57,
    SPEED_UP: 58,
    SPEED_DOWN: 59,
    RADIUS: 68,
    ROC_ON: 80,
    ROC_OFF: 81,
    ROD_ON: 82,
    ROD_OFF: 83,
    ISOLATED_INPUT1_TRIGGERED: 90,
    ISOLATED_INPUT1_RELEASED: 91,
    ISOLATED_INPUT2_TRIGGERED: 92,
    ISOLATED_INPUT2_RELEASED: 93,
    NON_ISOLATED_INPUT1_TRIGGERED: 94,
    NON_ISOLATED_INPUT1_RELEASED: 95,
    NON_ISOLATED_INPUT2_TRIGGERED: 96,
    NON_ISOLATED_INPUT2_RELEASED: 97,
    SOS_ON: 111,
    ENGINE_ON: 135,
    ENGINE_OFF: 136,
    HOVER_START: 150,
    HOVER_STOP: 151,
    MANIFEST: 160,
    TOUCH_AND_GO_TAKEOFF: 147,
    TOUCH_AND_GO_LANDING: 148
  },
  EVENTS: {
    DEFAULT: 200,
    CAS_WARNING_ASSERTED: 202,
    CAS_WARNING_CLEARED: 203,
    CAS_CAUTION_ASSERTED: 204,
    CAS_CAUTION_CLEARED: 205,
    CAS_ADVISORY_ASSERTED: 206,
    CAS_ADVISORY_CLEARED: 207,
    BLOCKS_OFF: 986,
    BLOCKS_ON: 987,
    FIRE_ATU: 988,
    LANDING: 989,
    TAKEOFF: 990,
    TOUCH_AND_GO_LANDING: 991,
    INSIGHTS_SEVERITY_NOT_SPECIFIED: 300,
    INSIGHTS_SEVERITY_LOW: 301,
    INSIGHTS_SEVERITY_MEDIUM: 302,
    INSIGHTS_SEVERITY_HIGH: 303
  }
};

const icon = (svg: string | undefined | null, unique: boolean) => ({ svg, unique });

const buttonModeMap = {
  [BUTTON_MODES.POINTS.NORMAL]: icon(smallGreyDotIcon(), false),
  [BUTTON_MODES.POINTS.MARK1]: icon(mark1(), true),
  [BUTTON_MODES.POINTS.MARK2]: icon(mark2(), true),
  [BUTTON_MODES.POINTS.MARK3]: icon(mark3(), true),
  [BUTTON_MODES.POINTS.MARK4]: icon(mark4(), true),
  [BUTTON_MODES.POINTS.WATCH_ON]: icon(watchon(), false),
  [BUTTON_MODES.POINTS.WATCH_OFF]: icon(watchon(), false),
  [BUTTON_MODES.POINTS.WATCH_ON_RESEND]: icon(watchon(), false),
  [BUTTON_MODES.POINTS.WATCH_OFF_RESEND]: icon(watchon(), false),
  [BUTTON_MODES.POINTS.WATCH_ON_AUTOMATED]: icon(watchon(), false),
  [BUTTON_MODES.POINTS.SPEED_UP]: icon(speedup(), true),
  [BUTTON_MODES.POINTS.SPEED_DOWN]: icon(speeddown(), true),
  [BUTTON_MODES.POINTS.ROC_OFF]: icon(normalRocRod(), false),
  [BUTTON_MODES.POINTS.ROD_OFF]: icon(normalRocRod(), false),
  [BUTTON_MODES.POINTS.ROC_ON]: icon(rocExceeded(), true),
  [BUTTON_MODES.POINTS.ROD_ON]: icon(rodExceeded(), true),
  [BUTTON_MODES.POINTS.SOS_ON]: icon(sosOn(), true),
  [BUTTON_MODES.POINTS.RADIUS]: icon(radius(), true),
  [BUTTON_MODES.EVENTS.FIRE_ATU]: icon(greyRoundIcon(), false),
  [BUTTON_MODES.EVENTS.LANDING]: icon(landing(), true),
  [BUTTON_MODES.EVENTS.TAKEOFF]: icon(takeoff(), true),
  [BUTTON_MODES.EVENTS.BLOCKS_OFF]: icon(blocksoff(), true),
  [BUTTON_MODES.EVENTS.BLOCKS_ON]: icon(blockson(), true),
  [BUTTON_MODES.EVENTS.DEFAULT]: icon(greyRoundIcon(), false),
  [StartFinishIconNumber]: icon(startFinishTrackIcon(), true),
  [BUTTON_MODES.POINTS.ENGINE_ON]: icon(greyRoundIcon(), false),
  [BUTTON_MODES.POINTS.ENGINE_OFF]: icon(greyRoundIcon(), false),
  [BUTTON_MODES.POINTS.HOVER_START]: icon(greyRoundIcon(), false),
  [BUTTON_MODES.POINTS.HOVER_STOP]: icon(greyRoundIcon(), false),
  [BUTTON_MODES.POINTS.ISOLATED_INPUT1_TRIGGERED]: icon(greyRoundIcon(), false),
  [BUTTON_MODES.POINTS.ISOLATED_INPUT1_RELEASED]: icon(greyRoundIcon(), false),
  [BUTTON_MODES.POINTS.ISOLATED_INPUT2_TRIGGERED]: icon(greyRoundIcon(), false),
  [BUTTON_MODES.POINTS.ISOLATED_INPUT2_RELEASED]: icon(greyRoundIcon(), false),
  [BUTTON_MODES.POINTS.NON_ISOLATED_INPUT1_TRIGGERED]: icon(greyRoundIcon(), false),
  [BUTTON_MODES.POINTS.NON_ISOLATED_INPUT1_RELEASED]: icon(greyRoundIcon(), false),
  [BUTTON_MODES.POINTS.NON_ISOLATED_INPUT2_TRIGGERED]: icon(greyRoundIcon(), false),
  [BUTTON_MODES.POINTS.NON_ISOLATED_INPUT2_RELEASED]: icon(greyRoundIcon(), false),
  [BUTTON_MODES.POINTS.MANIFEST]: icon(null, false),
  [BUTTON_MODES.POINTS.TOUCH_AND_GO_TAKEOFF]: icon(null, false),
  [BUTTON_MODES.POINTS.TOUCH_AND_GO_LANDING]: icon(null, false),
  [BUTTON_MODES.EVENTS.TOUCH_AND_GO_LANDING]: icon(touchAndGoLandingIcon(), true),
  [BUTTON_MODES.EVENTS.CAS_WARNING_ASSERTED]: icon(casWarningAssertedIcon(), true),
  [BUTTON_MODES.EVENTS.CAS_CAUTION_ASSERTED]: icon(casCautionAssertedIcon(), true),
  [BUTTON_MODES.EVENTS.CAS_ADVISORY_ASSERTED]: icon(casAdvisoryAssertedIcon(), true),
  [BUTTON_MODES.EVENTS.CAS_WARNING_CLEARED]: icon(casWarningOrCautionClearedIcon(), false),
  [BUTTON_MODES.EVENTS.CAS_CAUTION_CLEARED]: icon(casWarningOrCautionClearedIcon(), false),
  [BUTTON_MODES.EVENTS.CAS_ADVISORY_CLEARED]: icon(casAdvisoryClearedIcon(), true),
  [BUTTON_MODES.EVENTS.INSIGHTS_SEVERITY_NOT_SPECIFIED]: icon(
    insightSeverityNotSpecifiedIcon(),
    true
  ),
  [BUTTON_MODES.EVENTS.INSIGHTS_SEVERITY_LOW]: icon(insightSeverityLowIcon(), true),
  [BUTTON_MODES.EVENTS.INSIGHTS_SEVERITY_MEDIUM]: icon(insightSeverityMediumIcon(), true),
  [BUTTON_MODES.EVENTS.INSIGHTS_SEVERITY_HIGH]: icon(insightSeverityHighIcon(), true),
  [`${BUTTON_MODES.EVENTS.FIRE_ATU}_FIRE_PUMP_ON`]: icon(firePumpOnIcon(), true),
  [`${BUTTON_MODES.EVENTS.FIRE_ATU}_FIRE_PUMP_OFF`]: icon(firePumpOffIcon(), true),
  [`${BUTTON_MODES.EVENTS.FIRE_ATU}_FIRE_FILL_START`]: icon(fireFillStartIcon(), true),
  [`${BUTTON_MODES.EVENTS.FIRE_ATU}_FIRE_FILL_END`]: icon(fireFillEndIcon(), true),
  [`${BUTTON_MODES.EVENTS.FIRE_ATU}_FIRE_DROP_START`]: icon(fireDropStartIcon(), true),
  [`${BUTTON_MODES.EVENTS.FIRE_ATU}_FIRE_DROP_END`]: icon(fireDropEndIcon(), true)
};

// Keep this in sync with https://github.com/Spidertracks/ingest.pipeline/tree/master/src/packages/schema/lib/schema/events
const eventToIcon: Record<string, Record<string, string | undefined>> = {
  ENGINE: {
    STARTS: undefined,
    OFF: greyRoundIcon(),
    ON: greyRoundIcon(),
    RUNTIME: undefined
  },
  FIRE: {
    DROP_END: fireDropEndIcon(),
    DROP_START: fireDropStartIcon(),
    FILL_END: fireFillEndIcon(),
    FILL_START: fireFillStartIcon(),
    PUMP_OFF: firePumpOffIcon(),
    PUMP_ON: firePumpOnIcon()
  },
  FLIGHT: {
    BLOCKS_OFF: blocksoff(),
    BLOCKS_ON: blockson(),
    HOVER_START: greyRoundIcon(),
    HOVER_STOP: greyRoundIcon(),
    LANDING: landing(),
    TAKEOFF: takeoff(),
    TAXI_START: undefined,
    TAXI_STOP: undefined,
    TOUCH_AND_GO_LANDING: touchAndGoLandingIcon(),
    TOUCH_AND_GO_TAKEOFF: undefined,
    UNQUALIFIED_LANDING: undefined,
    UNQUALIFIED_TAKEOFF: undefined,
    ROC_EXCEEDED: rocExceeded(),
    ROC_NORMAL: normalRocRod(),
    ROD_EXCEEDED: rodExceeded(),
    ROD_NORMAL: normalRocRod(),
    SPEED_UP: speedup(),
    SPEED_DOWN: speeddown(),
    MARK1: mark1(),
    MARK2: mark2(),
    MARK3: mark3(),
    MARK4: mark4(),
    SOS: sosOn()
  },
  POWER: {
    OFF: undefined,
    ON: undefined,
    RESTART: undefined,
    RESTORED: undefined
  }
};

export const getIconSvg = (
  buttonMode: number,
  pointDescription?: string
): { svg: string | undefined | null; unique: boolean } => {
  if (buttonMode === BUTTON_MODES.EVENTS.FIRE_ATU && pointDescription) {
    const fireIcon =
      buttonModeMap[`${buttonMode}_FIRE_${pointDescription.toUpperCase().replace(' ', '_')}`];

    return fireIcon ?? icon(fireFightingIcon(), false);
  }

  return buttonModeMap[buttonMode] || icon(null, false);
};

export const getEncodedIconUrl = (iconSvg: string | undefined | null) =>
  iconSvg ? `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(iconSvg)}` : '';

export const getIconUrlFromButtonMode = (buttonMode: number, pointDescription?: string) =>
  getEncodedIconUrl(getIconSvg(buttonMode, pointDescription)?.svg || '');

export const getIconForEvent = (
  eventType: string,
  eventSubtype: string | undefined
): string | undefined => {
  if (!eventSubtype) {
    return undefined;
  }

  const event = eventToIcon[eventType];
  if (!event) {
    return undefined;
  }
  const svg = event[eventSubtype];
  if (svg) {
    return getEncodedIconUrl(svg);
  }

  return undefined;
};

export const getIconForInsightsEvents = (severity: EventSeverity): string | undefined => {
  switch (severity) {
    case EventSeverity.INSIGHTS__NOT_SPECIFIED:
      return getEncodedIconUrl(insightSeverityNotSpecifiedIcon());
    case EventSeverity.INSIGHTS__LOW:
      return getEncodedIconUrl(insightSeverityLowIcon());
    case EventSeverity.INSIGHTS__MEDIUM:
      return getEncodedIconUrl(insightSeverityMediumIcon());
    case EventSeverity.INSIGHTS__HIGH:
      return getEncodedIconUrl(insightSeverityHighIcon());
    default:
      return getEncodedIconUrl(insightSeverityNotSpecifiedIcon());
  }
};

function getMapIconScaleSizeAnchorLabelOrigin(point: TrackPoint) {
  if (point.buttonMode === BUTTON_MODES.POINTS.NORMAL) {
    return {
      scaledSize: new window.google.maps.Size(
        STANDARD_POINT_GMAP_CONTROLLED_ICON.height,
        STANDARD_POINT_GMAP_CONTROLLED_ICON.width
      ),
      anchor: new window.google.maps.Point(
        STANDARD_POINT_GMAP_CONTROLLED_ICON.positionX,
        STANDARD_POINT_GMAP_CONTROLLED_ICON.positionY
      ),
      labelOrigin: new window.google.maps.Point(
        STANDARD_POINT_GMAP_CONTROLLED_ICON.width / 2, // anchor x coordinate to the center of the icon
        STANDARD_POINT_GMAP_CONTROLLED_ICON.height // anchor y coordinate to past the bottom of the icon
      )
    };
  }

  if (
    Object.values(BUTTON_MODES.EVENTS).includes(point.buttonMode) ||
    Object.values(BUTTON_MODES.POINTS).includes(point.buttonMode)
  ) {
    return {
      scaledSize: new window.google.maps.Size(
        EVENT_CONTROLLED_ICON.height,
        EVENT_CONTROLLED_ICON.width
      ),
      anchor: new window.google.maps.Point(
        EVENT_CONTROLLED_ICON.positionX,
        EVENT_CONTROLLED_ICON.positionY
      ),
      labelOrigin: new window.google.maps.Point(
        EVENT_CONTROLLED_ICON.width / 2, // anchor x coordinate to the center of the icon
        EVENT_CONTROLLED_ICON.height // anchor y coordinate to past the bottom of the icon
      )
    };
  }

  return {
    scaledSize: new window.google.maps.Size(
      GMAP_CONTROLLED_ICON.height,
      GMAP_CONTROLLED_ICON.width
    ),
    anchor: new window.google.maps.Point(
      GMAP_CONTROLLED_ICON.positionX,
      GMAP_CONTROLLED_ICON.positionY
    ),
    labelOrigin: new window.google.maps.Point(
      GMAP_CONTROLLED_ICON.width / 2, // anchor x coordinate to the center of the icon
      GMAP_CONTROLLED_ICON.height + 8 // anchor y coordinate to past the bottom of the icon
    )
  };
}

export const getColouredPointSvg = (point: TrackPoint, trackStatus: string, iconColour: string) => {
  const markerTemplate = smallColouredDotIcon(iconColour, trackStatus === 'ACTIVE');
  const urlEncoded = getEncodedIconUrl(markerTemplate);
  const { scaledSize, anchor, labelOrigin } = getMapIconScaleSizeAnchorLabelOrigin(point);

  return {
    url: urlEncoded,
    scaledSize,
    anchor,
    labelOrigin
  };
};

export const getMarkerIcon = (point: TrackPoint) => {
  let markerTemplate = getIconSvg(point.buttonMode, point.description).svg;
  if (markerTemplate == null) {
    markerTemplate = emptyIcon();
  }
  const urlEncoded = getEncodedIconUrl(markerTemplate);
  const { scaledSize, anchor, labelOrigin } = getMapIconScaleSizeAnchorLabelOrigin(point);

  return {
    url: urlEncoded,
    scaledSize,
    anchor,
    labelOrigin
  };
};

// This function is used to render icons for specific track points with status the UI do not care about to more generic status.
// For example, SpiderTxt points in a track in watch mode are to be rendered as watch points.
function getNormalisedPointTrackStatus(
  pointTrackStatus: string | undefined,
  aircraftTrackState: AircraftTrackState | undefined
): string | undefined {
  if (aircraftTrackState === AircraftTrackState.SPIDERWATCH) {
    if (pointTrackStatus === 'TEXT_COMMAND' || pointTrackStatus === 'TEXT_MESSAGE') {
      return 'SPIDERWATCH';
    }
  }

  if (aircraftTrackState === AircraftTrackState.SOS) {
    if (pointTrackStatus === 'TEXT_COMMAND' || pointTrackStatus === 'TEXT_MESSAGE') {
      return 'SOS';
    }
  }

  return pointTrackStatus;
}

export function getVehicleMarkerIcon(
  aircraftIcon: string | undefined,
  aircraftIconColour: string | undefined,
  point?: TrackPoint
) {
  let markerTemplate;
  // trackStatus is actually track point state and aircraftTrackState is track state
  let { heading: aircraftHeading, trackStatus, aircraftTrackState } = point ?? {
    heading: 0,
    trackStatus: undefined
  };
  let pointTrackStatus = getNormalisedPointTrackStatus(trackStatus, aircraftTrackState);

  switch (aircraftIcon) {
    case 'FIXED_WING_SINGLE':
      markerTemplate =
        checkPointTrackStatus(pointTrackStatus, aircraftHeading) ||
        fixedWingSingleMarker(aircraftIconColour, aircraftHeading);
      break;
    case 'FIXED_WING_TWIN':
      markerTemplate =
        checkPointTrackStatus(pointTrackStatus, aircraftHeading) ||
        fixedWingTwinMarker(aircraftIconColour, aircraftHeading);
      break;
    case 'ROTARY_WING':
      markerTemplate =
        checkPointTrackStatus(pointTrackStatus, aircraftHeading) ||
        rotaryWingMarker(aircraftIconColour, aircraftHeading);
      break;
    default:
      markerTemplate =
        checkPointTrackStatus(pointTrackStatus, aircraftHeading) ||
        defaultMarker(aircraftIconColour || 'default', aircraftHeading);
  }

  return markerTemplate;
}

export function getVehicleMarkerIconForEventsTimeline(aircraftType: string) {
  let markerTemplate;

  switch (aircraftType) {
    case 'FIXED_WING':
      markerTemplate = fixedWingSingleEventsTimelineMarker();
      break;
    case 'FIXED_WING_SINGLE':
      markerTemplate = fixedWingSingleEventsTimelineMarker();
      break;
    case 'FIXED_WING_TWIN':
      markerTemplate = fixedWingTwinEventsTimelineMarker();
      break;
    case 'ROTARY_WING':
      markerTemplate = rotaryWingEventsTimelineMarker();
      break;
    default:
      markerTemplate = defaultEventsTimelineMarker();
      break;
  }

  return getEncodedIconUrl(markerTemplate);
}

export function getSelectedPointMarker(
  point: TrackPoint,
  aircraftProperties: AircraftTrackProperties
) {
  let markerTemplate = getVehicleMarkerIcon(
    aircraftProperties.aircraftIcon,
    aircraftProperties.aircraftIconColour,
    point
  );
  let mapIconScaleSizeAnchorLabelOrigin = {
    scaledSize: new window.google.maps.Size(
      GMAP_CONTROLLED_ICON.height,
      GMAP_CONTROLLED_ICON.width
    ),
    anchor: new window.google.maps.Point(
      GMAP_CONTROLLED_ICON.positionX,
      GMAP_CONTROLLED_ICON.positionY
    ),
    labelOrigin: new window.google.maps.Point(
      GMAP_CONTROLLED_ICON.width / 2, // anchor x coordinate to the center of the icon
      GMAP_CONTROLLED_ICON.height + 8 // anchor y coordinate to past the bottom of the icon
    )
  };
  if (point.buttonMode !== BUTTON_MODES.POINTS.NORMAL) {
    const m = getIconSvg(point.buttonMode, point.description);
    if (m.svg) {
      markerTemplate = m.svg;
    }
    const { scaledSize, anchor, labelOrigin } = getMapIconScaleSizeAnchorLabelOrigin(point);
    mapIconScaleSizeAnchorLabelOrigin = {
      scaledSize,
      anchor,
      labelOrigin
    };
  }
  const urlEncoded = getEncodedIconUrl(markerTemplate);
  return {
    url: urlEncoded,
    ...mapIconScaleSizeAnchorLabelOrigin
  };
}

export function getLatestPointMarker(
  lastPoint: TrackPoint,
  aircraftTrackProperties: AircraftTrackProperties
) {
  const urlEncoded = getEncodedIconUrl(
    getVehicleMarkerIcon(
      aircraftTrackProperties.aircraftIcon,
      aircraftTrackProperties.aircraftIconColour,
      lastPoint
    )
  );
  return {
    url: urlEncoded,
    scaledSize: new window.google.maps.Size(
      GMAP_CONTROLLED_ICON.height,
      GMAP_CONTROLLED_ICON.width
    ),
    anchor: new window.google.maps.Point(
      GMAP_CONTROLLED_ICON.positionX,
      GMAP_CONTROLLED_ICON.positionY
    ),
    labelOrigin: new window.google.maps.Point(
      GMAP_CONTROLLED_ICON.width / 2, // anchor x coordinate to the center of the icon
      GMAP_CONTROLLED_ICON.height + 8 // anchor y coordinate to past the bottom of the icon
    )
  };
}

export const createMarker = (options: any) => {
  const google = GOOGLE();
  const gMap = GMAP();
  const { latitude, longitude, icon, isDraggable } = options;
  window.mapData = {
    markers: [],
    ...window.mapData
  };
  const marker = new google.maps.Marker({
    position: new google.maps.LatLng(latitude, longitude),
    map: gMap,
    icon: icon || getMarkerIcon(options),
    draggable: isDraggable,
    clickable: true
  });
  window.mapData.markers.push(marker);

  return new Promise(resolve => resolve(marker));
};

export const activeMarkerIcon = () => {
  const google = GOOGLE();
  const urlEncoded = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(activeMarker())}`;
  return {
    url: urlEncoded,
    scaledSize: new google.maps.Size(
      ACTIVE_GMAP_CONTROLLED_ICON.height,
      ACTIVE_GMAP_CONTROLLED_ICON.width
    ),
    anchor: new google.maps.Point(
      ACTIVE_GMAP_CONTROLLED_ICON.positionX,
      ACTIVE_GMAP_CONTROLLED_ICON.positionY
    )
  };
};
