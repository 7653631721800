import { AvailableChartParameters } from './availableChartParameters';
import { CompleteFlightDataSamples } from '../../../../types/FlightData';

export type CHART_AXIS = 'LEFT' | 'RIGHT';

const DEFAULT_PARAMETER_GROUP_NAME_LEFT = 'Altitude';
const DEFAULT_PARAMETER_GROUP_NAME_RIGHT = 'Speed';

export type ScopedUseFeatureFlag = (feature: string) => boolean;

export const getParameterGroupMenuItems = (
  isAHRSAllowed: boolean,
  flightData: CompleteFlightDataSamples,
  hasFeatureFlagGetter: ScopedUseFeatureFlag
) => {
  const parameterGroupsFiltered = AvailableChartParameters.filter(group => {
    const membersWithoutAhrsRequirement = group.members.filter(
      member => !member.prerequisites?.AHRS || member.prerequisites?.AHRS === isAHRSAllowed
    );
    if (membersWithoutAhrsRequirement.length === 0) {
      return false;
    }
    if (group.featureFlag && !hasFeatureFlagGetter(group.featureFlag)) {
      return false;
    }

    const membersWithoutFlightDataRequirement = group.members.filter(
      member =>
        !member.prerequisites?.flightDataSample ||
        (flightData.raw.length > 0 &&
          flightData.raw.reduce<boolean>(
            (acc, data): boolean =>
              acc ||
              (member.prerequisites?.flightDataSample
                ? member.prerequisites?.flightDataSample(data)
                : false),
            false
          ))
    );
    if (membersWithoutFlightDataRequirement.length === 0) {
      return false;
    }

    return true;
  });

  return parameterGroupsFiltered.map(group => {
    return { key: group.key, label: group.name };
  });
};

export const getParameterGroupByName = (
  parameterGroupName: string,
  hasFeatureFlagGetter: ScopedUseFeatureFlag = () => false
) => {
  const pg = AvailableChartParameters.find(group => group.name === parameterGroupName);
  if (pg) {
    return {
      ...pg,
      members: pg.members.filter(member => {
        return (
          member.prerequisites?.featureFlag === undefined ||
          hasFeatureFlagGetter(member.prerequisites?.featureFlag)
        );
      })
    };
  }
};

export const getDisplayNameForParameter = (parameterName: string) => {
  for (const parameterGroup of AvailableChartParameters) {
    const parameter = parameterGroup.members.find(member => member.name === parameterName);
    if (parameter) {
      return parameter.displayName;
    }
  }

  return parameterName;
};

export const getGroupNameForParameter = (parameterName: string) => {
  for (const group of AvailableChartParameters) {
    if (group.members.find(member => member.name === parameterName)) {
      return group.name;
    }
  }

  return undefined;
};

export const getParameterByName = (name: string) => {
  for (const group of AvailableChartParameters) {
    const parameter = group.members.find(member => member.name === name);
    if (parameter) {
      return parameter;
    }
  }
  return undefined;
};

export const getDefaultSelectedMembers = (chartAxis: CHART_AXIS) => {
  const defaultParameterGroupNameForAxis =
    chartAxis === 'LEFT' ? DEFAULT_PARAMETER_GROUP_NAME_LEFT : DEFAULT_PARAMETER_GROUP_NAME_RIGHT;

  const defaultParameterGroup = getParameterGroupByName(defaultParameterGroupNameForAxis);
  if (defaultParameterGroup) {
    return defaultParameterGroup.defaultSelectedMemberNames;
  }

  return [];
};
