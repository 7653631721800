import React from 'react';

export const EmptyDrawer = () => (
  <div className="p-2">
    <p>
      Favourites allow you to save locations on the flying map so you can quickly load them in the
      future.
    </p>
    <p>
      You haven&apos;t saved any favourites yet. Click the Blue + button below to save your first
      one.
    </p>
  </div>
);
