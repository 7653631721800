import { useEffect, useState } from 'react';
import * as Cesium from 'cesium';
import { CesiumComponentRef } from 'resium';

let clockViewModelRef: Cesium.ClockViewModel | null = null;
let animationViewModelRef: Cesium.AnimationViewModel | null = null;

const useCesiumPlayerControls = (viewerRef: CesiumComponentRef<Cesium.Viewer> | null) => {
  const [playbackSpeed, setPlaybackSpeed] = useState(1);

  // Initialization
  useEffect(() => {
    if (viewerRef && viewerRef.cesiumElement) {
      const clockViewModel = new Cesium.ClockViewModel(viewerRef.cesiumElement.clock);
      clockViewModelRef = clockViewModel;

      const animationViewModel = new Cesium.AnimationViewModel(clockViewModel);
      animationViewModelRef = animationViewModel;
    }
  }, [viewerRef]);

  // Handlers
  const togglePlaybackStatus = (isPlaying: boolean) => {
    if (animationViewModelRef) {
      // @ts-ignore
      animationViewModelRef[isPlaying ? 'playForwardViewModel' : 'pauseViewModel'].command();
    }
  };

  const handlePlaybackSpeedChange = (value: number) => {
    if (clockViewModelRef) {
      clockViewModelRef.multiplier = value;
      setPlaybackSpeed(value);
    }
  };

  const updatePlaybackTimeline = (value: number) => {
    if (clockViewModelRef) {
      Cesium.JulianDate.addSeconds(
        clockViewModelRef.startTime,
        value,
        clockViewModelRef.currentTime
      );
    }
  };

  return {
    playbackSpeed,
    togglePlaybackStatus,
    handlePlaybackSpeedChange,
    updatePlaybackTimeline
  };
};

export default useCesiumPlayerControls;
