import React from 'react';
import Map2DView from '../Map2DView';
import Flight3DPlayer from '../Flight3DPlayer';
import { PointDetailPanel } from '../PointDetailPanel';

import './styles.scss';
import {
  DisplayPoint,
  PrivateTrackData
} from '../../../common/api/spidertracks-sdk/types/TrackData';

interface FlightTracklogViewerProps {
  selectedTrack: PrivateTrackData;
  displayPoints: DisplayPoint[];
  timezone: string;
  className: string;
}

const FlightTracklogViewer: React.FC<FlightTracklogViewerProps> = ({
  selectedTrack,
  displayPoints,
  className,
  timezone
}) => {
  const { aircraft } = selectedTrack;

  return (
    <div className={`flight-tracklog-viewer ${className}`}>
      <div className="multiViewContainer">
        <Map2DView className="map2DViewContainer" />
        <Flight3DPlayer
          selectedTrack={selectedTrack}
          displayPoints={displayPoints}
          className="map3DViewContainer"
          aircraftId={aircraft.id}
          timezone={timezone}
        />
      </div>

      <div className="pointDetailPanel">
        <PointDetailPanel timezone={timezone} />
      </div>
    </div>
  );
};

export default FlightTracklogViewer;
