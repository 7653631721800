import React, { FC } from 'react';
import { Button } from 'antd';

const LegacyInsightsDashboardButton: FC = ({}) => {
  return (
    <Button
      type="default"
      icon="inbox"
      size="large"
      style={{ padding: '0 1.0rem', marginRight: '1.3rem', float: 'right' }}
      href="/insights"
    >
      Archived Data
    </Button>
  );
};
export default LegacyInsightsDashboardButton;
