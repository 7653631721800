import * as constants from '../../GoogleMap/constants';
import { signature } from './signature';
import weatherdata from '../../../../../components/Flying/Map/MapControls/weather/weatherdata';

const baseUrl = `${constants.BARON.BASE_URL}/${constants.BARON.KEY}`;

const getWeatherConfig = productCode => {
  const selectedWeather = weatherdata.options.find(item => item.productCode === productCode);
  if (selectedWeather) {
    return selectedWeather.config;
  }
  return undefined;
};

export const imageUrlHelper = productCode => {
  const weatherConfig = getWeatherConfig(productCode);
  return `${baseUrl}/meta/tiles/product-instances/${productCode}/${weatherConfig}.jsonp?${signature()}&page_size=1&callback=?`;
};

export const baronUrlHelper = (productCode, time, zoom, x, y) => {
  const weatherConfig = getWeatherConfig(productCode);
  const imagePath = `${baseUrl}/tms/1.0.0/${productCode}+${weatherConfig}+${time}/${zoom}/${x}/${y}.png`;
  const query = `?${signature()}&valid_time=${time}`;

  return `${imagePath}${query}`;
};

// http://api.velocityweather.com/v1/YOUR_KEY/reports/aircraft_report/region.json?page=1&ts={timestamp}&sig={signature}&n_lat=35.0&s_lat=30.0&w_lon=-88.1&e_lon=-84.86
export const baronReportUrlHelper = (
  { productCode, config, incrementalLoadSupported },
  mapBounds,
  page
) => {
  if (incrementalLoadSupported) {
    const w = mapBounds.getSouthWest().lng();
    const e = mapBounds.getNorthEast().lng();

    return (
      `${baseUrl}/reports/${productCode}/${config}.json?` +
      `${signature()}&` +
      `page=${page}&` +
      `n_lat=${mapBounds.getNorthEast().lat()}&` +
      `s_lat=${mapBounds.getSouthWest().lat()}&` +
      `w_lon=${mapBounds.getSouthWest().lng()}&` +
      `e_lon=${w > e ? 180 : e}`
    );
  }

  return `${baseUrl}/reports/${productCode}/${config}.json?` + `${signature()}`;
};
