import React from 'react';
import PropTypes from 'prop-types';
import { EnabledSelectOption } from './EnabledSelectOption';
import { DisabledSelectOption } from './DisabledSelectOption';
import { Select } from 'antd';

export const getEventTypeOptions = ({
  ruleAlreadyConfigured,
  eventTypeDisplayName,
  eventType,
  newEventType
}) => (
  <Select.Option
    disabled={ruleAlreadyConfigured}
    key={eventType}
    label={eventTypeDisplayName}
    style={{ width: '100%' }}
  >
    {ruleAlreadyConfigured ? (
      <DisabledSelectOption name={eventTypeDisplayName} />
    ) : (
      <EnabledSelectOption name={eventTypeDisplayName} isNew={newEventType} />
    )}
  </Select.Option>
);

getEventTypeOptions.propTypes = {
  ruleAlreadyConfigured: PropTypes.bool,
  eventClass: PropTypes.string,
  eventType: PropTypes.string,
  eventTypeDisplayName: PropTypes.string,
  newEventType: PropTypes.bool
};
