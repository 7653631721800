import React, { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import WindDetails from './WindDetails';
import SkyConditionDetails from './SkyConditionDetails';
import VisibilityDetails from './VisibilityDetails';
import ChangeIndicatorDetails from './ChangeIndicatorDetails';

export const ForecastDetails = ({ forecast }) => {
  return (
    <Fragment>
      {forecast.change_indicator && (
        <ChangeIndicatorDetails changeIndicator={forecast.change_indicator} />
      )}
      {forecast.wind && typeof forecast.wind.speed === 'number' && (
        <WindDetails wind={forecast.wind} />
      )}
      {forecast.sky_condition && <SkyConditionDetails skyConditions={forecast.sky_condition} />}
      {forecast.visibility && typeof forecast.visibility.value === 'number' && (
        <VisibilityDetails visibility={forecast.visibility} />
      )}
    </Fragment>
  );
};

ForecastDetails.propTypes = {
  forecast: PropTypes.object.isRequired,
  utcTime: PropTypes.string
};

export default memo(ForecastDetails);
