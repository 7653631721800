import React from 'react';
import { FC } from 'react';
import { SearchOutlined } from '@material-ui/icons';
import styled from 'styled-components';
import FilterAlt from './icons/FilterAlt';

interface StyledProps {
  $filtered: boolean;
}

interface FilterIconProps {
  filtered: boolean;
  icon?: React.ReactNode;
}

const StyledSearchIcon = styled(SearchOutlined)<StyledProps>`
  &&&&&& {
    color: ${props => (props.$filtered ? '#ffffff' : '#40A9FF')};
  }
`;

const StyledFunnelIcon = styled(FilterAlt)<StyledProps>`
  height: 20px;
  path {
    fill: ${props => (props.$filtered ? '#ffffff' : '#40A9FF')};
  }
`;

const StyledWrapper = styled.div`
  &&&&&& {
    height: 100%;
    width: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #fafafa;
    :hover {
      background-color: inherit;
    }
  }
`;

const StyledSpan = styled.span<StyledProps>`
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: ${props => (props.$filtered ? '#69C0FF' : 'inherit')};
  :hover {
    background-color: ${props => (props.$filtered ? '#69C0FF' : '#e8e8e8')};
  }
`;

export const FilterIcon: FC<FilterIconProps> = props => {
  const { filtered, icon, ...defaults } = props;
  return (
    <StyledWrapper {...defaults}>
      <StyledSpan $filtered={props.filtered}>
        {icon || <StyledFunnelIcon $filtered={props.filtered} />}
      </StyledSpan>
    </StyledWrapper>
  );
};

export const FilterIconFunnel = (filtered: boolean) => <FilterIcon filtered={filtered} />;

export const FilterIconSearch = (filtered: boolean) => (
  <FilterIcon icon={<StyledSearchIcon $filtered={filtered} />} filtered={filtered} />
);
