import { createContext } from 'react';

const DownloadModalContextDefault = {
  modalVisible: false,
  setModalVisible: () => {}
};

const DownloadModalContext = createContext(DownloadModalContextDefault);

export { DownloadModalContext };
