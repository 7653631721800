import { Amplify } from '@spidertracks/common';
import { ServiceAbstract } from '../../ServiceAbstract';

enum InsightRuleCondition {
  pitchDown = 'pitchDown',
  pitchUp = 'pitchUp',
  lowAltitudeAgl = 'lowAltitudeAgl',
  lowAltitudeAmsl = 'lowAltitudeAmsl',
  lowSpeed = 'lowSpeed',
  highSpeed = 'highSpeed',
  rateOfClimb = 'rateOfClimb',
  roll = 'roll',
  rateOfDescent = 'rateOfDescent',
  highAltitudeAgl = 'highAltitudeAgl',
  highAltitudeAmsl = 'highAltitudeAmsl',
  gForce = 'gForce',
  temperature = 'temperature',
  rpm = 'rpm'
}

enum InsightRuleThresholdProperty {
  aglMetres = 'aglMetres',
  amslMetres = 'amslMetres',
  aircraftRollRadians = 'aircraftRollRadians',
  aircraftPitchRadians = 'aircraftPitchRadians',
  aircraftYawRadians = 'aircraftYawRadians',
  groundSpeed = 'groundSpeed',
  /**
   * Vertical speed DOWN
   */
  verticalSpeed = 'verticalSpeed',
  gForce = 'gForce',
  /** Used to mean that the property is determined by the value primary condition + the primary condition source
   * e.g. primaryCondition is temperature, then we look at primaryConditionSource. Say it's engine1Egt1, then we look at engine1Egt1DegreesC
   */
  primaryConditionValue = 'primaryConditionValue'
}

enum ConditionSource {
  engine1Cht1DegreesC = 'engine1Cht1DegreesC',
  engine1Egt1DegreesC = 'engine1Egt1DegreesC',
  engine1Rpm = 'engine1Rpm',
  indicatedAirSpeedMetresPerSecond = 'indicatedAirSpeedMetresPerSecond',
  groundSpeedMetresPerSecond = 'groundSpeedMetresPerSecond'
}

interface InsightEventRuleThreshold {
  thresholdProperty: InsightRuleThresholdProperty;
  thresholdValue: number;
  severityEnabled: boolean;
  thresholdValueLow: number;
  thresholdValueMedium: number;
  thresholdValueHigh: number;
}

interface InsightRule {
  id: string;
  name: string;
  enabled: boolean;
  organisationId: string;
  /// AKA trigger. We can have other conditions too.
  primaryCondition: InsightRuleCondition;
  secondaryCondition?: InsightRuleCondition;
  primaryConditionSource?: ConditionSource;
  aircraftIds: string[];
  allAircraft: boolean;
  thresholds: InsightEventRuleThreshold[];
}

export class InsightRulesService extends ServiceAbstract {
  public async getAllInsightRules(organisationId: string): Promise<InsightRule[]> {
    return await Amplify.API.get(
      'api.spidertracks.io-authenticated',
      `internal/organisations/${organisationId}/get-insight-rules`
    );
  }
}
