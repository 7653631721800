import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { UserData } from "./redux/types";

export function initSentry(config: any) {
  Sentry.init({
    dsn: config.dsn,
    integrations: [new BrowserTracing()],
    environment: config.environment,
    tracesSampleRate: 1.0,
  });
}

export function setSentryUserContext(userData: UserData) {
  Sentry.setUser({ email: userData.email });
}
