import { EventClass } from '../../constants';
import { BUTTON_MODES } from '../../components/Flying/Map/utils/drawing/marker';
import { getGeofenceEventDisplayName, getGeofenceEventProperties } from './geofence';
import { getFSIEventProperties, getFSIEventWithSeverityButtonMode } from './fsi';
import {
  CAS_SEVERITY_MAP,
  getCASEventButtonMode,
  getCASEventDisplayName,
  getCASEventProperties
} from './cas';

export const getEventDisplayName = (event, eventTypeNameMap) => {
  if (event.eventClass === EventClass.GEOFENCE) {
    return getGeofenceEventDisplayName(event);
  } else if (event.eventClass === EventClass.CAS) {
    return getCASEventDisplayName(event);
  }

  if (event.ruleName) {
    return event.ruleName;
  }
  return eventTypeNameMap[event.eventType];
};

export const getEventProperties = ({ event, latLongFormat, altitudeUnit, speedUnit }) => {
  switch (event.eventClass) {
    case EventClass.FSI:
      return getFSIEventProperties(event, latLongFormat, altitudeUnit, speedUnit);
    case EventClass.GEOFENCE:
      return getGeofenceEventProperties(event, latLongFormat, altitudeUnit, speedUnit);
    case EventClass.CAS:
      return getCASEventProperties(event, latLongFormat, altitudeUnit, speedUnit);
    default:
      return {};
  }
};

const getButtonMode = event => {
  if (event.eventClass === EventClass.CAS) {
    return getCASEventButtonMode(event);
  }

  if (event.eventClass === EventClass.FSI && !!event.eventDetails.severity) {
    return getFSIEventWithSeverityButtonMode(event);
  }

  return BUTTON_MODES.EVENTS.DEFAULT;
};

export function mapEventsToTrackPoints({
  events,
  eventTypes,
  latLongFormat,
  speedUnit,
  altitudeUnit
}) {
  const eventTypeNameMap = eventTypes.reduce((typeNameMapping, event) => {
    typeNameMapping[event.eventType] = event.eventTypeDisplayName;
    return typeNameMapping;
  }, {});

  return events.map(event => ({
    buttonMode: getButtonMode(event),
    timestamp: new Date(event.eventTime).getTime(),
    id: event.eventId,
    description: getEventDisplayName(event, eventTypeNameMap),
    ...getEventProperties({ event, latLongFormat, speedUnit, altitudeUnit })
  }));
}

export const getCasEventDisplayProperties = point => {
  const { description } = point;
  const [displayName, severityAndStatus] = description.split(' | ');
  const alert = `CAS ${severityAndStatus.toLowerCase()}`;
  const [severity, status] = severityAndStatus.split(' ');

  return {
    ...point,
    type: EventClass.CAS,
    alert,
    displayName,
    alertDisplayColorClass: CAS_SEVERITY_MAP[severity][status].displayColorClass
  };
};
