import { headers } from '@spidertracks/common';

const getSosUrl = () => `${window.env.STL_NODE_ORIGIN}/sos/latest`;
//this will not work for SOS as the URL structure changes
const sosActionUrl = () => `${window.env.STL_GO_ORIGIN}/api/sos/`;

export const getSos = dispatch =>
  fetch(getSosUrl(), {
    withCredentials: true,
    headers: headers()
  })
    .then(r => r.json())
    .then(sosData =>
      sosData.reduce((previousData, sos) => {
        if (sos.id !== undefined) {
          previousData.push(sos);
        } else {
          console.warn('%cSOS API Data is Malformed', 'font-size: 20px; color: red');
        }
        return previousData;
      }, [])
    )
    .then(data => {
      dispatch.sosData(data);
    })
    .catch(error => {
      console.error(error);
      return null;
    });

export const closeSos = id =>
  fetch(`${sosActionUrl()}${id}/resolve`, {
    method: 'POST',
    withCredentials: true,
    headers: headers()
  })
    .then(r => {
      if (!r.ok) {
        throw new Error('Bad response from sos resolve api');
      }
    })
    .catch(error => {
      console.error(error);
      return null;
    });

export const escalateSos = id =>
  fetch(`${sosActionUrl()}${id}/escalate`, {
    method: 'POST',
    withCredentials: true,
    headers: headers()
  })
    .then(r => r.text())
    .catch(error => {
      console.error(error);
      return null;
    });
