import React from 'react';
import PropTypes from 'prop-types';
import './styles/scss/aircraftlisttoggle.scss';

const AircraftListToggle = ({ aircraftListVisible, handleClick }) => (
  <div className="aircraft-list-menu-wrapper">
    <span
      className={`aircraft-list-menu ${!aircraftListVisible ? 'show-menu' : 'hide-menu'}`}
      role="presentation"
      onClick={handleClick}
    >
      <div className="aircraft-list-menu__slider">
        <i className="aircraft-list-menu__open material-icons">arrow_back_ios</i>
        <i className="aircraft-list-menu__close material-icons">arrow_forward_ios</i>
      </div>
    </span>
  </div>
);

AircraftListToggle.propTypes = {
  aircraftListVisible: PropTypes.bool,
  handleClick: PropTypes.func
};

export default AircraftListToggle;
