import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Marker, InfoWindow } from '@react-google-maps/api';
import { Row, Typography } from 'antd';
import InfoWindowRow from '../InfoWindowRow';
import {
  formatStandardDateStrings,
  fromISO8601,
  dateFormatter
} from '../../../../../helpers/formatTime';

const { Title } = Typography;

export const MetarMarker = ({ metar, dateFormat, timezone }) => {
  const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false);
  const [markerInstance, setMarkerInstance] = useState();

  const onMarkerClick = () => {
    setIsInfoWindowOpen(true);
  };

  const metarIssuetime = fromISO8601(metar.issuetime);
  const formatFn = dateFormatter(dateFormat);
  const [localIssuetime, utcIssuetime] = formatStandardDateStrings(
    formatFn,
    metarIssuetime,
    timezone
  );

  return (
    <Fragment>
      <Marker
        key={metar.raw_metar}
        icon={{
          scale: 5,
          fillColor: '#FD8D06',
          fillOpacity: 1,
          path: window.google.maps.SymbolPath.CIRCLE,
          strokeColor: '#FD8D06',
          strokeOpacity: 0.4,
          strokeWeight: 6
        }}
        onClick={onMarkerClick}
        onLoad={setMarkerInstance}
        position={{ lat: metar.station.coordinates[1], lng: metar.station.coordinates[0] }}
      />
      {markerInstance && isInfoWindowOpen && (
        <InfoWindow
          anchor={markerInstance}
          onCloseClick={() => setIsInfoWindowOpen(false)}
          zIndex={0}
          options={{ maxWidth: '450' }}
        >
          <Fragment>
            <Title level={4}>{metar.station.name}</Title>
            <InfoWindowRow title="Issued" content={localIssuetime} />
            <InfoWindowRow content={utcIssuetime} />

            {metar.temperature && typeof metar.temperature.value === 'number' && (
              <InfoWindowRow
                title="Temperature"
                content={`${metar.temperature.value}°${metar.temperature.units}`}
              />
            )}
            {metar.temperature && typeof metar.temperature.dew_point === 'number' && (
              <InfoWindowRow
                title="Dew Point"
                content={`${metar.temperature.dew_point}°${metar.temperature.units}`}
              />
            )}

            {metar.wind &&
              typeof metar.wind.dir === 'number' &&
              typeof metar.wind.speed === 'number' && (
                <InfoWindowRow
                  title="Wind"
                  content={`${metar.wind.dir}${
                    metar.wind.dir_units === 'deg' ? '°' : metar.wind.dir_units
                  } ${metar.wind.speed}${metar.wind.speed_units}`}
                />
              )}
            {metar.relative_humidity && typeof metar.relative_humidity.value === 'number' && (
              <InfoWindowRow
                title="Relative Humidity"
                content={`${metar.relative_humidity.value}${metar.relative_humidity.units}`}
              />
            )}
            {metar.weather_code && metar.weather_code.text && (
              <InfoWindowRow title="Sky Conditions" content={metar.weather_code.text} />
            )}
            {metar.visibility && typeof metar.visibility.value === 'number' && (
              <InfoWindowRow
                title="Visibility"
                content={`${metar.visibility.value}${metar.visibility.units}`}
              />
            )}
            <hr />
            <Row>{metar.raw_metar}</Row>
          </Fragment>
        </InfoWindow>
      )}
    </Fragment>
  );
};

MetarMarker.propTypes = {
  metar: PropTypes.object,
  dateFormat: PropTypes.string,
  timezone: PropTypes.string
};

MetarMarker.displayName = 'MetarMarker';

export default MetarMarker;
