import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Button, Spin } from 'antd';
import { useParams } from 'react-router';
import AddAircraftFormInput from './AddAircraftFormInput';
import AddAircraftFormDropdown from './AddAircraftFormDropdown';
import EditDisabled from './EditDisabled';
import showDeleteAircraftModal from './AircraftDeleteConfirmModal';
import AircraftRegistrationInput from './AircraftRegistrationInput';
import { aircraftMarkerColors } from '../../Flying/Map/GoogleMap/markers/markerColorCodes';
import styled from 'styled-components';
import { AircraftIcon } from './AircraftIcon';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ColorSwatch = styled.div`
  width: 18px;
  height: 18px;
  background-color: ${props => props.backgroundColor};
  border-radius: 5px;
`;

const getAircraftIconColourOption = (colour, colourName) => {
  const backgroundColor = aircraftMarkerColors[colour.toLowerCase()];

  return (
    <Wrapper>
      <ColorSwatch backgroundColor={backgroundColor} />
      &nbsp;&nbsp;
      {colourName}
    </Wrapper>
  );
};

const getAircraftIconOption = (colour, icon, iconName) => {
  return (
    <Wrapper>
      <AircraftIcon aircraft={{ aircraftIcon: icon, aircraftIconColor: colour }} dimensions={20} />
      &nbsp;&nbsp;
      {iconName}
    </Wrapper>
  );
};

export const AircraftForm = ({
  form,
  onClose,
  skipped,
  save,
  remove,
  currentAircraftSpider,
  submitButtonValue,
  isSubmitButtonDisabled,
  handleFieldChange
}) => {
  const { organisationId } = useParams();
  const { getFieldDecorator, validateFields, setFieldsValue, getFieldValue } = form;
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleteHidden, setIsDeleteHidden] = useState(true);
  const [isAircraftEditDisabled, setIsAircraftEditDisabled] = useState(true);

  const iconColourValue = getFieldValue('iconColour');

  useEffect(() => {
    if (currentAircraftSpider && currentAircraftSpider.aircraftId) {
      setFieldsValue(currentAircraftSpider);
      setIsDeleteHidden(false);
    }

    if (currentAircraftSpider && currentAircraftSpider.type === 'OTHER') {
      setIsAircraftEditDisabled(true);
    } else {
      setIsAircraftEditDisabled(false);
    }
  }, []);

  const saveAircraft = async e => {
    e.preventDefault();
    if (submitButtonValue === 'Skip') {
      skipped();
    } else {
      validateFields(async (err, values) => {
        if (!err) {
          setIsSaving(true);
          const aircraft = {
            organisationId,
            registration: values.registration,
            make: values.make ? values.make : '',
            model: values.model ? values.model : '',
            type: values.type ? values.type : 'UNDEFINED',
            icon: values.icon ? values.icon : 'DEFAULT',
            iconColour: values.iconColour ? values.iconColour : 'DEFAULT'
          };

          await save(aircraft);
          setIsSaving(false);
        }
      });
    }
  };

  return (
    <Form
      onSubmit={saveAircraft}
      layout="vertical"
      hideRequiredMark={false}
      style={{ width: '308px', margin: '30px 0 0 32px' }}
    >
      <AircraftRegistrationInput
        name="registration"
        label="Registration"
        getFieldDecorator={getFieldDecorator}
        validationRules={[
          {
            required: true,
            message: 'An Aircraft registration is required'
          },
          { whitespace: true, message: 'Registration cannot be empty' }
        ]}
        placeholder="Enter aircraft registration"
        handleFieldChange={handleFieldChange}
      />
      <AddAircraftFormDropdown
        name="type"
        label="Type"
        getFieldDecorator={getFieldDecorator}
        handleFieldChange={handleFieldChange}
        dropdownOptions={[
          { actualValue: 'FIXED_WING', displayedValue: 'Fixed Wing' },
          { actualValue: 'ROTARY_WING', displayedValue: 'Rotary Wing' }
        ]}
        placeholder="Select aircraft type"
        validationRules={[
          {
            required: true,
            message: 'An Aircraft type is required'
          }
        ]}
        disabled={isAircraftEditDisabled}
      />
      <EditDisabled
        show={isAircraftEditDisabled}
        message="Please contact customer support if this needs to be updated."
      />
      <AddAircraftFormInput
        name="make"
        label="Make"
        getFieldDecorator={getFieldDecorator}
        handleFieldChange={handleFieldChange}
      />
      <AddAircraftFormInput
        name="model"
        label="Model"
        getFieldDecorator={getFieldDecorator}
        handleFieldChange={handleFieldChange}
      />
      <AddAircraftFormDropdown
        name="icon"
        label="Icon"
        getFieldDecorator={getFieldDecorator}
        dropdownOptions={[
          {
            actualValue: 'DEFAULT',
            displayedValue: getAircraftIconOption(iconColourValue, 'DEFAULT', 'Default')
          },
          {
            actualValue: 'FIXED_WING_SINGLE',
            displayedValue: getAircraftIconOption(
              iconColourValue,
              'FIXED_WING_SINGLE',
              'Fixed Wing single'
            )
          },
          {
            actualValue: 'FIXED_WING_TWIN',
            displayedValue: getAircraftIconOption(
              iconColourValue,
              'FIXED_WING_TWIN',
              'Fixed Wing twin'
            )
          },
          {
            actualValue: 'ROTARY_WING',
            displayedValue: getAircraftIconOption(iconColourValue, 'ROTARY_WING', 'Rotary Wing')
          }
        ]}
        placeholder="Select aircraft icon"
        handleFieldChange={handleFieldChange}
        disabled={isAircraftEditDisabled}
      />
      <AddAircraftFormDropdown
        name="iconColour"
        label="Icon colour"
        getFieldDecorator={getFieldDecorator}
        dropdownOptions={[
          {
            actualValue: 'MAGENTA',
            displayedValue: getAircraftIconColourOption('MAGENTA', 'Magenta')
          },
          {
            actualValue: 'PURPLE',
            displayedValue: getAircraftIconColourOption('PURPLE', 'Purple')
          },
          {
            actualValue: 'DARKBLUE',
            displayedValue: getAircraftIconColourOption('DARKBLUE', 'Dark Blue')
          },
          { actualValue: 'BLUE', displayedValue: getAircraftIconColourOption('BLUE', 'Blue') },
          { actualValue: 'CYAN', displayedValue: getAircraftIconColourOption('CYAN', 'Cyan') },
          {
            actualValue: 'DARKGREEN',
            displayedValue: getAircraftIconColourOption('DARKGREEN', 'Dark Green')
          },
          { actualValue: 'GREEN', displayedValue: getAircraftIconColourOption('GREEN', 'Green') },
          {
            actualValue: 'DEFAULT',
            displayedValue: getAircraftIconColourOption('DEFAULT', 'Lime Green (default)')
          },
          {
            actualValue: 'YELLOW',
            displayedValue: getAircraftIconColourOption('YELLOW', 'Yellow')
          },
          { actualValue: 'AMBER', displayedValue: getAircraftIconColourOption('AMBER', 'Amber') },
          {
            actualValue: 'ORANGE',
            displayedValue: getAircraftIconColourOption('ORANGE', 'Orange')
          },
          { actualValue: 'RED', displayedValue: getAircraftIconColourOption('RED', 'Red') }
        ]}
        placeholder="Select aircraft icon colour"
        handleFieldChange={handleFieldChange}
      />
      <Form.Item style={{ textAlign: 'right' }} className="mt-3">
        <Row>
          <Button
            className="mx-2 px-3"
            type="danger"
            size="large"
            style={{ width: '85px' }}
            hidden={isDeleteHidden}
            onClick={() => {
              showDeleteAircraftModal(currentAircraftSpider.aircraftId, remove, onClose);
            }}
          >
            Delete
          </Button>
          <Button
            className="mx-2 px-3"
            type="secondary"
            size="large"
            onClick={onClose}
            style={{ width: '85px' }}
          >
            Cancel
          </Button>
          <Button
            className="mx-2 px-4"
            disabled={isSaving || isSubmitButtonDisabled}
            type="primary"
            htmlType="submit"
            size="large"
            style={{ width: '85px' }}
          >
            {isSaving ? (
              <span className="px-2">
                <Spin size="small" />
              </span>
            ) : (
              submitButtonValue
            )}
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
};

AircraftForm.propTypes = {
  form: PropTypes.object,
  onClose: PropTypes.func,
  skipped: PropTypes.func,
  save: PropTypes.func.isRequired,
  remove: PropTypes.func,
  submitButtonDefaultName: PropTypes.string,
  currentAircraftSpider: PropTypes.object,
  submitButtonValue: PropTypes.string.isRequired,
  isSubmitButtonDisabled: PropTypes.bool.isRequired,
  handleFieldChange: PropTypes.func.isRequired
};

export default Form.create()(AircraftForm);
