import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Popover, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import WeatherIcon from '../icons/WeatherIcon';
import WeatherSelectionMenuContainer from './WeatherSelectionMenuContainer';
import { GOOGLE_MAP_CONTAINER } from '../../GoogleMap/constants';
import { MenuIcon } from '../MenuIcon';
import GraphicalOverlayMapView from '../../GoogleMap/GraphicalOverlayMapView';
import TextualOverlayMapView from '../../GoogleMap/TextualOverlayMapView';
import {
  getCurrentGraphicalWeatherLayer,
  getCurrentTextualWeatherLayer
} from '../../../../../redux/selectors/mapData';

export const WeatherContainer = ({ currentGraphicalWeatherLayer, currentTextualWeatherLayer }) => {
  const [visible, setVisible] = useState(false);
  const hide = () => {
    setVisible(false);
  };
  const handleVisibleChange = visibilty => {
    setVisible(visibilty);
  };

  return (
    <div className="map-control">
      <Tooltip
        placement="left"
        title="Weather"
        getPopupContainer={() => document.getElementById(GOOGLE_MAP_CONTAINER)}
      >
        <Popover
          placement="leftTop"
          getPopupContainer={() => document.getElementById(GOOGLE_MAP_CONTAINER)}
          content={<WeatherSelectionMenuContainer clickCallback={hide} />}
          title=""
          role="button"
          trigger="click"
          visible={visible}
          onVisibleChange={handleVisibleChange}
        >
          <MenuIcon>
            <Icon height={18} width={18} type={WeatherIcon} />
          </MenuIcon>
        </Popover>
      </Tooltip>

      {currentGraphicalWeatherLayer && currentGraphicalWeatherLayer.productCode && (
        <GraphicalOverlayMapView layerProductCode={currentGraphicalWeatherLayer.productCode} />
      )}
      {currentTextualWeatherLayer && currentTextualWeatherLayer.productCode && (
        <TextualOverlayMapView weatherLayer={currentTextualWeatherLayer} />
      )}
    </div>
  );
};

WeatherContainer.propTypes = {
  currentGraphicalWeatherLayer: PropTypes.shape({
    productCode: PropTypes.string
  }),
  currentTextualWeatherLayer: PropTypes.shape({
    productCode: PropTypes.string
  })
};

const mapStateToProps = state => ({
  currentGraphicalWeatherLayer: getCurrentGraphicalWeatherLayer(state),
  currentTextualWeatherLayer: getCurrentTextualWeatherLayer(state)
});

export default connect(mapStateToProps)(WeatherContainer);
