import { createSelector, createSlice } from '@reduxjs/toolkit';
import deepEqual from 'deep-equal';
import { getInstance } from '../../common/api/spidertracks-sdk';
import { getSelectedTrack } from '../selectors/aircraftData';

const initialState = {};

export const aircraftSlice = createSlice({
  name: 'aircraft',
  initialState,
  reducers: {
    setAircraft: (state, { payload }) => {
      window.performance.mark('setAircraft_start');

      for (const aircraft of payload) {
        if (!deepEqual(aircraft, state[aircraft.id])) {
          state[aircraft.id] = aircraft;
        }
      }
      window.performance.mark('setAircraft_end');
      window.performance.measure('setAircraft', 'setAircraft_start', 'setAircraft_end');
    }
  }
});

// actions
export const { setAircraft } = aircraftSlice.actions;

// asynchronous thunk action
export const fetchAircraft = organisationSlug => async dispatch => {
  const SpidertracksSDK = getInstance();
  const aircraft = await SpidertracksSDK.getAircraft(organisationSlug);
  dispatch(setAircraft(aircraft));
};

export const fetchSelectedTrackAircraft = () => async (dispatch, getState) => {
  const selectedTrack = getSelectedTrack(getState());
  if (!selectedTrack || !selectedTrack.aircraft || !selectedTrack.aircraft.id) {
    return;
  }

  try {
    const SpidertracksSDK = getInstance();
    const aircraft = await SpidertracksSDK.getAircraftService().get(selectedTrack.aircraft.id);
    if (aircraft) {
      // TODO: this is likely temporary. The workaround is required because `GET /aircrafts` returns
      // snake case, while `GET /aircrafts/:id` returns camelCase!
      aircraft['aircraft_icon'] = aircraft.icon;
      aircraft['aircraft_icon_colour'] = aircraft.iconColour;
      dispatch(setAircraft([aircraft]));
    }
  } catch (e) {
    // TODO: conversation around error handling, this is potentially a non-fatal error
    // (e.g. if just retrieving for the custom aircraft icon)
    console.error(e);
  }
};

export const getAircraft = state => state.aircraft;

export const getAircraftById = createSelector([getAircraft, (_, id) => id], (aircraft, id) => {
  return aircraft[id];
});

export default aircraftSlice;
