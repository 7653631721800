import React, { memo } from 'react';
import PropTypes from 'prop-types';
import InfoWindowRow from '../../InfoWindowRow';

export function getWindDetails(wind) {
  let windDetails = '';

  if (wind.dir && wind.dir_units) {
    const directionUnit =
      wind.dir_units === 'deg' || wind.dir_units === 'degrees' ? '°' : ' ' + wind.dir_units;
    windDetails = wind.dir + directionUnit + ' ';
  }
  windDetails += wind.speed + ' ' + wind.speed_units;

  return windDetails;
}

export const WindDetails = ({ wind }) => {
  return <InfoWindowRow title="Wind" content={getWindDetails(wind)} />;
};

WindDetails.propTypes = {
  wind: PropTypes.object.isRequired
};

WindDetails.displayName = 'WindDetails';

export default memo(WindDetails);
