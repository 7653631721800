import React, { Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from '@spidertracks/common';

export function PrivateRoute({ component: Component = null, children = null, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => (
        <Fragment>
          {isAuthenticated() && Component && <Component {...props} {...rest} />}
          {isAuthenticated() && children}
          {!isAuthenticated() && (
            <Redirect
              to={{
                pathname: '/login',
                state: { referrer: `${props.location.pathname}${props.location.search || ''}` }
              }}
            />
          )}
        </Fragment>
      )}
    />
  );
}
