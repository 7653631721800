import { ServiceAbstract } from '../../ServiceAbstract';
import { Spider } from '../../types/Spider';
import { Registration } from '../../types/Registration';
import { SpiderAssociation } from '../../types/SpiderAssociation';
import { SpiderSettings, SpiderStatusResponse } from '../../types/SpiderSettings';
import { SpiderPlans } from '../../types/SpiderPlans';
import { Amplify } from '@spidertracks/common';

export class SpiderSettingsService extends ServiceAbstract {
  public async getSpiders(orgId?: string): Promise<Spider[]> {
    try {
      let result;
      if (orgId) {
        result = await Amplify.API.get('authenticated', 'spiders', {
          queryStringParameters: {
            orgId
          }
        });
        return result.items;
      }

      result = await Amplify.API.get('authenticated', 'spiders');

      return result.items;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  public async getSpiderSettings(serialNumber: string): Promise<SpiderSettings | undefined> {
    try {
      return Amplify.API.get('authenticated', `spiders/${serialNumber}/settings`);
    } catch (e) {
      console.log(e);
    }
  }

  public async getSpiderPlans(orgId: string): Promise<SpiderPlans | undefined> {
    try {
      return Amplify.API.get('authenticated', `spiders/plans?orgId=${orgId}`);
    } catch (e) {
      console.log(e);
    }
  }

  public async registerSpider(registration: Registration): Promise<object> {
    return Amplify.API.put('authenticated', `registration`, {
      body: registration
    });
  }

  public async getStatus(
    serialNumber: string,
    organisationId: string
  ): Promise<SpiderStatusResponse> {
    return Amplify.API.get(
      'authenticated',
      `spiders/${serialNumber}/status?orgId=${organisationId}`
    );
  }

  public async updateSpiderSettings(
    serialNumber: string,
    spiderSettings: SpiderSettings
  ): Promise<Spider> {
    return Amplify.API.put('authenticated', `spiders/${serialNumber}/settings`, {
      body: spiderSettings
    });
  }
  public async assignAircraftToSpider({
    aircraftId,
    serialNumber
  }: SpiderAssociation): Promise<Spider> {
    return Amplify.API.put('authenticated', `spiders/${serialNumber}/aircraft`, {
      body: { aircraftId }
    });
  }
}
