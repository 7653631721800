import { Aircraft, Point } from '../redux/types';
import {
  getLatestTrackFromAircraft,
  getTrackPointsWithoutEvents,
  getLatestPoint
} from '../redux/selectors/aircraftData';
import { FIFTEEN_MINUTES } from '../components/Flying/Map/GoogleMap/constants';

export const isPointRecent = (point: Point): boolean => {
  return new Date().getTime() - point.timestamp < FIFTEEN_MINUTES;
};

export const isAircraftActive = (aircraft: Aircraft): boolean => {
  const latestTrack = getLatestTrackFromAircraft(aircraft);
  const latestPoints = getTrackPointsWithoutEvents(latestTrack);
  const latestPoint = getLatestPoint(latestPoints);

  return isPointRecent(latestPoint);
};
