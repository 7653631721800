import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Select, Button, Row, DatePicker } from 'antd';
import { toTimestamp } from '../../../helpers/formatTime';
import CopyLink from '../../CopyLink';

const { Option } = Select;

const disabledDate = current => {
  return (
    current &&
    current < new Date(new Date().getTime() - 1000 * 60 * 60 * 24).setHours(23, 59, 59, 999) //disable dates before today
  );
};

export const CreateAircraftPublicLinkForm = ({ publicAircraftData, closeDrawer, form }) => {
  const urlOrigin = window.location.origin;

  const [generatedLink, setGeneratedLink] = useState(undefined);
  const [fieldsDisabled, setFieldsDisabled] = useState(false);

  const { getFieldDecorator, validateFields } = form;

  const options = publicAircraftData.map(option => {
    return (
      <Option key={option.id} value={JSON.stringify(option)}>
        {option.registration}
      </Option>
    );
  });

  const createLink = async e => {
    e.preventDefault();

    validateFields(async (err, values) => {
      if (!err) {
        const aircraftIds = values.aircraft.map(singleAircraft => JSON.parse(singleAircraft).id);
        let expiryDate;
        if (!values.expiryDate) {
          expiryDate = new Date('3000-01-01T00:00:00Z').getTime();
        } else {
          expiryDate = toTimestamp(values.expiryDate.endOf('day'));
        }

        const token = btoa(`${expiryDate},${aircraftIds.join(',')}`);
        setGeneratedLink(`${urlOrigin}/public/e/${token}`);
        setFieldsDisabled(true);
      }
    });
  };

  return (
    <div>
      <Form
        onSubmit={createLink}
        layout="vertical"
        hideRequiredMark={false}
        style={{ width: '290px', margin: '0 auto' }}
      >
        <Form.Item name="aircraft" label="Aircraft">
          {getFieldDecorator('aircraft', {
            rules: [
              {
                required: true,
                message: 'Aircraft field is required'
              }
            ]
          })(
            <Select
              size="large"
              placeholder="Start typing to search for an aircraft"
              mode="multiple"
              style={{ width: '100%' }}
              disabled={fieldsDisabled}
            >
              {options}
            </Select>
          )}
        </Form.Item>
        <Form.Item name="expiryDate" label="Expiry Date">
          {getFieldDecorator('expiryDate', {
            rules: [
              {
                required: false
              }
            ]
          })(
            <DatePicker
              size="large"
              disabledDate={disabledDate}
              style={{ width: '100%' }}
              disabled={fieldsDisabled}
            />
          )}
        </Form.Item>
        <Form.Item style={{ textAlign: 'right' }}>
          <Row>
            <Button
              type="secondary"
              size="large"
              onClick={closeDrawer}
              className="mx-2 px-3"
              disabled={fieldsDisabled}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="mx-2 px-4"
              disabled={fieldsDisabled}
            >
              Create Link
            </Button>
          </Row>
        </Form.Item>
      </Form>
      {generatedLink && <CopyLink url={generatedLink} />}
    </div>
  );
};

CreateAircraftPublicLinkForm.propTypes = {
  publicAircraftData: PropTypes.array.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired
};

export const WrappedCreateAircraftPublicLinkForm = Form.create()(CreateAircraftPublicLinkForm);

export default WrappedCreateAircraftPublicLinkForm;
