import React from 'react';
import PropTypes from 'prop-types';

const FullScreenIcon = props => {
  const { color } = props;
  return (
    <path
      style={{
        fill: color
      }}
      d="M25,25v50l19.1-19.1l30,30l11.8-11.8l-30-30L75,25H25z M125,25l19.1,19.1l-30,30l11.8,11.8l30-30L175,75V25H125z M74.1,114.1l-30,30L25,125v50h50l-19.1-19.1l30-30L74.1,114.1z M125.9,114.1l-11.8,11.8l30,30L125,175h50v-50l-19.1,19.1
L125.9,114.1z"
    />
  );
};

FullScreenIcon.defaultProps = {
  color: 'grey'
};

FullScreenIcon.propTypes = {
  color: PropTypes.string
};

export default FullScreenIcon;
