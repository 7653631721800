import React, { MouseEventHandler } from 'react';
import { Form, Row } from 'antd';
import { Save, Cancel } from '.';

interface ConfirmationButtonsProps {
  onClose: MouseEventHandler;
  isSaving: boolean;
  disabled?: boolean;
  text?: string;
}

export const ConfirmationButtons = ({
  onClose,
  isSaving,
  disabled = false,
  text = 'Save'
}: ConfirmationButtonsProps) => (
  <Form.Item style={{ textAlign: 'right' }} className="mt-3">
    <Row>
      <Cancel onClose={onClose} />
      <Save text={text} isSaving={isSaving} disabled={disabled} />
    </Row>
  </Form.Item>
);

ConfirmationButtons.displayName = 'ConfirmationButtons';

export default ConfirmationButtons;
