import React, { useState, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from 'antd';
import { InfoWindow, useGoogleMap } from '@react-google-maps/api';
import { getActiveKmls } from '../../../../../redux/selectors/mapData';
import { getUserData, getAltitudeUnit } from '../../../../../redux/selectors/userData';
import { getInstance } from 'common/api/spidertracks-sdk';
import InfoWindowRow from '../../GoogleMap/InfoWindowRow';
import { getGeofenceInfoAltitude } from '../../utils/helper';

const { Title } = Typography;

export const GeofencesContainer = () => {
  const activeKmls = useSelector(getActiveKmls);
  const activeGeofence = activeKmls.includes('geofence');
  const userData = useSelector(getUserData);
  const altitudeUnit = useSelector(getAltitudeUnit);
  const { orgs = [] } = userData;

  const map = useGoogleMap();
  const SpidertracksSDK = getInstance();
  const [geofenceClickEventLatLng, setGeofenceClickEventLatLng] = useState({});
  const [geofencesProperties, setGeofencesProperties] = useState([]);
  const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false);
  const [isLoadingFences, setIsLoadingFences] = useState(false);
  const [polygonArray, setPolygonArray] = useState([]);

  useEffect(() => {
    let addFeatureListener;
    const getGeofences = async () => {
      if (activeGeofence) {
        if (!isLoadingFences) {
          // get geofences from api
          setIsLoadingFences(true);
          const geofences = await SpidertracksSDK.getGeofencesService().getGeofences();
          setIsLoadingFences(false);

          // a listener to add feature, where we create a google.maps.polygon for every added feature, then add it to the polygon array
          const addedPolygons = [];
          addFeatureListener = map.data.addListener('addfeature', e => {
            const featureGeometry = e.feature.getGeometry().getArray();

            // for each geometry get the latLng array, which will be used to form a polygon
            featureGeometry.forEach(latLngArray => {
              const polygon = new window.google.maps.Polygon({
                map: null,
                paths: latLngArray.getArray(),
                strokeWeight: 1,
                fillColor: 'green',
                clickable: false,
                name: e.feature.getProperty('name'),
                altitudeLimit: e.feature.getProperty('altitudeLimit'),
                altitudeContext: e.feature.getProperty('altitudeContext'),
                altitudeReference: e.feature.getProperty('altitudeReference'),
                organisationName: orgs.length
                  ? orgs.find(orgObj => orgObj.org.id === e.feature.getProperty('organisationId'))
                      .org.orgName
                  : ''
              });
              addedPolygons.push(polygon);
            });
            setPolygonArray(addedPolygons);
          });

          // add the polygon to the map data layer (this will show the polygon on the map and cause the addfeature listener to fire)
          map.data.addGeoJson(geofences);
          map.data.setStyle({ fillColor: 'green', strokeWeight: 1 });
        }
      } else {
        map.data.forEach(
          feature => feature.getProperty('altitudeContext') && map.data.remove(feature)
        );
        setPolygonArray([]);
        setIsInfoWindowOpen(false);
      }
    };
    getGeofences();

    return () => {
      if (addFeatureListener) {
        addFeatureListener.remove();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeGeofence]);

  useEffect(() => {
    let clickListener;
    if (activeKmls.includes('geofence')) {
      // a listener on click that checks whether the point is in a polygon and updates the necessary state to show the proper info window
      clickListener = map.data.addListener('click', function(event) {
        // this state is updated to identify the place where the info window will open
        setGeofenceClickEventLatLng(event.latLng);

        // for every polygon in the created polygons array check if it includes the clicked point, then update what to display in the info window
        const selectedGeofences = [];
        for (const polygon of polygonArray) {
          if (window.google.maps.geometry.poly.containsLocation(event.latLng, polygon)) {
            const name = polygon.name;
            const altitudeLimit = polygon.altitudeLimit;
            const altitudeContext = polygon.altitudeContext;
            const organisationName = polygon.organisationName;
            const altitudeReference = polygon.altitudeReference;

            const altitude = getGeofenceInfoAltitude({
              altitudeUnit,
              altitudeContext,
              altitudeLimit,
              altitudeReference
            });

            selectedGeofences.push({ key: name, name, altitude, organisationName });
          }
        }
        if (selectedGeofences.length) {
          setIsInfoWindowOpen(true);
        } else {
          setIsInfoWindowOpen(false);
        }
        setGeofencesProperties(selectedGeofences);
      });
    }

    return () => {
      if (clickListener) {
        clickListener.remove();
      }
    };
  }, [activeKmls, polygonArray, altitudeUnit, map]);

  return (
    <Fragment>
      {isInfoWindowOpen && (
        <InfoWindow
          position={geofenceClickEventLatLng}
          onCloseClick={() => setIsInfoWindowOpen(false)}
          zIndex={0}
          options={{ maxWidth: '450' }}
        >
          <Fragment>
            {geofencesProperties.map(geofenceProperties => {
              return (
                <Fragment key={geofenceProperties.key}>
                  <Title level={4}>{geofenceProperties.name}</Title>
                  <InfoWindowRow
                    title="Organisation"
                    content={geofenceProperties.organisationName}
                  />
                  <InfoWindowRow title="Altitude" content={geofenceProperties.altitude} />
                </Fragment>
              );
            })}
          </Fragment>
        </InfoWindow>
      )}
    </Fragment>
  );
};

export default GeofencesContainer;
