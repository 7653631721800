import { Polyline } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { CustomizedTrackLines } from './CustomizedTrackLines';

export const AircraftTrackLine = ({
  coordinates,
  isTrackSelected,
  customizedTrackPolylineEnabled,
  trackLineOptions
}) => {
  if (customizedTrackPolylineEnabled) {
    return <CustomizedTrackLines coordinates={coordinates} trackLineOptions={trackLineOptions} />;
  }

  return (
    <Fragment>
      <Polyline
        options={{
          strokeColor: isTrackSelected ? 'blue' : '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 2
        }}
        path={coordinates}
        zIndex={trackLineOptions.innerLineOptions.zIndex}
      />
    </Fragment>
  );
};

AircraftTrackLine.propTypes = {
  coordinates: PropTypes.array,
  isTrackSelected: PropTypes.bool,
  customizedTrackPolylineEnabled: PropTypes.bool,
  trackLineOptions: PropTypes.object
};

export default AircraftTrackLine;
