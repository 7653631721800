import React from 'react';
import { Button, Spin } from 'antd';

interface SaveProps {
  isSaving: boolean;
  disabled: boolean;
  text: string;
}

export const Save = ({ isSaving, disabled, text }: SaveProps) => (
  <Button
    className="mx-2 px-4"
    disabled={disabled || isSaving}
    type="primary"
    htmlType="submit"
    size="large"
  >
    {isSaving ? (
      <span className="px-2">
        <Spin size="small" />
      </span>
    ) : (
      text
    )}
  </Button>
);

Save.displayName = 'Save';
