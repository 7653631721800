import React from 'react';
import SisenseFilters from './filters/SisenseFilters';
import InsightsDashboardExportButton from './InsightsDashboardExportButton';
import LegacyInsightsDashboardButton from './LegacyInsightsDashboardButton';
import { PageHeader } from 'antd';
import { getUserData } from '../../redux/selectors/userData';
import { useFeatureFlag } from '../../hooks';
import { useSelector } from 'react-redux';

export const SafetyInsightsHeader = () => {
  const userData = useSelector(getUserData);
  const userOrgIds = userData.orgs.reduce((acc, o) => acc.concat(o.org.id), []);
  const isLegacyInsightsFeatureEnabled = useFeatureFlag('legacy-insights', userOrgIds);

  return (
    <div className="mb-1" style={{ paddingLeft: '20px' }}>
      <PageHeader
        className="site-page-header"
        style={{ paddingLeft: '0px' }}
        title="Safety Insights"
        subTitle="View and manage safety flight insights"
      />
      <SisenseFilters
        datasource="flightdata_prod"
        aircraftdim="[enriched_safety_event.aircraftRegistration]"
        eventTypeDim="[enriched_safety_event.formattedEventType]"
        severityTypeDim="[enriched_safety_event.severity]"
      />
      <InsightsDashboardExportButton />
      {isLegacyInsightsFeatureEnabled && <LegacyInsightsDashboardButton />}
    </div>
  );
};

SafetyInsightsHeader.displayName = 'SafetyInsightsHeader';
export default SafetyInsightsHeader;
