/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-undef */
import { connect } from 'react-redux';
import { getUserData } from '../redux/selectors/userData';

export const Pendo = ({ userData = {} }) => {
  if (!window.pendo) {
    console.log('pendo not found');
    return null;
  }

  // Initialize Pendo whether the user has logged in or not (so we can track anonymous users on public pages)
  window.pendo.initialize({
    disableGuides:
      window.env.pendo && window.env.pendo.disableGuides ? window.env.pendo.disableGuides : false,
    visitor: {
      id: 'VISITOR-UNIQUE-ID'
    }
  });

  // If the user is logged in, identify them in Pendo so all the data is associated with their account
  if (userData) {
    window.pendo.identify({
      disableGuides:
        window.env.pendo && window.env.pendo.disableGuides ? window.env.pendo.disableGuides : false,
      visitor: {
        id: userData?.id,
        email: userData?.email,
        full_name: userData?.displayName
      },
      account: {
        id: userData?.id,
        name: userData?.displayName,
        creationDate: userData?.createdDate
      }
    });
  }

  return null;
};

Pendo.displayName = 'Pendo';

const mapStateToProps = state => {
  return {
    userData: getUserData(state)
  };
};

export default connect(mapStateToProps)(Pendo);
