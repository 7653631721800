import React from 'react';
import './Loading.scss';

const Loading = () => (
  <div className="loading active">
    <div className="loading-inner">
      <div className="loader">
        <div className="loading-content">
          <p>Loading...</p>
          <div>
            <div className="loader-one" />
            <div className="loader-two" />
            <div className="loader-three" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Loading;
