import React, { useEffect } from 'react';
import { Form, Input } from 'antd';

interface GeofenceNameInputProps {
  name: string;
  disabled: boolean;
  getFieldDecorator: Function;
  setFieldsValue: Function;
}

export const GeofenceNameInput = ({
  name,
  disabled,
  getFieldDecorator,
  setFieldsValue
}: GeofenceNameInputProps) => {
  useEffect(() => {
    if (disabled) {
      setFieldsValue({ name });
    }
  }, [disabled, setFieldsValue, name]);

  return (
    <Form.Item label="Geofence Name">
      {getFieldDecorator('name', {
        rules: [{ required: true, message: 'Please enter a name to identify the geofence' }]
      })(
        <Input
          autoComplete="off"
          placeholder="Enter Name"
          disabled={disabled}
          onChange={() => {}}
        />
      )}
    </Form.Item>
  );
};

export default GeofenceNameInput;
