import { Track } from '../types/Track';
import { getTrackPointsWithoutEvents, getLatLng } from '../redux/selectors/aircraftData';

export function fitMapToTrackBounds(tracks: Track[], map: google.maps.Map) {
  const bounds = new window.google.maps.LatLngBounds();

  tracks
    .reduce<google.maps.LatLngLiteral[]>((accumulator, aircraftTrack) => {
      const points = getTrackPointsWithoutEvents(aircraftTrack);
      return accumulator.concat(points.map(point => getLatLng(point)));
    }, [])
    .forEach(coord => bounds.extend(coord));

  const zoom = map.getZoom();
  if (zoom && zoom < 16) {
    map.setZoom(16);
  }
  map.setCenter(bounds.getCenter());
  map.fitBounds(bounds);
}

export default fitMapToTrackBounds;
