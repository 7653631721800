import useLocalStorage from './useLocalStorage';
import { useParams } from 'react-router';
import { useEffect } from 'react';

export function useMostRecentOrgSettingPage() {
  const defaultSettingPage = 'basicinformation';

  // get setting page from url param
  const { settingsPage } = useParams();

  //setup local storage to persist page selection
  const [mostRecentOrgSettingsPage, setMostRecentOrgSettingsPage] = useLocalStorage(
    'mostRecentOrgSettingPage',
    defaultSettingPage
  );

  // Update most recent page, when user selected page changes
  useEffect(() => {
    if (settingsPage !== undefined) {
      setMostRecentOrgSettingsPage(settingsPage);
    }
  }, [settingsPage]);

  return mostRecentOrgSettingsPage;
}
