export function enumToTitleCase(group: string) {
  return (
    group[0].toUpperCase() +
    group
      .slice(1)
      .replace(/_/g, ' ')
      .toLowerCase()
  );
}

export function enumToTitleCaseWithAcronyms(value: string, acronyms: RegExp[]) {
  return acronyms.reduce<string>(
    (acc, regex) => acc.replace(regex, match => match.toUpperCase()),
    enumToTitleCase(value)
  );
}

export const eventTypeAcronyms = [/AGL/gi, /\bG\b/gi, /AMSL/gi];
