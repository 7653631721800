export class UnknownOrganisationError extends Error {
  // You have to extend Error, set the __proto__ to Error, and use
  // Object.setPrototypeOf in order to have a proper custom error type in JS.
  // Because JS/TS are dumb sometimes, and all three are needed to make this
  // work in all browsers.
  __proto__ = Error;

  private httpResponse: Response;

  constructor(organisationSlug: string, httpResponse: Response) {
    super(`Unknown Organisation ID(${organisationSlug})`);
    // See https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, UnknownOrganisationError.prototype);

    this.httpResponse = httpResponse;
  }
}
