import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'antd';
import '../../../../../assets/scss/global.scss';

const centerStyle = {
  textAlign: 'center',
  padding: 2
};

const EtaButton = ({
  aircraftLocation,
  isEtaButtonActive,
  requestLocationText,
  onClickETAButton,
  etaButtonText,
  isRequestLocationButtonActive
}) => {
  return (
    <Row type="flex">
      <Col className="d-flex" span={12} style={centerStyle}>
        <Row className="d-flex flex-basis-100 flex-1 flex-align-stretch flex-direction-col">
          {isRequestLocationButtonActive && (
            <Button type="button" className={`point-info-panel flex-1`} onClick={aircraftLocation}>
              {requestLocationText}
            </Button>
          )}
        </Row>
      </Col>

      <Col className="d-flex" span={12} style={centerStyle}>
        <Row className="d-flex flex-basis-100 flex-1 flex-align-stretch flex-direction-col">
          <Button
            type="button"
            style={{ whiteSpace: 'normal', height: 'auto' }}
            className={`point-info-panel flex-1`}
            disabled={!isEtaButtonActive}
            onClick={onClickETAButton}
          >
            {etaButtonText}
          </Button>
        </Row>
      </Col>
    </Row>
  );
};

EtaButton.propTypes = {
  aircraftLocation: PropTypes.func,
  aircraftData: PropTypes.string,
  requestLocationText: PropTypes.string,
  onClickETAButton: PropTypes.func,
  etaButtonText: PropTypes.string,
  isEtaButtonActive: PropTypes.bool,
  isRequestLocationButtonActive: PropTypes.bool
};

export default EtaButton;
