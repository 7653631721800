/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import { Close, Error } from '@material-ui/icons';

export const SosStyledHeader = styled.div`
  color: white;
  background: #e13c39;
  text-align: center;
  font-size: 16px;
  padding: 7px 0;
`;

export const closeStyle = {
  position: 'absolute',
  top: '4px',
  right: '5px',
  width: '30px',
  height: '30px',
  cursor: 'pointer'
};

export const errorStyle = {
  position: 'absolute',
  top: '3px',
  left: '3px',
  fill: 'white',
  width: '32px',
  height: '32px'
};

export const SosHeader = props => (
  <SosStyledHeader>
    <Error style={errorStyle} />
    S.O.S Alert
    <Close data-key={props['data-key']} onClick={props.callback} style={closeStyle} />
  </SosStyledHeader>
);

SosHeader.displayName = 'SosHeader';

export const BodyWrapper = styled.div`
  padding: 10px 10px 0 10px;
`;

export const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
  padding-bottom: 10px;
`;

export const BodyContent = styled.div`
  color: black;
  position: relative;
`;

export const Time = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const buttonStyle = {
  marginRight: '10px'
};

export const popupStyles = {
  width: '300px',
  height: '180px',
  padding: '10px'
};
