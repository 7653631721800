import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';

const MapViewOptions = ({ viewOption, name }) => <Radio value={viewOption}>{name}</Radio>;

MapViewOptions.defaultProps = {
  viewOption: 'Option',
  name: 'Option name'
};

MapViewOptions.propTypes = {
  viewOption: PropTypes.string,
  name: PropTypes.string
};

export default MapViewOptions;
