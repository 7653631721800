import React from 'react';
import { GOOGLE_MAP_CONTAINER } from '../../Flying/Map/GoogleMap/constants';
import { MapProvider } from '../../../context/MapContext';
import MapContainer from '../../../components/Flying/Map/MapContainer';
import MapControls from '../../../components/Flying/Map/MapControls';
import EventsPointInfoPanelContainer from '../../../components/Flying/Map/PointInfoPanel/EventsPointInfoPanelContainer';
import EventsMapWrapper from '../../../pages/flying/EventsMapWrapper';
import { EventClass } from '../../../constants';
import { useSelector } from 'react-redux';
import { getPanelVisibility } from '../../../redux/selectors/flightExplorer';
import { PanelVisibility } from '../../../redux/types';
import PropTypes from 'prop-types';

interface Map2DViewProps {
  className?: string;
}

const Map2DView: React.FC<Map2DViewProps> = ({ className }) => {
  const mapContext = {
    events: { displayEventClasses: [EventClass.GEOFENCE, EventClass.FSI] },
    strictPointGrouping: { displayFullTrack: true }
  };
  const panelVisibility = useSelector(getPanelVisibility);
  let displayClassName = className;
  if (panelVisibility['2dmap'] !== PanelVisibility.VISIBLE) {
    displayClassName += ' hidden';
  }

  return (
    <div id={GOOGLE_MAP_CONTAINER} className={`${displayClassName}`}>
      <MapProvider context={mapContext}>
        <MapContainer>
          <EventsMapWrapper>
            <EventsPointInfoPanelContainer hidePanelOffset={true} hidePointInfoPanel={true} />
            <MapControls
              fullScreenEnabled={false}
              downloadTracksEnabled={false}
              favoritesEnabled={false}
              pointGroupingEnabled={false}
              weatherEnabled={false}
            />
          </EventsMapWrapper>
        </MapContainer>
      </MapProvider>
    </div>
  );
};

Map2DView.propTypes = {
  className: PropTypes.string
};

export default Map2DView;
