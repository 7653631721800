import React, { memo } from 'react';
import PropTypes from 'prop-types';

import {
  DownloadTracksButton,
  CenterMapButton,
  FullScreenButton,
  PointGroupingOptionsButton,
  MapViewButton,
  MapLayersButton,
  WeatherButton,
  FavouritesButton,
  MeasureToolButton
} from '.';

const style = {
  position: 'absolute',
  top: 0,
  right: 0
};

const MapControls = ({
  fullScreenEnabled = true,
  pointGroupingEnabled = true,
  centerMapEnabled = true,
  weatherEnabled = true,
  mapViewEnabled = true,
  mapLayerEnabled = true,
  favoritesEnabled = true,
  measureToolEnabled = true,
  downloadTracksEnabled = false
}) => (
  <div className="d-flex flex-direction-col" style={style}>
    {fullScreenEnabled && <FullScreenButton />}
    {pointGroupingEnabled && <PointGroupingOptionsButton />}
    {centerMapEnabled && <CenterMapButton />}
    {weatherEnabled && <WeatherButton />}
    {mapViewEnabled && <MapViewButton />}
    {mapLayerEnabled && <MapLayersButton />}
    {favoritesEnabled && <FavouritesButton />}
    {measureToolEnabled && <MeasureToolButton />}
    {downloadTracksEnabled && <DownloadTracksButton />}
  </div>
);

MapControls.propTypes = {
  downloadReportEnabled: PropTypes.bool,
  fullScreenEnabled: PropTypes.bool,
  pointGroupingEnabled: PropTypes.bool,
  centerMapEnabled: PropTypes.bool,
  weatherEnabled: PropTypes.bool,
  mapViewEnabled: PropTypes.bool,
  mapLayerEnabled: PropTypes.bool,
  favoritesEnabled: PropTypes.bool,
  measureToolEnabled: PropTypes.bool
};

export default memo(MapControls);
