import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Input } from 'antd';

export const dropdownColumnSpans = {
  full: 24,
  icon: 3,
  title: 21
};

export const EventNameInput = ({ ruleName, setRuleName, getFieldDecorator }) => (
  <Form.Item label={'Event Name'} name={'ruleName'} style={{ textAlign: 'left' }} className="mt-3">
    <Row>
      {getFieldDecorator('ruleName', {
        ...(ruleName && { initialValue: ruleName }),
        rules: [
          {
            required: true,
            message: 'Please enter an event name'
          }
        ]
      })(
        <Input
          data-testid="ruleName"
          onChange={e => setRuleName(e.target.value)}
          style={{ width: '300px' }}
          placeholder={''}
        />
      )}
    </Row>
  </Form.Item>
);

EventNameInput.propTypes = {
  ruleName: PropTypes.string,
  setRuleName: PropTypes.func,
  getFieldDecorator: PropTypes.func
};

EventNameInput.displayName = 'EventNameInput';
