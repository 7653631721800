import { FilterConfig, HistoryTracksState, TrackStatus } from '../types';
import { FullState } from '../../store';
import { createSelector } from 'reselect';

export const getHistoryTracksState = (state: FullState): HistoryTracksState => state.historyTracks;

export const getHistoryTrackStatus = createSelector(
  [getHistoryTracksState],
  (historyTracksState: HistoryTracksState): TrackStatus[] => historyTracksState.trackStatus
);

export const getHistoryFilterConfig = createSelector(
  [getHistoryTracksState],
  (historyTracksState: HistoryTracksState): FilterConfig => historyTracksState.filterConfig
);
