import { Select } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

const { Option } = Select;

export const Member = ({ memberOrgs }) => {
  const elements = memberOrgs.map(org => {
    const { id, orgName } = org.org;
    return (
      <Option key={id} disabled={true}>
        <span style={{ fontWeight: 'normal', display: 'block', width: '100%' }}>{orgName}</span>
      </Option>
    );
  });
  return elements;
};

Member.propTypes = {
  memberOrgs: PropTypes.array
};

export default Member;
