import { ServiceAbstract } from '../../ServiceAbstract';
import { Favourite } from '../../types/Favourite';
import { Amplify } from '@spidertracks/common';

export class FavouritesService extends ServiceAbstract {
  public async get(): Promise<Favourite> {
    return Amplify.API.get('authenticated', 'favourites');
  }

  public async save(favourite: Favourite): Promise<Favourite> {
    return Amplify.API.post('authenticated', 'favourites', { body: favourite });
  }

  public async delete(id: number): Promise<Favourite> {
    return Amplify.API.del('authenticated', `favourites/${id}`);
  }
}

export default FavouritesService;
