import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Radio } from 'antd';
import './styles/scss/mapviewoptions.scss';
import MapViewOptions from './Mapviewoptions';
import { mapView } from '../../../../../redux/reducers/mapReducer/actions/map';
import mapViewData from './mapviewdata';
import { getInstance } from 'common/api/spidertracks-sdk';
import { UserPreferenceKey } from 'common/api/spidertracks-sdk/types/UserData';
import { gaEventHelper } from '@spidertracks/common';
import { getGoogleView, getSkyVectorView } from '../../../../../redux/selectors/mapData';

const gaEventSender = gaEventHelper('MapControls - Map Type');
const RadioGroup = Radio.Group;

class ViewOptions extends Component {
  static propTypes = {
    mapView: PropTypes.object
  };

  constructor(props) {
    super(props);
    const { googleView, skyVectorView } = props;
    this.state = {
      value: googleView || skyVectorView || ''
    };
  }

  sendGAEvent(mapTypeName) {
    let eventAction = mapTypeName;
    let eventLabel = 'Map Type (' + mapTypeName + ')';
    gaEventSender(eventAction, eventLabel);
  }

  onChange = e => {
    const GOOGLE = 'google';
    const selectedOption = e.target.value;
    const dataViewOption = mapViewData.find(value => value.viewOption === selectedOption);

    this.props.clickCallback();
    this.setState({ value: selectedOption });

    getInstance().saveUserPreference(UserPreferenceKey.MAP_LAYER, {
      mapLayer: dataViewOption.key
    });
    if (dataViewOption.provider === GOOGLE) {
      this.props.mapView({ googleView: selectedOption, skyVectorView: '' });
    } else {
      this.props.mapView({ googleView: '', skyVectorView: selectedOption });
    }

    this.sendGAEvent(dataViewOption.name);
  };

  render() {
    return (
      <span role="button" tabIndex={0}>
        <RadioGroup onChange={this.onChange} value={this.state.value} className="mapview-radio">
          {mapViewData.map(object => (
            <React.Fragment key={object.id}>
              <MapViewOptions viewOption={object.viewOption} name={object.name} />
              <br />
            </React.Fragment>
          ))}
        </RadioGroup>
      </span>
    );
  }
}

ViewOptions.defaultProps = {
  clickCallback: () => {}
};

ViewOptions.propTypes = {
  googleView: PropTypes.string.isRequired,
  skyVectorView: PropTypes.string.isRequired,
  clickCallback: PropTypes.func
};

const mapState = state => ({
  googleView: getGoogleView(state),
  skyVectorView: getSkyVectorView(state)
});

const mapDispatchToProps = {
  mapView
};

export default connect(mapState, mapDispatchToProps)(ViewOptions);
