import { Tag } from 'antd';
import React, { ReactNode } from 'react';
import { Track } from '../types/Track';

const DELETED = 'DELETED';

export const getAircraftRegistrationLabel = (track: Track): ReactNode => {
  if (!!track.aircraft) {
    return track.aircraft.status !== DELETED ? (
      track.aircraft.registration
    ) : (
      <span>
        {track.aircraft.registration} <Tag color="red">{DELETED}</Tag>
      </span>
    );
  }
  return 'Registration unavailable';
};
