import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TafMarker } from './TafMarker';

export const TafsMarkers = ({ tafData = [] }) => {
  return (
    <Fragment>
      {tafData.map(taf => (
        <TafMarker key={taf.raw_text} taf={taf} />
      ))}
    </Fragment>
  );
};

TafsMarkers.propTypes = {
  tafData: PropTypes.array
};

export default TafsMarkers;
