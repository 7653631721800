import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { ORGANISATION } from '../../../common/constants';
import { NavLink } from 'react-router-dom';

const { Option } = Select;

export const OwnerAdmin = ({ ownerAdminOrgs }) => {
  const elements = ownerAdminOrgs.map(org => {
    const { id, orgName } = org.org;
    return (
      <Option key={id}>
        <NavLink to={`${ORGANISATION}/${id}/settings`}>
          <span style={{ fontWeight: 'normal', display: 'block', width: '100%' }}>{orgName}</span>
        </NavLink>
      </Option>
    );
  });
  return elements;
};

OwnerAdmin.propTypes = {
  ownerAdminOrgs: PropTypes.array
};

export default OwnerAdmin;
