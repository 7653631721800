import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Dropdown, Menu, Tooltip } from 'antd';
import styled from 'styled-components';
import { DownOutlined } from '@ant-design/icons';
import {
  CHART_AXIS,
  getGroupNameForParameter,
  getParameterGroupByName,
  getParameterGroupMenuItems
} from './parameterGroups';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { UserData } from '../../../../redux/types';
import { useDispatch, useSelector } from 'react-redux';
import { setChartSeries } from '../../../../redux/slice/flightExplorer';
import {
  getChartSeries,
  getSelectedTrackFlightData
} from '../../../../redux/selectors/flightExplorer';
import { CheckboxOptionType } from 'antd/lib/checkbox';
import { useFlightReport } from '../useFlightReport';
import { scopedUseFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { CompleteFlightDataSamples } from '../../../../types/FlightData';

interface ComponentProps {
  chartAxis: CHART_AXIS;
  userData: UserData;
  defaultParameterGroup: string;
}

const DropdownLabel = styled(Button)`
  &&& {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 5px;
    height: 25px;
  }
`;

const ParameterGroupWrapper = styled.div<{ paddingTop: number }>`
  padding-top: ${props => props.paddingTop}em;
  padding-left: 0.5em;
  display: flex;
  flex-direction: column;
  text-wrap: nowrap;
  width: 100%;
`;

const ParameterGroupTitleWrapper = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  padding-right: 1em;
  width: 3rem;
`;

export const ParameterGroupContainer = ({
  chartAxis,
  userData,
  defaultParameterGroup
}: ComponentProps) => {
  const dispatch = useDispatch();
  const chartSeries = useSelector(getChartSeries);
  const flightData = useSelector(getSelectedTrackFlightData);
  const { isAHRSAllowed } = useFlightReport();

  const { orgs = [] } = userData;
  const userOrgIds = orgs.reduce<string[]>((acc, o) => acc.concat(o.org.id), []);
  const scopedFF = scopedUseFeatureFlag(userOrgIds);

  const selectedParameters =
    chartAxis.toLowerCase() === 'left' ? chartSeries.left : chartSeries.right;
  const menuItems = getParameterGroupMenuItems(isAHRSAllowed, flightData, scopedFF);
  if (chartAxis.toLowerCase() === 'right') {
    menuItems.unshift({ key: 'none', label: 'None' });
  }
  const getFormattedTitle = (chartAxis: CHART_AXIS): string => {
    return chartAxis === 'LEFT' ? 'Left:' : 'Right:';
  };

  const [parameterGroup, setParameterGroup] = useState<string>();
  const [checkboxOptions, setCheckboxOptions] = useState<CheckboxOptionType[]>([]);
  const [lastSelectedParameterDisabled, setLastSelectedParameterDisabled] = useState<string>();

  const updateSelectedParametersInStore = (selectedParameters: string[]) => {
    const payload: Record<string, string[]> = {};
    payload[chartAxis.toLowerCase()] = selectedParameters;
    dispatch(setChartSeries(payload));
  };

  const updateCheckboxOptionsForParameterGroup = (
    groupName: string,
    flightData: CompleteFlightDataSamples
  ) => {
    const parameterGroup = getParameterGroupByName(groupName, scopedFF);
    if (parameterGroup) {
      const checkboxOptions: CheckboxOptionType[] = parameterGroup.members.map(member => {
        const ahrsNotSatisfied = member.prerequisites?.AHRS && !isAHRSAllowed;
        let flightDataNotSatisfied =
          !!member.prerequisites?.flightDataSample && flightData.raw.length == 0;
        if (member.prerequisites?.flightDataSample && flightData.raw.length > 0) {
          const flightDataSatisfied =
            flightData.raw.find(data =>
              member.prerequisites?.flightDataSample
                ? member.prerequisites?.flightDataSample(data)
                : false
            ) !== undefined;
          flightDataNotSatisfied = !flightDataSatisfied;
        }
        const disabled =
          ahrsNotSatisfied ||
          flightDataNotSatisfied ||
          (selectedParameters.length === 1 &&
            selectedParameters[0] === member.name &&
            chartAxis === 'LEFT');
        return {
          label: member.tooltip ? (
            <Tooltip title={member.tooltip}>{member.name}</Tooltip>
          ) : (
            member.name
          ),
          value: member.name,
          disabled: disabled
        };
      });
      setCheckboxOptions(checkboxOptions);
    } else {
      setCheckboxOptions([]);
      updateSelectedParametersInStore([]);
    }
  };

  // Disables last parameter selection in left axis. Required to prevent unselecting all parameters from left axis.
  const disableLastParameterInLeftAxis = () => {
    if (chartAxis.toLowerCase() === 'left') {
      if (selectedParameters.length === 1) {
        const lastSelectedParameter = selectedParameters[0];
        checkboxOptions.forEach(option => {
          if (option.value === lastSelectedParameter) {
            option.disabled = true;
            setLastSelectedParameterDisabled(option.value);
          }
        });
      } else {
        if (lastSelectedParameterDisabled) {
          checkboxOptions.forEach(option => {
            if (option.value === lastSelectedParameterDisabled) {
              option.disabled = false;
              setLastSelectedParameterDisabled(undefined);
            }
          });
        }
      }
    }
  };

  useEffect(() => {
    if (chartSeries.left.length !== 0 && chartAxis === 'LEFT') {
      setParameterGroup(getGroupNameForParameter(chartSeries.left[0]));
    } else if (chartSeries.right.length !== 0 && chartAxis === 'RIGHT') {
      setParameterGroup(getGroupNameForParameter(chartSeries.right[0]));
    } else {
      setParameterGroup(defaultParameterGroup);
    }
  }, [defaultParameterGroup]);

  useEffect(() => {
    if (parameterGroup) {
      updateCheckboxOptionsForParameterGroup(parameterGroup, flightData);
    }
  }, [parameterGroup, flightData, isAHRSAllowed]);

  useEffect(() => {
    disableLastParameterInLeftAxis();
  }, [selectedParameters, checkboxOptions]);

  const onParameterGroupChanged = (parameterGroupName: string) => {
    setParameterGroup(parameterGroupName);
    updateCheckboxOptionsForParameterGroup(parameterGroupName, flightData);
    if (parameterGroup !== parameterGroupName) {
      const newParameterGroup = getParameterGroupByName(parameterGroupName);
      if (newParameterGroup) {
        updateSelectedParametersInStore(newParameterGroup.defaultSelectedMemberNames);
      }
    }
  };

  const onParametersChanged = (checkedValues: CheckboxValueType[]) => {
    const checkedValuesArray = checkedValues.map(value => String(value));
    updateSelectedParametersInStore(checkedValuesArray);
  };

  const paddingTop = chartAxis.toLowerCase() === 'left' ? 1 : 5;

  return (
    <ParameterGroupWrapper className={'parameterGroup'} paddingTop={paddingTop}>
      <ParameterGroupTitleWrapper>{getFormattedTitle(chartAxis)}</ParameterGroupTitleWrapper>
      <div>
        <Dropdown
          overlay={
            <Menu>
              {menuItems.map(item => (
                <Menu.Item key={item.key} onClick={() => onParameterGroupChanged(item.label)}>
                  {item.label}
                </Menu.Item>
              ))}
            </Menu>
          }
          trigger={['click']}
        >
          <DropdownLabel data-testid="parameter-group-selector-dropdown">
            {parameterGroup &&
              (getParameterGroupByName(parameterGroup)?.axisDisplayString(userData) ??
                parameterGroup)}
            <DownOutlined />
          </DropdownLabel>
        </Dropdown>

        <Checkbox.Group
          options={checkboxOptions}
          value={selectedParameters}
          onChange={onParametersChanged}
        />
      </div>
    </ParameterGroupWrapper>
  );
};
