import { SOS, SPIDERWATCH, ACTIVE, INACTIVE } from '../constants';

export const getLastPoint = points => {
  const pointsSortedByTimestamp = [...points].sort((a, b) => b.timestamp - a.timestamp);
  return pointsSortedByTimestamp[0];
};

export const getTrackStatus = (trackState, isActive) => {
  if (trackState === SOS) {
    return SOS;
  }
  if (trackState === SPIDERWATCH) {
    return SPIDERWATCH;
  }
  if (isActive) {
    return ACTIVE;
  }
  return INACTIVE;
};
