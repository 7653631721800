import {
  EventType,
  EventRule
} from '../../../common/api/spidertracks-sdk/private/services/EventRulesService';
import moment from 'moment';
import { PublicAircraftData } from '../../../common/api/spidertracks-sdk/types/AircraftData';

export const getCheckedEventTypes = (
  eventTypes: EventType[],
  eventRules: EventRule[],
  aircraft: PublicAircraftData[]
): EventType[] => {
  const types = eventTypes.map(eventType => {
    const relevantRules = eventRules.filter(rule => rule.eventType === eventType.eventType);
    const ruleAlreadyConfigured =
      relevantRules.some(rule => rule.allAircraft) ||
      aircraft.every(vehicle =>
        relevantRules.some(rule => rule.aircraftIds && rule.aircraftIds.includes(vehicle.id))
      );
    return {
      ...eventType,
      ruleAlreadyConfigured,
      newEventType: eventType.createdAt
        ? moment().add(-30, 'days') < moment(eventType.createdAt)
        : false
    };
  });
  return types;
};
