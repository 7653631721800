import { bool } from 'prop-types';

export enum PersonType {
  USER = 'User',
  CONTACTED_PERSON = 'ContactedPerson',
  INVITED_PERSON = 'InvitedPerson',
  ORGANISATION = 'Organisation',
  PERSON = 'Person'
}

export enum Role {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
  OWNER = 'OWNER'
}

export enum PhoneType {
  MOBILE = 'MOBILE',
  WORK = 'WORK',
  HOME = 'HOME'
}

export interface Member {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  countryCode: string;
  spiderTxtId: number;
  personType: PersonType;
  phones: {
    fullNumber: string;
    countryId: string;
    countryCode: string;
    number: string;
    type: PhoneType;
  }[];
  isEditable: boolean;
  role: Role;
}

export interface MembersAPIResponse {
  items: Member[];
}
