import React from 'react';
import { useSelector } from 'react-redux';
import { getSelectedDisplayPoint } from '../../../redux/selectors/eventData';
import { DisplayPoint } from '../../../common/api/spidertracks-sdk/types/TrackData';
import { PointDetail } from './PointDetail';
import styled from 'styled-components';
import { getDateTimeFormat, getTimezone } from '../../../redux/selectors/userData';
import {
  dateFormatter,
  formatStandardDateStrings,
  fromTimestamp
} from '../../../helpers/formatTime';

const PointDetailPanelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  margin-top: 0px;
  margin-bottom: 5px;
`;

const PointDetailContent = styled.div`
  padding-right: 2rem;
`;

const hasProperty = (selectedMarkerPoint: DisplayPoint, key: string) => {
  const p = selectedMarkerPoint[key as keyof DisplayPoint];
  return !!(p || typeof p === 'string' || p === 0);
};

const hasNumericProperty = (selectedMarkerPoint: DisplayPoint, key: string) =>
  hasProperty(selectedMarkerPoint, key) && !isNaN(selectedMarkerPoint[key as keyof DisplayPoint]);

type PointDetailPanelProps = {
  timezone: string;
};

export const PointDetailPanel:React.FC<PointDetailPanelProps> =  ({timezone}) => {
  const selectedDisplayPoint = useSelector(getSelectedDisplayPoint);
  if (selectedDisplayPoint === undefined) {
    return <React.Fragment />;
  }

  const dateFormat = useSelector(getDateTimeFormat);

  const localMoment = fromTimestamp(selectedDisplayPoint.timestamp);
  const formatFn = dateFormatter(dateFormat.replace(' z',''));
  const [localTime] = formatStandardDateStrings(formatFn, localMoment, timezone);

  const hasCoordinates =
    hasProperty(selectedDisplayPoint, 'coordinates') &&
    !!selectedDisplayPoint.coordinates.value.length;
  const hasAltitude = hasNumericProperty(selectedDisplayPoint, 'altitude');
  const hasHeightAgl = hasNumericProperty(selectedDisplayPoint, 'heightAgl');
  const hasSpeed = hasNumericProperty(selectedDisplayPoint, 'speed');
  const hasHeading = hasNumericProperty(selectedDisplayPoint, 'heading');
  const altitudeValues = [];
  if (hasAltitude) {
    altitudeValues.push(
      `${selectedDisplayPoint.altitude} ${selectedDisplayPoint.altitudeUnit} AMSL`
    );
  }
  if (hasHeightAgl) {
    altitudeValues.push(
      `${selectedDisplayPoint.heightAgl} ${selectedDisplayPoint.altitudeUnit} AGL`
    );
  }
  const hasAltitudeValues = hasAltitude || hasHeightAgl;

  return (
    <PointDetailPanelWrapper>
      <PointDetailContent>
        <PointDetail name={'TIME'} value={`${localTime}`} />
      </PointDetailContent>

      {hasCoordinates && (
        <PointDetailContent>
          <PointDetail
            name={'POSITION'}
            value={selectedDisplayPoint.coordinates.value.join(' ')}
            copySupported={true}
          />
        </PointDetailContent>
      )}

      {hasAltitudeValues && (
        <PointDetailContent>
          <PointDetail name={'ALTITUDE'} value={altitudeValues.join(' | ')} />
        </PointDetailContent>
      )}

      {hasSpeed && (
        <PointDetailContent>
          <PointDetail
            name={'SPEED'}
            value={`${selectedDisplayPoint.speed} ${selectedDisplayPoint.speedUnit}`}
          />
        </PointDetailContent>
      )}

      {hasHeading && (
        <PointDetailContent>
          <PointDetail name={'DIRECTION'} value={`${selectedDisplayPoint.heading}° T`} />
        </PointDetailContent>
      )}
    </PointDetailPanelWrapper>
  );
};
