import React, { useContext, FC } from 'react';
import { Button } from 'antd';
import { Context as SisenseContext } from '../../context/SisenseContextSafetyEvents';

const InsightsDashboardExportButton: FC = ({}) => {
  const {
    sisense,
    sisenseDashboardReady,
    sisenseDashboardBeingExported,
    setSisenseDashboardBeingExported
  } = useContext(SisenseContext);

  const openExportDialog = () => {
    // @ts-ignore
    setSisenseDashboardBeingExported(true);
    // @ts-ignore
    sisense.dashboard.export().then(() => {
      // @ts-ignore
      setSisenseDashboardBeingExported(false);
    });
  };

  return (
    <Button
      type="primary"
      icon="download"
      size="large"
      // ghost
      style={{ padding: '0 1.25rem', float: 'right' }}
      onClick={openExportDialog}
      disabled={!sisenseDashboardReady || sisenseDashboardBeingExported}
    >
      Export
    </Button>
  );
};
export default InsightsDashboardExportButton;
