import React from 'react';
import PropTypes from 'prop-types';

import './styles/scss/styles.scss';

const FlightEventTimelineRow = ({ name, time, encodedIconUrl, classNames = '' }) => {
  return (
    <div className={`event-timeline-row ${classNames}`}>
      <img className="icon" alt="event-icon" src={encodedIconUrl} />
      <div className="text-container">
        <div className="name">{name}</div>
        <div className="time">{time}</div>
      </div>
    </div>
  );
};

FlightEventTimelineRow.propTypes = {
  time: PropTypes.string,
  encodedIconUrl: PropTypes.string,
  name: PropTypes.string,
  classNames: PropTypes.string
};

export default FlightEventTimelineRow;
