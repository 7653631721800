import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col, Select, Checkbox } from 'antd';

const formItemStyle = { marginBottom: '1px' };

const centerStyle = {
  textAlign: 'center',
  padding: '6px 10px'
};

const { Option } = Select;

const SpiderSettingsRodRocDropdown = ({
  name,
  label,
  unit,
  getFieldDecorator,
  getFieldValue,
  setFieldsValue,
  validation,
  setSaveButtonDisabled,
  disabled = false
}) => {
  /**
   * This text explains why we're displaying a vertical speed value (rate of
   * descent threshold, rate of climb threshold) different from the actual
   * value we're setting or retrieving from the spider.
   *
   * Background - The spider only accepts a value that's in m/s and of bigint type,
   * so it can't have a decimal value. The value that is set by the end user is always
   * ft/min (e.g. 500 ft/min). We need to convert it to m/s before sending it to the API,
   * which multiplies it by 10 then updates the spider (through IOT) with that value.
   * This means that we can only send a value of a single point decimal from the front end
   * to the api, so that it reaches the spider in its proper format.
   *
   * If the user sets the value to 500 ft/min, we convert it first to m/s; this is
   * 500 / 196.85039 = 2.540000047752001. We round this value to the tenth (2.5) before sending
   * it to the API, so that the API could convert it to int by multipying it by 10
   * (So the spider has now the value of 25, which is equivalent to 2.5 m/s).
   *
   * Therefore ft/min values are set in approximate m/s values, but displayed in ft/min
   */
  const dropdownOptionsFeetsPerMinute = [
    { actualValue: 492.1, displayedValue: 500 },
    { actualValue: 984.3, displayedValue: 1000 },
    { actualValue: 1476.4, displayedValue: 1500 },
    { actualValue: 1988.2, displayedValue: 2000 },
    { actualValue: 2500, displayedValue: 2500 },
    { actualValue: 2992.1, displayedValue: 3000 }
  ];
  const dropdownOptionsMetersPerSeconds = [
    { actualValue: 2.5, displayedValue: 2.5 },
    { actualValue: 5, displayedValue: 5 },
    { actualValue: 7.5, displayedValue: 7.5 },
    { actualValue: 10.1, displayedValue: 10.1 },
    { actualValue: 12.7, displayedValue: 12.7 },
    { actualValue: 15.2, displayedValue: 15.2 }
  ];
  const dropdownOptions =
    unit === 'ft/min' ? dropdownOptionsFeetsPerMinute : dropdownOptionsMetersPerSeconds;
  const options = dropdownOptions.map(option => {
    return (
      <Option key={option.displayedValue} value={option.actualValue}>
        {option.displayedValue}
      </Option>
    );
  });

  const fieldValue = getFieldValue(name);
  const checked = fieldValue !== 0;
  const checkboxStyle = { transform: 'scale(1.8)', marginLeft: '9px', paddingBottom: '4px' };
  const inputStyle = fieldValue === 0 ? { color: 'rgba(0,0,0,0)' } : {};

  const setCheckbox = e => {
    if (e.target.checked) {
      setFieldsValue({ [name]: undefined });
    } else {
      setSaveButtonDisabled(false);
      setFieldsValue({ [name]: 0 });
    }
  };

  const setInput = value => {
    setSaveButtonDisabled(false);
    setFieldsValue({ [name]: value });
  };

  return (
    <Form.Item name={name} label={label} style={formItemStyle}>
      <Row>
        <Col span={16}>
          <Checkbox
            checked={checked}
            style={checkboxStyle}
            onChange={setCheckbox}
            disabled={disabled}
          />
          {getFieldDecorator(name, {
            rules: [
              {
                required: validation.required,
                message: validation.errorMessage
              }
            ]
          })(
            <Select
              onSelect={setInput}
              disabled={!checked}
              size="large"
              style={{ width: '78%', marginLeft: '20px', ...inputStyle }}
            >
              {options}
            </Select>
          )}
        </Col>
        <Col className="d-flex" span={8} style={centerStyle}>
          {unit}
        </Col>
      </Row>
    </Form.Item>
  );
};

SpiderSettingsRodRocDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  validation: PropTypes.object.isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
  getFieldValue: PropTypes.func.isRequired,
  setFieldsValue: PropTypes.func.isRequired,
  unit: PropTypes.string,
  setSaveButtonDisabled: PropTypes.func,
  disabled: PropTypes.bool
};

export default SpiderSettingsRodRocDropdown;
