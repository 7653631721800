import React from 'react';
import useScript from '../hooks/useScript';

export const ZendeskContainer = () => {
  useScript(
    'https://static.zdassets.com/ekr/snippet.js?key=b0b5148d-515d-4219-83e6-c0dc4193dd6b',
    'ze-snippet'
  );

  return <></>;
};

export default ZendeskContainer;
