import React, { useEffect } from 'react';
import { Form, Select, Tooltip } from 'antd';
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';

interface EventTypeOptionsProps {
  disabled: boolean;
  getFieldDecorator: Function;
  options: Function;
  eventType: string;
  setFieldsValue: Function;
}

export const EventTypeOptions = ({
  disabled,
  getFieldDecorator,
  options,
  eventType,
  setFieldsValue
}: EventTypeOptionsProps) => {
  useEffect(() => {
    if (disabled) {
      setFieldsValue({ eventType });
    }
  }, [disabled, eventType, setFieldsValue]);

  return (
    <Form.Item
      label={
        <span>
          Event Type
          <Tooltip
            placement="right"
            title={
              'Select Event Type from the drop down to determine when a Geofence Event will be created, e.g. on entry or exit of the fenced area, or both'
            }
          >
            <ExclamationCircleFilled className="ml-2" style={{ color: 'rgba(0,0,0,0.25)' }} />
          </Tooltip>
        </span>
      }
    >
      {getFieldDecorator('eventType', {
        rules: [{ required: true, message: 'Please enter an event type' }]
      })(
        <Select placeholder="Type" disabled={disabled} onChange={() => {}}>
          {options([
            { actualValue: 'both', displayedValue: 'Both' },
            { actualValue: 'entry', displayedValue: 'Entry' },
            { actualValue: 'exit', displayedValue: 'Exit' }
          ])}
        </Select>
      )}
    </Form.Item>
  );
};

export default EventTypeOptions;
