import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { BeaconGreenHex } from '../../../../common/constants';

const Icon = props => {
  const Element = props.type;

  return (
    <SvgIcon
      className={props.className}
      style={{
        height: props.height,
        width: props.width,
        color: props.color,
        ...(props.active ? { color: BeaconGreenHex } : {})
      }}
      viewBox="0 0 200 200"
      preserveAspectRatio="none"
    >
      <Element />
    </SvgIcon>
  );
};

const DefaultIconType = () => <path />;

Icon.defaultProps = {
  type: DefaultIconType,
  color: 'grey',
  className: '',
  height: 50,
  width: 50,
  active: false
};

Icon.propTypes = {
  type: PropTypes.elementType,
  color: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  active: PropTypes.bool
};

export default Icon;
