import React from 'react';
import { PrivateRoute } from './PrivateRoute';
import { MainLayout } from '../layout/Main';

export function MainLayoutPrivateRoute({ ...rest }) {
  return (
    <MainLayout>
      <PrivateRoute {...rest} />
    </MainLayout>
  );
}
