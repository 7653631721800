const parseVersion = (version: string): number[] => {
  const versionNumbers = version.split('.').map(Number);
  while (versionNumbers.length < 4) {
    versionNumbers.push(0);
  }
  return versionNumbers;
};

/*
 * returns 1 if version1 is greater than version2
 * returns -1 if version1 is less than version2
 * returns 0 if version1 is equal to version2
 */
export const compareSpiderSoftwareVersion = (version1: string, version2: string): number => {
  const [major1, minor1, patch1, patch2_1] = parseVersion(version1);
  const [major2, minor2, patch2, patch2_2] = parseVersion(version2);

  if (major1 !== major2) {
    return major1 > major2 ? 1 : -1;
  }
  if (minor1 !== minor2) {
    return minor1 > minor2 ? 1 : -1;
  }
  if (patch1 !== patch2) {
    return patch1 > patch2 ? 1 : -1;
  }
  if (patch2_1 !== patch2_2) {
    return patch2_1 > patch2_2 ? 1 : -1;
  }
  return 0;
};
