import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { message } from 'antd';
import MapSlider from './styles/js/styled';
import AircraftList from '../AircraftList/AircraftList';
import { getAircraftListVisibility } from '../../../redux/selectors/mapData';
import AircraftListToggle from '../AircraftList/components/aircraftlisttoggle/AircraftListToggle';
import { gaEventHelper } from '@spidertracks/common';
import { setAircraftListVisibility } from '../../../redux/reducers/mapReducer/actions/map';

class MapNavigation extends Component {
  static propTypes = {
    sosData: PropTypes.array,

    selectedPoint: PropTypes.object
  };
  constructor(props) {
    super(props);
    this.openPopup = this.openPopup.bind(this);
  }

  openPopup = () => {
    message.error('Weather not currently available. Please try again later.');
  };

  sendGAEvent = aircraftListOpen => {
    let eventAction = aircraftListOpen ? 'Show' : 'Hide';
    let eventLabel = eventAction + ' Aircraft List';
    gaEventHelper('AircraftListToggle')(eventAction, eventLabel);
  };

  toggleAircraftList = () => {
    this.props.setAircraftListVisibility(!this.props.aircraftListVisible);
    this.sendGAEvent(this.props.aircraftListVisible);
  };

  render() {
    const mapSliderStyle = !this.props.aircraftListVisible ? { left: '-261px' } : {};

    return (
      <MapSlider style={mapSliderStyle}>
        <AircraftList />
        <AircraftListToggle
          aircraftListVisible={this.props.aircraftListVisible}
          handleClick={this.toggleAircraftList}
        />
      </MapSlider>
    );
  }
}

MapNavigation.propTypes = {
  aircraftListVisible: PropTypes.bool
};

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      setAircraftListVisibility
    },
    dispatch
  )
});

const mapStateToProps = state => ({
  aircraftListVisible: getAircraftListVisibility(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(MapNavigation);
