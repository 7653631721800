import React, { useEffect } from 'react';
import { node } from 'prop-types';

const LoginLayout = ({ children }) => {
  useEffect(() => {
    document.title = `Login | Spidertracks`;
  }, []);
  return <div>{children}</div>;
};

LoginLayout.propTypes = { children: node };
LoginLayout.defaultProps = { children: {} };

export default LoginLayout;
