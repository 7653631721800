import React, { memo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { gaEventHelper } from '@spidertracks/common';
import { MenuIcon } from '../MenuIcon';
import SquareFootIcon from '@material-ui/icons/SquareFoot';
import Icon from '../Icon';
import MeasureTool from 'measuretool-googlemaps-v3';
import { useGoogleMap } from '@react-google-maps/api';
import { connect } from 'react-redux';
import { getDistanceUnit } from '../../../../../redux/selectors/userData';
import { GOOGLE_MAP_CONTAINER } from '../../GoogleMap/constants';

const meaureToolUnitMap = {
  Kilometres: MeasureTool.UnitTypeId.METRIC,
  Miles: MeasureTool.UnitTypeId.IMPERIAL,
  'Nautical Miles': MeasureTool.UnitTypeId.NAUTICAL
};

const getMeasureToolUnit = userDistanceUnit => {
  return meaureToolUnitMap.hasOwnProperty(userDistanceUnit)
    ? meaureToolUnitMap[userDistanceUnit]
    : meaureToolUnitMap.Kilometres;
};

const gaEventSender = gaEventHelper('MapControls - Measure Tool');

const sendGAEvent = () => {
  let eventAction = 'Enable Measure Tool';
  let eventLabel = 'Measure Tool ' + eventAction;
  gaEventSender(eventAction, eventLabel);
};

let measureTool;
let initMeasureTool = (map, userDistanceUnit) => {
  measureTool = new MeasureTool(map, {
    showSegmentLength: true,
    unit: getMeasureToolUnit(userDistanceUnit),
    contextMenu: false
  });
};

export const MeasureToolButton = ({ userDistanceUnit }) => {
  const [isMeasuring, setIsMeasuring] = useState(false);
  const map = useGoogleMap();

  useEffect(() => {
    if (!measureTool) {
      initMeasureTool(map, userDistanceUnit);
      measureTool.addListener('measure_start', () => {
        setIsMeasuring(true);
      });
      measureTool.addListener('measure_end', () => {
        setIsMeasuring(false);
      });
    }
    return () => {
      measureTool = null;
    };
  }, [map]);

  useEffect(() => {
    if (measureTool) {
      measureTool.setOption('unit', getMeasureToolUnit(userDistanceUnit));
    }
  }, [userDistanceUnit]);

  const enableMeasureTool = useCallback(() => {
    if (!isMeasuring) {
      sendGAEvent();
      measureTool.start();
    } else {
      measureTool.end();
    }
  }, [isMeasuring]);

  return (
    <div className="map-control">
      <Tooltip
        placement="left"
        title="Measure Tool"
        onClick={enableMeasureTool}
        getPopupContainer={() => document.getElementById(GOOGLE_MAP_CONTAINER)}
      >
        <MenuIcon active={isMeasuring}>
          <Icon height={18} width={18} type={SquareFootIcon} active={isMeasuring} />
        </MenuIcon>
      </Tooltip>
    </div>
  );
};

MeasureToolButton.propTypes = {
  userDistanceUnit: PropTypes.string
};

MeasureToolButton.defaultProps = {
  userDistanceUnit: 'Kilometres'
};

const mapStateToProps = state => {
  return {
    userDistanceUnit: getDistanceUnit(state)
  };
};

export default connect(mapStateToProps)(memo(MeasureToolButton));
