import { MapState } from '../types';
import { FullState } from '../../store';
import wd from '../../components/Flying/Map/MapControls/weather/weatherdata.json';
import { createSelector } from 'reselect';

const weatherdata = wd as WeatherDataFile;

interface WeatherDataFile {
  options: WeatherData[];
}

interface WeatherData {
  productCode: string;
  type: 'graphical' | 'textual';
  name: string;
  config: 'Standard-Mercator' | 'Standard-Geodesic';
  provider: 'baron';
}

export const mapData = (state: FullState): MapState => state.mapReducer;

export const getSelectedMarkerPoint = createSelector(
  [mapData],
  mapData => mapData.selectedMarkerPoint
);

export const getSelectedMarkerPointTimestamp = createSelector(
  [getSelectedMarkerPoint],
  selectedMarkerPoint => selectedMarkerPoint && selectedMarkerPoint.timestamp
);

export const getPointGroupingOption = createSelector(
  [mapData],
  mapData => mapData.pointGroupingOption
);

export const getSelectedFilter = createSelector([mapData], mapData => mapData.selectedFilter);

export const getUiSettings = createSelector([mapData], mapData => mapData.uiSettings);

export const getKmlData = createSelector([mapData], mapData => mapData.kmlData);

export const getActiveKmls = createSelector([mapData], mapData => mapData.activeKmls);

export const getMapView = createSelector([mapData], mapData => mapData.mapView);

export const getSosData = createSelector([mapData], mapData => mapData.sosData);

export const getMapStatus = createSelector([mapData], mapData => mapData.mapStatus);

export const getMapZoomLevel = createSelector([mapData], mapData => mapData.mapZoomLevel);

export const getAircraftListVisibility = createSelector(
  [mapData],
  mapData => mapData.aircraftListVisible
);

export const mapInitialLoaded = createSelector([getMapStatus], mapStatus => mapStatus.loaded);

export const getGoogleView = createSelector([getMapView], mapView => mapView.googleView);

export const getSkyVectorView = createSelector([getMapView], mapView => mapView.skyVectorView);

export const getRawWeatherData = (): WeatherData[] => weatherdata.options;

export const getSelectedWeatherLayer = (state: FullState) => mapData(state).weatherLayer;

export const getWeatherDataByProductCode = (
  productCode: WeatherData['productCode']
): WeatherData | undefined =>
  getRawWeatherData().find(weatherLayer => weatherLayer.productCode === productCode);

export const getCurrentWeatherLayerByType = (state: FullState, type: WeatherData['type']) => {
  const selectedWeatherLayer = getSelectedWeatherLayer(state);
  if (!selectedWeatherLayer) {
    return;
  }

  const weatherLayerData = getWeatherDataByProductCode(selectedWeatherLayer);
  if (weatherLayerData === undefined || weatherLayerData.type !== type) {
    return;
  }

  return weatherLayerData;
};

export const getCurrentGraphicalWeatherLayer = (state: FullState): WeatherData | undefined =>
  getCurrentWeatherLayerByType(state, 'graphical');

export const getCurrentTextualWeatherLayer = (state: FullState): WeatherData | undefined =>
  getCurrentWeatherLayerByType(state, 'textual');

export default {
  mapData,
  getSelectedMarkerPoint,
  getAircraftListVisibility
};
