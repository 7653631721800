import React, { memo } from 'react';
import styled from 'styled-components';
import { Checkbox } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { togglePanelVisibility } from '../../../redux/slice/flightExplorer';
import { getPanelVisibility } from '../../../redux/selectors/flightExplorer';
import { PanelVisibility, PanelVisibilityKeys } from '../../../redux/types';

const PanelVisibilityControlButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-left: auto;
  padding-right: 2rem;
  height: 100%;
`;

const PanelVisibilitySelectionWrapper = styled.div<{ paddingLeft: number; paddingRight: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  padding-left: ${props => props.paddingLeft}rem;
  padding-right: ${props => props.paddingRight}rem;
`;

const MapVisibilitySelectionGroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  height: 100%;
`;

export const PanelVisibilityControlButtons: React.FC = () => {
  const dispatch = useDispatch();
  const panelVisibility = useSelector(getPanelVisibility);

  const handlePanelVisibilityClick = (panelName: PanelVisibilityKeys) => {
    dispatch(togglePanelVisibility({ panel: panelName }));
  };

  const panelVisibility2DMapDisabled =
    panelVisibility['3dfr'] !== PanelVisibility.VISIBLE ||
    panelVisibility['2dfr'] === PanelVisibility.DISABLED;
  const panelVisibility3DFRDisabled =
    panelVisibility['2dmap'] !== PanelVisibility.VISIBLE ||
    panelVisibility['3dfr'] === PanelVisibility.DISABLED;

  return (
    <PanelVisibilityControlButtonsWrapper>
      <PanelVisibilitySelectionWrapper paddingLeft={0} paddingRight={0.5}>
        <Checkbox
          data-testid="flightEventsTimeline"
          value={'flightEventsTimeline'}
          checked={panelVisibility['flightEventsTimeline'] === PanelVisibility.VISIBLE}
          disabled={panelVisibility['flightEventsTimeline'] === PanelVisibility.DISABLED}
          onChange={() => handlePanelVisibilityClick('flightEventsTimeline')}
        >
          Timeline
        </Checkbox>
      </PanelVisibilitySelectionWrapper>

      <MapVisibilitySelectionGroupWrapper>
        <PanelVisibilitySelectionWrapper paddingLeft={0.3} paddingRight={0.25}>
          <Checkbox
            data-testid="2dmap"
            value={'2dmap'}
            disabled={panelVisibility2DMapDisabled}
            checked={panelVisibility['2dmap'] === PanelVisibility.VISIBLE}
            onChange={() => handlePanelVisibilityClick('2dmap')}
          >
            Map
          </Checkbox>
        </PanelVisibilitySelectionWrapper>

        <PanelVisibilitySelectionWrapper paddingLeft={0.1} paddingRight={0.3}>
          <Checkbox
            name="3D-FR"
            data-testid="3dfr"
            value={'3dfr'}
            disabled={panelVisibility3DFRDisabled}
            checked={panelVisibility['3dfr'] === PanelVisibility.VISIBLE}
            onChange={() => handlePanelVisibilityClick('3dfr')}
          >
            3D-FR
          </Checkbox>
        </PanelVisibilitySelectionWrapper>
      </MapVisibilitySelectionGroupWrapper>

      <PanelVisibilitySelectionWrapper paddingLeft={0.5} paddingRight={0}>
        <Checkbox
          name="Chart"
          data-testid="flightReportSummaryChart"
          value={'flightReportSummaryChart'}
          checked={panelVisibility['flightReportSummaryChart'] === PanelVisibility.VISIBLE}
          disabled={panelVisibility['flightReportSummaryChart'] === PanelVisibility.DISABLED}
          onChange={() => handlePanelVisibilityClick('flightReportSummaryChart')}
        >
          Chart
        </Checkbox>
      </PanelVisibilitySelectionWrapper>
    </PanelVisibilityControlButtonsWrapper>
  );
};

export default memo(PanelVisibilityControlButtons);
