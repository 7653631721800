import React, { FC, useEffect, useState } from 'react';
import { FormComponentProps } from 'antd/lib/form';
import { ConfirmationButtons } from '../../common/form';
import { Button, Form } from 'antd';
import { EventRuleSelector, EventRule } from './EventRuleSelector';
import { UserSelector, User } from '../../common/form/UserSelector';
import showDeleteNotificationConfigurationModal from './NotificationDeleteConfirmModal';
import {
  NotificationConfiguration,
  checkboxUserOptionTransformer,
  checkboxRuleOptionTransformer
} from './NotificationForm';
import { NotificationMethodSelector } from './NotificationMethodSelector';
import useFeatureFlag from '../../../hooks/useFeatureFlag';
import { useParams } from 'react-router-dom';
import {
  ExcludedSeveritiesDropdown,
  SendNotificationForSeverity,
  mapSendNotificationForSeverityToExludedSeverities,
  mapExcludedSeveritiesToSendNotificationForSeverity
} from './ExcludedSeveritiesDropdown';
import { Severity } from '../../../common/api/spidertracks-sdk/private/services/NotificationConfigurationService';

export interface ExceedanceByTypeNotificationFormProps extends FormComponentProps {
  initialData: NotificationConfiguration | null;
  createNotification: (config: NotificationConfiguration) => Promise<void>;
  updateNotification: (config: NotificationConfiguration) => Promise<void>;
  deleteNotification: (notificationConfigurationId: string) => Promise<void>;
  closeDrawer: () => void;
  users: User[];
  eventRules: EventRule[];
}

interface FieldValues {
  users: string[];
  notifyMethod: string[];
  eventRules: string[];
  sendNotificationForSeverity: SendNotificationForSeverity;
}

export const ExceedanceByTypeNotificationForm: FC<ExceedanceByTypeNotificationFormProps> = ({
  initialData,
  users,
  eventRules,
  createNotification,
  updateNotification,
  deleteNotification,
  closeDrawer,
  form
}) => {
  const { validateFields } = form;
  const [isSaving, setIsSaving] = useState(false);
  const [showHideDeleteBtn] = useState(initialData ? false : true);
  const notifyMethodOptions = [
    { label: 'Email', value: 'email', disabled: false },
    { label: 'SMS', value: 'sms', disabled: false }
  ];

  const { organisationId } = useParams<{ organisationId: string }>();
  const orgHasInsightsSafety = useFeatureFlag('insights-safety', [organisationId]);

  useEffect(() => {
    if (!initialData) {
      return;
    }
    form.setFields({
      users: {
        value: initialData.users
      },
      notifyMethod: {
        value: initialData.notifyMethod
      },
      eventRules: {
        value: initialData.eventRuleIds
      },
      sendNotificationForSeverity: {
        value: mapExcludedSeveritiesToSendNotificationForSeverity(
          initialData.excludedSeverities as Severity[]
        )
      }
    });
  }, [initialData]);

  const mapEventRulesToUniqueEventTypes = (
    eventRuleIds: string[],
    allEventRules: EventRule[]
  ): string[] => {
    if (eventRuleIds.find(id => id === '*')) {
      return ['*'];
    }
    const uniqueEventTypes = new Set<string>();
    const eventRulesFiltered = allEventRules.filter(rule => eventRuleIds.includes(rule.id));
    for (const rule of eventRulesFiltered) {
      if (rule.eventType) {
        uniqueEventTypes.add(rule.eventType);
      }
    }
    return Array.from(uniqueEventTypes);
  };
  const saveNotificationConfiguration = async (
    err: Error | undefined,
    fieldValues: FieldValues
  ) => {
    try {
      if (!err) {
        setIsSaving(true);
        const notificationConfiguration: NotificationConfiguration = {
          ...(initialData && initialData?.id ? { id: initialData.id } : {}),
          users: fieldValues.users,
          aircraft: ['*'],
          notifyMethod: fieldValues.notifyMethod,
          eventRules: mapEventRulesToUniqueEventTypes(fieldValues.eventRules, eventRules),
          eventRuleIds: fieldValues.eventRules,
          eventClass: 'fsi',
          excludedSeverities: mapSendNotificationForSeverityToExludedSeverities(
            fieldValues.sendNotificationForSeverity
          )
        };

        if (initialData) {
          await updateNotification(notificationConfiguration);
        } else {
          await createNotification(notificationConfiguration);
        }

        setIsSaving(false);
        closeDrawer();
      }
    } catch (e) {
      console.error(e);
      setIsSaving(false);
    }
  };

  const formSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    validateFields((err, fieldValues) => {
      saveNotificationConfiguration(err, fieldValues);
    });
  };

  return (
    <div>
      <div className="ant-drawer-title">Exceedance Notification Configuration</div>

      <Form
        onSubmit={formSubmit}
        layout="vertical"
        hideRequiredMark={false}
        style={{ width: '308px', margin: '30px 0 0 32px' }}
      >
        <UserSelector
          form={form}
          initialValue={[]}
          isEditForm={false}
          users={users}
          userOptionTransformer={checkboxUserOptionTransformer(false)}
          showAllChoice={true}
          showAdminChoice={true}
        />
        <NotificationMethodSelector form={form} notifyMethodOptions={notifyMethodOptions} />
        <EventRuleSelector
          form={form}
          eventClassLabel={'Exceedance (by name)'}
          eventClassLabelSingular={'exceedance'}
          eventClassLabelPlural={'exceedances'}
          eventRuleOptions={eventRules.map(checkboxRuleOptionTransformer(false))}
          showAllChoice={true}
        />
        {orgHasInsightsSafety && (
          <ExcludedSeveritiesDropdown
            form={form}
            initialValue={
              initialData
                ? mapExcludedSeveritiesToSendNotificationForSeverity(
                    initialData?.excludedSeverities as Severity[]
                  )
                : SendNotificationForSeverity.ALL
            }
          />
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'right'
          }}
        >
          <Button
            type="danger"
            size="large"
            style={{ width: '85px', margin: '16px 8px 0 0' }}
            hidden={showHideDeleteBtn}
            onClick={() => {
              showDeleteNotificationConfigurationModal({
                deleteNotification: deleteNotification,
                notificationConfigurationId: initialData?.id!,
                onClose: closeDrawer,
                form
              });
            }}
          >
            Delete
          </Button>
          <ConfirmationButtons onClose={closeDrawer} isSaving={isSaving} />
        </div>
      </Form>
    </div>
  );
};
