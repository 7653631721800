import { Cartesian3, Quaternion, Transforms, Matrix3, Matrix4, HeadingPitchRoll } from 'cesium';

interface CalibrationCube {
  x: number;
  y: number;
  z: number;
}

export function computeQuaternionForOrientation(
  longitude: number,
  latitude: number,
  altitude: number,
  heading: number,
  pitch: number,
  roll: number,
  calibrationCube: CalibrationCube = { x: 0, y: 0, z: 0 }
) {
  const position = Cartesian3.fromDegrees(longitude, latitude, altitude);
  const transform4 = Transforms.eastNorthUpToFixedFrame(position);
  const transform3 = new Matrix3();
  Matrix4.getMatrix3(transform4, transform3);
  const transformQ = Quaternion.fromRotationMatrix(transform3);
  // Convert the h/p/r values to a (local-reference) Quaternion
  const { x, y, z } = calibrationCube;
  const localHpr = new HeadingPitchRoll(heading + x, pitch + y, roll + z);
  const localQ = Quaternion.fromHeadingPitchRoll(localHpr);
  // Result is local times transform
  const ret = new Quaternion();
  Quaternion.multiply(transformQ, localQ, ret);
  return ret;
}
