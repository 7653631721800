import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Popover, Tooltip } from 'antd';
import { MenuIcon } from '../../Flying/Map/MapControls/MenuIcon';
import MapViewIcon from '../../Flying/Map/MapControls/icons/MapViewIcon';
import Icon from '../../Flying/Map/MapControls/Icon';
import { MapViewOptions, options } from './MapViewOptions';
import { FullState } from '../../../store';
import { getGoogleView, getSkyVectorView } from '../../../redux/selectors/mapData';
import { mapView } from '../../../redux/reducers/mapReducer/actions/map';
import { setTheme } from '../../../redux/slice/eventsExplorer';
import { getInstance } from '../../../common/api/spidertracks-sdk';
import { UserPreferenceKey } from '../../../common/api/spidertracks-sdk/types/UserData';

const MapControlsContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  top: 8rem;
  right: 0;
`;

const MapControl = styled.div`
  padding: 10px;
`;

const MapViewToChartTheme: Record<string, string> = {
  roadmap: 'WorldStreetMap',
  satellite: 'WorldImagery',
  terrain: 'WorldTopoMap',
  hybrid: 'NatGeoWorldMap',
  basic: 'WorldGrayCanvas',
  vfr: 'SECTIONAL',
  hi: 'HIGH',
  lo: 'LOW'
};

const MapControls = () => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const handleVisibleChange = (state: boolean) => setVisible(state);
  const googleView = useSelector(getGoogleView);
  const skyVectorView = useSelector(getSkyVectorView);

  useEffect(() => {
    const initialView = googleView || skyVectorView;
    if (initialView) {
      const chartTheme = MapViewToChartTheme[initialView];
      if (chartTheme) {
        dispatch(setTheme(chartTheme));
      }
    }
  }, [googleView, skyVectorView]);

  const mapViewChanged = ({ key, value }: { key: string; value: string }) => {
    const provider = options.find(option => option.value === key)?.provider ?? 'ESRI';
    getInstance()
      .saveUserPreference(UserPreferenceKey.MAP_LAYER, {
        mapLayer: key
      })
      .catch(r => console.error(r));

    dispatch(
      mapView({
        googleView: provider === 'ESRI' ? value : '',
        skyVectorView: provider === 'SkyVector' ? value : ''
      })
    );
    setVisible(false);
  };

  return (
    <MapControlsContainer>
      <Tooltip placement={'left'} title={'Map View'}>
        <MapControl data-testid={'map-view'}>
          <Popover
            data-testid={'map-view-popover'}
            placement={'leftTop'}
            overlayStyle={{ width: '218px' }}
            content={
              <MapViewOptions onChange={mapViewChanged} initialView={googleView ?? skyVectorView} />
            }
            title={''}
            trigger={'click'}
            visible={visible}
            onVisibleChange={handleVisibleChange}
          >
            <MenuIcon>
              <Icon height={18} width={18} type={MapViewIcon} />
            </MenuIcon>
          </Popover>
        </MapControl>
      </Tooltip>
    </MapControlsContainer>
  );
};

const mapState = (state: FullState) => ({
  mapView: state.mapReducer.mapView
});

export default memo(connect(mapState)(MapControls));
