import React from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';
import GeofenceForm from './GeofenceForm';
import { useSelector } from 'react-redux';
import { getAltitudeUnit } from '../../../redux/selectors/userData';

export const GeofenceDrawer = ({ visibility, onClose, save, disabled, selectedGeofence }) => {
  const altitudeUnit = useSelector(getAltitudeUnit);
  const title = disabled ? 'View' : 'Add';
  const viewOnly = title === 'View';

  return (
    <Drawer title={title} placement="right" onClose={onClose} visible={visibility} width="85%">
      <GeofenceForm
        onClose={onClose}
        save={save}
        altitudeUnit={altitudeUnit}
        viewOnly={viewOnly}
        selectedGeofence={selectedGeofence}
      />
    </Drawer>
  );
};

GeofenceDrawer.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  selectedGeofence: PropTypes.object
};

GeofenceDrawer.displayName = 'GeofenceDrawer';

export default GeofenceDrawer;
