import React from 'react';

const FilterAlt = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="74"
    height="74"
    viewBox="0 0 74 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.5838 18.4997H52.4171L36.9696 37.9247L21.5838 18.4997ZM13.1046 17.2972C19.3329 25.283 30.8338 40.083 30.8338 40.083V58.583C30.8338 60.2788 32.2213 61.6663 33.9171 61.6663H40.0838C41.7796 61.6663 43.1671 60.2788 43.1671 58.583V40.083C43.1671 40.083 54.6371 25.283 60.8654 17.2972C62.4379 15.2622 60.9888 12.333 58.4296 12.333H15.5404C12.9813 12.333 11.5321 15.2622 13.1046 17.2972Z"
      fill="#40A9FF"
    />
  </svg>
);

export default FilterAlt;
