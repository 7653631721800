import React, { useContext, useEffect } from 'react';
import { connect, Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { notification } from 'antd';
import PointInfoPanel from './PointInfoPanel';
import { selectedMarkerPoint as selectedMarkerPointAction } from '../../../../redux/reducers/mapReducer/actions/map';
import PointInfoPanelParent from './styles/js/styled';
import './styles/scss/styles.scss';
import {
  getAircraftListVisibility,
  getSelectedMarkerPoint
} from '../../../../redux/selectors/mapData';

import store from '../../../../store';
import { CloseIcon } from './CloseIcon';
import { MapContext } from '../../../../context/MapContext';

const NOTIFICATION_KEY = 'PointInfoPanel';

const notificationStyle = {
  borderRadius: '10px',
  position: 'relative',
  marginBottom: 0
};

export const PointInfoPanelContainer = ({
  aircraftListVisible,
  selectedMarkerPoint,
  setSelectedMarkerPoint
}) => {
  const {
    events: { displayEventClasses },
    strictPointGrouping
  } = useContext(MapContext);

  const closePointInfoPanel = () => {
    notification.destroy();
    setSelectedMarkerPoint(undefined);
  };

  const openPointInfoPanel = () => {
    return notification.open({
      style: notificationStyle,
      key: NOTIFICATION_KEY,
      duration: 0,
      placement: 'bottomLeft',
      getContainer: () => document.getElementById(NOTIFICATION_KEY),
      onClose: closePointInfoPanel,
      description: (
        <Provider store={store}>
          {selectedMarkerPoint && (
            <PointInfoPanel
              displayEventClasses={displayEventClasses}
              strictPointGrouping={strictPointGrouping}
            />
          )}
        </Provider>
      ),
      closeIcon: <CloseIcon />
    });
  };

  useEffect(() => {
    if (selectedMarkerPoint) {
      openPointInfoPanel();
    } else {
      // if for what ever reason another part of the system sets selectedMarkerPoint undefined, remove the notification
      notification.destroy();
    }
  }, [selectedMarkerPoint]);

  return <PointInfoPanelParent id={NOTIFICATION_KEY} aircraftListVisible={aircraftListVisible} />;
};

PointInfoPanelContainer.propTypes = {
  aircraftListVisible: PropTypes.bool,
  selectedMarkerPoint: PropTypes.object,
  setSelectedMarkerPoint: PropTypes.func
};

PointInfoPanelContainer.displayName = 'PointInfoPanelContainer';

const mapStateToProps = (state, ownProps) => ({
  selectedMarkerPoint: getSelectedMarkerPoint(state),
  aircraftListVisible: ownProps.hidePanelOffset ? false : getAircraftListVisibility(state)
});

const mapDispatchToProps = {
  setSelectedMarkerPoint: selectedMarkerPointAction
};

export default connect(mapStateToProps, mapDispatchToProps)(PointInfoPanelContainer);
