import deepEqual from 'deep-equal';

export const historyReducer = (state, { type, payload }) => {
  switch (type) {
    case 'aircraftFilterList':
      return deepEqual(state.aircraftFilterList, payload)
        ? state
        : { ...state, aircraftFilterList: payload };
    case 'totalDistance':
      return deepEqual(state.totalDistance, payload) ? state : { ...state, totalDistance: payload };
    case 'totalDuration':
      return deepEqual(state.totalDuration, payload) ? state : { ...state, totalDuration: payload };
    case 'tableData':
      return deepEqual(state.tableData, payload) ? state : { ...state, tableData: payload };
    case 'selectedRowKeys':
      return deepEqual(state.selectedRowKeys, payload)
        ? state
        : { ...state, selectedRowKeys: payload };
    case 'paginationState':
      return deepEqual(state.paginationState, payload)
        ? state
        : { ...state, paginationState: payload };
    case 'startTime':
      return deepEqual(state.startTime, payload) ? state : { ...state, startTime: payload };
    case 'endTime':
      return deepEqual(state.endTime, payload) ? state : { ...state, endTime: payload };
    case 'aircraftIds':
      return deepEqual(state.aircraftIds, payload) ? state : { ...state, aircraftIds: payload };
    case 'zeroMinFlights':
      return deepEqual(state.zeroMinFlights, payload)
        ? state
        : { ...state, zeroMinFlights: payload };
    case 'zeroDistanceFlights':
      return deepEqual(state.zeroDistanceFlights, payload)
        ? state
        : { ...state, zeroDistanceFlights: payload };
    case 'sos':
      return deepEqual(state.sos, payload) ? state : { ...state, sos: payload };
    case 'viewTrack':
      return deepEqual(state.viewingTrack, payload) ? state : { ...state, viewingTrack: payload };
    default:
      return { ...state };
  }
};
