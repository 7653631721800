import { useFeatureConfig } from './useFeatureConfig';
import { getFromLocalStorage } from './useLocalStorage';

export const useFeatureFlag = (feature, organisationIds = []) => {
  const iAmDeveloper = getFromLocalStorage('iAmDeveloper', false);
  const featureConfig = useFeatureConfig();

  if (featureConfig.features[feature] === undefined) {
    console.info(`You have requested an undefined feature - "${feature}"`, featureConfig);
  }

  // has the browser been hijacked by a developer?
  if (iAmDeveloper) {
    return !featureConfig.features[feature];
  }

  // is the feature enabled at an org level?
  // Loop over all organisations in the feature config
  let result = false;
  for (let orgid in featureConfig.organisations) {
    // check if we requested the feature for a matching org
    if (organisationIds.includes(orgid)) {
      // check if the matching org has the feature enabled
      if (Object.keys(featureConfig.organisations[orgid]).includes(feature)) {
        result ||= featureConfig.organisations[orgid][feature];
      }
    }
  }
  // is the feature enabled globally?
  if (Object.keys(featureConfig.features).includes(feature)) {
    result ||= featureConfig.features[feature];
  }
  // else we have run out of things to check and the user still has no access
  return result;
};

export const scopedUseFeatureFlag = (organisationIds = []) => {
  return feature => useFeatureFlag(feature, organisationIds);
};

// TODO: This should probably be considered temporary. It checks for a feature flag without considering
// `iAmDeveloper` status. Useful when you want to add a flag but don't want to break e2e tests that
// (currently, at least) use iAmDeveloper.
export const useNonDeveloperFlag = (feature, organisationIds = []) => {
  const featureConfig = useFeatureConfig();

  if (featureConfig.features[feature] === undefined) {
    console.info(`You have requested an undefined feature - "${feature}"`, featureConfig);
  }

  if (featureConfig.features[feature]) {
    return true;
  }

  for (let orgid in featureConfig.organisations) {
    if (organisationIds.includes(orgid)) {
      if (featureConfig.organisations[orgid][feature]) {
        return true;
      }
    }
  }

  return false;
};

export default useFeatureFlag;
