import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import { useDispatch } from 'react-redux';

import MapLayers from './MapLayers';

const CheckboxGroup = Checkbox.Group;

const MapLayerViewOptions = ({ mapLayers = [], activeKmls = [], updateKmlData }) => {
  const dispatch = useDispatch();
  const onChange = e => {
    const { checked, value } = e.target;
    const currentActiveKmlIndex = activeKmls.indexOf(value);
    const newActiveKmls = activeKmls.slice();

    if (currentActiveKmlIndex > -1 && !checked) {
      newActiveKmls.splice(currentActiveKmlIndex, 1);
    }
    if (currentActiveKmlIndex === -1 && checked) {
      newActiveKmls.splice(0, 0, value);
    }

    dispatch(updateKmlData({ kmlData: mapLayers, activeKmls: newActiveKmls }));

    localStorage.setItem('activeKmls', JSON.stringify(newActiveKmls));
  };

  return (
    <span role="button" tabIndex={0}>
      <CheckboxGroup className="map-layers-checkbox" defaultValue={activeKmls}>
        {mapLayers.map(item => (
          <React.Fragment key={item.id}>
            <MapLayers item={item} onChange={onChange} />
            <br />
          </React.Fragment>
        ))}
      </CheckboxGroup>
    </span>
  );
};

MapLayerViewOptions.propTypes = {
  mapLayers: PropTypes.array,
  activeKmls: PropTypes.array,
  updateKmlData: PropTypes.func
};

export default MapLayerViewOptions;
