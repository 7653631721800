import moment from 'moment';

export const getRanges = () => {
  const now = moment();
  const startOfWeek = moment().startOf('week');
  const startOfMonth = moment().startOf('month');
  const thirtyDaysAgo = moment().add(-30, 'days');
  const threeMonthsAgo = moment().add(-3, 'month');
  const sixMonthsAgo = moment().add(-6, 'month');
  const twelveMonthsAgo = moment().add(-12, 'month');

  return {
    'This week': [startOfWeek, now],
    'Month to date': [startOfMonth, now],
    'Last 30 days': [thirtyDaysAgo, now],
    'Last 3 months': [threeMonthsAgo, now],
    'Last 6 months': [sixMonthsAgo, now],
    'Last 12 months': [twelveMonthsAgo, now]
  };
};

export default getRanges;
