import { formatCoordinates } from '../../helpers/formatCoordinates';
import { convertMetresTo } from '../../components/Flying/Map/utils/helper';
import { EventClass } from '../../constants';
import { decodeGeoHash } from '../../util/geoHash';
import { getPointPropertiesFromSchemaEvent } from './common';
import { formatSpeedFromMetersPerSeconds } from '../../helpers';
import { BUTTON_MODES } from '../../components/Flying/Map/utils/drawing/marker';

export const CAS_SEVERITY_MAP = {
  WARNING: {
    ASSERTED: {
      buttonMode: BUTTON_MODES.EVENTS.CAS_WARNING_ASSERTED,
      displayColorClass: 'alert-color-red'
    },
    CLEARED: {
      buttonMode: BUTTON_MODES.EVENTS.CAS_WARNING_CLEARED,
      displayColorClass: 'alert-color-black'
    }
  },
  CAUTION: {
    ASSERTED: {
      buttonMode: BUTTON_MODES.EVENTS.CAS_CAUTION_ASSERTED,
      displayColorClass: 'alert-color-yellow'
    },
    CLEARED: {
      buttonMode: BUTTON_MODES.EVENTS.CAS_CAUTION_CLEARED,
      displayColorClass: 'alert-color-black'
    }
  },
  ADVISORY: {
    ASSERTED: {
      buttonMode: BUTTON_MODES.EVENTS.CAS_ADVISORY_ASSERTED,
      displayColorClass: 'alert-color-black'
    },
    CLEARED: {
      buttonMode: BUTTON_MODES.EVENTS.CAS_ADVISORY_CLEARED,
      displayColorClass: 'alert-color-black'
    }
  }
};

export const getCASEventButtonMode = event => {
  const casEvent = event.eventDetails.crewAlertEvent;

  return (
    CAS_SEVERITY_MAP[casEvent.severity][casEvent.subtype].buttonMode || BUTTON_MODES.EVENTS.DEFAULT
  );
};

export const getCASEventDisplayName = event => {
  if (event.eventDetails) {
    const crewAlertEvent = event.eventDetails.crewAlertEvent;
    if (crewAlertEvent && crewAlertEvent.severity && crewAlertEvent.subtype) {
      const severity = crewAlertEvent.severity.trim().toLowerCase();
      const status = crewAlertEvent.subtype.trim().toLowerCase();
      return 'CAS ' + severity + ' ' + status;
    }
  }

  return 'CAS alert';
};

const getCASAlertDisplayProperties = event => {
  const casEvent = event.eventDetails.crewAlertEvent;
  if (!casEvent) {
    return {
      alert: 'CAS event',
      displayName: 'CAS event',
      alertDisplayColorClass: 'alert-color-black'
    };
  }

  const alert = getCASEventDisplayName(event);

  return {
    alert,
    displayName: casEvent.message,
    alertDisplayColorClass: CAS_SEVERITY_MAP[casEvent.severity][casEvent.subtype].displayColorClass
  };
};

export const getCASEventProperties = (event, latLongFormat, altitudeUnit, speedUnit) => {
  const { latitude, longitude } = decodeGeoHash(event.geohash);
  const { heading, altitude, speed } = getPointPropertiesFromSchemaEvent(event);

  return {
    latitude,
    longitude,
    coordinates: formatCoordinates({ latitude, longitude }, latLongFormat),
    heading: Math.round(heading),
    altitude: convertMetresTo(altitude, altitudeUnit, 0)[0],
    altitudeUnit,
    speed: Math.ceil(formatSpeedFromMetersPerSeconds(speed, speedUnit)),
    speedUnit,
    type: EventClass.CAS,
    ...getCASAlertDisplayProperties(event)
  };
};
