import { Amplify } from '@spidertracks/common';
import { ServiceAbstract } from '../../ServiceAbstract';
import { ApiResponse } from '../../types/ApiResponse';
import { Event, EventType } from '../../types/Event';

export class EventService extends ServiceAbstract {
  public async getEventsByAircraftAndTime(
    aircraftId: string,
    startTimestamp: number,
    endTimestamp: number
  ): Promise<ApiResponse<Event>> {
    try {
      const events = await Amplify.API.get('authenticated', `events`, {
        queryStringParameters: {
          aircraftId,
          startTimestamp,
          endTimestamp
        }
      });
      return events;
    } catch (error) {
      const emptyEventsData = { items: [], meta: { count: 0, total: 0 }, errors: [] };
      return emptyEventsData;
    }
  }

  public async getEventTypes(organisationId: string): Promise<ApiResponse<EventType>> {
    try {
      const eventTypes = await Amplify.API.get('authenticated', `events/types`, {
        queryStringParameters: {
          organisationId
        }
      });
      return eventTypes;
    } catch (error) {
      const emptyEventTypesData = { items: [], meta: { count: 0, total: 0 }, errors: [] };
      return emptyEventTypesData;
    }
  }
}
