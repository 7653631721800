import React, { createContext, useState, useCallback, useMemo } from 'react';
import { FavouritesDrawer } from '../components/Flying/Map/MapControls/favourites/FavouritesDrawer';
import PropTypes from 'prop-types';

const favouritesContextDefault = {
  drawerOpen: false,
  open: () => {},
  close: () => {}
};

const FavouritesContext = createContext(favouritesContextDefault);
const { Provider } = FavouritesContext;

const FavouritesProvider = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const open = useCallback(() => setDrawerOpen(true), []);
  const close = useCallback(() => setDrawerOpen(false), []);
  const providerValue = useMemo(() => ({ open, close, drawerOpen }), [open, close, drawerOpen]);

  return <Provider value={providerValue}>{children}</Provider>;
};

FavouritesProvider.propTypes = {
  children: PropTypes.node
};

export { FavouritesContext, FavouritesDrawer, FavouritesProvider };
