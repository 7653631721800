import styled from 'styled-components';

export const Divider = styled.div`
  overflow: hidden;
  text-align: center;
  &:before,
  &:after {
    background-color: #cecece;
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }
  &:before {
    right: 0.5em;
    margin-left: -50%;
  }
  &:after {
    left: 0.5em;
    margin-right: -50%;
  }
`;
