import React, { Fragment, useState, useRef } from 'react';
import GeofenceActionTableCell from './GeofenceActionTableCell';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import GeofenceDrawer from './GeofenceDrawer';
import { getFilterDropdown } from '../../common/filters/getFilterDropdown';
import { getRowClassName } from '../../common';
import { FilterIconFunnel } from '../../common/filters';

export const GeofenceTable = ({ isLoading, geofences, deleteGeofence, geofenceNameFilterList }) => {
  const [viewGeofenceDrawerVisibility, setViewGeofenceDrawerVisibility] = useState(false);
  const [selectedGeofence, setSelectedGeofence] = useState();
  const [eventType, setEventType] = useState([]);
  const ref = useRef();
  const [selectedItems, setSelectedItems] = useState([]);

  const clearSelection = () => setSelectedItems([]);
  const remoteTrigger = clearEventFilter => {
    ref.current = clearEventFilter;
  };

  const geofenceFilterDropdownProps = getFilterDropdown({
    onFilter: setSelectedItems,
    clearSelection,
    remoteTrigger,
    displayName: 'name',
    showSearch: true
  });

  const eventFilterDropdownProps = getFilterDropdown({
    onFilter: setEventType,
    displayName: 'event',
    showSearch: false
  });

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      filters: geofenceNameFilterList,
      ...geofenceFilterDropdownProps(),
      filteredValue: selectedItems,
      filterMultiple: true,
      onFilter: (value, record) => record.name === value
    },
    {
      title: 'ALTITUDE',
      dataIndex: 'altitude',
      key: 'altitude'
    },
    {
      title: 'EVENT TYPE',
      dataIndex: 'eventType',
      key: 'eventType',
      filters: [
        { label: 'Both', value: 'breach_any' },
        { label: 'Entry', value: 'breach_in' },
        { label: 'Exit', value: 'breach_out' }
      ],
      ...eventFilterDropdownProps(),
      filterIcon: FilterIconFunnel,
      filteredValue: eventType,
      onFilter: (value, record) => record.rawEventType === value
    },
    {
      title: 'ACTION',
      dataIndex: 'action',
      key: 'action',
      render: function deleteAction(text, record) {
        return <GeofenceActionTableCell value={record.key} text={text} onClick={deleteGeofence} />;
      }
    }
  ];

  const onRow = record => ({
    onClick: () => {
      setSelectedGeofence(record.feature);
      setViewGeofenceDrawerVisibility(true);
    }
  });

  return (
    <Fragment>
      <Table
        id="geofence-list"
        loading={isLoading}
        size="middle"
        columns={columns}
        dataSource={geofences}
        pagination={false}
        onRow={onRow}
        bordered
        rowClassName={getRowClassName}
      />
      {viewGeofenceDrawerVisibility && (
        <GeofenceDrawer
          visibility={viewGeofenceDrawerVisibility}
          selectedGeofence={selectedGeofence}
          onClose={() => setViewGeofenceDrawerVisibility(false)}
          save={() => {}}
          disabled={true}
        />
      )}
    </Fragment>
  );
};

GeofenceTable.propTypes = {
  isLoading: PropTypes.bool,
  geofences: PropTypes.array,
  deleteGeofence: PropTypes.func,
  openViewGeofenceDrawer: PropTypes.func,
  geofenceNameFilterList: PropTypes.array
};

export default GeofenceTable;
