import React from 'react';
import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';

import '../../Flying/Map/MapControls/mapview/styles/scss/mapviewoptions.scss';

export const options = [
  {
    label: 'Map',
    value: 'roadmap',
    key: 'MAP',
    provider: 'ESRI'
  },
  {
    label: 'Satellite',
    value: 'satellite',
    key: 'SATELLITE',
    provider: 'ESRI'
  },
  {
    label: 'Terrain',
    value: 'terrain',
    key: 'TERRAIN',
    provider: 'ESRI'
  },
  {
    label: 'Hybrid',
    value: 'hybrid',
    key: 'HYBRID',
    provider: 'ESRI'
  },
  {
    label: 'VFR View',
    value: 'vfr',
    key: 'SECTIONAL',
    provider: 'SkyVector'
  },
  {
    label: 'IFR High',
    value: 'hi',
    key: 'HIGH',
    provider: 'SkyVector'
  },
  {
    label: 'IFR Low',
    value: 'lo',
    key: 'LOW',
    provider: 'SkyVector'
  },
  {
    label: 'Basic',
    value: 'basic',
    key: 'BASIC',
    provider: 'ESRI'
  }
];

interface ComponentProps {
  initialView: string;
  onChange: ({ key, value }: { key: string; value: string }) => void;
}

export const MapViewOptions = (props: ComponentProps) => {
  const onChange = (e: RadioChangeEvent) => {
    const selectedOption = options.find(option => option.value === e.target.value);
    if (selectedOption) {
      props.onChange({ key: selectedOption.key, value: selectedOption.value });
    }
  };

  return (
    <span role="button" tabIndex={0}>
      <Radio.Group
        data-testid="events-explorer-mapview-selector"
        options={options}
        defaultValue={props.initialView}
        onChange={onChange}
        className="mapview-radio"
      >
        {options.map(option => (
          <Radio key={option.value} value={option.value}>
            {option.label}
          </Radio>
        ))}
      </Radio.Group>
    </span>
  );
};
