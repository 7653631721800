import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Popover, Tooltip } from 'antd';
import Icon from '../Icon';
import MapViewIcon from '../icons/MapViewIcon';
import ViewOptions from './MapViewOptionsContainer';
import { GOOGLE_MAP_CONTAINER } from '../../GoogleMap/constants';
import { MenuIcon } from '../MenuIcon';

const Mapview = () => {
  const [visible, setVisible] = useState(false);
  const hide = () => setVisible(false);
  const handleVisibleChange = state => setVisible(state);

  return (
    <div className="map-control">
      <Tooltip
        placement="left"
        title="Map View"
        getPopupContainer={() => document.getElementById(GOOGLE_MAP_CONTAINER)}
      >
        <Popover
          placement="leftTop"
          getPopupContainer={() => document.getElementById(GOOGLE_MAP_CONTAINER)}
          content={<ViewOptions clickCallback={hide} />}
          title=""
          role="button"
          trigger="click"
          visible={visible}
          onVisibleChange={handleVisibleChange}
        >
          <MenuIcon>
            <Icon height={18} width={18} type={MapViewIcon} />
          </MenuIcon>
        </Popover>
      </Tooltip>
    </div>
  );
};

const mapState = state => ({
  mapView: state
});

export default connect(mapState)(Mapview);
