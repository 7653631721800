import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';
import { FavouritesContext } from '../../../../../../context/FavouritesContext';
import { EmptyDrawer } from './EmptyDrawer';
import { AddFavourite } from './AddFavourite';
import { AddCircle } from '@material-ui/icons';
import FavouritesList from './FavouritesList';
import { populateFavourites } from '../../../../../../redux/reducers/navigationReducer/actions/thunk';
import { getFavourites } from '../../../../../../redux/selectors/userData';
import { GOOGLE_MAP_CONTAINER } from '../../../GoogleMap/constants';

export const FavouritesDrawer = ({ favourites, populateFavourites }) => {
  const [showAddFavourite, setShowAddFavourite] = useState(false);

  const addFavourite = () => {
    setShowAddFavourite(false);
    populateFavourites();
  };

  return (
    <FavouritesContext.Consumer>
      {favouritesDrawer => (
        <Drawer
          className="favourites-list-table"
          bodyStyle={{ padding: 0 }}
          title="Favourites"
          placement="right"
          closable={true}
          onClose={favouritesDrawer.close}
          visible={favouritesDrawer.drawerOpen}
          getContainer={() => document.getElementById(GOOGLE_MAP_CONTAINER)}
        >
          {showAddFavourite ? (
            <AddFavourite onClose={addFavourite} />
          ) : (
            <Fragment>
              {favourites.length === 0 ? (
                <EmptyDrawer />
              ) : (
                <FavouritesList favourites={favourites} />
              )}
              <AddCircle
                style={{
                  fontSize: '80px',
                  color: '#4285F4',
                  position: 'absolute',
                  padding: '10px',
                  right: 0,
                  bottom: 0
                }}
                onClick={() => setShowAddFavourite(true)}
              />
            </Fragment>
          )}
        </Drawer>
      )}
    </FavouritesContext.Consumer>
  );
};

FavouritesDrawer.propTypes = {
  favourites: PropTypes.array,
  populateFavourites: PropTypes.func
};

const mapStateToProps = state => ({
  favourites: getFavourites(state)
});

const mapDispatchToProps = {
  populateFavourites
};

export default connect(mapStateToProps, mapDispatchToProps)(FavouritesDrawer);
