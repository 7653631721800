import { createSlice } from '@reduxjs/toolkit';
import { FlightExplorerState, PanelVisibility, PanelVisibilityKeys } from '../types';
import { getDefaultSelectedMembers } from '../../components/FlightExplorer/FlightExplorerChart/ChartParameters/parameterGroups';

const initialState: FlightExplorerState = {
  elapsedTime: 0,
  totalTime: -1,
  events: {
    selectedDisplayPoint: null
  },
  playbackSpeed: 1,
  isPlaying: false,
  displayTimezone: '',
  updateSelectedDisplayPoint: true,
  panelVisibility: {
    flightEventsTimeline: PanelVisibility.VISIBLE,
    flightReportSummaryChart: PanelVisibility.VISIBLE,
    '2dmap': PanelVisibility.VISIBLE,
    '3dfr': PanelVisibility.HIDDEN
  },
  '3dReplayFeatures': {
    showEvents: true,
    showWall: false
  },
  selectedTrackFlightData: {
    raw: [],
    smoothed: []
  },
  isFlightDataFetchingInProgress: false,
  chartSeries: {
    left: getDefaultSelectedMembers('LEFT'),
    right: getDefaultSelectedMembers('RIGHT')
  },
  // TODO Remove once GA
  dataSource: ''
};

export const flightExplorerSlice = createSlice({
  name: 'flightExplorer',
  initialState,
  reducers: {
    togglePlaybackStatus: state => {
      state.isPlaying = !state.isPlaying;
    },
    setIsFlightDataFetchingInProgress: (
      state,
      {
        payload
      }: {
        payload: { isFlightDataFetchingInProgress: boolean };
      }
    ) => {
      state.isFlightDataFetchingInProgress = payload.isFlightDataFetchingInProgress;
    },
    setPlaybackSpeed: (state, { payload }) => {
      state.playbackSpeed = payload.playbackSpeed;
    },
    setElapsedTime: (state, { payload }) => {
      state.elapsedTime = payload.elapsedTime;
      if (payload.hasOwnProperty('updateSelectedDisplayPoint')) {
        state.updateSelectedDisplayPoint = payload.updateSelectedDisplayPoint;
      } else {
        state.updateSelectedDisplayPoint = true;
      }
    },
    setTotalTime: (state, { payload }) => {
      state.totalTime = payload.totalTime;
    },
    setDisplayTimezone: (state, { payload }) => {
      state.displayTimezone = payload.displayTimezone;
    },
    setSelectedTrackFlightData: (state, { payload }) => {
      state.selectedTrackFlightData = payload.selectedTrackFlightData;
    },
    togglePanelVisibility: (state, { payload }: { payload: { panel: PanelVisibilityKeys } }) => {
      if (payload.hasOwnProperty('panel')) {
        if (
          Object.keys(state.panelVisibility).includes(payload.panel) &&
          state.panelVisibility[payload.panel] !== PanelVisibility.DISABLED
        ) {
          state.panelVisibility[payload.panel] =
            state.panelVisibility[payload.panel] === PanelVisibility.VISIBLE
              ? PanelVisibility.HIDDEN
              : PanelVisibility.VISIBLE;
        }
      }
    },
    set3dReplayFeatures: (state, { payload }) => {
      state['3dReplayFeatures'] = {
        ...state['3dReplayFeatures'],
        ...payload
      };
    },
    setPanelVisibility: (state, { payload }) => {
      state.panelVisibility = {
        flightEventsTimeline:
          payload?.panelVisibility?.flightEventsTimeline ??
          state.panelVisibility.flightEventsTimeline,
        flightReportSummaryChart:
          payload?.panelVisibility?.flightReportSummaryChart ??
          state.panelVisibility.flightReportSummaryChart,
        '2dmap': payload?.panelVisibility?.['2dmap'] ?? state.panelVisibility['2dmap'],
        '3dfr': payload?.panelVisibility?.['3dfr'] ?? state.panelVisibility['3dfr']
      };
    },
    setChartSeries: (state, { payload }) => {
      if (payload.hasOwnProperty('left')) {
        state.chartSeries.left = payload.left;
      }
      if (payload.hasOwnProperty('right')) {
        state.chartSeries.right = payload.right;
      }
    },
    // TODO: Remove once GA
    setDataSource: (state, { payload }) => {
      state.dataSource = payload.dataSource;
    },
    reset: state => {
      state.isPlaying = false;
      state.playbackSpeed = 1;
      state.elapsedTime = 0;
      state.totalTime = 0;
      state.displayTimezone = '';
      state.updateSelectedDisplayPoint = true;
      state.panelVisibility = {
        flightEventsTimeline: PanelVisibility.VISIBLE,
        flightReportSummaryChart: PanelVisibility.VISIBLE,
        '2dmap': PanelVisibility.VISIBLE,
        '3dfr': PanelVisibility.HIDDEN
      };
      state['3dReplayFeatures'] = {
        showEvents: true,
        showWall: false
      };
      state.selectedTrackFlightData = {
        raw: [],
        smoothed: []
      };
      state.isFlightDataFetchingInProgress = false;
      state.chartSeries = {
        left: getDefaultSelectedMembers('LEFT'),
        right: getDefaultSelectedMembers('RIGHT')
      };
    }
  }
});

export const {
  togglePlaybackStatus,
  setIsFlightDataFetchingInProgress,
  setPlaybackSpeed,
  setElapsedTime,
  setTotalTime,
  setDisplayTimezone,
  togglePanelVisibility,
  set3dReplayFeatures,
  setPanelVisibility,
  setSelectedTrackFlightData,
  setChartSeries,
  setDataSource,
  reset
} = flightExplorerSlice.actions;

export default flightExplorerSlice;
