import React from 'react';
import { PropTypes } from 'prop-types';
import { Icon } from 'antd';
import { linkCopied } from '../helpers/globalNotifications';

const CopyLink = ({ url }) => {
  const copyLink = () => {
    navigator.clipboard.writeText(url);
    linkCopied();
  };

  return (
    <React.Fragment>
      <div
        style={{
          backgroundColor: '#F5F5F5',
          width: '100%',
          minHeight: '50px',
          margin: '20px 0 0 0',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px'
        }}
      >
        <div style={{ overflowWrap: 'anywhere', marginRight: '25px', color: '#007bff' }}>{url}</div>
        <Icon
          type="copy"
          style={{ fontSize: '25px', cursor: 'pointer', color: '#007bff' }}
          onClick={copyLink}
        />
      </div>
      <div style={{ marginTop: '20px', color: '#8C8C8C' }}>
        Note that the URL generated will not be saved in the Spidertracks platform so you may choose
        to save this elsewhere if needed.
      </div>
    </React.Fragment>
  );
};

CopyLink.propTypes = {
  url: PropTypes.string.isRequired
};

export default CopyLink;
