export enum FlightStatus {
  NOT_AVAILABLE = 'Not Available',
  AWAITING_OFFLOAD = 'Awaiting Offload',
  AVAILABLE = 'Available',
  PROCESSING = 'Processing',
  IN_REVIEW = 'In Review',
  INCOMPLETE = 'Incomplete',
  NOT_SUPPORTED = 'Not Supported',
  ARCHIVED = 'Archived'
}
