import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

const centerStyle = {
  textAlign: 'center',
  padding: 2
};

const buttonAsLink = {
  WebkitAppearance: 'none',
  background: 'none',
  border: 0,
  WebkitBoxShadow: 'none',
  boxShadow: 'none',
  color: '#1890ff',
  cursor: 'pointer',
  display: 'inline',
  fontSize: 'inherit',
  margin: 0,
  padding: 0,
  textDecoration: 'none',
  outline: 0
};

const PointInfoPanelButtons = ({
  isFirstPointInTrack,
  isLastPointInTrack,
  setNextPoint,
  setPreviousPoint,
  setFirstPoint,
  setLastPoint
}) => {
  return (
    <Row>
      <Col className="d-flex" span={12} style={centerStyle}>
        <Row className="flex-basis-100 flex-1 flex-align-stretch flex-direction-col">
          <span className="align-right">
            <button
              type="button"
              style={buttonAsLink}
              className={`point-info-panel align-left ${isFirstPointInTrack && 'disabled'}`}
              disabled={isFirstPointInTrack}
              data-nav="first"
              onClick={setFirstPoint}
            >
              {'<<'}
            </button>
            <button
              type="button"
              style={buttonAsLink}
              className={`point-info-panel align-right  pl-3 ${isFirstPointInTrack && 'disabled'}`}
              disabled={isFirstPointInTrack}
              data-nav="prev"
              onClick={setPreviousPoint}
            >
              Previous
            </button>
          </span>
        </Row>
      </Col>
      <Col className="d-flex" span={12} style={centerStyle}>
        <Row className="flex-basis-100 flex-1 flex-align-stretch flex-direction-col">
          <span className="align-left">
            <button
              type="button"
              style={buttonAsLink}
              className={`point-info-panel align-left pr-3 ${isLastPointInTrack && 'disabled'}`}
              disabled={isLastPointInTrack}
              data-nav="next"
              onClick={setNextPoint}
            >
              Next
            </button>
            <button
              type="button"
              style={buttonAsLink}
              className={`point-info-panel align-right ${isLastPointInTrack && 'disabled'}`}
              disabled={isLastPointInTrack}
              data-nav="last"
              onClick={setLastPoint}
            >
              {'>>'}
            </button>
          </span>
        </Row>
      </Col>
    </Row>
  );
};

PointInfoPanelButtons.propTypes = {
  centerStyle: PropTypes.object,
  buttonAsLink: PropTypes.object,
  isFirstPointInTrack: PropTypes.bool,
  isLastPointInTrack: PropTypes.bool,
  setNextPoint: PropTypes.func,
  setPreviousPoint: PropTypes.func,
  setFirstPoint: PropTypes.func,
  setLastPoint: PropTypes.func
};

export default PointInfoPanelButtons;
