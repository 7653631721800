import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getLatLng } from '../../../../../../redux/selectors/aircraftData';
import { getColouredPointSvg, getMarkerIcon } from '../../../utils/drawing/marker';
import AircraftTrackPoint from './AircraftTrackPoint';
import { useFeatureFlag } from '../../../../../../hooks';

const NormalTrackPoints = ({
  points,
  onMarkerClick,
  aircraftProperties,
  trackLineColour,
  orgId,
  zIndex
}) => {
  const customizedTrackPolylineEnabled = useFeatureFlag('customized-track-polyline', [orgId]);

  const getOtherMarkers = useCallback(
    () =>
      points.map(
        keyPoint =>
          !!keyPoint && (
            <AircraftTrackPoint
              key={`clustered-${keyPoint.id}`}
              onClick={onMarkerClick(keyPoint)}
              position={getLatLng(keyPoint)}
              icon={
                customizedTrackPolylineEnabled
                  ? getColouredPointSvg(keyPoint, aircraftProperties.trackStatus, trackLineColour)
                  : getMarkerIcon(keyPoint)
              }
              clusterer={false}
              trackStatus={aircraftProperties.trackStatus}
              pointStatus={keyPoint.trackStatus}
              zIndex={zIndex}
            />
          )
      ),
    [
      aircraftProperties.trackStatus,
      points,
      customizedTrackPolylineEnabled,
      onMarkerClick,
      trackLineColour,
      zIndex
    ]
  );

  return <Fragment>{!!points && getOtherMarkers()}</Fragment>;
};

NormalTrackPoints.propTypes = {
  points: PropTypes.array,
  onMarkerClick: PropTypes.func,
  getLatLng: PropTypes.func,
  getMarkerIcon: PropTypes.func,
  aircraftProperties: PropTypes.object,
  trackLineColour: PropTypes.string,
  orgId: PropTypes.string,
  zIndex: PropTypes.number
};

export default NormalTrackPoints;
