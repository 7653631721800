import React from 'react';
import Geofence from './Geofence';

const style = { overflow: 'scroll', height: '100%' };

const GeofenceContainer = () => (
  <div className="px-5 py-4" style={style}>
    <Geofence />
  </div>
);

export default GeofenceContainer;
