import React from 'react';
import PublicRoute from './PublicRoute';
import { MainLayout } from '../layout/Main';

export function MainLayoutPublicRoute({ ...rest }) {
  return (
    <MainLayout>
      <PublicRoute {...rest} />
    </MainLayout>
  );
}
