import React from 'react';
import PropTypes from 'prop-types';
import { Button, PageHeader } from 'antd';

export const EventsConfigHeader = ({ disabled, openDrawer }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <PageHeader
        className="site-page-header"
        style={{ paddingLeft: '0px' }}
        title="Events"
        subTitle="Click the row to edit"
      />
      <Button
        disabled={disabled}
        size="large"
        onClick={openDrawer}
        type="primary"
        style={{ padding: '0 50px' }}
      >
        Add
      </Button>
    </div>
  );
};

EventsConfigHeader.propTypes = {
  openDrawer: PropTypes.func,
  disabled: PropTypes.bool
};

EventsConfigHeader.displayName = 'EventsConfigHeader';

export default EventsConfigHeader;
