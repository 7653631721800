import { ServiceAbstract } from '../../ServiceAbstract';
import { Amplify } from '@spidertracks/common';
import { Geojson } from '../../types/GeoJSON';

export class GeofencesService extends ServiceAbstract {
  public async getGeofences(organisationId?: string): Promise<Geojson> {
    if (organisationId) {
      return Amplify.API.get('authenticated', `geofences?organisationId=${organisationId}`);
    } else {
      return Amplify.API.get('authenticated', `geofences`);
    }
  }

  public async saveGeofence(geofence: object): Promise<Geojson> {
    return Amplify.API.post('authenticated', `geofences`, { body: geofence });
  }

  public async deleteGeofence(geofenceId: string): Promise<Geojson> {
    return Amplify.API.del('authenticated', `geofences/${geofenceId}`);
  }
}
