import React, { useEffect } from 'react';
import { Form, InputNumber } from 'antd';
import { convertMetresTo } from '../../../Flying/Map/utils/helper';

interface AltitudeLimitInputProps {
  getFieldDecorator: Function;
  setFieldsValue: Function;
  isLimitEnabled: boolean;
  disabled: boolean;
  displayUnit: string;
  altitudeLimit: string;
  altitudeUnit: string;
}

export const AltitudeLimitInput = ({
  getFieldDecorator,
  isLimitEnabled,
  disabled,
  displayUnit,
  altitudeLimit,
  setFieldsValue,
  altitudeUnit
}: AltitudeLimitInputProps) => {
  useEffect(() => {
    if (disabled) {
      if (!altitudeLimit) {
        setFieldsValue({ altitudeLimit: '' });
      }
      if (!!altitudeLimit) {
        const val = convertMetresTo(altitudeLimit, altitudeUnit)[0];
        setFieldsValue({ altitudeLimit: val });
      }
    }
  }, [setFieldsValue, altitudeLimit, disabled, altitudeUnit]);

  return (
    <div
      id="altitude-limit-container"
      style={{
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Form.Item style={{ paddingTop: '29px', minWidth: '170px' }} className="mx-2">
        {getFieldDecorator('altitudeLimit', {
          rules: [
            { required: isLimitEnabled, message: 'Please enter an altitude' },
            { type: 'number', message: 'Please enter a number' }
          ]
        })(
          <InputNumber
            autoComplete="off"
            placeholder="e.g 3000"
            disabled={disabled}
            style={{ width: '100%' }}
          />
        )}
      </Form.Item>
      <span id="altitude-limit-unit" style={{ fontSize: 16, paddingTop: 32, marginRight: 8 }}>
        {displayUnit}
      </span>
    </div>
  );
};

export default AltitudeLimitInput;
