import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getLatLng,
  getIndexOfPointInTrack,
  getAircraftWithLatestTrackById,
  getSelectedAircraftById,
  getSelectedTrack
} from '../../../../../../redux/selectors/aircraftData';
import { getHideMapRadiusShading } from '../../../../../../redux/selectors/userData';
import { getSelectedMarkerPoint, getMapZoomLevel } from '../../../../../../redux/selectors/mapData';
import { getSelectedDisplayPoint } from '../../../../../../redux/selectors/eventData';
import LatestTrackPoint from './LatestTrackPoint';
import TrackWithEvents from './TrackWithEvents';
import NormalTrackPoints from './NormalTrackPoints';
import {
  NORMAL_BUTTON_MODES,
  MIN_MAP_ZOOM_LEVEL_TO_SHOW_TRACK_LINE_MARKERS
} from '../../constants';

import { createSelector } from 'reselect';
import { splitLast } from '../../../../../../helpers';
import SelectedTrackPoint from './SelectedTrackPoint';
import { BUTTON_MODES } from '../../../utils/drawing/marker';

export const AircraftTrackPoints = ({
  lastPoint,
  onMarkerClick,
  hideMapRadiusShading,
  showEvents,
  showNormalPointMarkers,
  selectedMarkerLatLng,
  selectedMarkerPoint,
  nonNormalPoints,
  aircraftProperties,
  trackLineColour,
  orgId,
  normalPoints,
  zIndex
}) => {
  return (
    <Fragment>
      {showNormalPointMarkers && (
        <NormalTrackPoints
          points={normalPoints}
          onMarkerClick={onMarkerClick}
          aircraftProperties={aircraftProperties}
          trackLineColour={trackLineColour}
          orgId={orgId}
          zIndex={zIndex}
        />
      )}
      {showEvents && (
        <TrackWithEvents
          points={nonNormalPoints}
          onMarkerClick={onMarkerClick}
          aircraftProperties={aircraftProperties}
          trackLineColour={trackLineColour}
          zIndex={zIndex + 1} // so that events will render over normal points
        />
      )}
      {!!selectedMarkerLatLng && (
        <SelectedTrackPoint point={selectedMarkerPoint} aircraftProperties={aircraftProperties} />
      )}
      <LatestTrackPoint
        point={lastPoint}
        onMarkerClick={onMarkerClick}
        aircraftProperties={aircraftProperties}
        hideMapRadiusShading={hideMapRadiusShading}
        zIndex={zIndex}
      />
    </Fragment>
  );
};

AircraftTrackPoints.propTypes = {
  normalPoints: PropTypes.array,
  nonNormalPoints: PropTypes.array,
  aircraftTrack: PropTypes.object,
  lastPoint: PropTypes.object,
  selectedMarkerPoint: PropTypes.object,
  onMarkerClick: PropTypes.func,
  selectedMarkerLatLng: PropTypes.object,
  aircraftProperties: PropTypes.object,
  trackLineColour: PropTypes.string,
  orgId: PropTypes.string,
  hideMapRadiusShading: PropTypes.bool.isRequired,
  showEvents: PropTypes.bool.isRequired,
  showNormalPointMarkers: PropTypes.bool.isRequired,
  isTrackSelected: PropTypes.bool,
  zIndex: PropTypes.number
};

AircraftTrackPoints.defaultProps = {
  isFirstPointInTrack: false,
  isLastPointInTrack: false
};

const mapStateToProps = function connectFactoryFunction() {
  const selectSplitLast = createSelector(
    points => points,
    points => splitLast(points)
  );

  const selectNormalAndNonNormal = createSelector([selectSplitLast], ([points]) =>
    points.reduce(
      (accumulator, point) => {
        if (NORMAL_BUTTON_MODES.includes(point.buttonMode)) {
          accumulator[0].push({ ...point, buttonMode: BUTTON_MODES.POINTS.NORMAL });
        } else {
          accumulator[1].push(point);
        }
        return accumulator;
      },
      [[], []]
    )
  );

  return (state, ownProps) => {
    const selectedTrack = getSelectedTrack(state);
    const noOfEventClassesToDisplay = ownProps.displayEventClasses.length;
    // displayPoint is only used on the history flight report page
    const selectedMarkerPoint =
      noOfEventClassesToDisplay && ownProps.onHistoryPage
        ? getSelectedDisplayPoint(state)
        : getSelectedMarkerPoint(state);
    let selectedMarkerBelongsToTrack = false;

    if (selectedMarkerPoint) {
      selectedMarkerBelongsToTrack =
        noOfEventClassesToDisplay && ownProps.onHistoryPage
          ? !!ownProps.points.find(p => p.id === selectedMarkerPoint.id)
          : getIndexOfPointInTrack(ownProps.aircraftTrack, selectedMarkerPoint) > -1;
    }

    const aircraft =
      noOfEventClassesToDisplay && ownProps.onHistoryPage
        ? getSelectedAircraftById(state, ownProps.aircraftTrack.aircraft.id)
        : getAircraftWithLatestTrackById(state, ownProps.aircraftTrack.aircraft.id);
    const lastPoint = selectSplitLast(ownProps.points)[1];
    const [normalPoints, nonNormalPoints] = selectNormalAndNonNormal(ownProps.points);

    // when there is no selected track, show the normal points for all tracks
    // when there is a selected track, only show the normal points for the selected track
    const showNormalPointMarkers =
      (!selectedTrack || ownProps.isTrackSelected) &&
      getMapZoomLevel(state) >= MIN_MAP_ZOOM_LEVEL_TO_SHOW_TRACK_LINE_MARKERS &&
      ownProps.showEvents;

    return {
      normalPoints,
      nonNormalPoints,
      selectedMarkerPoint: {
        ...selectedMarkerPoint,
        aircraftTrackState: ownProps.aircraftTrack.state
      },
      lastPoint,
      showEvents: ownProps.showEvents,
      showNormalPointMarkers,
      selectedMarkerLatLng: selectedMarkerBelongsToTrack
        ? getLatLng(selectedMarkerPoint)
        : undefined,
      aircraftProperties: {
        trackStatus: aircraft[0].trackStatus,
        isActive: aircraft[0].isActive,
        aircraftRegistration: aircraft[0].registration,
        aircraftIcon: aircraft[0].aircraftIcon,
        aircraftIconColour: aircraft[0].aircraftIconColour
      },
      trackLineColour: ownProps.trackLineColour,
      orgId: ownProps.aircraftTrack.orgId,
      hideMapRadiusShading: getHideMapRadiusShading(state)
    };
  };
};

export default connect(mapStateToProps)(memo(AircraftTrackPoints));
