//LATER: fix eslint parse error: https://stackoverflow.com/questions/62079477/line-0-parsing-error-cannot-read-property-map-of-undefined
import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, PageHeader, Progress, Spin } from 'antd';
import { Moment } from 'moment';

import './styles.scss';

import {
  EventExplorerDashboardFilters,
  EventsExplorerDashboard
} from './Dashboard/EventsExplorerDashboard';

import {
  DateFilter,
  getRanges,
  GroupedEnumFilter,
  OrgsFilter,
  AircraftFilter
} from '../common/filters';

import { eventsExplorerApi500 } from '../../helpers/globalNotifications';
import {
  enumToTitleCase,
  enumToTitleCaseWithAcronyms,
  eventTypeAcronyms
} from '../FlightExplorer/utilities/stringUtilities';

import {
  getDates,
  setDates,
  getEventSubtypes,
  setEventSubTypes,
  getOrganisations,
  setOrganisations,
  getAircraft,
  setAircraft,
  getEventSeverities,
  setEventSeverities,
  getEventsLoadingInfo,
  setEventsLoadingCancel
} from '../../redux/slice/eventsExplorer';
import { EventSeverity, EventSubtype } from '../../redux/types';

export function splitEnumToGroups<T>(
  enumObject: {
    [key: string]: T;
  },
  acronyms: RegExp[] = []
): { [key: string]: { title: string; value: T }[] } {
  return Object.keys(enumObject).reduce<{ [key: string]: { title: string; value: T }[] }>(
    (acc, key) => {
      const [group, ...values] = key.split('__');
      if (!group) {
        return acc;
      }
      const value = values.join('__');
      const groupName = enumToTitleCase(group);

      return {
        ...acc,
        [groupName]: [
          ...(acc[groupName] || []),
          {
            value,
            title: enumToTitleCaseWithAcronyms(value, acronyms)
          }
        ]
      } as { [key: string]: { title: string; value: T }[] };
    },
    {}
  );
}

const EventsExplorer = () => {
  const dispatch = useDispatch();

  const dates = useSelector(getDates);
  const setDateFilter = useCallback(
    (dates: [Moment, Moment]) => {
      dispatch(setDates({ startTime: dates[0], endTime: dates[1] }));
    },
    [dispatch, setDates]
  );

  const organisations = useSelector(getOrganisations);
  const applyOrganisations = useCallback(
    (orgIds: string[]) => {
      dispatch(setOrganisations(orgIds));
    },
    [setOrganisations]
  );

  const aircraft = useSelector(getAircraft);
  const applyAircraft = useCallback(
    (aircraft: string[]) => {
      dispatch(setAircraft(aircraft));
    },
    [dispatch, setAircraft]
  );

  const eventSubtypes = useSelector(getEventSubtypes);
  const selectEventSubtypes = useCallback(
    selected => {
      dispatch(setEventSubTypes(selected));
    },
    [dispatch, setEventSubTypes]
  );
  const eventSubtypesGroups = splitEnumToGroups(EventSubtype, eventTypeAcronyms);

  const eventSeverities = useSelector(getEventSeverities);
  const applyEventSeverities = useCallback(
    selected => {
      dispatch(setEventSeverities(selected));
    },
    [dispatch, setEventSeverities]
  );

  const severitiesGroups = splitEnumToGroups(EventSeverity);

  const { loading, total, loaded: eventsLoaded, flights, error: eventsApiError } = useSelector(
    getEventsLoadingInfo
  );

  if (eventsApiError) {
    eventsExplorerApi500();
  }

  const dataLoaded = total !== undefined;
  const progress = (eventsLoaded * 100) / total!;
  const percent = progress | 0; // 0dp

  const [filters, setFilters] = useState<EventExplorerDashboardFilters>(() => ({
    startTimestamp: dates.startTime?.valueOf(),
    endTimestamp: dates.endTime?.valueOf(),
    organisationIds: organisations,
    aircraft,
    eventSubtypes,
    eventSeverities
  }));

  const apply = () => {
    cancelling.current = false;
    setFilters({
      startTimestamp: dates.startTime?.valueOf(),
      endTimestamp: dates.endTime?.valueOf(),
      organisationIds: organisations,
      aircraft,
      eventSubtypes,
      eventSeverities
    });
  };

  const cancelling = useRef(false);
  const cancel = () => {
    cancelling.current = true;
    dispatch(setEventsLoadingCancel(true));
  };

  const filtersInvalid = !(organisations.length && aircraft.length);

  const shouldCancel = useCallback(() => cancelling.current, []);

  return (
    <div className="eventsExplorerPage">
      <PageHeader
        className="site-page-header"
        style={{ paddingLeft: '0px', height: '4rem' }}
        title="Events Explorer"
        subTitle="Locate and investigate flight events."
      />
      <div data-testid="controls-panel" className="filterPanelContainer">
        <DateFilter
          className="date-filter"
          ranges={getRanges()}
          calendarFieldsValues={[dates.startTime ?? undefined, dates.endTime ?? undefined]}
          timezone={'Pacific/Auckland'}
          dateFormat={'YYYY-MM-DD'}
          handleChange={setDateFilter}
          disabled={loading}
        />
        <OrgsFilter
          selectedOrgs={organisations}
          setSelectedOrgs={applyOrganisations}
          disabled={loading}
        />
        <AircraftFilter
          selectedAircraft={aircraft}
          setSelectedAircraft={applyAircraft}
          orgsToFilterBy={organisations}
          disabled={loading}
        />
        <GroupedEnumFilter<EventSubtype>
          title="All Event Types"
          placeholder={['Event Types', 'Event Type']}
          groupMap={eventSubtypesGroups}
          value={eventSubtypes}
          setValue={selectEventSubtypes}
          disabled={loading}
        />
        <GroupedEnumFilter<EventSeverity>
          title="All Severities"
          placeholder={['Severities', 'Severity']}
          groupMap={severitiesGroups}
          value={eventSeverities}
          setValue={applyEventSeverities}
          disabled={loading}
          expandAll
        />
        <div className="button-content">
          <Spin spinning={loading} />
          <Button
            data-testid="apply-button"
            disabled={filtersInvalid}
            onClick={loading ? cancel : apply}
            type={loading ? 'default' : 'primary'}
            title={
              organisations.length
                ? aircraft.length
                  ? 'Apply filters'
                  : 'Please select at least one Aircraft'
                : 'Please select at least one Organisation'
            }
          >
            {loading ? 'Cancel' : 'Apply'}
          </Button>
        </div>
        <div className="info">
          {dataLoaded && (
            <>
              <span>Events:&nbsp;{total}</span>
              {eventsLoaded < total! ? (
                <Progress percent={percent} />
              ) : (
                <>
                  <br />
                  <span>Flights:&nbsp;{flights}</span>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div data-testid="charts-panel" className="chartsPanelContainer">
        <div style={{ overflow: 'show' }}>
          <EventsExplorerDashboard filters={filters} cancel={shouldCancel} />
        </div>
      </div>
    </div>
  );
};

export default EventsExplorer;
