import { formatCoordinates } from '../../helpers/formatCoordinates';
import { convertMetresTo, convertKnotsTo } from '../../components/Flying/Map/utils/helper';
import { EventClass, GeofenceEventType } from '../../constants';
import { decodeGeoHash } from '../../util/geoHash';

export const getGeofenceEventDisplayName = event => {
  switch (event.eventType) {
    case GeofenceEventType.BreachIn:
      return 'Geofence Entry';
    case GeofenceEventType.BreachOut:
      return 'Geofence Exit';
    default:
      return 'Geofence';
  }
};

export const getGeofenceEventProperties = (event, latLongFormat, altitudeUnit, speedUnit) => {
  const { latitude, longitude } = decodeGeoHash(event.geohash);
  const { heading, altitude, speed } = event.eventDetails.point.properties;
  const { heightAgl } = event.eventDetails;

  return {
    latitude,
    longitude,
    coordinates: formatCoordinates({ latitude, longitude }, latLongFormat),
    heading,
    altitude: convertMetresTo(altitude, altitudeUnit, 0)[0],
    heightAgl: heightAgl != undefined ? convertMetresTo(heightAgl, altitudeUnit, 0)[0] : undefined,
    altitudeUnit,
    speed: convertKnotsTo(speed, speedUnit),
    speedUnit,
    type: EventClass.GEOFENCE
  };
};
