import React, { useState } from 'react';
import { Popover, Tooltip } from 'antd';
import './styles/scss/pointGroupingOptions.module.scss';
import Icon from '../../MapControls/Icon';
import ToggleTracksIcon from '../../MapControls/icons/ToggleTracksIcon';
import { GOOGLE_MAP_CONTAINER } from '../../GoogleMap/constants';
import { MenuIcon } from '../../MapControls/MenuIcon';
import PointGroupingOptionsContainer from './PointGroupingOptionsContainer';

const PointGroupingOptionsButton = () => {
  const [visible, setVisable] = useState(false);

  const hide = () => {
    setVisable(false);
  };

  const handleVisibleChange = visible => {
    setVisable(visible);
  };

  return (
    <div className="map-control">
      <Tooltip
        placement="left"
        title="Track Display Options"
        getPopupContainer={() => document.getElementById(GOOGLE_MAP_CONTAINER)}
      >
        <Popover
          placement="leftTop"
          getPopupContainer={() => document.getElementById(GOOGLE_MAP_CONTAINER)}
          content={<PointGroupingOptionsContainer clickCallback={hide} />}
          title=""
          role="button"
          trigger="click"
          visible={visible}
          onVisibleChange={handleVisibleChange}
        >
          <MenuIcon>
            <Icon
              height={18}
              width={18}
              className="rotate-cw-40"
              type={ToggleTracksIcon}
              color="grey"
            />
          </MenuIcon>
        </Popover>
      </Tooltip>
    </div>
  );
};

export default PointGroupingOptionsButton;
