import React from 'react';
import PropTypes from 'prop-types';

function CenterMapIcon(props) {
  const { color } = props;
  return (
    <path
      style={{
        fill: color
      }}
      d="M91.67,8.33v16.93c-35.03,3.78-62.63,31.38-66.41,66.41H8.33v16.67h16.93c3.78,35.03,31.38,62.63,66.41,66.41v16.93h16.67 v-16.93c35.03-3.78,62.63-31.38,66.41-66.41h16.93V91.67h-16.93c-3.78-35.03-31.38-62.63-66.41-66.41V8.33H91.67z M100,41.67 c32.49,0,58.33,25.85,58.33,58.33s-25.85,58.33-58.33,58.33S41.67,132.49,41.67,100S67.51,41.67,100,41.67z M100,66.67 c-18.42,0-33.33,14.91-33.33,33.33s14.91,33.33,33.33,33.33s33.33-14.91,33.33-33.33S118.42,66.67,100,66.67z"
    />
  );
}

CenterMapIcon.defaultProps = {
  color: 'grey'
};

CenterMapIcon.propTypes = {
  color: PropTypes.string
};

export default CenterMapIcon;
