import React from 'react';
import PropTypes from 'prop-types';
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';
import { Modal } from 'antd';
const { confirm } = Modal;

const showConfirmSpiderRegistrationModal = validateNewSerial =>
  confirm({
    title: 'Register new Spider and activate subscription?',
    icon: <QuestionCircleOutlined />,
    content: (
      <>
        <p>Your Spider will be assigned to the Tango Monthly plan by default.</p>
        <p>
          If you opted for a different plan or an optional module during the purchasing process, our
          Customer Experience team will make this adjustment automatically within 24 hours.
        </p>
        <p>
          If you have existing Spiders, our Customer Experience team will automatically update your
          new plan to match the rest of your fleet.
        </p>
        <p>
          For more information on available plans and pricing refer to our{' '}
          <a href="https://www.spidertracks.com/" target="_blank" rel="noopener noreferrer">
            website
          </a>
          . Please contact
          <b> support@spidertracks.com</b> to discuss and implement changes.
        </p>
        <p>
          To complete registration, you must accept Spidertracks{' '}
          <a
            href="https://spidertrackshelp.zendesk.com/hc/en-nz/articles/8635138372623"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms and Conditions.
          </a>
        </p>
      </>
    ),
    zIndex: 10000,
    onOk: () => {
      validateNewSerial();
    },
    okText: 'Accept'
  });

showConfirmSpiderRegistrationModal.propTypes = {
  validateNewSerial: PropTypes.func.isRequired
};
export default showConfirmSpiderRegistrationModal;
