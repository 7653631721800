import React from 'react';
import PropTypes from 'prop-types';

const ToggleTracksIcon = props => {
  const { color } = props;
  return (
    <path
      style={{
        fill: color
      }}
      d="M136.49,50.9C120.74,26.31,104.76,6.03,104.6,5.82L100,0l-4.6,5.82c-0.16,0.2-16.14,20.49-31.89,45.08 c-21.55,33.65-32.48,59.13-32.48,75.73C31.03,167.09,61.97,200,100,200s68.97-32.91,68.97-73.37 C168.97,110.03,158.04,84.55,136.49,50.9z"
    />
  );
};

ToggleTracksIcon.defaultProps = {
  color: 'grey'
};

ToggleTracksIcon.propTypes = {
  color: PropTypes.string
};

export default ToggleTracksIcon;
