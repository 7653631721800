import React from 'react';

export const Available = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.3831 8.27344H15.2839C15.0449 8.27344 14.8175 8.38828 14.6769 8.58516L10.9925 13.6945L9.32374 11.3789C9.18311 11.1844 8.95811 11.0672 8.71671 11.0672H7.61748C7.46514 11.0672 7.37608 11.2406 7.46514 11.3648L10.3855 15.4148C10.4545 15.5111 10.5454 15.5896 10.6508 15.6437C10.7561 15.6978 10.8729 15.7261 10.9913 15.7261C11.1098 15.7261 11.2265 15.6978 11.3319 15.6437C11.4373 15.5896 11.5282 15.5111 11.5972 15.4148L16.5331 8.57109C16.6245 8.44688 16.5355 8.27344 16.3831 8.27344Z"
      fill="#52C41A"
    />
    <path
      d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z"
      fill="#E8E8E8"
    />
  </svg>
);

export const NotAvailable = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z"
      fill="#E8E8E8"
    />
    <path
      d="M10.875 16.125C10.875 16.4234 10.9935 16.7095 11.2045 16.9205C11.4155 17.1315 11.7016 17.25 12 17.25C12.2984 17.25 12.5845 17.1315 12.7955 16.9205C13.0065 16.7095 13.125 16.4234 13.125 16.125C13.125 15.8266 13.0065 15.5405 12.7955 15.3295C12.5845 15.1185 12.2984 15 12 15C11.7016 15 11.4155 15.1185 11.2045 15.3295C10.9935 15.5405 10.875 15.8266 10.875 16.125ZM11.4375 13.5H12.5625C12.6656 13.5 12.75 13.4156 12.75 13.3125V6.9375C12.75 6.83437 12.6656 6.75 12.5625 6.75H11.4375C11.3344 6.75 11.25 6.83437 11.25 6.9375V13.3125C11.25 13.4156 11.3344 13.5 11.4375 13.5Z"
      fill="#FA8C16"
    />
  </svg>
);

export const AwaitingOffload = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.3906 7.45312V4.3125H19.4062C19.5094 4.3125 19.5938 4.22813 19.5938 4.125V2.8125C19.5938 2.70937 19.5094 2.625 19.4062 2.625H4.59375C4.49062 2.625 4.40625 2.70937 4.40625 2.8125V4.125C4.40625 4.22813 4.49062 4.3125 4.59375 4.3125H6.60938V7.45312C6.60938 9.36328 7.60312 11.0438 9.10312 12C7.60312 12.9562 6.60938 14.6367 6.60938 16.5469V19.6875H4.59375C4.49062 19.6875 4.40625 19.7719 4.40625 19.875V21.1875C4.40625 21.2906 4.49062 21.375 4.59375 21.375H19.4062C19.5094 21.375 19.5938 21.2906 19.5938 21.1875V19.875C19.5938 19.7719 19.5094 19.6875 19.4062 19.6875H17.3906V16.5469C17.3906 14.6367 16.3969 12.9562 14.8969 12C16.3969 11.0438 17.3906 9.36328 17.3906 7.45312ZM15.7031 16.5469V19.6875H8.29688V16.5469C8.29688 15.5578 8.68125 14.6273 9.38203 13.9289C10.0805 13.2281 11.0109 12.8438 12 12.8438C12.9891 12.8438 13.9195 13.2281 14.618 13.9289C15.3188 14.6273 15.7031 15.5578 15.7031 16.5469ZM15.7031 7.45312C15.7031 8.44219 15.3188 9.37266 14.618 10.0711C13.9195 10.7719 12.9891 11.1562 12 11.1562C11.0109 11.1562 10.0805 10.7719 9.38203 10.0711C9.0367 9.7284 8.76293 9.32048 8.57664 8.87105C8.39035 8.42161 8.29525 7.93963 8.29688 7.45312V4.3125H15.7031V7.45312Z"
      fill="#E8E8E8"
    />
  </svg>
);

export const NotSupported = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 9.91406 4.01484 7.99688 5.23828 6.49688L17.5031 18.7617C16.0031 19.9852 14.0859 20.7188 12 20.7188ZM18.7617 17.5031L6.49688 5.23828C7.99688 4.01484 9.91406 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 14.0859 19.9852 16.0031 18.7617 17.5031Z"
      fill="#E8E8E8"
    />
  </svg>
);

export const Incomplete = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.1476 10.7586C12.13 10.7362 12.1076 10.7181 12.0821 10.7056C12.0565 10.6931 12.0284 10.6866 11.9999 10.6866C11.9715 10.6866 11.9434 10.6931 11.9178 10.7056C11.8922 10.7181 11.8698 10.7362 11.8523 10.7586L9.22728 14.0797C9.20564 14.1073 9.19221 14.1405 9.18853 14.1754C9.18485 14.2103 9.19107 14.2455 9.20647 14.2771C9.22187 14.3086 9.24584 14.3352 9.27563 14.3537C9.30542 14.3723 9.33983 14.3821 9.37493 14.382H11.107V20.0633C11.107 20.1664 11.1913 20.2508 11.2945 20.2508H12.7007C12.8038 20.2508 12.8882 20.1664 12.8882 20.0633V14.3844H14.6249C14.782 14.3844 14.8687 14.2039 14.7726 14.082L12.1476 10.7586Z"
      fill="#FA8C16"
    />
    <path
      d="M19.0172 8.59453C17.9438 5.76328 15.2086 3.75 12.0047 3.75C8.80078 3.75 6.06563 5.76094 4.99219 8.59219C2.98359 9.11953 1.5 10.95 1.5 13.125C1.5 15.7148 3.59766 17.8125 6.18516 17.8125H7.125C7.22813 17.8125 7.3125 17.7281 7.3125 17.625V16.2188C7.3125 16.1156 7.22813 16.0312 7.125 16.0312H6.18516C5.39531 16.0312 4.65234 15.7172 4.09922 15.1477C3.54844 14.5805 3.25547 13.8164 3.28125 13.0242C3.30234 12.4055 3.51328 11.8242 3.89531 11.3344C4.28672 10.8352 4.83516 10.4719 5.44453 10.3102L6.33281 10.0781L6.65859 9.22031C6.86016 8.68594 7.14141 8.18672 7.49531 7.73438C7.8447 7.28603 8.25857 6.89191 8.72344 6.56484C9.68672 5.8875 10.8211 5.52891 12.0047 5.52891C13.1883 5.52891 14.3227 5.8875 15.2859 6.56484C15.7523 6.89297 16.1648 7.28672 16.5141 7.73438C16.868 8.18672 17.1492 8.68828 17.3508 9.22031L17.6742 10.0758L18.5602 10.3102C19.8305 10.6523 20.7188 11.8078 20.7188 13.125C20.7188 13.9008 20.4164 14.632 19.868 15.1805C19.599 15.451 19.2791 15.6655 18.9266 15.8115C18.5742 15.9576 18.1963 16.0323 17.8148 16.0312H16.875C16.7719 16.0312 16.6875 16.1156 16.6875 16.2188V17.625C16.6875 17.7281 16.7719 17.8125 16.875 17.8125H17.8148C20.4023 17.8125 22.5 15.7148 22.5 13.125C22.5 10.9523 21.0211 9.12422 19.0172 8.59453Z"
      fill="#E8E8E8"
    />
  </svg>
);

export const Archived = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.3831 8.27344H15.2839C15.0449 8.27344 14.8175 8.38828 14.6769 8.58516L10.9925 13.6945L9.32374 11.3789C9.18311 11.1844 8.95811 11.0672 8.71671 11.0672H7.61748C7.46514 11.0672 7.37608 11.2406 7.46514 11.3648L10.3855 15.4148C10.4545 15.5111 10.5454 15.5896 10.6508 15.6437C10.7561 15.6978 10.8729 15.7261 10.9913 15.7261C11.1098 15.7261 11.2265 15.6978 11.3319 15.6437C11.4373 15.5896 11.5282 15.5111 11.5972 15.4148L16.5331 8.57109C16.6245 8.44688 16.5355 8.27344 16.3831 8.27344Z"
      fill="#595959"
    />
    <path
      d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z"
      fill="#E8E8E8"
    />
  </svg>
);
