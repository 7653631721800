import { ServiceAbstract } from '../../ServiceAbstract';
import { Amplify } from '@spidertracks/common';

interface MetaData {
  dim: string;
}
interface Jaql {
  datasource: string;
  metadata: MetaData[];
}

export class SisenseService extends ServiceAbstract {
  public async getSisenseToken() {
    return Amplify.API.get('authenticated', 'sso-login');
  }

  public async querySisenseElasticube(requestPayload: Jaql) {
    try {
      const response = await fetch(`${window.env.sisense.endpoint}/api/datasources/x/jaql`, {
        body: JSON.stringify(requestPayload),
        method: 'POST',
        mode: 'cors',
        credentials: 'include'
      });

      const { values } = await response.json();
      if (!values) {
        return [];
      } else {
        return values;
      }
    } catch (e) {
      console.log(e);
    }
  }
}
