import React, { useCallback, useEffect, useState, CSSProperties } from 'react';
import { Select } from 'antd';

import { getInstance } from '../../../common/api/spidertracks-sdk';
import { Organisation } from '../../../common/api/spidertracks-sdk/types/Organisation';

const { Option } = Select;

const dropDownTitle: CSSProperties = {
  paddingLeft: '1vw',
  fontWeight: 'bold',
  fontSize: 'large'
};

const byOrgName = (a: Organisation, b: Organisation) => (a.org.orgName > b.org.orgName ? 1 : -1);

const MemberOptions = ({ memberOrgs }: { memberOrgs: Organisation[] }) =>
  memberOrgs.map((org: Organisation) => {
    const { id, orgName } = org.org;
    return (
      <Option key={id} value={id}>
        {orgName}
      </Option>
    );
  });

interface OrgDropdownProps {
  organisationId?: string;
  setOrganisationId: (orgId: string) => void;
  disabled: boolean;
  allowNothingSelected: boolean;
}

const OrgDropdown = ({
  organisationId,
  setOrganisationId,
  disabled,
  allowNothingSelected
}: OrgDropdownProps) => {
  const [memberOrgs, setMemberOrgData] = useState<Organisation[]>([]);

  const fetchOrgData = useCallback(async () => {
    const SpidertracksSDK = getInstance();
    const userData = await SpidertracksSDK.getUsers();
    const memberOrgs = [...userData.orgs];

    memberOrgs.sort(byOrgName);

    setMemberOrgData(memberOrgs);
    return memberOrgs;
  }, [setMemberOrgData]);

  useEffect(() => {
    fetchOrgData()
      .then(orgs => {
        if (!allowNothingSelected && !organisationId && orgs.length) {
          setOrganisationId(orgs[0].org.id);
        }
      })
      .catch(console.error);
  }, []);

  return (
    <div style={{ margin: '5px 5px 5px 21px', minWidth: 'fit-content' }}>
      <Select
        value={organisationId ?? 'Select an Organisation'}
        style={{ width: '100%' }}
        dropdownStyle={dropDownTitle}
        defaultOpen={false}
        onChange={setOrganisationId}
        disabled={disabled}
      >
        {MemberOptions({ memberOrgs })}
      </Select>
    </div>
  );
};

export default OrgDropdown;
