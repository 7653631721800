import erf from 'math-erf';

let { SQRT2, abs, log, max, min, exp } = Math;

// Inverted half normal cumulative distrubtion (== Folded with mean 0), models VSpeed/Alt
// https://en.wikipedia.org/wiki/Half-normal_distribution
export function buildHalfNormProb(std: number) {
  const div = SQRT2 * std;
  return (x: number) => 1 - erf(abs(x) / div);
}
export function halfNormalProb(x: number, std: number) {
  const div = SQRT2 * std;
  return 1 - erf(abs(x) / div);
}

// Inverted folded normal cumulative distribution, currently unused (essentially half normal with non-zero mean)
// https://en.wikipedia.org/wiki/Folded_normal_distribution
export function buildFoldedNormProb(mean: number, std: number) {
  const erfDiv = SQRT2 * std;
  return (x: number) => {
    const X = abs(x);
    return 1 - 0.5 * (erf((X + mean) / erfDiv) + erf((X - mean) / erfDiv));
  };
}

// Inverted rayleigh cumulative to get prob above rather than prob below x, models HSpeed
// https://en.wikipedia.org/wiki/Rayleigh_distributionf
export function buildRayleighProb(std: number) {
  const div = 2 * std ** 2;
  return (x: number) => Math.E ** (-(x ** 2) / div);
}
export function rayleighProb(x: number, std: number) {
  const X = Math.abs(x);
  const s2 = std ** 2;
  const p = Math.E ** (-(X ** 2) / (2 * s2));
  return p;
}
// Variable std dev that follows a log trend
// https://math.stackexchange.com/questions/716152/graphing-given-two-points-on-a-graph-find-the-logarithmic-function-that-passes
export function buildLogStdDev(xMin: number, xMax: number, stdMin: number, stdMax: number) {
  const a = (stdMax - stdMin) / log(xMax / xMin);
  const b = exp((stdMax * log(xMax) - stdMin * log(xMin)) / (stdMax - stdMin));

  return (x: number) => a * log(min(max(x, xMin), xMax) * b);
}
