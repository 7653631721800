import React from 'react';
import AircraftTrackPoint from './AircraftTrackPoint';
import PropTypes from 'prop-types';
import { getLatLng } from '../../../../../../redux/selectors/aircraftData';
import { getLatestPointMarker } from '../../../utils/drawing/marker';

const LatestTrackPoint = ({
  point,
  onMarkerClick,
  aircraftProperties,
  hideMapRadiusShading,
  zIndex
}) => (
  <AircraftTrackPoint
    onClick={onMarkerClick(point)}
    position={getLatLng(point)}
    icon={getLatestPointMarker(point, aircraftProperties)}
    radius={point.radius}
    aircraftRegistration={aircraftProperties.aircraftRegistration}
    trackStatus={aircraftProperties.trackStatus}
    pointStatus={point.trackStatus}
    hideMapRadiusShading={hideMapRadiusShading}
    zIndex={zIndex}
  />
);

LatestTrackPoint.propTypes = {
  point: PropTypes.object,
  onMarkerClick: PropTypes.func,
  aircraftProperties: PropTypes.object,
  hideMapRadiusShading: PropTypes.bool,
  zIndex: PropTypes.number
};

export default LatestTrackPoint;
