export const MAP_CONTAINER = 'mapContainer';
export const GOOGLE_MAP_CONTAINER = 'googleMapContainer';

export const FIFTEEN_MINUTES = 900000; // in milliseconds

export const POINT_INFO_PANEL = {
  header: 'Aircraft',
  time: 'Time',
  position: 'Position',
  altitude: 'Altitude',
  heightAgl: 'Height AGL',
  speed: 'Speed',
  direction: 'Direction',
  degree: 'T',
  utc: 'UTC',
  description: 'Description',
  fsiEventName: 'Event',
  fsiEventThreshold: 'Threshold',
  fsiEventValue: 'Value',
  alert: 'Alert'
};

export const SVG_MARKER_ICON_SIZE = {
  width: 200,
  height: 200
};

export const BARON = {
  BASE_URL: 'https://api.velocityweather.com/v1',
  KEY: 'Rf55lhYIwpcS',
  SECRET: 'E2hXoOBDVCuTFjXmdUV1fdDQ89eYF0n2Ly2jhniJ8k'
};

export const MAP_ZOOM = {
  SELECTED_MAX_MAP_ZOOM: 14
};

export const ACTIVE_ICON_CIRCLE_OPACITY = 0.2;

/** The names of the last 2 don't match the strings, because the strings are how it's represented in the user
 * state settings table in the DB, named that way for backwards compatibility.
 * The enum names better reflect the behaviour.
 */
export enum PointGroupingOption {
  LAST_POINT = 'LAST_POINT',
  TRACK_ONLY = 'TRACK_WITHOUT_EVENTS',
  TRACK_WITH_EVENTS = 'FULL_TRACK'
}
export const LAST_POINT = PointGroupingOption.LAST_POINT;
export const TRACK_ONLY = PointGroupingOption.TRACK_ONLY;
export const TRACK_WITH_EVENTS = PointGroupingOption.TRACK_WITH_EVENTS;

export const NORMAL_BUTTON_MODES = [
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  69,
  70,
  75,
  100,
  101,
  102,
  120,
  130,
  131,
  132,
  135,
  136,
  140,
  141,
  145,
  146,
  150,
  151
];

export const MIN_MAP_ZOOM_LEVEL_TO_SHOW_TRACK_LINE_MARKERS = 15;
