import { DisplayPoint } from '../../../../common/api/spidertracks-sdk/types/TrackData';
import {
  DateFormatterFn,
  formatStandardDateStrings,
  fromTimestamp
} from '../../../../helpers/formatTime';
import { getIconSvg, getIconUrlFromButtonMode } from '../utils/drawing/marker';

export const formatDisplayPoint = (
  points: DisplayPoint[],
  formatTimefn: DateFormatterFn,
  timezone: string
) => {
  return points
    .filter(p => p.description && getIconSvg(p.buttonMode).svg)
    .map(p => ({
      name: p.description,
      iconUrl: getIconUrlFromButtonMode(p.buttonMode, p.description),
      time: formatStandardDateStrings(formatTimefn, fromTimestamp(p.timestamp), timezone)[0]
    }));
};
