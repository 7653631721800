import {
  clearSelectedMarkerPoint,
  selectedMarkerPoint as setSelectedMarkerPoint
} from '../mapReducer/actions/map';
import { getAircraftList, getSelectedTrack } from '../../selectors/aircraftData';
import { setSelectedTracks, setSelectedTrackId, clearSelectedTracks } from '../../slice/tracks';

export const selectAircraftFromList = id => (dispatch, getState) => {
  const aircraftList = getAircraftList(getState());
  const selectedAircraft = aircraftList.filter(aircraft => aircraft.id === id)[0];
  const { mostRecentTrack } = selectedAircraft;
  const { aircraftIcon, aircraftIconColour } = selectedAircraft;
  const lastPoint = mostRecentTrack.points.slice(-1)[0];

  window.performance.mark('selectAircraftFromList_start');
  //TODO:change these dispatches, need a smarter way to handle selected track

  dispatch(setSelectedTracks([mostRecentTrack.trackId]));
  dispatch(setSelectedTrackId(mostRecentTrack.trackId));
  dispatch(clearSelectedMarkerPoint());
  dispatch(setSelectedMarkerPoint({ ...lastPoint, aircraftIcon, aircraftIconColour }));

  window.performance.mark('selectAircraftFromList_end');
  window.performance.measure(
    'selectAircraftFromList',
    'selectAircraftFromList_start',
    'selectAircraftFromList_end'
  );
};

export const selectLatestPointInSelectedTrack = () => (dispatch, getState) => {
  const selectedTrack = getSelectedTrack(getState());
  if (selectedTrack) {
    const lastPoint = selectedTrack.points.slice(-1)[0];

    dispatch(setSelectedMarkerPoint(lastPoint));
  }
};

export const clearSelectedFlight = () => dispatch => {
  dispatch(clearSelectedTracks());
  dispatch(clearSelectedMarkerPoint());
};

export const setSelectedFlight = track => dispatch => {
  dispatch(clearSelectedMarkerPoint());
  if (track.points && track.points.length) {
    const lastPoint = track.points[track.points.length - 1];
    dispatch(setSelectedMarkerPoint({ ...lastPoint }));
  }
};
