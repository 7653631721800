import React, { useState } from 'react';
import FullScreen from './FullScreen';
import { GOOGLE_MAP_CONTAINER } from '../../GoogleMap/constants';
import { gaEventHelper } from '@spidertracks/common';

const gaEventSender = gaEventHelper('MapControls - FullScreen');

const FullScreenContainer = () => {
  const [active, setActive] = useState(false);

  const fullscreen = () => {
    const elem = document.getElementById(GOOGLE_MAP_CONTAINER);
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  };

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  const sendGAEvent = fullScreen => {
    let eventAction = fullScreen ? 'On' : 'Off';
    let eventLabel = 'Full screen ' + eventAction;
    gaEventSender(eventAction, eventLabel);
  };

  const toggle = () => {
    setActive(!active);
    if (!active) {
      fullscreen();
    } else {
      exitFullScreen();
    }
    sendGAEvent(!active);
    return active;
  };

  return (
    <div className="map-control">
      <FullScreen toggleFullScreen={toggle} fullScreenWrapper={GOOGLE_MAP_CONTAINER} />
    </div>
  );
};

export default FullScreenContainer;
