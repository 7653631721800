import React, { useCallback, useRef, useState, useMemo } from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import AircraftCell from './AircraftCell';
import { getRowClassName } from '../../common';
import { EventsConfigTableHeader } from './EventsConfigTableHeader';
import { getFilterDropdown } from '../../common/filters/getFilterDropdown';
import { eventRuleDeleteError, eventRuleDeleteSuccess } from '../../../helpers';
import EventsConfigDrawer from './EventsConfigDrawer';
import { getInstance } from '../../../common/api/spidertracks-sdk';
import { sortDescriptionsByAZ, sortRuleNamesByAZ } from './utils';
import { useParams } from 'react-router-dom';
import { eventRuleUpdateSuccess, eventRuleUpdateError } from '../../../helpers';
import { TableFullRowSelect } from '@spidertracks/components';
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';

export const aircraftOnFilter = (value, record) =>
  record.allAircraft || (record.aircraftIds && record.aircraftIds.includes(value));

export const EventsConfigTable = ({
  aircraft,
  eventRules,
  isLoading,
  tableData,
  eventTypes,
  refresh,
  insightsSafetyEnabled
}) => {
  const ref = useRef(() => {});
  const { organisationId } = useParams();
  const sortedTableData = useMemo(
    () =>
      tableData.sort((a, b) => {
        if (a.eventTypeDisplayName > b.eventTypeDisplayName) {
          return 1;
        } else if (a.eventTypeDisplayName < b.eventTypeDisplayName) {
          return -1;
        }
        return 0;
      }),
    [tableData]
  );

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedEventRule, setSelectedEventRule] = useState();
  const [visibility, setVisibility] = useState(false);
  const [selectedAircraft, setAircraftFilters] = useState([]);

  const onOpen = () => setVisibility(true);

  const onClose = () => {
    setVisibility(false);
    setSelectedEventRule([]);
  };

  const aircraftFilters = aircraft.map(a => ({
    key: a.id,
    value: a.id,
    label: a.registration
  }));

  const allAircraftRegistrations = useMemo(
    () => aircraft.reduce((acc, a) => ({ ...acc, [a.id]: a.registration }), {}),
    [aircraft]
  );

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'ruleName',
      key: `rule_name`,
      sorter: sortRuleNamesByAZ
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      key: 'description',
      sorter: sortDescriptionsByAZ,
      render: (text, record) => {
        if (record.severityDisplayThresholds) {
          return <div dangerouslySetInnerHTML={{ __html: text }}></div>;
        }
        return text;
      }
    },
    {
      title: 'AIRCRAFT',
      dataIndex: 'aircraftIds',
      key: `aircraftIds${aircraftFilters.length}`,
      filters: aircraftFilters,
      onFilter: aircraftOnFilter,
      ...getFilterDropdown({
        displayName: 'aircraft',
        onFilter: setAircraftFilters,
        clearSelection: () => setNameFilters([]),
        showSearch: true
      })(),
      filteredValue: selectedAircraft,
      render: function AircraftTableCell(aircraftIds = [], row) {
        return (
          <AircraftCell
            aircraftRegistrations={aircraftIds.map(id => allAircraftRegistrations[id]).sort()}
            row={row}
          />
        );
      }
    }
  ];

  const handleTableChange = (pagination, { eventType }) => {
    ref.current();
  };

  const deleteEventRule = useCallback(async () => {
    try {
      await Promise.all(
        selectedRowKeys.map(id =>
          getInstance()
            .getEventRulesService()
            .deleteEventRule(organisationId, id)
        )
      );

      eventRuleDeleteSuccess();
    } catch (e) {
      console.log(e);
      eventRuleDeleteError();
    } finally {
      setSelectedRowKeys([]);
      setSelectedEventRule([]);
      refresh();
    }
  }, [refresh, organisationId, selectedRowKeys]);

  const keys = new Set(selectedRowKeys);
  const selectedEventRules = sortedTableData.filter(row => keys.has(row.id));

  const rowConfig = row => ({
    onClick: e => {
      e.preventDefault();
      onOpen();
      setSelectedEventRule(row);
    }
  });

  const eventParameters = sortedTableData.reduce((acc, row) => {
    return [
      ...acc,
      {
        eventType: row.eventType,
        eventClass: row.eventClass,
        eventTypeDisplayName: row.eventTypeDisplayName,
        parameters: row.parameters
      }
    ];
  }, []);

  const onChange = selectedRowKeys => setSelectedRowKeys(selectedRowKeys);
  const onSelectAll = selected => !selected && setSelectedRowKeys([]);
  const rowClassName = record =>
    selectedEventRule && record.id === selectedEventRule.id
      ? `${getRowClassName()} sts-row-clicked`
      : getRowClassName();

  const getContent = selectedEventRules => {
    if (selectedEventRules.length === 1) {
      return `Are you sure you want to delete the event ${selectedEventRules[0].eventTypeDisplayName}?`;
    }
    return `Are you sure you want to delete these ${selectedEventRules.length} events?`;
  };

  const content = getContent(selectedEventRules);

  const onDeleteClick = useCallback(
    () =>
      Modal.confirm({
        title: 'Delete Event',
        icon: <ExclamationCircleFilled />,
        content,
        okText: 'Delete',
        cancelText: 'Cancel',
        onOk: deleteEventRule
      }),
    [content, deleteEventRule]
  );

  const updateEventRule = async eventRule => {
    try {
      await getInstance()
        .getEventRulesService()
        .updateEventRule(organisationId, selectedEventRule.id, eventRule);
      eventRuleUpdateSuccess();
    } catch (e) {
      console.log(e);
      eventRuleUpdateError();
    } finally {
      refresh();
    }
  };

  return (
    <React.Fragment>
      <EventsConfigTableHeader
        selectedEventRules={selectedEventRules}
        onDeleteClick={onDeleteClick}
      />
      <TableFullRowSelect
        className="event-rules-table"
        pagination={{ pageSize: 30 }}
        rowSelection={{
          selectedRowKeys,
          onChange,
          onSelectAll
        }}
        rowKey="id"
        size="middle"
        bordered
        dataSource={sortedTableData}
        columns={columns}
        loading={isLoading}
        rowClassName={rowClassName}
        onChange={handleTableChange}
        onRow={rowConfig}
      />
      {visibility && (
        <EventsConfigDrawer
          aircraft={aircraft}
          isEditForm={true}
          visibility={visibility}
          onClose={onClose}
          selectedEventRule={selectedEventRule}
          setSelectedEventRule={setSelectedEventRule}
          eventParameters={eventParameters}
          eventRules={eventRules}
          eventTypes={eventTypes}
          save={updateEventRule}
          organisationIds={[organisationId]}
          insightsSafetyEnabled={insightsSafetyEnabled}
        />
      )}
    </React.Fragment>
  );
};

EventsConfigTable.propTypes = {
  aircraft: PropTypes.array,
  eventRules: PropTypes.array,
  tableData: PropTypes.array,
  isLoading: PropTypes.bool,
  eventTypes: PropTypes.array,
  refresh: PropTypes.func,
  insightsSafetyEnabled: PropTypes.bool
};

export default EventsConfigTable;
