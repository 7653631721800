import React from 'react';
import PropTypes from 'prop-types';
import { GMAP_CONTROLLED_ICON } from '../../../../../../common/constants';

import { InfoBox } from '@react-google-maps/api';

const AircraftRegistrationLabel = ({ backgroundColor, borderColor, position, label, zIndex }) => {
  return (
    <InfoBox
      position={position}
      zIndex={zIndex}
      options={{
        pixelOffset: new window.google.maps.Size(
          GMAP_CONTROLLED_ICON.width / 2, // shift left by half of icon width
          -15 // shift up by half the infobox height
        ),

        boxStyle: {
          backgroundColor,
          opacity: 1,
          width: 'auto',
          padding: '5px',
          borderRadius: '.5em',
          minWidth: '45px',
          border: `1px solid ${borderColor}`,
          zIndex: -1
        },
        disableAutoPan: true,
        closeBoxURL: ''
      }}
    >
      <div className="info-window-label" style={{ fontSize: '12px' }}>
        {label}
      </div>
    </InfoBox>
  );
};

AircraftRegistrationLabel.propTypes = {
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  position: PropTypes.object,
  label: PropTypes.string,
  zIndex: PropTypes.number
};

export default function DefaultAircraftRegistrationLabel(props) {
  return (
    <AircraftRegistrationLabel
      backgroundColor="silver"
      borderColor="rgb(102, 102, 102)"
      {...props}
    />
  );
}

export const ActiveAircraftRegistrationLabel = props => (
  <AircraftRegistrationLabel
    backgroundColor="rgb(216, 231, 131)"
    borderColor="rgb(197, 219, 61)"
    {...props}
  />
);

export const SOSAircraftRegistrationLabel = props => (
  <AircraftRegistrationLabel backgroundColor="red" borderColor="pink" {...props} />
);
