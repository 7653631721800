import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useGoogleMap } from '@react-google-maps/api';
import { gaEventHelper } from '@spidertracks/common';
import CenterMap from './CenterMap';
import { GOOGLE_MAP_CONTAINER } from '../../GoogleMap/constants';
import fitMapToTrackBounds from '../../../../../helpers/fitMapToTrackBounds';
import {
  getSelectedTrack,
  getLatestTracksFromFilteredAircraftList
} from '../../../../../redux/selectors/aircraftData';

const gaEventSender = gaEventHelper('MapControls - Center Map');

const CenterMapWrapper = ({ selectedTracks }) => {
  const map = useGoogleMap();

  const centerMap = () => {
    if (map) {
      fitMapToTrackBounds(selectedTracks, map);
    }
    gaEventSender('CenterMap', 'Center Map');
  };
  return (
    <div className="map-control">
      <CenterMap centerMap={centerMap} fullScreenWrapper={GOOGLE_MAP_CONTAINER} />
    </div>
  );
};

CenterMapWrapper.propTypes = {
  selectedTracks: PropTypes.array
};

const mapStateToProps = state => {
  const selectedTrack = getSelectedTrack(state);

  return {
    selectedTracks: selectedTrack ? [selectedTrack] : getLatestTracksFromFilteredAircraftList(state)
  };
};

export default connect(mapStateToProps)(CenterMapWrapper);
