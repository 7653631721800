/* eslint-disable @typescript-eslint/camelcase */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col, Input, Spin, Divider, Icon, Select } from 'antd';
import { blue } from '@ant-design/colors';

const { Option } = Select;

export const SerialNumberInput = ({
  open,
  lockClose,
  onDropdownVisibleChange,
  disabled,
  validation,
  serialNumberInput,
  serialNumberError,
  handleFieldChange,
  getFieldDecorator,
  serialNumbers,
  validateSerialNumber,
  setOnSaveIsDisabled,
  setSelectedSerialNumber,
  setSelectedSerialNumberAssociatedAircraft,
  preselectedSerialNumber
}) => {
  return (
    <Form.Item
      name={'serialNumber'}
      label={'Serial Number'}
      style={{ marginBottom: '1px' }}
      validateStatus={serialNumberError ? 'error' : ''}
    >
      <Row>
        <Col
          className="d-flex"
          span={25}
          style={{
            textAlign: 'left'
          }}
        >
          {getFieldDecorator(`serialNumber`, {
            rules: [
              {
                type: 'string',
                required: true,
                pattern: new RegExp(validation.validationRegexPattern),
                message: validation.errorMessage
              }
            ]
          })(
            <Select
              maxTagTextLength={10}
              size="large"
              style={{ width: '300px' }}
              placeholder="e.g XYXYXYX121212"
              open={open}
              onDropdownVisibleChange={onDropdownVisibleChange}
              dropdownRender={menu => (
                <div>
                  {menu}
                  <Divider style={{ margin: '4px 0' }} />
                  <div
                    onMouseDown={lockClose}
                    onMouseUp={lockClose}
                    style={{ display: 'flex', flexWrap: 'nowrap', padding: '8px' }}
                  >
                    <Input
                      maxLength={10}
                      disabled={disabled}
                      style={{ flex: 'auto' }}
                      value={serialNumberInput}
                      onChange={handleFieldChange}
                    />
                    <span
                      style={{
                        flex: 'none',
                        padding: '8px',
                        display: 'block',
                        cursor: 'pointer',
                        color: blue.primary
                      }}
                      onClick={validateSerialNumber}
                    >
                      {disabled && <Spin size="small" />}
                      {!disabled && (
                        <Fragment>
                          <Icon type="plus" /> Add
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
              )}
            >
              {serialNumbers.map(value => (
                <Option
                  key={value.serialNumber}
                  value={value.serialNumber.toUpperCase()}
                  onClick={() => {
                    if (preselectedSerialNumber !== value.serialNumber) {
                      setSelectedSerialNumberAssociatedAircraft(value.associatedAircraft);
                      setSelectedSerialNumber(value.serialNumber);
                      setOnSaveIsDisabled(false);
                    } else {
                      setOnSaveIsDisabled(true);
                    }
                  }}
                >
                  {`${value.serialNumber.toUpperCase()} -  ${value.associatedAircraft}`}
                </Option>
              ))}
            </Select>
          )}
        </Col>
      </Row>
    </Form.Item>
  );
};

SerialNumberInput.propTypes = {
  open: PropTypes.bool,
  lockClose: PropTypes.func,
  onDropdownVisibleChange: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  validation: PropTypes.object,
  serialNumberError: PropTypes.bool,
  getFieldDecorator: PropTypes.func,
  validateSerialNumber: PropTypes.func.isRequired,
  serialNumberInput: PropTypes.string.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  serialNumbers: PropTypes.array,
  setOnSaveIsDisabled: PropTypes.func,
  setSelectedSerialNumber: PropTypes.func,
  setSelectedSerialNumberAssociatedAircraft: PropTypes.func,
  preselectedSerialNumber: PropTypes.string
};

export default SerialNumberInput;
