import { TimelineEvent } from '../utilities/pointClassifier';
import { SpeechBubbleSVG } from './speechBubbleSVG';
import { LollipopIcon } from './lollipopIcon';
import { getIconSvg } from '../../Flying/Map/utils/drawing/marker';

export class EventMarkerSVG {
  private speechBubble: SpeechBubbleSVG | undefined;
  private lollipop: LollipopIcon | undefined;
  public readonly boundingBoxHeight: number;
  public readonly boundingBoxWidth: number;
  public readonly stickOrigin: { x: number; y: number };
  private iconHeight: number;
  private speechBubbleHeight: number;

  public constructor(
    event: TimelineEvent,
    options: { showName: boolean; showDescription: boolean; stickColor: string }
  ) {
    const icon = getIconSvg(event.point.buttonMode, event.point.description);
    if (icon.svg) {
      this.lollipop = new LollipopIcon(icon.svg, 0.25, options.stickColor);
      if (!event.uniqueIcon || options.showName) {
        this.speechBubble = new SpeechBubbleSVG(event.name);
        this.boundingBoxHeight = this.lollipop.boundingBoxHeight;
        this.boundingBoxWidth = this.lollipop.boundingBoxWidth + this.speechBubble.boundingBoxWidth;
      } else {
        this.boundingBoxHeight = this.lollipop.boundingBoxHeight;
        this.boundingBoxWidth = this.lollipop.boundingBoxWidth;
      }
      this.iconHeight = this.lollipop.iconHeight;
      this.speechBubbleHeight = this.speechBubble?.boundingBoxHeight || 0;
      this.stickOrigin = {
        x: this.lollipop.boundingBoxWidth / 2,
        y: this.iconHeight / 2 - this.speechBubbleHeight / 2
      };
    } else {
      this.boundingBoxHeight = 0;
      this.boundingBoxWidth = 0;
      this.iconHeight = 0;
      this.speechBubbleHeight = 0;
      this.stickOrigin = {
        x: 0,
        y: 0
      };
    }
  }

  public generateSVG(): string {
    if (!this.speechBubble && this.lollipop) {
      return this.lollipop.generateSVG();
    } else if (this.speechBubble && this.lollipop) {
      return `
  <svg width="${this.boundingBoxWidth}" height="${
        this.boundingBoxHeight
      }" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(0, 0)" >
        ${this.lollipop.generateSVG()}
    </g>
    <g transform="translate(${this.lollipop.boundingBoxWidth}, ${this.iconHeight / 2 -
        this.speechBubbleHeight / 2})" >
        ${this.speechBubble.generateSVG()}
    </g>
  </svg>
  `;
    } else {
      return '';
    }
  }
}
