import { ServiceAbstract } from '../../ServiceAbstract';
import { FlightData } from '../../types/FlightData';
import { Amplify } from '@spidertracks/common';

interface FlightDataStatus {
  items: { id: string; status: string }[];
  meta: {
    count: number;
    limit: number;
  };
}

interface FlightDataMetadata {
  _meta: {
    count: number;
    total: number;
  };
  items: {
    trackId: string;
    metadata: {
      ahrsParametersAvailable: {
        elevationM: boolean;
        altitudeAglM: boolean;
        groundSpeedMps: boolean;
        verticalSpeedMps: boolean;
        rollDegrees: boolean;
        pitchDegrees: boolean;
      };
    };
  };
}

export class FlightDataService extends ServiceAbstract {
  public async get(
    trackId: number | string,
    stId: string,
    from?: number | string,
    to?: number | string,
    limit?: number,
    ahrsStartKey?: string,
    positionStartKey?: string
  ): Promise<FlightData> {
    return Amplify.API.get('authenticated', `flight-data/${trackId}`, {
      queryStringParameters: {
        stId,
        limit,
        from,
        to,
        ahrsStartKey,
        positionStartKey
      }
    });
  }

  public async getFlightStatus(trackIds: number[] = []): Promise<FlightDataStatus> {
    return Amplify.API.get('authenticated', `flight-data/status`, {
      queryStringParameters: {
        trackIds: trackIds.join(',')
      }
    });
  }

  public async getFlightMetadata(trackIds: number[] = []): Promise<FlightDataMetadata> {
    return Amplify.API.get('authenticated', `flight-data/metadata`, {
      queryStringParameters: {
        trackIds: trackIds.join(',')
      }
    });
  }
}

export default FlightDataService;
