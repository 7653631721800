import { DisplayPoint, TrackPoint } from '../../../common/api/spidertracks-sdk/types/TrackData';
import {
  DateFormatterFn,
  formatStandardDateStrings,
  fromTimestamp
} from '../../../helpers/formatTime';
import {
  BUTTON_MODES,
  getIconSvg,
  getIconUrlFromButtonMode
} from '../../Flying/Map/utils/drawing/marker';
import { GroundEvent, TakeoffLanding } from '../Flight3DPlayer/lockToGroundTypes';

export interface TimelineEvent {
  id: string;
  name: string;
  iconUrl: string;
  uniqueIcon: boolean;
  time: string;
  point: DisplayPoint;
  simulated: boolean;
}

export const getTimelineEvents = (
  points: DisplayPoint[],
  formatTimefn: DateFormatterFn,
  timezone: string
): TimelineEvent[] => {
  return points
    .filter(p => p.description && getIconSvg(p.buttonMode).svg)
    .map(p => ({
      id: p.id.toString(),
      name: p.description || '',
      iconUrl: getIconUrlFromButtonMode(p.buttonMode, p.description || ''),
      uniqueIcon: getIconSvg(p.buttonMode, p.description).unique,
      time: formatStandardDateStrings(formatTimefn, fromTimestamp(p.timestamp), timezone)[0],
      point: p,
      simulated: false
    }))
    .sort((a, b) => a.point.timestamp - b.point.timestamp);
};

export const getGroundEvents = (trackPoints: TrackPoint[]) => {
  return trackPoints.reduce<GroundEvent[]>((acc, point) => {
    if (
      point.buttonMode === BUTTON_MODES.EVENTS.TAKEOFF ||
      point.buttonMode === BUTTON_MODES.EVENTS.LANDING ||
      point.buttonMode === BUTTON_MODES.EVENTS.TOUCH_AND_GO_LANDING
    ) {
      const buttonModeMap = {
        [BUTTON_MODES.EVENTS.TAKEOFF]: TakeoffLanding.Takeoff,
        [BUTTON_MODES.EVENTS.LANDING]: TakeoffLanding.Landing,
        [BUTTON_MODES.EVENTS.TOUCH_AND_GO_LANDING]: TakeoffLanding.TouchAndGo
      };
      acc.push({
        timestamp: point.timestamp,
        type: buttonModeMap[point.buttonMode]
      });
    }
    return acc;
  }, []);
};
