import React, { useMemo, useState } from 'react';
import { getSpinner } from '../../util';
import { useHistoryParams } from './useHistoryParams';
import { boldFont, lightFont } from '../common/styles';
import { Divider } from 'antd';
import { getInstance } from '../../common/api/spidertracks-sdk';
import moment from 'moment';
import PropTypes from 'prop-types';
import { convertMetresTo } from '../Flying/Map/utils/helper';

export const TracksTotalsHeader = ({ totalTrackCount, distanceUnit }) => {
  const {
    aircraftFilteredValues,
    distanceFilteredValues,
    durationFilteredValues,
    endTime,
    sosFilteredValues,
    startTime
  } = useHistoryParams();

  const [totalDistance, setTotalDistance] = useState(undefined);
  const [totalTime, setTotalTime] = useState(undefined);

  const SpidertracksSDK = getInstance();

  useMemo(() => {
    setTotalDistance(undefined);
    setTotalTime(undefined);
    const params = new URLSearchParams();

    if (startTime) params.set('startTimeEpochMillis', moment(startTime).unix() * 1000);

    if (endTime) params.set('endTimeEpochMillis', moment(endTime).unix() * 1000);

    if (aircraftFilteredValues !== undefined && aircraftFilteredValues.length !== 0)
      params.set('aircraftIds', aircraftFilteredValues.join(','));

    if (sosFilteredValues !== undefined && sosFilteredValues.length !== 0)
      params.set('sosStatus', sosFilteredValues.join(','));

    if (durationFilteredValues !== undefined)
      params.set('excludeZeroMinTracks', durationFilteredValues);

    if (distanceFilteredValues !== undefined)
      params.set('excludeZeroDistanceTracks', distanceFilteredValues);

    const getTotals = async () => {
      const data = await SpidertracksSDK.getHistoryTableTracksTotals(params);

      setTotalDistance(data.totalDistanceMeters);
      setTotalTime(data.totalDurationMilliSeconds);
    };

    getTotals();
  }, [
    startTime,
    endTime,
    aircraftFilteredValues,
    distanceFilteredValues,
    durationFilteredValues,
    sosFilteredValues
  ]);

  return (
    <div>
      <span style={boldFont}>
        {`${totalTrackCount} Results`}
        <Divider type="vertical" style={{ background: '#727272' }} />
        Total Distance:{' '}
        <span style={lightFont}>
          {totalDistance !== undefined
            ? `${convertMetresTo(totalDistance, distanceUnit)[0].toLocaleString(undefined, {
                maximumFractionDigits: 2
              })} ${distanceUnit}`
            : getSpinner()}
        </span>
        <Divider type="vertical" style={{ background: '#727272' }} />
        Total Time:{' '}
        <span style={lightFont}>
          {totalTime !== undefined
            ? `${(totalTime / (1000 * 60 * 60)).toLocaleString(undefined, {
                maximumFractionDigits: 2
              })} hrs`
            : getSpinner()}
        </span>
      </span>
    </div>
  );
};

TracksTotalsHeader.propTypes = {
  totalTrackCount: PropTypes.number,
  distanceUnit: PropTypes.string
};
