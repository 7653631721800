/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';
import AirmaestroForm from './AirmaestroForm';

export const ConnectToAirmaestroDrawer = ({ visibility, onClose, connectToAm }) => {
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
  const [submitButtonValue, setSubmitButtonValue] = useState('Connect');

  return (
    <Drawer
      title="Connect to Airmaestro"
      placement="right"
      closable={true}
      visible={visibility}
      onClose={onClose}
      getContainer={false}
      style={{ position: 'absolute' }}
      width={400}
    >
      <AirmaestroForm
        onClose={onClose}
        connect={connectToAm}
        submitButtonValue={submitButtonValue}
        setSubmitButtonValue={setSubmitButtonValue}
        isSubmitButtonDisabled={isSubmitButtonDisabled}
        setIsSubmitButtonDisabled={setIsSubmitButtonDisabled}
        handleFieldChange={() => setIsSubmitButtonDisabled(false)}
      ></AirmaestroForm>
    </Drawer>
  );
};

ConnectToAirmaestroDrawer.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  connectToAm: PropTypes.func.isRequired
};

export default ConnectToAirmaestroDrawer;
