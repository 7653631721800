import { createSelector, defaultMemoize } from 'reselect';
import { Aircraft, Point } from '../types';
import { FullState } from '../../store';
import { AircraftListFilter } from '../../components/Flying/AircraftList/constants';
import aircraftListFilter from '../../helpers/aircraftListFilter';
import { getDuration } from '../../helpers/formatTime';
import {
  getLastPoint,
  getTrackStatus
} from '../../components/Flying/AircraftList/utils/aircraftlistUtils';
import { Track } from '../../types/Track';
import { PrivateTrackData } from '../../common/api/spidertracks-sdk/types/TrackData';
import { sortAircraftListBy } from '../../helpers/sortAircraftBy';

export const getAircraftData = (state: FullState): { [key: string]: any } => state.aircraft;
export const getTracksData = (state: FullState): { [key: string]: any } => state.tracks.all;
export const getLatestTracksIds = (state: FullState): { [key: string]: any } => state.tracks.latest;
export const getSelectedTrackId = (state: FullState): string => state.tracks.selectedTrackId;
export const getSelectedTracksIds = (state: FullState): { [key: string]: any } =>
  state.tracks.selected;

const processAircraft = (
  trackData: { [key: string]: any },
  aircraftData: { [key: string]: any }
) => (acc: any, trackId: string) => {
  if (Object.keys(trackData).length) {
    let latestPoint = undefined;
    if (trackData[trackId].points) {
      latestPoint = getLastPoint(trackData[trackId].points);
    }
    const isActive = trackData[trackId]?.aircraft?.isActive ?? false;
    const track = trackData[trackId];
    const aircraftId = trackData[trackId].aircraft.id;

    const points = track.points.map((point: any, index: number) => {
      return { ...point, pointIndex: index };
    });
    const mostRecentTrack = {
      ...track,
      isActive,
      points
    };
    const ts = latestPoint ? latestPoint.timestamp : undefined;
    acc.push({
      id: aircraftId,
      aircraftIcon: aircraftData[aircraftId] ? aircraftData[aircraftId].aircraft_icon : 'DEFAULT',
      aircraftIconColour: aircraftData[aircraftId]
        ? aircraftData[aircraftId].aircraft_icon_colour
        : 'DEFAULT',
      registration: trackData[trackId].aircraft.registration,
      departedTime: trackData[trackId].departedTime,
      lastPointTimestamp: ts,
      flightDuration: getDuration(trackData[trackId].departedTime, ts).formatted,
      flightDistance: trackData[trackId].totalDistance,
      isActive,
      trackStatus: getTrackStatus(trackData[trackId].state, isActive),
      mostRecentTrack,
      status: trackData[trackId].aircraft.status
    });
  }
  return acc;
};

export const getAircraftList = createSelector(
  [getTracksData, getLatestTracksIds, getAircraftData],
  (trackData, latestTracksIds, aircraftData) => {
    window.performance.mark('getAircraftList_selector_start');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const aircraftList = latestTracksIds.reduce(processAircraft(trackData, aircraftData), []);
    window.performance.mark('getAircraftList_selector_end');
    window.performance.measure(
      'getAircraftList_selector',
      'getAircraftList_selector_start',
      'getAircraftList_selector_end'
    );
    return aircraftList;
  }
);

export const getSelectedAircraftList = createSelector(
  [getTracksData, getSelectedTracksIds, getAircraftData],
  (trackData, selectedTrackIds, aircraftData) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return selectedTrackIds.reduce(processAircraft(trackData, aircraftData), []);
  }
);

export const getSelectedAircraftId = createSelector(
  [getTracksData, getSelectedTracksIds],
  (trackData, selectedTrackIds) => {
    if (selectedTrackIds[0]) {
      return trackData[selectedTrackIds[0]]?.aircraft.id;
    } else {
      return '';
    }
  }
);

export const getSelectedAircraftById = createSelector(
  [getSelectedAircraftList, (_: FullState, id: string) => id],
  (aircraftList, id) => aircraftList.filter((aircraft: Aircraft) => aircraft.id === id)
);

export const getAircraftWithLatestTrackById = createSelector(
  [getAircraftList, (_: FullState, id: string) => id],
  (aircraftList, id) => aircraftList.filter((aircraft: Aircraft) => aircraft.id === id)
);

export const getLatestTrackFromAircraft = (aircraft: Aircraft) => aircraft.mostRecentTrack;

export const getLatestTracksFromFilteredAircraftList = createSelector(
  [getAircraftList, (_: FullState, filter: AircraftListFilter['value']) => filter],
  (aircraftList: Aircraft[], filter: AircraftListFilter['value']) => {
    const sortedAircraftList = sortAircraftListBy(aircraftList, filter);
    return aircraftListFilter(sortedAircraftList, filter).map(getLatestTrackFromAircraft);
  }
);

export const getTrackById = createSelector(
  [getAircraftList, (_: FullState, trackId: Track['trackId']) => trackId],
  (aircraftList, trackId) => {
    window.performance.mark('get_track_by_id_start');
    const matchAircraft = aircraftList.find(
      (aircraft: Aircraft) => getLatestTrackFromAircraft(aircraft).trackId === trackId
    );
    window.performance.mark('get_track_by_id_end');
    window.performance.measure('get_track_by_id', 'get_track_by_id_start', 'get_track_by_id_end');
    return matchAircraft && getLatestTrackFromAircraft(matchAircraft);
  }
);

export const getSelectedTrackById = createSelector(
  [getSelectedAircraftList, (_: FullState, trackId: Track['trackId']) => trackId],
  (aircraftList, trackId) => {
    const matchAircraft = aircraftList.find(
      (aircraft: Aircraft) => getLatestTrackFromAircraft(aircraft).trackId === trackId
    );
    return matchAircraft && getLatestTrackFromAircraft(matchAircraft);
  }
);

export const getSelectedTrack = defaultMemoize((state: FullState) => {
  const selectedTrackId = getSelectedTrackId(state);
  if (!selectedTrackId) {
    return;
  }

  return getSelectedTrackById(state, selectedTrackId);
});

export const getSelectedTracks = defaultMemoize((state: FullState): PrivateTrackData[] => {
  const selectedTracks = getSelectedAircraftList(state);
  if (!selectedTracks.length || !selectedTracks) return [];
  return selectedTracks.map((aircraft: Aircraft) => getLatestTrackFromAircraft(aircraft));
});

const defaultTrackPoints: Point[] = [];

const isTrack = (track: Track | PrivateTrackData): track is Track => 'flightLog' in track;

export const getTrackPointsWithoutEvents = (track: Track | PrivateTrackData): Point[] => {
  if (isTrack(track)) {
    return track.points || defaultTrackPoints;
  }
  // Currently only filters out CAS events coming through the /latest endpoint
  // If other events are returned, the filter should be updated
  return track.points.filter(point => point.trackStatus !== 'CREW_ALERT') || defaultTrackPoints;
};

export const getLatestPoint = (points: Point[]): Point => points.slice(-1)[0];

export const getLatLng = (point: Point): google.maps.LatLngLiteral => ({
  lat: point.latitude,
  lng: point.longitude
});

export const isActive = (aircraft: Aircraft): boolean => aircraft.isActive;

export const isTrackSelected = createSelector(
  [getSelectedTrack, (_: FullState, track: Track) => track && track.trackId],
  (selectedTrack, trackId) => (!!selectedTrack ? selectedTrack.trackId === trackId : false)
);

export const getIndexOfPointInTrack = defaultMemoize((track: Track, point: Point): number =>
  getTrackPointsWithoutEvents(track)
    .map(({ id }) => id)
    .indexOf(point.id)
);

export const isFirstPointInTrack = (track: Track, point: Point) =>
  getIndexOfPointInTrack(track, point) === 0;

export const isLastPointInTrack = (track: Track, point: Point) =>
  getIndexOfPointInTrack(track, point) === getTrackPointsWithoutEvents(track).length - 1;
