import React from 'react';
import PropTypes from 'prop-types';

const Weather = props => {
  const { color } = props;
  return (
    <path
      style={{
        fill: color
      }}
      d="M161.29,83.63c-5.68-28.68-30.95-50.3-61.29-50.3c-24.1,0-44.98,13.66-55.4,33.63C19.52,69.67,0,90.88,0,116.67 c0,27.62,22.38,50,50,50h108.33c23.01,0,41.67-18.66,41.67-41.67C200,103,182.9,85.17,161.29,83.63z M158.33,150H50 c-18.38,0-33.33-14.95-33.33-33.33c0-17.07,12.77-31.31,29.71-33.13l8.87-0.96l4.12-7.91C67.32,59.46,82.89,50,100,50 c21.81,0,40.71,15.51,44.94,36.87l2.48,12.48l12.69,0.91c13.02,0.93,23.22,11.8,23.22,24.74C183.33,138.78,172.12,150,158.33,150z"
    />
  );
};

Weather.defaultProps = {
  color: 'grey'
};

Weather.propTypes = {
  color: PropTypes.string
};

export default Weather;
