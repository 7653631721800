import { PublicAircraftData } from './AircraftData';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PublicTrackData {}

export interface PrivateTrackData extends PublicTrackData {
  aircraft: PublicAircraftData & {
    isActive: boolean;
    type: 'FIXED_WING' | 'ROTARY_WING';
    aircraftIcon: 'DEFAULT' | 'ROTARY_WING' | 'FIXED_WING_SINGLE' | 'FIXED_WING_TWIN';
    model?: string;
    org: {
      id: string;
      isPublic: boolean;
      orgName: string;
    };
  };
  sos: SOS | null;
  departedTime: number;
  endTime: number;
  flightId: number;
  flightTime: number;
  id: number;
  imei: string;
  isEditable: boolean;
  isPublic: boolean;
  pointCount: number;
  points: TrackPoint[];
  state?: string;
  totalDistance: number;
  trackId: number;
  spider: {
    serialNumber: string;
    type: string;
  };
}

export interface SOS {
  id: string;
  aircraft: PublicAircraftData;
  closedBy: {};
  closedChannel: 'WEBSITE';
  closedTime: number;
  createdDate: number;
  escalatedBy: any;
  escalatedChannel: 'SYSTEM';
  escalatedTime: number;
  imei: string;
  open: boolean;
  orgId: string;
  tier: 'TWO' | 'ONE';
  trackIdentifier: number;
  trackIndexTime: number;
}

export enum AircraftTrackState {
  'NORMAL' = 'NORMAL',
  'SPIDERWATCH' = 'SPIDERWATCH',
  'SOS' = 'SOS'
}

export interface TrackPoint {
  coordinates: {
    value: string[];
    format: string;
  };
  pointType?: 'IRIDIUM';
  pointIndex?: number;
  id: number | string;
  altitude: number;
  altitudeUnit: 'ft' | 'm';
  buttonMode: number;
  description?: string;
  heading: number | null;
  imei?: string;
  latitude: number;
  longitude: number;
  radius?: number | null;
  speed: number;
  speedUnit: 'knots' | 'm/s' | 'km/h' | 'mi/h';
  timestamp: number;
  // This is fetched from the API. It is actually the point state and could be different for each point in a track.
  // This field name cannot be changed now since there is a mobile dependency in the API.
  // Optional since this type also represents virtual points constructed in FE from events without any track info.
  trackStatus?:
    | 'TEXT_COMMAND'
    | 'TEXT_MESSAGE'
    | 'SPIDERWATCH'
    | 'MARK_1'
    | 'MARK_3'
    | 'SPIDERWATCH_ON'
    | 'SPIDERWATCH_OFF'
    | 'NORMAL'
    | 'RADIUS'
    | 'CREW_ALERT';
  // This is the actual track state. This is unique for a track. All points in a track will have the same track state.
  // Optional since this is not returned by the API as part of points and is populated by FE when needed.
  aircraftTrackState?: AircraftTrackState;
}

export interface DisplayPoint extends TrackPoint {
  displayName?: string;
  valueDisplayString?: string;
  thresholdDisplayString?: string;
  eventData?: { key: string; value: string }[];
  [key: string | number]: any; // This is lazy!
}
