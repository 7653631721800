import React, { useState, useCallback, forwardRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { GoogleMap, useLoadScript, DrawingManager, Data } from '@react-google-maps/api';
import { Spin } from 'antd';
import { debounce } from '../../../helpers/debounce';
import { getMapView } from '../../../redux/selectors/mapData';
import MapControls from '../../Flying/Map/MapControls';
import { GOOGLE_MAP_CONTAINER } from '../../Flying/Map/GoogleMap/constants';
import { getNormalisedCoordinates } from '../../Flying/Map/utils/vectorUtils';
import { setMapProperties } from '../../Flying/Map/utils/skyVectorMap';

// move to googlemapcontainer
const mapOptions = {
  minZoom: 2,
  mapTypeControl: false,
  panControl: false,
  streetViewControl: false,
  fullscreenControl: false,
  preserveViewport: true,
  rotateControl: false,
  center: { lat: 0, lng: 0 },
  zoom: 4
};

const shapeOptions = {
  strokeWeight: 1
};
const preDrawingManagerOptions = {
  drawingControlOptions: {
    position: 2,
    drawingModes: ['polygon', 'rectangle']
  },
  polygonOptions: shapeOptions,
  rectangleOptions: shapeOptions
};
const postDrawingManagerOptions = {
  drawingControlOptions: {
    position: 2,
    drawingModes: []
  },
  polygonOptions: shapeOptions,
  rectangleOptions: shapeOptions
};
const libraries = ['drawing'];

export const GoogleMapContainer = forwardRef(
  ({ className, value = {}, onChange, drawingControl = true, selectedGeofence, mapView }, ref) => {
    const [drawingManagerOptions, setDrawingManagerOptions] = useState({
      ...preDrawingManagerOptions,
      drawingControl
    });
    const [drawingMode, setDrawingMode] = useState('polygon');
    const [map, setMap] = useState(null);

    const { isLoaded, loadError: isError } = useLoadScript({
      googleMapsApiKey: window.env.STL_GOOGLE_MAPS_API,
      libraries,
      version: '3.47' // Temporary fix see https://github.com/JustFly1984/react-google-maps-api/issues/2963
    });

    const convertShapeToGeoJSON = shape => {
      let GeoJSON = {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [[]]
        },
        properties: {}
      };
      if (shape.getBounds) {
        // Is a Rectangle
        const ne = shape.getBounds().getNorthEast();
        const sw = shape.getBounds().getSouthWest();
        const latLngArray = [
          [ne.lng(), sw.lat()],
          [ne.lng(), ne.lat()],
          [sw.lng(), ne.lat()],
          [sw.lng(), sw.lat()]
        ];
        GeoJSON.geometry.coordinates[0].push(...latLngArray);
      } else {
        // Is a Polygon
        for (let point of shape.getPath().getArray()) {
          GeoJSON.geometry.coordinates[0].push([point.lng(), point.lat()]);
        }
      }

      GeoJSON.geometry.coordinates[0].push(GeoJSON.geometry.coordinates[0][0]);
      return GeoJSON;
    };

    const triggerChange = debounce(changedValue => {
      if (onChange) {
        onChange({
          ...value,
          ...convertShapeToGeoJSON(changedValue)
        });
      }
    }, 200);

    const onShapeComplete = (shape, type) => {
      shape.setEditable(true);
      shape.setDraggable(true);

      if (type === 'rectangle') {
        shape.addListener('bounds_changed', () => {
          triggerChange(shape);
        });
      } else {
        shape.getPath().addListener('insert_at', () => {
          triggerChange(shape);
        });
        shape.getPath().addListener('set_at', () => {
          triggerChange(shape);
        });
      }

      triggerChange(shape);
      setDrawingManagerOptions(postDrawingManagerOptions);
      setDrawingMode();
    };
    const onPolygonComplete = polygon => {
      onShapeComplete(polygon, 'polygon');
    };
    const onRectangleComplete = rectangle => {
      onShapeComplete(rectangle, 'rectangle');
    };

    const onLoad = useCallback(
      function callback(map) {
        if (mapView.googleView) {
          const mapTypeId = mapView.googleView;
          map.setMapTypeId(mapTypeId);
        } else {
          setMapProperties({
            map,
            getNormalisedCoordinates,
            skyVectorView: mapView.skyVectorView
          });
        }
        setMap(map);
      },
      [mapView.googleView, mapView.skyVectorView]
    );

    const onUnmount = useCallback(function callback() {
      setMap(null);
    }, []);

    if (isError) {
      return <div>Map cannot be loaded right now, sorry.</div>;
    }

    const setGeoJsonToMap = data => {
      data.addGeoJson(selectedGeofence);
      const bounds = new window.google.maps.LatLngBounds();
      data.forEach(feature => {
        const geo = feature.getGeometry();
        geo.forEachLatLng(LatLng => bounds.extend(LatLng));
      });
      data.map.fitBounds(bounds);
    };

    if (isLoaded && map && mapView) {
      if (mapView.googleView) {
        const mapTypeId = mapView.googleView;
        map.setMapTypeId(mapTypeId);
        map.extraMapTypes = [];
      } else {
        setMapProperties({
          map,
          getNormalisedCoordinates,
          skyVectorView: mapView.skyVectorView
        });
      }
    }

    return isLoaded ? (
      <React.Fragment>
        <GoogleMap
          id={GOOGLE_MAP_CONTAINER}
          ref={ref}
          mapContainerClassName={className}
          options={mapOptions}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          {drawingControl && (
            <DrawingManager
              drawingMode={drawingMode}
              onPolygonComplete={onPolygonComplete}
              onRectangleComplete={onRectangleComplete}
              options={drawingManagerOptions}
            />
          )}
          <MapControls
            mapViewEnabled={true}
            mapLayerEnabled={true}
            weatherEnabled={false}
            fullScreenEnabled={false}
            pointGroupingEnabled={false}
            centerMapEnabled={false}
            favoritesEnabled={false}
            measureToolEnabled={false}
            downloadTracksEnabled={false}
          />
          {selectedGeofence && <Data onLoad={setGeoJsonToMap} />}
        </GoogleMap>
      </React.Fragment>
    ) : (
      <Spin />
    );
  }
);

GoogleMapContainer.propTypes = {
  className: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
  selectedGeofence: PropTypes.object,
  mapView: PropTypes.object
};

const mapStateToProps = state => {
  const mapViewSelected = getMapView(state);

  return {
    mapView: {
      googleView: mapViewSelected.googleView,
      skyVectorView: mapViewSelected.skyVectorView
    }
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(GoogleMapContainer);
