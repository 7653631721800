import React, { useEffect } from 'react';
import { Form, Select } from 'antd';

interface AltitudeReferenceOptionsProps {
  altitudeReference: string;
  disabled: boolean;
  getFieldDecorator: Function;
  setFieldsValue: Function;
  options: Function;
}

export const AltitudeReferenceOptions = ({
  altitudeReference,
  disabled,
  getFieldDecorator,
  setFieldsValue,
  options
}: AltitudeReferenceOptionsProps) => {
  useEffect(() => {
    if (disabled) {
      setFieldsValue({ altitudeReference });
    }
  }, [setFieldsValue, disabled, altitudeReference]);

  return (
    <Form.Item style={{ width: '100%', paddingTop: '29px', minWidth: '90px' }}>
      {getFieldDecorator('altitudeReference', {
        rules: [
          { required: true, message: 'Please enter altitude type, AMSL or AGL' } // This validation will never fire (since we have something selected always)
        ]
      })(
        <Select disabled={disabled}>
          {options([
            { actualValue: 'amsl', displayedValue: 'AMSL' },
            { actualValue: 'agl', displayedValue: 'AGL' }
          ])}
        </Select>
      )}
    </Form.Item>
  );
};

export default AltitudeReferenceOptions;
