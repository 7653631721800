import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Divider, notification } from 'antd';
import { GetApp } from '@material-ui/icons';
import { boldFont, lightFont } from '../common/styles';
import { ViewFlights } from './ViewFlights';
import { DeleteTracks } from './DeleteTracks';
import showDeleteTracksModal from './DeleteTracksModal';
import styled from 'styled-components';
import { TracksTotalsHeader } from './TrackTotalsHeader';
import { DownloadTracks } from '../common/modal/DownloadTracks';
import { getSpinner } from '../../util';
import { buildHistoryUrl } from './buildHistoryUrl';
import { convertMetresTo } from '../Flying/Map/utils/helper';

const TableHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  min-height: 26px;
  margin-bottom: 2px;
`;

const TableHeaderInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TableHeaderActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const DownloadTracksModalContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: #1890ff;
`;

export const FullSummaryDownloadStyle = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: #1890ff;
`;

export const HistoryTableHeader = ({
  totalDuration,
  totalDistance,
  distanceUnit,
  selectedRowKeys,
  history,
  deleteTracks,
  selectedTracksInfo,
  downloadLimit,
  totalTrackCount,
  downloadFullSummary
}) => {
  const [downloadModalVisible, setDownloadModalVisible] = useState(false);
  const [activeDownloadsCount, setActiveDownloadsCount] = useState(0);
  const downloadNotificationKey = 'download-notification';
  const convertedTotalDistance = convertMetresTo(totalDistance, distanceUnit)[0];
  const selectedTrackCount = selectedRowKeys.length;

  const downloadStartedCallback = () => {
    const downloadMessage = selectedTrackCount > 1 ? 'Downloading tracks' : 'Downloading track';
    notification.open({
      placement: 'topRight',
      message: downloadMessage,
      description: 'Your download is being processed, it will start shortly.',
      key: downloadNotificationKey,
      duration: 0,
      icon: getSpinner({})
    });
    setActiveDownloadsCount(current => current + 1);
  };

  const downloadCompletedCallback = () => {
    setActiveDownloadsCount(current => current - 1);
  };

  const onClick = () => {
    if (selectedTrackCount === 1) {
      const url = buildHistoryUrl(selectedRowKeys[0], false);
      history.push(url);
    } else {
      const encodedTrackIds = selectedRowKeys.join(encodeURIComponent(','));
      history.push(`history/flight-report-multiview?trackIds=${encodedTrackIds}`);
    }
  };

  const showDeleteIcon =
    selectedTrackCount > 0 &&
    selectedTracksInfo.every(
      ({ orgUserRole }) => orgUserRole && (orgUserRole === 'OWNER' || orgUserRole === 'ADMIN')
    );

  const showDeleteConfirm = () => {
    showDeleteTracksModal(deleteTracks);
  };

  useEffect(() => {
    if (activeDownloadsCount <= 0) {
      notification.close(downloadNotificationKey);
    }
  }, [activeDownloadsCount]);

  const downloadFullCSVSummaryLabelText =
    totalTrackCount <= downloadLimit
      ? `Summary download (all ${totalTrackCount} tracks)`
      : `Summary download (first ${downloadLimit} tracks)`;

  return (
    <Fragment>
      <DownloadTracks
        visible={downloadModalVisible}
        trackIds={selectedRowKeys}
        downloadLimit={downloadLimit}
        requestClose={() => setDownloadModalVisible(false)}
        downloadStartedCallback={downloadStartedCallback}
        downloadCompletedCallback={downloadCompletedCallback}
      />
      <TableHeaderContainer>
        <TableHeaderInfoContainer>
          {!selectedTrackCount && !!totalTrackCount && (
            <TracksTotalsHeader totalTrackCount={totalTrackCount} distanceUnit={distanceUnit} />
          )}
          {!!selectedTrackCount && <div style={boldFont}>{selectedTrackCount} Selected</div>}
          {!!selectedTrackCount && <Divider type="vertical" style={{ background: '#727272' }} />}
          {!!selectedTrackCount && (
            <div>
              <span style={boldFont}>
                Total Distance:
                <span style={lightFont}>{` ${convertedTotalDistance} ${distanceUnit}`}</span>
                <Divider type="vertical" style={{ background: '#727272' }} />
                Total Time: <span style={lightFont}>{` ${totalDuration}`}</span>
              </span>
            </div>
          )}
        </TableHeaderInfoContainer>
        <TableHeaderActionsContainer>
          {selectedTrackCount <= 30 && selectedTrackCount > 0 && (
            <ViewFlights handleClick={onClick} rowLength={selectedTrackCount} trigger={['click']} />
          )}
          {!!selectedTrackCount && (
            <DownloadTracksModalContainer
              className="download-row-items"
              onClick={() => {
                if (selectedTrackCount > downloadLimit) {
                  notification.error({
                    message: 'Error',
                    description: `You have selected a large number of flights. Please select up to ${downloadLimit} flights`,
                    duration: 10
                  });
                } else {
                  setDownloadModalVisible(true);
                }
              }}
            >
              <GetApp style={{ fontSize: '14px' }} />
              <span>Download</span>
            </DownloadTracksModalContainer>
          )}
          {!selectedTrackCount && totalTrackCount > 0 && (
            <FullSummaryDownloadStyle onClick={downloadFullSummary}>
              {downloadFullCSVSummaryLabelText}
            </FullSummaryDownloadStyle>
          )}
          {showDeleteIcon && (
            <>
              <Divider type="vertical" style={{ background: '#727272' }} />
              <DeleteTracks
                handleClick={showDeleteConfirm}
                rowLength={selectedTrackCount}
                trigger={['click']}
              />
            </>
          )}
        </TableHeaderActionsContainer>
      </TableHeaderContainer>
    </Fragment>
  );
};

HistoryTableHeader.propTypes = {
  deleteTracks: PropTypes.func,
  totalDuration: PropTypes.string,
  totalDistance: PropTypes.number,
  distanceUnit: PropTypes.string,
  selectedRowKeys: PropTypes.array,
  history: PropTypes.object,
  selectedTracksInfo: PropTypes.array,
  downloadLimit: PropTypes.number,
  totalTrackCount: PropTypes.number,
  downloadFullSummary: PropTypes.func
};

HistoryTableHeader.displayName = 'HistoryTableHeader';

export default HistoryTableHeader;
