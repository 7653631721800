import React from 'react';
import './styles.scss';

type IconProps = {
  onClick: () => void;
  enabled?: boolean;
};

export const FILL_COLOUR_ENABLED = '#BFBFBF';
export const FILL_COLOUR_DISABLED = '#BFBFBF';

export const STROKE_COLOUR_ENABLED = 'black';
export const STROKE_COLOUR_DISABLED = '#8C8C8C';

const getFillColour = (enabled: boolean) => (enabled ? FILL_COLOUR_ENABLED : FILL_COLOUR_DISABLED);
const getStrokeColour = (enabled: boolean) =>
  enabled ? STROKE_COLOUR_ENABLED : STROKE_COLOUR_DISABLED;

export const GotoStartOfTrack = ({ onClick, enabled = true }: IconProps) => (
  <svg
    className={enabled ? 'player-control-enabled' : 'player-control-disabled'}
    data-testid="start-of-track-icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={enabled ? onClick : undefined}
  >
    <circle
      data-testid="start-of-track-icon-circle"
      cx="12"
      cy="12"
      r="11"
      fill={getFillColour(enabled)}
      stroke={getStrokeColour(enabled)}
      strokeWidth="2"
    />
    <path
      d="M8.09375 6.56238H7.09375C7.025 6.56238 6.96875 6.61863 6.96875 6.68738V17.4374C6.96875 17.5061 7.025 17.5624 7.09375 17.5624H8.09375C8.1625 17.5624 8.21875 17.5061 8.21875 17.4374V6.68738C8.21875 6.61863 8.1625 6.56238 8.09375 6.56238ZM15.0312 7.69363V6.56238C15.0312 6.45613 14.9078 6.39832 14.8266 6.46707L8.23438 11.9999L14.8266 17.5327C14.9078 17.6014 15.0312 17.5436 15.0312 17.4374V16.3061C15.0312 16.1593 14.9656 16.0186 14.8531 15.9233L10.1781 11.9999L14.8531 8.07644C14.9656 7.98113 15.0312 7.84051 15.0312 7.69363V7.69363Z"
      fill={getStrokeColour(enabled)}
    />
  </svg>
);

export const StepBack = ({ onClick, enabled = true }: IconProps) => (
  <svg
    className={enabled ? 'player-control-enabled' : 'player-control-disabled'}
    data-testid="step-back-icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={enabled ? onClick : undefined}
  >
    <circle
      data-testid="step-back-icon-circle"
      cx="12"
      cy="12"
      r="11"
      fill={getFillColour(enabled)}
      stroke={getStrokeColour(enabled)}
      strokeWidth="2"
    />
    <path
      d="M14.3125 7.41058V6.20277C14.3125 6.09808 14.1922 6.04027 14.111 6.10433L7.06721 11.6059C7.00736 11.6524 6.95894 11.712 6.92563 11.7801C6.89232 11.8482 6.875 11.923 6.875 11.9989C6.875 12.0747 6.89232 12.1495 6.92563 12.2176C6.95894 12.2857 7.00736 12.3453 7.06721 12.3918L14.111 17.8934C14.1938 17.9575 14.3125 17.8996 14.3125 17.795V16.5871C14.3125 16.5106 14.2766 16.4371 14.2172 16.3903L8.59221 11.9996L14.2172 7.60745C14.2766 7.56058 14.3125 7.48714 14.3125 7.41058V7.41058Z"
      fill={getStrokeColour(enabled)}
    />
  </svg>
);

export const PlayFlight = ({ onClick, enabled = true }: IconProps) => (
  <svg
    className={enabled ? 'player-control-enabled' : 'player-control-disabled'}
    data-testid="play-flight-icon"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={enabled ? onClick : undefined}
  >
    <circle
      data-testid="play-flight-icon-circle"
      cx="18"
      cy="18"
      r="17"
      fill={getFillColour(enabled)}
      stroke={getStrokeColour(enabled)}
      strokeWidth="2"
    />
    <path
      d="M24.7766 17.5658L15.8516 9.86889C15.5187 9.58295 15.0312 9.84077 15.0312 10.3025V25.6962C15.0312 26.158 15.5187 26.4158 15.8516 26.1298L24.7766 18.433C25.032 18.2126 25.032 17.7861 24.7766 17.5658Z"
      fill={getStrokeColour(enabled)}
    />
  </svg>
);

export const PauseFlight = ({ onClick, enabled = true }: IconProps) => (
  <svg
    className={enabled ? 'player-control-enabled' : 'player-control-disabled'}
    data-testid="pause-flight-icon"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={enabled ? onClick : undefined}
  >
    <circle
      data-testid="pause-flight-icon-circle"
      cx="18"
      cy="18"
      r="17"
      fill={getFillColour(enabled)}
      stroke={getStrokeColour(enabled)}
      strokeWidth="2"
    />
    <path
      d="M13.125 10.125H15V25.875H13.125V10.125ZM22.6875 10.125H21.1875C21.0844 10.125 21 10.2094 21 10.3125V25.6875C21 25.7906 21.0844 25.875 21.1875 25.875H22.6875C22.7906 25.875 22.875 25.7906 22.875 25.6875V10.3125C22.875 10.2094 22.7906 10.125 22.6875 10.125Z"
      fill={getStrokeColour(enabled)}
    />
  </svg>
);

export const StepForward = ({ onClick, enabled = true }: IconProps) => (
  <svg
    className={enabled ? 'player-control-enabled' : 'player-control-disabled'}
    data-testid="step-forward-icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={enabled ? onClick : undefined}
  >
    <circle
      data-testid="step-forward-icon-circle"
      cx="12"
      cy="12"
      r="11"
      fill={getFillColour(enabled)}
      stroke={getStrokeColour(enabled)}
      strokeWidth="2"
    />
    <path
      d="M16.964 11.6062L9.92031 6.10469C9.90191 6.0902 9.87979 6.08119 9.8565 6.07871C9.8332 6.07622 9.80968 6.08035 9.78863 6.09063C9.76758 6.10091 9.74986 6.11692 9.7375 6.13682C9.72514 6.15672 9.71864 6.1797 9.71875 6.20312V7.41094C9.71875 7.4875 9.75469 7.56094 9.81406 7.60781L15.439 12L9.81406 16.3922C9.75313 16.4391 9.71875 16.5125 9.71875 16.5891V17.7969C9.71875 17.9016 9.83906 17.9594 9.92031 17.8953L16.964 12.3938C17.0239 12.3471 17.0723 12.2873 17.1057 12.2191C17.139 12.1509 17.1563 12.0759 17.1563 12C17.1563 11.9241 17.139 11.8491 17.1057 11.7809C17.0723 11.7127 17.0239 11.6529 16.964 11.6062Z"
      fill={getStrokeColour(enabled)}
    />
  </svg>
);

export const GoToEndOfTrack = ({ onClick, enabled = true }: IconProps) => (
  <svg
    className={enabled ? 'player-control-enabled' : 'player-control-disabled'}
    data-testid="end-of-track-icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={enabled ? onClick : undefined}
  >
    <circle
      data-testid="end-of-track-icon-circle"
      cx="12"
      cy="12"
      r="11"
      fill={getFillColour(enabled)}
      stroke={getStrokeColour(enabled)}
      strokeWidth="2"
    />
    <path
      d="M15.9063 6.56277H14.9063C14.8375 6.56277 14.7813 6.61902 14.7813 6.68777V17.4378C14.7813 17.5065 14.8375 17.5628 14.9063 17.5628H15.9063C15.975 17.5628 16.0313 17.5065 16.0313 17.4378V6.68777C16.0313 6.61902 15.975 6.56277 15.9063 6.56277ZM7.96875 6.56277V7.69402C7.96875 7.84246 8.03438 7.98152 8.14688 8.07683L12.8219 12.0003L8.14688 15.9237C8.03438 16.019 7.96875 16.1581 7.96875 16.3065V17.4378C7.96875 17.544 8.09219 17.6018 8.17344 17.5331L14.7656 12.0003L8.17344 6.46746C8.15533 6.45205 8.13318 6.44217 8.10962 6.43899C8.08606 6.4358 8.06208 6.43944 8.04053 6.44948C8.01898 6.45951 8.00076 6.47552 7.98804 6.4956C7.97532 6.51568 7.96862 6.53899 7.96875 6.56277V6.56277Z"
      fill={getStrokeColour(enabled)}
    />
  </svg>
);
