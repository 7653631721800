import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col, Input, Alert, Tooltip } from 'antd';
import InfoCircleFilled from '@ant-design/icons/InfoCircleFilled';

const formItemStyle = { marginBottom: '1px' };

const AircraftRegistrationInput = ({
  getFieldDecorator,
  validationRules = [{ required: false }],
  placeholder,
  handleFieldChange,
  disabled = false
}) => {
  const [validationFailed, setValidationFailed] = useState(false);
  const validateRegistration = e => {
    handleFieldChange(e);
    const registration = e.target.value;
    const validRegistrationRegex = /^[A-Z0-9-]{1,11}$/g;
    if (registration.match(validRegistrationRegex) || registration.trim() === '') {
      setValidationFailed(false);
    } else {
      setValidationFailed(true);
    }
  };

  return (
    <Form.Item name={'registration'} label={'Registration'} style={formItemStyle}>
      <Row>
        <Col span={16}>
          {getFieldDecorator('registration', {
            rules: validationRules
          })(
            <Input
              autoComplete="off"
              placeholder={placeholder}
              style={{ width: '300px', height: '40px' }}
              onChange={validateRegistration}
              disabled={disabled}
            />
          )}
        </Col>
      </Row>
      {validationFailed && (
        <div style={{ width: '300px' }}>
          <Alert
            type="warning"
            style={{ paddingLeft: '6px', paddingRight: '6px' }}
            message={
              <Row>
                <Col span={23}>Spider X mobile pairing will be by SN only</Col>
                <Col span={1}>
                  <Tooltip
                    placement="right"
                    title={
                      <span>
                        Registrations must only contain the characters A-Z 0-9 or `-` and be 11
                        characters or less to be advertised by Spider X. Refer Help Center to{' '}
                        {
                          <a
                            target={'_blank'}
                            href={
                              'https://spidertrackshelp.zendesk.com/hc/en-nz/articles/9313290901903-How-To-Identify-and-Pair-with-your-Spider-X-by-Aircraft-Registration'
                            }
                          >
                            learn more
                          </a>
                        }
                      </span>
                    }
                    trigger="click"
                  >
                    <InfoCircleFilled style={{ fontSize: 10, color: 'gray' }} />
                  </Tooltip>
                </Col>
              </Row>
            }
          />
        </div>
      )}
    </Form.Item>
  );
};

AircraftRegistrationInput.propTypes = {
  getFieldDecorator: PropTypes.func.isRequired,
  validationRules: PropTypes.array,
  placeholder: PropTypes.string,
  handleFieldChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default AircraftRegistrationInput;
