import { createSlice } from '@reduxjs/toolkit';
import { InsightRule, InsightRulesState } from '../../types/insightRules';
import { Dispatch } from 'react';
import { getInstance } from '../../common/api/spidertracks-sdk';

const initialState: InsightRulesState = {
  organisationRules: {},
  eventParameters: [
    {
      eventClass: 'fsi',
      eventType: 'excessive_g_force',
      parameters: [
        {
          op: 'gt',
          unit: 'g',
          displayName: 'G Force',
          maxDisplayValue: 16,
          name: 'gForce',
          displayUnit: 'g',
          minDisplayValue: 1,
          type: 'numeric'
        }
      ]
    },
    {
      eventClass: 'fsi',
      eventType: 'excessive_pitch_down',
      parameters: [
        {
          op: 'lt',
          unit: 'radians',
          displayName: 'Pitch',
          name: 'pitch',
          maxDisplayValue: -10,
          displayUnit: 'degrees',
          minDisplayValue: -180,
          type: 'numeric'
        }
      ]
    },
    {
      eventClass: 'fsi',
      eventType: 'excessive_pitch_down_at_low_altitude_agl',
      parameters: [
        {
          op: 'lt',
          unit: 'radians',
          displayName: 'Pitch',
          name: 'pitch',
          maxDisplayValue: -10,
          displayUnit: 'degrees',
          minDisplayValue: -180,
          type: 'numeric'
        },
        {
          op: 'lt',
          unit: 'meters',
          displayName: 'Altitude (AGL)',
          name: 'agl',
          maxDisplayValue: 10000,
          displayUnit: 'ft',
          minDisplayValue: 0,
          type: 'numeric'
        }
      ]
    },
    {
      eventClass: 'fsi',
      eventType: 'excessive_pitch_up',
      parameters: [
        {
          op: 'gt',
          unit: 'radians',
          displayName: 'Pitch',
          name: 'pitch',
          maxDisplayValue: 180,
          displayUnit: 'degrees',
          minDisplayValue: 10,
          type: 'numeric'
        }
      ]
    },
    {
      eventClass: 'fsi',
      eventType: 'excessive_pitch_up_at_low_altitude_agl',
      parameters: [
        {
          op: 'gt',
          unit: 'radians',
          displayName: 'Pitch',
          name: 'pitch',
          maxDisplayValue: 180,
          displayUnit: 'degrees',
          minDisplayValue: 10,
          type: 'numeric'
        },
        {
          op: 'lt',
          unit: 'meters',
          displayName: 'Altitude (AGL)',
          name: 'agl',
          maxDisplayValue: 10000,
          displayUnit: 'ft',
          minDisplayValue: 0,
          type: 'numeric'
        }
      ]
    },
    {
      eventClass: 'fsi',
      eventType: 'excessive_pitch_up_at_low_speed',
      parameters: [
        {
          op: 'gt',
          unit: 'radians',
          displayName: 'Pitch',
          name: 'pitch',
          maxDisplayValue: 180,
          displayUnit: 'degrees',
          minDisplayValue: 10,
          type: 'numeric'
        },
        {
          op: 'lt',
          unit: 'm/sec',
          displayName: 'Ground Speed',
          name: 'gps_gspeed',
          maxDisplayValue: 500,
          displayUnit: 'kt',
          minDisplayValue: 5,
          type: 'numeric'
        }
      ]
    },
    {
      eventClass: 'fsi',
      eventType: 'excessive_rate_of_climb',
      parameters: [
        {
          op: 'gt',
          unit: 'm/sec',
          displayName: 'Vertical Speed',
          name: 'v_speed',
          maxDisplayValue: 10000,
          displayUnit: 'ft/min',
          minDisplayValue: 50,
          type: 'numeric'
        }
      ]
    },
    {
      eventClass: 'fsi',
      eventType: 'excessive_roll',
      parameters: [
        {
          op: 'gt',
          unit: 'radians',
          displayName: 'Roll',
          name: 'roll',
          maxDisplayValue: 180,
          displayUnit: 'degrees',
          minDisplayValue: 10,
          type: 'numeric'
        }
      ]
    },
    {
      eventClass: 'fsi',
      eventType: 'excessive_roll_at_low_altitude',
      parameters: [
        {
          op: 'gt',
          unit: 'radians',
          displayName: 'Roll',
          name: 'roll',
          maxDisplayValue: 180,
          displayUnit: 'degrees',
          minDisplayValue: 10,
          type: 'numeric'
        },
        {
          op: 'lt',
          unit: 'meters',
          displayName: 'Altitude',
          name: 'altitude',
          maxDisplayValue: 60000,
          displayUnit: 'ft',
          minDisplayValue: -1410.76,
          type: 'numeric'
        }
      ]
    },
    {
      eventClass: 'fsi',
      eventType: 'excessive_roll_at_low_altitude_agl',
      parameters: [
        {
          op: 'gt',
          unit: 'radians',
          displayName: 'Roll',
          name: 'roll',
          maxDisplayValue: 180,
          displayUnit: 'degrees',
          minDisplayValue: 10,
          type: 'numeric'
        },
        {
          op: 'lt',
          unit: 'meters',
          displayName: 'Altitude (AGL)',
          name: 'agl',
          maxDisplayValue: 10000,
          displayUnit: 'ft',
          minDisplayValue: 0,
          type: 'numeric'
        }
      ]
    },
    {
      eventClass: 'fsi',
      eventType: 'excessive_rpm',
      parameters: [
        {
          op: 'gt',
          unit: 'rpm',
          displayName: 'RPM',
          maxDisplayValue: 9999999,
          name: 'primaryConditionValue',
          displayUnit: 'rpm',
          minDisplayValue: 0,
          type: 'numeric'
        }
      ]
    },
    {
      eventClass: 'fsi',
      eventType: 'excessive_speed',
      parameters: [
        {
          op: 'gt',
          unit: 'm/sec',
          displayName: 'Speed',
          maxDisplayValue: 9999999,
          name: 'primaryConditionValue',
          displayUnit: 'kt',
          minDisplayValue: 0,
          type: 'numeric'
        }
      ]
    },
    {
      eventClass: 'fsi',
      eventType: 'excessive_temperature',
      parameters: [
        {
          op: 'gt',
          unit: '°C',
          displayName: 'Temperature',
          maxDisplayValue: 9999999,
          name: 'primaryConditionValue',
          displayUnit: '°C',
          minDisplayValue: 0,
          type: 'numeric'
        }
      ]
    },
    {
      eventClass: 'fsi',
      eventType: 'high_rate_of_descent',
      parameters: [
        {
          op: 'gt',
          unit: 'm/sec',
          displayName: 'Vertical Speed',
          name: 'v_speed',
          maxDisplayValue: 10000,
          displayUnit: 'ft/min',
          minDisplayValue: 50,
          type: 'numeric'
        }
      ]
    },
    {
      eventClass: 'fsi',
      eventType: 'high_rate_of_descent_at_low_altitude',
      parameters: [
        {
          op: 'gt',
          unit: 'm/sec',
          displayName: 'Vertical Speed',
          name: 'v_speed',
          maxDisplayValue: 10000,
          displayUnit: 'ft/min',
          minDisplayValue: 50,
          type: 'numeric'
        },
        {
          op: 'lt',
          unit: 'meters',
          displayName: 'Altitude',
          name: 'altitude',
          maxDisplayValue: 60000,
          displayUnit: 'ft',
          minDisplayValue: -1410.76,
          type: 'numeric'
        }
      ]
    },
    {
      eventClass: 'fsi',
      eventType: 'high_rate_of_descent_at_low_altitude_agl',
      parameters: [
        {
          op: 'gt',
          unit: 'm/sec',
          displayName: 'Vertical Speed',
          name: 'v_speed',
          maxDisplayValue: 10000,
          displayUnit: 'ft/min',
          minDisplayValue: 50,
          type: 'numeric'
        },
        {
          op: 'lt',
          unit: 'meters',
          displayName: 'Altitude (AGL)',
          name: 'agl',
          maxDisplayValue: 10000,
          displayUnit: 'ft',
          minDisplayValue: 0,
          type: 'numeric'
        }
      ]
    },
    {
      eventClass: 'fsi',
      eventType: 'maximum_altitude_agl',
      parameters: [
        {
          op: 'gt',
          unit: 'meters',
          displayName: 'Altitude (AGL)',
          name: 'agl',
          maxDisplayValue: 100000,
          displayUnit: 'ft',
          minDisplayValue: 0,
          type: 'numeric'
        }
      ]
    },
    {
      eventClass: 'fsi',
      eventType: 'maximum_altitude_amsl',
      parameters: [
        {
          op: 'gt',
          unit: 'meters',
          displayName: 'Altitude',
          name: 'altitude',
          maxDisplayValue: 60000,
          displayUnit: 'ft',
          minDisplayValue: -1410.76,
          type: 'numeric'
        }
      ]
    }
  ]
};

export const insightRulesSlice = createSlice({
  name: 'insightRules',
  initialState,
  reducers: {
    setRulesForOrg: (
      state,
      action: { payload: { organisationId: string; rules: InsightRule[] } }
    ) => {
      state.organisationRules[action.payload.organisationId] = action.payload.rules ?? [];
    },
    reset: state => {
      state.organisationRules = {};
    }
  }
});

export function fetchInsightRules(organisationId: string) {
  return async (dispatch: Dispatch<any>) => {
    const sdk = getInstance();
    const rules = await sdk.getInsightRulesService().getAllInsightRules(organisationId);
    dispatch(setRulesForOrg({ organisationId, rules }));
  };
}

export const { setRulesForOrg, reset } = insightRulesSlice.actions;

export default insightRulesSlice;
