import React, { FC, Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInstance } from '../../../common/api/spidertracks-sdk';
import { PrivateTrackData } from '../../../common/api/spidertracks-sdk/types/TrackData';
import { throwIfRequestError } from '../../../helpers';
import { trackFetchError, trackNotFound } from '../../../helpers/globalNotifications';
import { useTrackRouteParams } from '../../../hooks/useTrackRouteParams';
import { setSelectedFlight } from '../../../redux/reducers/aircraftReducer/thunk';
import { savedFilter } from '../../../redux/reducers/mapReducer/actions/map';
import { getSelectedTracks } from '../../../redux/selectors/aircraftData';
import {
  getEventTypesByTrack,
  getFlightStatus,
  getFlightReportDisplayPoints
} from '../../../redux/selectors/eventData';
import { getSelectedFilter } from '../../../redux/selectors/mapData';
import { fetchSelectedTrackAircraft } from '../../../redux/slice/aircraft';
import {
  clearSelectedDisplayPoint,
  fetchEvents,
  setEvents,
  setEventTypes,
  setFlightStatus
} from '../../../redux/slice/events';
import {
  clearSelectedTracks,
  updateAllTracks,
  setSelectedTracks,
  setSelectedTrackId
} from '../../../redux/slice/tracks';
import { AZ } from '../../Flying/AircraftList/constants';
import { LoadingBackground } from '../../LoadingBackground';
import { EventType } from '../../../common/api/spidertracks-sdk/types/Event';

// Wrap a child component in this to prevent its display until a specific track has been loaded and
// is available in the store. Designed to work on the following route parameters:
//  - :trackId (probably most requests)
//  - :serialNumber & :bootcount (click through from FSI dashboard)
//  - :trackIds in querystring (multiview)
// Also loads relevant events. Waits for everything to arrive in store, then renders the child component(s).
export const PreselectMultipleTracks: FC = ({ children }) => {
  const dispatch = useDispatch();
  const { trackHistoryRequest, trackIds } = useTrackRouteParams();

  const SpidertracksSDK = getInstance();

  const eventTypes = useSelector(getEventTypesByTrack);
  const flightStatus = useSelector(getFlightStatus);

  const displayPoints = useSelector(getFlightReportDisplayPoints);
  const selectedTracks = useSelector(getSelectedTracks);
  const selectedAircraftFilter = useSelector(getSelectedFilter);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const fetchTracks = async () => {
      dispatch(savedFilter(AZ));

      try {
        const trackHistoryResponse = await SpidertracksSDK.getTrackHistory(trackHistoryRequest);
        const track = trackHistoryResponse.items[0] as PrivateTrackData;
        dispatch(updateAllTracks(trackHistoryResponse.items));
        dispatch(setSelectedTracks(trackIds));
        dispatch(setSelectedFlight(track));
        dispatch(setSelectedTrackId(undefined));

        if (!track.aircraft || track.aircraft.id === '') {
          dispatch(setEvents({ items: [] }));
        } else {
          dispatch(fetchEvents(track.aircraft.id, track.departedTime, track.endTime));
        }
      } catch (e) {
        console.error(e);
        if (e.message.includes('404')) {
          return trackNotFound();
        }
        trackFetchError();
      }
    };

    fetchTracks();

    return () => {
      dispatch(clearSelectedTracks());
      dispatch(clearSelectedDisplayPoint());
      dispatch(savedFilter(selectedAircraftFilter));
    };
  }, []);

  const selectedTrackIds = selectedTracks.map(track => track.id).join();
  useEffect(() => {
    const requestedOrgCallbackMap = new Map<string, ((eventTypes: EventType[]) => void)[]>();

    for (let track of selectedTracks) {
      if (!track || !track.aircraft || !track.aircraft.org) {
        continue;
      }
      if (eventTypes[track.trackId]) {
        continue;
      }
      let orgId = track.aircraft.org.id;
      if (!requestedOrgCallbackMap.has(orgId)) {
        requestedOrgCallbackMap.set(orgId, []);
      }
      requestedOrgCallbackMap.get(orgId)!.push((eventTypes: EventType[]) => {
        dispatch(setEventTypes({ trackId: track.trackId, eventTypes }));
      });

      async function fetchOrganisationEventTypes() {
        let orgIds = requestedOrgCallbackMap.keys();
        for (let orgId of orgIds) {
          SpidertracksSDK.getEventService()
            .getEventTypes(orgId)
            .then(response => {
              throwIfRequestError(response);
              let callbacks = requestedOrgCallbackMap.get(orgId)!;
              callbacks.forEach(callback => callback(response.items));
            });
        }
      }
      fetchOrganisationEventTypes();
    }
  }, [selectedTrackIds]);

  useEffect(() => {
    const fetchFlightStatus = async () => {
      // Check cache!
      if (trackIds.every(trackId => flightStatus[trackId])) return;

      if (selectedTracks.length > 0) {
        try {
          const flightStatusResponse = await SpidertracksSDK.getFlightDataService().getFlightStatus(
            selectedTracks.map(t => t.id)
          );
          flightStatusResponse.items.forEach(flightStatus =>
            dispatch(
              setFlightStatus({
                trackId: selectedTracks.find(track => String(track.id) === flightStatus.id)
                  ?.trackId,
                flightStatus: flightStatus.status
              })
            )
          );
        } catch (e) {
          console.error(e);
          trackFetchError();
        }
      }
    };
    fetchFlightStatus();
  }, [selectedTrackIds]);

  useEffect(() => {
    if (displayPoints && Object.values(displayPoints).length && !ready) {
      dispatch(fetchSelectedTrackAircraft());
      setReady(true);
    }
  }, [displayPoints]);

  return <Fragment>{ready ? children : <LoadingBackground />}</Fragment>;
};

export default PreselectMultipleTracks;
