import { EventClass } from '../constants';
import { Event, CASEvent } from '../redux/types';

const CAS_EVENT_PRIORITY_MAP = {
  WARNING: {
    ASSERTED: 3,
    CLEARED: 0
  },
  CAUTION: {
    ASSERTED: 2,
    CLEARED: 0
  },
  ADVISORY: {
    ASSERTED: 1,
    CLEARED: 0
  }
};

const isCASEvent = (event: Event): event is CASEvent => event.eventClass === EventClass.CAS;

/**
 * Sorts CAS events at the same location and time by priority.
 * In a set of CAS events occuring at the same location and time, events with higher priority will be placed later than those with lower priority.
 */
export const sortByEventPriority = (e1: Event, e2: Event) => {
  if (e1.geohash !== e2.geohash) {
    return 0;
  }

  if (e1.eventTime !== e2.eventTime) {
    return 0;
  }

  if (!isCASEvent(e1) || !isCASEvent(e2)) {
    return 0;
  }

  const e1Priority =
    CAS_EVENT_PRIORITY_MAP[e1.eventDetails.crewAlertEvent.severity][
      e1.eventDetails.crewAlertEvent.subtype
    ];
  const e2Priority =
    CAS_EVENT_PRIORITY_MAP[e2.eventDetails.crewAlertEvent.severity][
      e2.eventDetails.crewAlertEvent.subtype
    ];

  return e1Priority - e2Priority;
};
