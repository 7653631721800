import React, { useEffect, useState } from 'react';
import { Drawer, Form, Checkbox, Row } from 'antd';
import { ConfirmationButtons } from '../../common/form';
import { EventNameInput } from './EventNameInput';
import { EventTypeDropdown } from './EventTypeDropdown';
import { PrimaryConditionSourceDropdown } from './PrimaryConditionSourceDropdown';
import { AircraftSelector } from '../../common/form/AircraftSelector';
import { buildAircraftOptions, getEventRuleTypeFromConditions } from './utils';
import { ParameterWithThresholds } from './ParameterWithThresholds';
import {
  getPrimaryConditionSourceOptions,
  getPrimaryConditionSourceLabel,
  getConditionSourceOptions
} from './getPrimaryConditionSourceOptions';
import './styles.scss';
import { EventType } from '../../../common/api/spidertracks-sdk/private/services/EventRulesService';
import { PublicAircraftData } from '../../../common/api/spidertracks-sdk/types/AircraftData';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { CheckboxOptionType } from 'antd/lib/checkbox';
import { TableRow } from './EventsConfigTable';
import { useSelector } from 'react-redux';
import { getInsightEventParameters } from '../../../redux/selectors/insightRules';

interface FieldValues {
  ruleName: string;
  displayThresholds?: {
    [key: string]: number;
  };
  severityDisplayThresholds?: {
    [key: string]: {
      low: number;
      medium: number;
      high: number;
    };
  };
  aircraft: string[];
  primaryConditionSource?: { key: string };
  eventType: {
    /** Actual event type selected */
    key: string;
  };
}

export const validateFormFields = async (
  err: any,
  fieldValues: FieldValues,
  state: { setIsSaving: Function; closeDrawer: Function },
  save: Function,
  aircraftOptions: any
) => {
  if (err) {
    return;
  }
  const { setIsSaving, closeDrawer } = state;
  try {
    // NOTE: `err` is purely a validation error, we don't want those in the console
    setIsSaving(true);
    const body: { [key: string]: any } = {
      eventClass: 'fsi',
      eventType: fieldValues.eventType.key,
      ruleName: fieldValues.ruleName,
      enabled: true //TODO set to true by default for now
    };

    if (fieldValues.primaryConditionSource) {
      body.primaryConditionSource = fieldValues.primaryConditionSource.key;
    }

    if (fieldValues.severityDisplayThresholds) {
      body.severityDisplayThresholds = fieldValues.severityDisplayThresholds;
      body.displayThresholds = undefined;
    } else if (fieldValues.displayThresholds) {
      body.displayThresholds = fieldValues.displayThresholds;
      body.severityDisplayThresholds = undefined;
    }

    if (fieldValues.aircraft.length === aircraftOptions.length) {
      body.allAircraft = true;
    } else {
      body.aircraftIds = fieldValues.aircraft;
    }

    console.log({ body });
    // await save(body); TODO uncomment when save function is implemented
    closeDrawer();
  } catch (e) {
    console.error(e);
  } finally {
    setIsSaving(false);
  }
};

interface EventsConfigDrawerProps {
  form: WrappedFormUtils;
  aircraft: PublicAircraftData[];
  isEditForm: boolean;
  visibility: boolean;
  onClose: Function;
  eventTypes: EventType[];
  selectedEventRule?: TableRow;
  save: Function;
  insightsSafetyEnabled: boolean;
}

/** Is not exported as is but wrapped in antd form, see bottom of file */
export const EventsConfigDrawer = (props: EventsConfigDrawerProps) => {
  const {
    eventTypes,
    form,
    selectedEventRule,
    isEditForm,
    aircraft,
    visibility,
    insightsSafetyEnabled,
    onClose,
    save
  } = props;
  const { validateFields, getFieldDecorator, setFieldsValue, setFields } = form;

  const [isSaving, setIsSaving] = useState(false);

  const eventType = selectedEventRule
    ? getEventRuleTypeFromConditions(selectedEventRule)
    : undefined;
  const [selectedEventType, setSelectedEventType] = useState(
    isEditForm ? { key: eventType, label: selectedEventRule?.name } : undefined
  );

  const [ruleName, setRuleName] = useState(
    isEditForm && selectedEventRule ? selectedEventRule.name : undefined
  );
  const [aircraftOptions, setAircraftOptions] = useState<CheckboxOptionType[]>([]);
  const [primaryConditionSourceOptions, setPrimaryConditionSourceOptions] = useState(
    isEditForm && selectedEventRule
      ? getConditionSourceOptions(selectedEventRule.primaryCondition)
      : undefined
  );

  const [severityThresholdsChecked, setSeverityThresholdsChecked] = useState(false);

  useEffect(() => {
    if (selectedEventType && selectedEventType.key) {
      form.resetFields();
      setFieldsValue({
        ruleName: ruleName
      }); // Sets event name back to old value after resetting all fields
      setPrimaryConditionSourceOptions(getPrimaryConditionSourceOptions(selectedEventType.key));
      setAircraftOptions(
        buildAircraftOptions(
          aircraft,
          isEditForm && selectedEventRule ? selectedEventRule : undefined
        )
      );

      if (insightsSafetyEnabled) {
        setSeverityThresholdsChecked(
          (selectedEventRule && selectedEventRule.thresholds.some(el => el.severityEnabled)) ||
            selectedEventRule === undefined
        );
      }
    }
  }, [aircraft, isEditForm, selectedEventType, selectedEventRule, insightsSafetyEnabled]);

  if (!visibility) {
    return null;
  }

  const closeDrawer = () => {
    onClose();
    setSelectedEventType(undefined);
    setRuleName(undefined);
    setPrimaryConditionSourceOptions(undefined);
  };

  const getErrorForThresholdValue = (
    higherThresholdValue: number,
    lowerThresholdValue: number,
    formItemFieldName: string
  ) => {
    const isNegative = higherThresholdValue < 0;
    if (Math.abs(higherThresholdValue) <= Math.abs(lowerThresholdValue)) {
      return {
        [formItemFieldName]: [
          {
            field: formItemFieldName,
            message: `Must be ${isNegative ? 'less' : 'greater'} than ` + lowerThresholdValue
          }
        ]
      };
    }
  };

  const validateSeverityThresholds = (fieldValues: any) => {
    if (fieldValues.severityDisplayThresholds) {
      const fieldNames = Object.keys(fieldValues.severityDisplayThresholds);
      for (const fieldName of fieldNames) {
        const thresholds = fieldValues.severityDisplayThresholds[fieldName];
        if (thresholds.low && thresholds.medium && thresholds.high) {
          const formItemFieldNameMedium = `severityDisplayThresholds.${fieldName}.medium`;
          const formItemFieldNameHigh = `severityDisplayThresholds.${fieldName}.high`;
          const mediumThresholdError = getErrorForThresholdValue(
            thresholds.medium,
            thresholds.low,
            formItemFieldNameMedium
          );
          if (mediumThresholdError) {
            setFields({
              [formItemFieldNameMedium]: {
                value: thresholds.medium,
                errors: [new Error(mediumThresholdError[formItemFieldNameMedium][0].message)]
              }
            });

            return mediumThresholdError;
          }

          const highThresholdError = getErrorForThresholdValue(
            thresholds.high,
            thresholds.medium,
            formItemFieldNameHigh
          );
          if (highThresholdError) {
            setFields({
              [formItemFieldNameHigh]: {
                value: thresholds.high,
                errors: [new Error(highThresholdError[formItemFieldNameHigh][0].message)]
              }
            });
          }

          return highThresholdError;
        }
      }
    }
  };

  const saveEventRule = async (e: any) => {
    e.preventDefault();

    validateFields((err, fieldValues) => {
      if (!err) {
        console.log({ fieldValues });
        err = validateSeverityThresholds(fieldValues);
      }

      validateFormFields(err, fieldValues, { setIsSaving, closeDrawer }, save, aircraftOptions);
    });
  };

  console.log({
    isEditForm,
    aircraftIds: selectedEventRule!.aircraftIds,
    options: aircraftOptions.map(o => o.value)
  });
  const initialAircraft = isEditForm
    ? selectedEventRule!.allAircraft
      ? aircraftOptions.map(o => o.value)
      : selectedEventRule!.aircraftIds
    : [];
  const initialPrimaryConditionSource =
    isEditForm && selectedEventRule && selectedEventRule.primaryConditionSource
      ? {
          key: selectedEventRule.primaryConditionSource,
          label: getPrimaryConditionSourceLabel(selectedEventRule.primaryConditionSource)
        }
      : undefined;
  const allEventParametersItems = useSelector(getInsightEventParameters);
  const parameters =
    isEditForm && selectedEventRule
      ? selectedEventRule.parameters
      : (selectedEventType &&
          allEventParametersItems.filter(el => el.eventType == selectedEventType.key)) ??
        [];

  return (
    <Drawer
      className={'events-config-drawer'}
      title={isEditForm ? 'Edit' : 'Add'}
      placement="right"
      closable={true}
      visible={visibility}
      onClose={closeDrawer}
      getContainer={false}
      style={{ position: 'absolute' }}
      width={400}
      bodyStyle={{ width: '356px', margin: '20px 0 0 32px' }}
    >
      <Form onSubmit={saveEventRule} layout="vertical" hideRequiredMark={true}>
        <EventNameInput
          ruleName={ruleName}
          setRuleName={name => setRuleName(name)}
          getFieldDecorator={getFieldDecorator}
        />
        <EventTypeDropdown
          selectedEventType={selectedEventType}
          isEditForm={isEditForm}
          setSelectedEventType={item => setSelectedEventType(item)}
          getFieldDecorator={getFieldDecorator}
          eventTypes={eventTypes}
        />
        {selectedEventType && primaryConditionSourceOptions && (
          <PrimaryConditionSourceDropdown
            sources={primaryConditionSourceOptions}
            selectedSource={initialPrimaryConditionSource}
            getFieldDecorator={getFieldDecorator}
          />
        )}
        {insightsSafetyEnabled && selectedEventType && (
          <Row style={{ paddingBottom: '2rem' }}>
            <Checkbox
              data-testid="severity-thresholds-checkbox"
              checked={severityThresholdsChecked}
              onChange={() => setSeverityThresholdsChecked(prevState => !prevState)}
            >
              Enable Severity (<span style={{ color: 'green' }}>Low</span>/
              <span style={{ color: 'orange' }}>Medium</span>/
              <span style={{ color: 'red' }}>High</span>)
            </Checkbox>
          </Row>
        )}
        {selectedEventType &&
          parameters
            .filter(parameter => isEditForm || parameter.eventType === selectedEventType.key)
            .map((p, i) => {
              return (
                <ParameterWithThresholds
                  key={`param#${i}`}
                  parameters={p.parameters as any} // todo fix yazdaan
                  getFieldDecorator={getFieldDecorator as any}
                  setFieldsValue={setFieldsValue as any}
                  severityThresholdsEnabled={severityThresholdsChecked}
                />
              );
            })}
        {selectedEventType && (
          <AircraftSelector
            key={Math.random().toString()}
            form={form}
            initialValue={initialAircraft}
            isEditForm={isEditForm}
            options={aircraftOptions}
            showAllChoice={true}
          />
        )}
        <ConfirmationButtons onClose={closeDrawer} isSaving={isSaving} />
      </Form>
    </Drawer>
  );
};

export default Form.create<EventsConfigDrawerProps>()(EventsConfigDrawer);
