import { Button } from 'antd';
import React, { FC, Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { getUserId, getUserOwnerAdminOrgs } from '../../../redux/selectors/userData';
import AccessDenied from './AccessDenied';

interface RequireOrgAdminProps {
  buttonLabel?: string;
  message?: string;
  redirectTo?: string;
}

const defaultMessage =
  'Sorry, you do not have access to view this page. If you think this may be incorrect, please contact your administrator.';

const P = styled.p`
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
`;

// This HoC will conditionally render child components if the current user is an administrator or
// owner of at least one organisation. Optionally provide a message:
//
//   <RequireOrgAdmin message="Nope, go away." redirectTo="/forbidden">
//     <OnlyAdminsMayViewThisImportantComponent />
//   </RequireOrgAdmin>
//
// The default redirect takes the user to "/" after clicking Ok.
//
// Note that the user object should be present in the Redux store (or be loading), and if the id is
// available the component will assume that the user's organisations are also available.
export const RequireOrgAdmin: FC<RequireOrgAdminProps> = ({
  buttonLabel = 'Go to Flying Page',
  children,
  message = defaultMessage,
  redirectTo = '/'
}) => {
  const [redirect, setRedirect] = useState(false);
  const adminOrgs = useSelector(getUserOwnerAdminOrgs);
  const userId = useSelector(getUserId);

  const hasAdminOrgs = adminOrgs.length > 0;

  if (!userId) {
    // TODO: potentially need a spinner here, though it may flicker on fast loads.
    return null;
  }

  if (redirect) {
    return <Redirect to={redirectTo} />;
  }

  if (hasAdminOrgs) {
    return <Fragment>{children}</Fragment>;
  }

  return (
    <AccessDenied>
      <P>{message}</P>
      <Button onClick={() => setRedirect(true)} size="large" type="primary">
        {buttonLabel}
      </Button>
    </AccessDenied>
  );
};

export default RequireOrgAdmin;
