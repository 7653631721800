import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router';
import OrganisationSettingsRoot from './OrganisationSettingsRoot';
import CreateOrganisationPage from './CreateOrganisationPage';
import { useMostRecentOrganisation } from '../../hooks/useMostRecentOrganisation';
import { useSelector } from 'react-redux';
import { getUserOrgData } from '../../redux/selectors/userData';

export const OrganisationRootRedirect = ({ match: { path } }) => {
  const orgs = useSelector(getUserOrgData);
  const [orgId] = useMostRecentOrganisation();
  const ownerAdminOrgs = orgs.filter(org => org.role !== 'MEMBER');

  if (!orgs.length || !ownerAdminOrgs.length) {
    return <Redirect to={`${path}/create`} />;
  }
  if (ownerAdminOrgs.some(org => org.org.id === orgId)) {
    return <Redirect to={`${path}/${orgId}/settings`} />;
  }
  return <Redirect to={`${path}/${ownerAdminOrgs[0].org.id}/settings`} />;
};

export const OrganisationRoot = ({ match: { path } }) => {
  return (
    <Switch>
      <Route path={`${path}/:organisationId/settings`} component={OrganisationSettingsRoot} />
      <Route path={`${path}/create`} component={CreateOrganisationPage} />
      <Route exact path={`${path}`} component={OrganisationRootRedirect} />
    </Switch>
  );
};

OrganisationRootRedirect.propTypes = {
  match: PropTypes.object
};

OrganisationRoot.propTypes = {
  match: PropTypes.object
};

export default OrganisationRoot;
