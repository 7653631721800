/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  dateFormatter,
  formatSecondsToHoursDecimal,
  formatStandardDateStrings,
  fromTimestamp,
  getDuration
} from '../helpers/formatTime';
import { convertMetresTo } from '../components/Flying/Map/utils/helper';
import { useFlightStatus } from './useFlightStatus';
import { useTrackHistory } from './useTrackHistory';
import { getAircraftRegistrationLabel, getAircraftSosStatus } from '../util';
import { HistoryTableTrack, TrackFlightLog } from '../types/Track';
import { FlightStatus } from '../constants';

interface HistoryTableDataSource {
  id: string;
  key: string;
  orgId: string;
  aircraft: ReactNode;
  aircraftId: string;
  aircraftType: string | null;
  startTime: string;
  distance: string;
  rawDistance: number;
  sosStatus: string;
  flightReplay?: ReactNode;
  actions?: string;

  durationDecimalHours: string;
  formattedTrackDuration: string;
  unformattedTrackDuration: number;
  replayStatus: string;
  flightId: string;
  flightLog: TrackFlightLog | {};
  spiderType: string;
}

const epochToHHmm = (epochMiliSeconds: number, timeZone: string) => {
  const format = dateFormatter('HH:mm');
  const localMoment = fromTimestamp(epochMiliSeconds);
  const [localTime] = formatStandardDateStrings(format, localMoment, timeZone);
  return localTime;
};

export const useHistoryTableData = (
  filters: URLSearchParams,
  props: {
    dateFormat: string;
    timezone: string;
    distanceUnit: string;
  },
  immediate: boolean = true
) => {
  const [getTrackHistory, tracks, meta, loadingTrackHistory] = useTrackHistory(filters, immediate);
  const [tableData, setTableData] = useState<HistoryTableDataSource[]>(tracks);
  const [tracksWithStatus, flightStatusLoading, flightStatusLoadingError] = useFlightStatus(tracks);

  useMemo(() => {
    setTableData(
      tracks.map((track: HistoryTableTrack) => {
        const localMoment = fromTimestamp(track.departedTime);
        const formatFn = dateFormatter(props.dateFormat);
        const [localTime] = formatStandardDateStrings(formatFn, localMoment, props.timezone);
        const [convertedDistance, unit] = convertMetresTo(track.totalDistance, props.distanceUnit);
        const duration = getDuration(track.departedTime, track.endTime);
        const formattedTrackDuration = duration.formatted;
        const unformattedTrackDuration = duration.unformatted.asMilliseconds();
        const aircraftId = !!track.aircraft ? track.aircraft.id : 'Registration unavailable';
        const aircraftType = !!track.aircraft ? track.aircraft.type : null;
        const aircraftModel = !!track.aircraft ? track.aircraft.model : null;
        const distance = convertedDistance;
        const registration = getAircraftRegistrationLabel(track);
        const sosStatus = getAircraftSosStatus(track);

        return {
          flightLog: track.flightLog
            ? {
                engineOnTime:
                  track.flightLog.engineOnTime > 0
                    ? epochToHHmm(track.flightLog.engineOnTime, props.timezone)
                    : '',
                engineOffTime:
                  track.flightLog.engineOffTime > 0
                    ? epochToHHmm(track.flightLog.engineOffTime, props.timezone)
                    : '',
                firstTakeoffTime:
                  track.flightLog.firstTakeoffTime > 0
                    ? epochToHHmm(track.flightLog.firstTakeoffTime, props.timezone)
                    : '',
                lastLandingTime:
                  track.flightLog.lastLandingTime > 0
                    ? epochToHHmm(track.flightLog.lastLandingTime, props.timezone)
                    : '',
                lastBlocksOnTime:
                  track.flightLog.lastBlocksOnTime > 0
                    ? epochToHHmm(track.flightLog.lastBlocksOnTime, props.timezone)
                    : '',
                firstBlocksOffTime:
                  track.flightLog.firstBlocksOffTime > 0
                    ? epochToHHmm(track.flightLog.firstBlocksOffTime, props.timezone)
                    : '',
                totalLanding: track.flightLog.totalLanding > 0 ? track.flightLog.totalLanding : '',
                totalEngineStarts:
                  track.flightLog.totalEngineStarts > 0 ? track.flightLog.totalEngineStarts : '',
                blockTime:
                  track.flightLog.blockTime > 0
                    ? formatSecondsToHoursDecimal(track.flightLog.blockTime, 2)
                    : '',
                airTime:
                  track.flightLog.airTime > 0
                    ? formatSecondsToHoursDecimal(track.flightLog.airTime, 2)
                    : '',
                engineTime:
                  track.flightLog.engineTime > 0
                    ? formatSecondsToHoursDecimal(track.flightLog.engineTime, 2)
                    : '',
                total3rdPartyEngineStarts:
                  track.flightLog.total3rdPartyEngineStarts > 0
                    ? track.flightLog.total3rdPartyEngineStarts
                    : '',
                total3rdPartyEngineRuntimeSeconds:
                  track.flightLog.total3rdPartyEngineRuntimeSeconds > 0
                    ? formatSecondsToHoursDecimal(
                        track.flightLog.total3rdPartyEngineRuntimeSeconds,
                        2
                      )
                    : ''
              }
            : {},
          id: String(track.id),
          key: track.trackId,
          orgId: track.orgId,
          aircraft: registration,
          aircraftId,
          aircraftType,
          aircraftModel,
          startTime: localTime,
          distanceUnit: `${convertedDistance} ${unit}`,
          distance: distance,
          rawDistance: track.totalDistance,
          sosStatus,
          durationDecimalHours: duration.formattedHoursDecimal,
          formattedTrackDuration,
          unformattedTrackDuration,
          replayStatus: 'Loading',
          flightId: String(track.flightId),
          spiderType: track.spider.type
        };
      }, [])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta, tracks]);

  useEffect(() => {
    if (tableData.length && tracksWithStatus) {
      setTableData(
        tableData.map(row => {
          return { ...row, replayStatus: tracksWithStatus[row.id] ?? FlightStatus.NOT_AVAILABLE };
        })
      );
    }
  }, [tracksWithStatus]);
  const isLoading = loadingTrackHistory && flightStatusLoading;
  return [getTrackHistory, tableData, meta, isLoading, flightStatusLoadingError];
};
