import { createSlice } from '@reduxjs/toolkit';
import { FilterConfig, HistoryTracksState, TrackStatusPayload, TrackStatus } from '../types';

const initialState: HistoryTracksState = {
  trackStatus: [],
  filterConfig: {}
};

const addOrUpdateTrackStatus = (state: HistoryTracksState, trackStatus: TrackStatus) => {
  const existing = state.trackStatus.find(ts => ts.id === trackStatus.id);
  if (existing) {
    existing['3dReplayAvailable'] = trackStatus['3dReplayAvailable'];
  } else {
    state.trackStatus.push(trackStatus);
  }
};

export const historyTracksSlice = createSlice({
  name: 'historyTracks',
  initialState,
  reducers: {
    setTrackStatus: (state, action: { payload: TrackStatusPayload }) => {
      const actionPayload = action.payload;
      if (actionPayload.init) {
        state.trackStatus = [];
      }
      actionPayload.trackStatus.forEach(ts => addOrUpdateTrackStatus(state, ts));
    },
    setFilterConfig: (state, action: { payload: FilterConfig }) => {
      state.filterConfig = action.payload;
    },
    resetFilterConfig: state => {
      state.filterConfig = {};
    }
  }
});

export const { setTrackStatus, setFilterConfig, resetFilterConfig } = historyTracksSlice.actions;

export default historyTracksSlice;
