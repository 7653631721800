import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col, Select } from 'antd';
import { HDFlightTrackingSupportNoticeInfo } from '../../../helpers/globalNotifications';
import { compareSpiderSoftwareVersion } from '../../../helpers/compareVersion';
import {
  SpiderType,
  SpiderUpdatingType
} from '../../../common/api/spidertracks-sdk/types/SpiderSettings';

const MIN_LEGACY_SPIDER_SOFTWARE_VERSION_SUPPORTING_HD_FLIGHT_TRACKING = '3.0.0.4';
export const HD_FLIGHT_TRACKING = 'HD (Adaptive)';

const formItemStyle = { marginBottom: '1px' };

const { Option } = Select;

const SpiderSettingsReportIntervalDropdown = ({
  name,
  label,
  getFieldDecorator,
  setFieldsValue,
  validation,
  setSaveButtonDisabled,
  spiderType,
  spiderSoftwareVersion,
  spiderUpdatingType,
  disabled = false
}) => {
  const dropdownOptions = [
    {
      value: HD_FLIGHT_TRACKING,
      displayValue: HD_FLIGHT_TRACKING,
      disabled:
        spiderType !== SpiderType.SX &&
        compareSpiderSoftwareVersion(
          spiderSoftwareVersion,
          MIN_LEGACY_SPIDER_SOFTWARE_VERSION_SUPPORTING_HD_FLIGHT_TRACKING
        ) === -1
    },
    { value: 1, displayValue: '1 minute' },
    { value: 2, displayValue: '2 minutes' },
    { value: 10, displayValue: '10 minutes' }
  ];
  if (spiderType !== SpiderType.SX && spiderUpdatingType === SpiderUpdatingType.DISTANCE) {
    dropdownOptions.push({ value: 'N/A', displayValue: 'N/A', disabled: true });
  }

  const options = dropdownOptions.map(option => {
    return (
      <Option key={option.value} value={option.value} disabled={option.disabled}>
        {option.displayValue}
      </Option>
    );
  });

  const setInput = value => {
    setSaveButtonDisabled(false);
    setFieldsValue({ [name]: value });
    if (value === HD_FLIGHT_TRACKING) {
      HDFlightTrackingSupportNoticeInfo();
    }
  };

  return (
    <Form.Item name={name} label={label} style={formItemStyle}>
      <Row>
        <Col span={16}>
          {getFieldDecorator(name, {
            rules: [
              {
                required: validation.required,
                message: validation.errorMessage
              }
            ]
          })(
            <Select onSelect={setInput} size="large" disabled={disabled}>
              {options}
            </Select>
          )}
        </Col>
      </Row>
    </Form.Item>
  );
};

SpiderSettingsReportIntervalDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.object.isRequired,
  validation: PropTypes.object.isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
  setFieldsValue: PropTypes.func.isRequired,
  setSaveButtonDisabled: PropTypes.func,
  spiderType: PropTypes.string.isRequired,
  spiderSoftwareVersion: PropTypes.string.isRequired,
  spiderUpdatingType: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default SpiderSettingsReportIntervalDropdown;
