export const mapView = view => ({
  type: 'MAP_VIEW',
  view
});

export const savedFilter = selectedFilter => ({
  type: 'SAVED_FILTER',
  selectedFilter
});

export const weatherLayer = weather => ({
  type: 'WEATHER_LAYER',
  weather
});

export const setPointGroupingOption = option => ({
  type: 'GROUPING_OPTION',
  payload: option
});

export const selectedMarkerPoint = point => ({
  type: 'SELECTED_MARKER_POINT',
  point
});

export const setKmlData = kmlData => ({
  type: 'KML_DATA',
  kmlData
});

export const setActiveKmls = activeKmls => ({
  type: 'ACTIVE_KMLS',
  activeKmls
});

export const clearSelectedMarkerPoint = () => ({
  type: 'CLEAR_SELECTED_MARKER_POINT'
});

export const uiSettings = settings => ({
  type: 'USER_SETTINGS',
  settings
});

export const sosData = sos => ({
  type: 'SOS_DATA',
  sos
});

export const setAircraftListVisibility = visibility => ({
  type: 'AIRCRAFT_LIST_VISIBILITY',
  payload: visibility
});

export const mapZoomLevelChange = mapZoomLevel => ({
  type: 'MAP_ZOOM_LEVEL',
  mapZoomLevel
});
