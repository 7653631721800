import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { POINT_INFO_PANEL, TRACK_WITH_EVENTS } from '../GoogleMap/constants';
import EtaButtonContainer from './etapanel/EtaButtonContainer';
import {
  getSelectedTrack,
  getTrackPointsWithoutEvents,
  isFirstPointInTrack,
  isLastPointInTrack
} from '../../../../redux/selectors/aircraftData';
import { getDateTimeFormat, getLatLongFormat } from '../../../../redux/selectors/userData';
import {
  getPointGroupingOption,
  getSelectedMarkerPoint
} from '../../../../redux/selectors/mapData';
import { selectedMarkerPoint as selectedMarkerPointAction } from '../../../../redux/reducers/mapReducer/actions/map';
import { Header } from './Header';
import { Row, Col, Slider, Tooltip } from 'antd';
import InfoCircleFilled from '@ant-design/icons/InfoCircleFilled';
import '../../../../assets/scss/global.scss';
import PointInfoPanelButtons from './PointInfoPanelButtons';
import {
  formatStandardDateStrings,
  dateFormatter,
  fromTimestamp
} from '../../../../helpers/formatTime';
import { getTimezone } from '../../../../redux/selectors/userData';
import { debounce } from '../../../../helpers';
import { getSelectedTrackFlyingPageDisplayPoints } from '../../../../redux/selectors/eventData';
import { EventClass } from '../../../../constants';

const columnOne = 8;
const columnTwo = 16;

const RowInfo = ({ name, value, tooltipText, colorClassName }) => {
  return (
    <Row>
      <Col span={columnOne}>
        <Header>
          {tooltipText ? (
            <Tooltip placement="right" title={tooltipText} trigger="click">
              {name} <InfoCircleFilled style={{ fontSize: 10, color: 'gray' }} />
            </Tooltip>
          ) : (
            <div>{name}</div>
          )}
        </Header>
      </Col>
      <Col className={colorClassName || ''} span={columnTwo}>
        {value}
      </Col>
    </Row>
  );
};

const isPropertyPresent = (selectedMarkerPoint, property) => {
  if (selectedMarkerPoint === undefined) {
    return false;
  }
  if (property in selectedMarkerPoint) {
    return selectedMarkerPoint[property] !== undefined && selectedMarkerPoint[property] !== null;
  }
  return false;
};

const isNumericPropertyPresent = (selectedMarkerPoint, property) => {
  return isPropertyPresent(selectedMarkerPoint, property) && !isNaN(selectedMarkerPoint[property]);
};

export const PointInfoPanel = ({
  selectedMarkerPoint,
  selectedTrack,
  dateFormat,
  timezone,
  isFirstPointInTrack,
  isLastPointInTrack,
  setNextPoint,
  setPreviousPoint,
  setFirstPoint,
  setLastPoint,
  setPoint,
  isActive,
  selectedMarkerPointIndex,
  maxPointIndex
}) => {
  if (!selectedMarkerPoint || !selectedTrack) {
    return null;
  }

  const { aircraft } = selectedTrack;
  const localMoment = fromTimestamp(selectedMarkerPoint.timestamp);
  const formatFn = dateFormatter(dateFormat);
  const [localTime, utcTime] = formatStandardDateStrings(formatFn, localMoment, timezone);
  const isSliderVisible = isFirstPointInTrack && isLastPointInTrack;

  const hasCoordinates =
    isPropertyPresent(selectedMarkerPoint, 'coordinates') &&
    !!selectedMarkerPoint.coordinates.value.length;

  const hasAltitude = isNumericPropertyPresent(selectedMarkerPoint, 'altitude');
  const hasSpeed = isNumericPropertyPresent(selectedMarkerPoint, 'speed');
  const hasHeading = isNumericPropertyPresent(selectedMarkerPoint, 'heading');
  const hasHeightAgl = isNumericPropertyPresent(selectedMarkerPoint, 'heightAgl');
  const isFSIEvent =
    isPropertyPresent(selectedMarkerPoint, 'type') && selectedMarkerPoint['type'] === 'fsi';
  const hasThresholdsAndValues =
    isFSIEvent &&
    isPropertyPresent(selectedMarkerPoint, 'thresholdDisplayString') &&
    isPropertyPresent(selectedMarkerPoint, 'valueDisplayString');
  const hasDescription =
    isPropertyPresent(selectedMarkerPoint, 'description') &&
    selectedMarkerPoint.description.length > 0;
  const descriptionKey = isFSIEvent ? POINT_INFO_PANEL.fsiEventName : POINT_INFO_PANEL.description;
  const descriptionValue = isPropertyPresent(selectedMarkerPoint, 'displayName')
    ? selectedMarkerPoint['displayName']
    : selectedMarkerPoint.description;
  const isCASEvent =
    isPropertyPresent(selectedMarkerPoint, 'type') && selectedMarkerPoint.type === EventClass.CAS;
  const hasAlert = isCASEvent && isPropertyPresent(selectedMarkerPoint, 'alert');

  const eventData = selectedMarkerPoint.eventData;

  return (
    <Fragment>
      <RowInfo name={POINT_INFO_PANEL.header} value={aircraft.registration} />
      <RowInfo name={POINT_INFO_PANEL.time} value={localTime} />
      <RowInfo value={utcTime} />
      {hasCoordinates && (
        <RowInfo
          name={POINT_INFO_PANEL.position}
          value={selectedMarkerPoint.coordinates.value.join(' ')}
        />
      )}
      {hasAltitude && (
        <RowInfo
          name={POINT_INFO_PANEL.altitude}
          value={`${selectedMarkerPoint.altitude} ${selectedMarkerPoint.altitudeUnit}`}
          tooltipText="Altitude above mean sea level (AMSL)"
        />
      )}
      {hasHeightAgl && (
        <RowInfo
          name={POINT_INFO_PANEL.heightAgl}
          value={`${selectedMarkerPoint.heightAgl} ${selectedMarkerPoint.altitudeUnit}`}
          tooltipText="Altitude above ground level (AGL)"
        />
      )}
      {hasSpeed && (
        <RowInfo
          name={POINT_INFO_PANEL.speed}
          value={`${selectedMarkerPoint.speed} ${selectedMarkerPoint.speedUnit}`}
          tooltipText="GPS speed over ground"
        />
      )}
      {hasHeading && (
        <RowInfo
          name={POINT_INFO_PANEL.direction}
          value={`${selectedMarkerPoint.heading}°${POINT_INFO_PANEL.degree}`}
        />
      )}
      {hasAlert && (
        <RowInfo
          name={POINT_INFO_PANEL.alert}
          value={selectedMarkerPoint.alert}
          colorClassName={selectedMarkerPoint.alertDisplayColorClass}
        />
      )}
      {hasDescription && <RowInfo name={descriptionKey} value={descriptionValue} />}
      {eventData?.map(e => {
        return <RowInfo key={e.key} name={e.key} value={e.value} />;
      })}
      {hasThresholdsAndValues && (
        <RowInfo
          name={POINT_INFO_PANEL.fsiEventThreshold}
          value={selectedMarkerPoint.thresholdDisplayString}
          tooltipText="The threshold for this Event at the time it was triggered"
        />
      )}
      {hasThresholdsAndValues && (
        <RowInfo
          name={POINT_INFO_PANEL.fsiEventValue}
          value={selectedMarkerPoint.valueDisplayString}
          tooltipText="The value of the flight parameter when the Event was triggered"
        />
      )}
      {isActive && <EtaButtonContainer />}
      {!isSliderVisible && (
        <Fragment>
          <Slider
            min={0}
            max={maxPointIndex}
            value={selectedMarkerPointIndex}
            onChange={debounce(setPoint, 50)}
            tipFormatter={null}
          />
          <PointInfoPanelButtons
            isFirstPointInTrack={isFirstPointInTrack}
            isLastPointInTrack={isLastPointInTrack}
            setNextPoint={setNextPoint}
            setPreviousPoint={setPreviousPoint}
            setFirstPoint={setFirstPoint}
            setLastPoint={setLastPoint}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

RowInfo.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  tooltipText: PropTypes.string
};

PointInfoPanel.propTypes = {
  selectedMarkerPoint: PropTypes.object,
  selectedTrack: PropTypes.object,
  dateFormat: PropTypes.string,
  timezone: PropTypes.string,
  latLongFormat: PropTypes.string,
  isFirstPointInTrack: PropTypes.bool,
  isLastPointInTrack: PropTypes.bool,
  setNextPoint: PropTypes.func,
  setPreviousPoint: PropTypes.func,
  setFirstPoint: PropTypes.func,
  setLastPoint: PropTypes.func,
  setPoint: PropTypes.func,
  isActive: PropTypes.bool,
  selectedMarkerPointIndex: PropTypes.number,
  maxPointIndex: PropTypes.number,
  colorClassName: PropTypes.string
};

PointInfoPanel.defaultProps = {
  disabledControl: '',
  isFirstPointInTrack: false,
  isLastPointInTrack: false
};

const mapStateToProps = (state, { displayEventClasses, strictPointGrouping }) => {
  const selectedMarkerPoint = getSelectedMarkerPoint(state);
  const selectedTrack = getSelectedTrack(state);

  const showEvents =
    getPointGroupingOption(state) === TRACK_WITH_EVENTS || strictPointGrouping.displayFullTrack;

  return {
    selectedTrack,
    selectedMarkerPoint,
    ...(selectedTrack
      ? {
          trackPoints:
            displayEventClasses.length && showEvents
              ? getSelectedTrackFlyingPageDisplayPoints(state)
              : getTrackPointsWithoutEvents(selectedTrack),
          isFirstPointInTrack: isFirstPointInTrack(selectedTrack, selectedMarkerPoint),
          isLastPointInTrack: isLastPointInTrack(selectedTrack, selectedMarkerPoint),
          isActive: selectedTrack.isActive
        }
      : {}),
    dateFormat: getDateTimeFormat(state),
    timezone: getTimezone(state),
    latLongFormat: getLatLongFormat(state)
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const getIndexOfPointInTrack = (trackPoints, point) => {
    if (!trackPoints || !point) {
      return -1;
    }

    return trackPoints.findIndex(p => p.id === point.id);
  };
  const selectedMarkerPointIndex = getIndexOfPointInTrack(
    stateProps.trackPoints,
    stateProps.selectedMarkerPoint
  );
  const maxPointIndex = stateProps.trackPoints ? stateProps.trackPoints.length - 1 : 0;

  const setNextPoint = () => {
    return dispatchProps.setSelectedMarkerPoint(
      stateProps.trackPoints[
        getIndexOfPointInTrack(stateProps.trackPoints, stateProps.selectedMarkerPoint) + 1
      ]
    );
  };

  const setPreviousPoint = () => {
    const index = getIndexOfPointInTrack(stateProps.trackPoints, stateProps.selectedMarkerPoint);
    return dispatchProps.setSelectedMarkerPoint(stateProps.trackPoints[index - 1]);
  };

  const setFirstPoint = () => dispatchProps.setSelectedMarkerPoint(stateProps.trackPoints[0]);

  const setLastPoint = () =>
    dispatchProps.setSelectedMarkerPoint(stateProps.trackPoints[stateProps.trackPoints.length - 1]);

  const setPoint = pointIndex =>
    dispatchProps.setSelectedMarkerPoint(stateProps.trackPoints[pointIndex]);

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    setNextPoint,
    setPreviousPoint,
    setFirstPoint,
    setLastPoint,
    setPoint,
    selectedMarkerPointIndex,
    maxPointIndex
  };
};

export default connect(
  mapStateToProps,
  { setSelectedMarkerPoint: selectedMarkerPointAction },
  mergeProps
)(PointInfoPanel);
