import styled from 'styled-components';
import * as polished from 'polished';

const whiteColor = '#ffffff';
const blackColor = '#000000';

export const FilterContainer = styled.div`
  font-size: 14px;
  border-radius: 5px;
  background: ${whiteColor};
  box-shadow: 0px 0px 18px 2px ${polished.rgba(blackColor, 0.18)};
`;

export const FilterMenuItem = styled.a`
  position: relative;
  display: block;
  width: 100%;
  padding: 8px 10px;
  line-height: 18px;
  vertical-align: bottom;

  &:hover {
    background-color: ${polished.darken(0.1, whiteColor)};
  }
`;
