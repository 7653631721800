import styled from 'styled-components';

export const MenuIcon = styled.span`
  background-color: ${({ active }) => (active ? 'grey' : '#fff')};
  cursor: pointer;
  width: auto;
  height: auto;
  position: relative;
  padding: 7px 7px 1px;
  border-radius: 50%;
  float: left;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.23), 0 3px 10px rgba(0, 0, 0, 0.16);
  margin: 5px;
`;
