import React, { FC } from 'react';
import styled from 'styled-components';
import NoAccessIcon from '../../../assets/no-access.svg';

const Centred = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LiftedNotice = styled(Centred)`
  height: 100%;
  margin: 1rem;
  margin-top: -3rem;
  text-align: center;
`;

const H = styled.h1`
  font-size: 2rem;
`;

const IconWrapper = styled.div`
  margin-bottom: 1.5rem;
`;

const LimitWidth = styled.div`
  max-width: 50rem;
`;

export const AccessDenied: FC = ({ children }) => (
  <LiftedNotice>
    <IconWrapper>
      <NoAccessIcon />
    </IconWrapper>
    <LimitWidth>
      <H>Access Denied</H>
      {children}
    </LimitWidth>
  </LiftedNotice>
);

export default AccessDenied;
