export enum SpiderType {
  SX = 'SX',
  S1 = 'S1',
  S2 = 'S2',
  S3 = 'S3',
  S3_LITE = 'S3_LITE',
  S4 = 'S4',
  S5 = 'S5',
  S6 = 'S6',
  S7 = 'S7',
  S8 = 'S8',
  DEV_KIT_SPIDER = 'DEV_KIT_SPIDER',
  FLIGHTCELL_DZM = 'FLIGHTCELL_DZM',
  UNSET = 'UNSET'
}
export enum SpiderUpdatingType {
  DISTANCE = 'DISTANCE',
  SPIDER_WATCH = 'SPIDER_WATCH',
  TIME = 'TIME',
  TIME_DISTANCE = 'TIME_DISTANCE'
}

export interface SpiderSettings {
  version: number;
  settings: {
    horizontalSpeed_1EntryTriggerMpsE1: number;
    verticalSpeedClimbEntryTriggerMpsE1: number;
    verticalSpeedDescentEntryTriggerMpsE1: number;
    headingTriggerDegrees: number;
    timeTriggerPrimaryS: number;
  };
  spiderType: SpiderType;
  spiderSoftwareVersion: string;
  spiderUpdatingType: SpiderUpdatingType;
}

export enum SpiderGoStatus {
  AVAILABLE = 'Available',
  ACTIVE = 'Active',
  UNKNOWN = 'Unknown'
}

export interface SpiderStatusResponse {
  go: SpiderGoStatus;
  iridium: string;
  iridiumStatus: string;
  cellular: string;
  imei: string;
  type: string;
  plan: string;
  firmwareVersion: string;
}
