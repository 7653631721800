import React from 'react';
import SisenseFilters from './filters/SisenseFilters';
import InsightsDashboardExportButton from './InsightsDashboardExportButton';
import { PageHeader } from 'antd';

export const InsightsHeader = () => {
  return (
    <div className="mb-1" style={{ paddingLeft: '20px' }}>
      <PageHeader
        className="site-page-header"
        style={{ paddingLeft: '0px' }}
        title="Insights"
        subTitle="View and manage flight insights"
      />
      <SisenseFilters
        datasource="safety_events_production_data_model"
        aircraftdim="[joined_safety_events.vehicle_registration_number]"
        eventTypeDim="[joined_safety_events.event_name]"
      />
      <InsightsDashboardExportButton />
    </div>
  );
};

InsightsHeader.displayName = 'InsightsHeader';
export default InsightsHeader;
