import { ServiceAbstract } from '../../ServiceAbstract';
import { EventConfiguration } from '../../../../../types/EventConfiguration';
import { KeyIndex } from '../../../../../types/KeyIndex';
import { Amplify } from '@spidertracks/common';
import { updateFlightEventConfigurationError } from '../../../../../helpers';

const operations: KeyIndex = { lt: 'less than', gt: 'greater than' };

export interface Parameter {
  op: string;
  unit: string;
  displayName: string;
  name: string;
  maxDisplayValue: number;
  displayUnit: string;
  minDisplayValue: number;
  type: string;
}

export class FlightEventConfigurationsService extends ServiceAbstract {
  public async getEventConfigurations(organisationId: string): Promise<EventConfiguration[]> {
    try {
      const result = await Amplify.API.get(
        'authenticated',
        `flight-events/configuration?organisationId=${organisationId}`
      );

      const configs = result.items.map((config: EventConfiguration) => {
        const rules = config._metadata.parameters.map((param: Parameter, rowKey: number) => {
          const {
            name,
            displayName,
            displayUnit,
            op,
            type,
            minDisplayValue,
            maxDisplayValue
          } = param;

          return {
            rowKey,
            name,
            operation: operations[op],
            displayName: displayName,
            displayValue: config.displayThresholds[name],
            displayUnit,
            type,
            minDisplayValue,
            maxDisplayValue
          };
        });

        return {
          ...config,
          rules
        };
      });
      return configs;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  public async updateEventConfigurations(
    organisationId: string,
    config: EventConfiguration
  ): Promise<EventConfiguration> {
    let result;

    try {
      result = await Amplify.API.put(
        'authenticated',
        `flight-events/configuration?organisationId=${organisationId}`,
        { body: config }
      );
    } catch (e) {
      console.log(e);
      updateFlightEventConfigurationError();
    }
    return result;
  }
}
