export class LollipopIcon {
  private iconSvg: string;
  private iconScale: number;
  public readonly boundingBoxWidth: number;
  public readonly boundingBoxHeight: number;
  public readonly iconWidth: number;
  public readonly iconHeight: number;
  private strokeWidth: number;
  public readonly spikeHeight: number;
  private color: string;

  public constructor(iconSvg: string, iconScale: number, color: string = '#BAD928') {
    this.iconSvg = iconSvg;
    this.iconScale = iconScale;
    this.color = color;
    this.spikeHeight = 40;
    this.strokeWidth = 5;
    const s = this.getSvgSize();
    this.iconWidth = s.width;
    this.iconHeight = s.height;
    this.boundingBoxHeight = s.height + this.spikeHeight;
    this.boundingBoxWidth = s.width;
  }

  private getSvgSize() {
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(this.iconSvg, 'image/svg+xml');
    const svgElement = (svgDoc.documentElement as unknown) as SVGGraphicsElement;

    let width = svgElement.getAttribute('width');
    let height = svgElement.getAttribute('height');

    // If width and height are not set, calculate the bounding box
    if (!width || !height) {
      const bbox = svgElement.getBBox();
      width = bbox.width.toString();
      height = bbox.height.toString();
    }

    return {
      width: parseFloat(width) * this.iconScale,
      height: parseFloat(height) * this.iconScale
    };
  }

  public generateSVG(): string {
    return `
      <svg width="${this.boundingBoxWidth}" height="${
      this.boundingBoxHeight
    }" xmlns="http://www.w3.org/2000/svg">
        <line x1='${this.boundingBoxWidth / 2}' y1='${this.boundingBoxHeight}' x2='${this
      .boundingBoxWidth / 2}' y2='${this.boundingBoxHeight - this.spikeHeight}' style="stroke:${
      this.color
    };stroke-width:${this.strokeWidth}" />
        <g transform="scale(${this.iconScale})" >
            ${this.iconSvg}
        </g>
      </svg>
    `;
  }
}
