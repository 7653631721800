import React from 'react';
import PropTypes from 'prop-types';
import TimelineItem from 'antd/lib/timeline/TimelineItem';
import './styles.scss';
import { DisplayPoint } from '../../../../common/api/spidertracks-sdk/types/TrackData';
import { EventClass } from '../../../../constants';

interface EventRowProps {
  name: string;
  time: string;
  encodedIconUrl: string;
  point: DisplayPoint;
  isSelectedEvent: boolean;
  isSimulatedEvent: boolean;
  handlePointSelection: (point: DisplayPoint) => void;
  scrollTargetDivNext?: React.MutableRefObject<HTMLDivElement | null>;
  scrollTargetDivPrevious?: React.MutableRefObject<HTMLDivElement | null>;
}

const hasProperty = (selectedMarkerPoint: DisplayPoint, key: string) => {
  if (selectedMarkerPoint === undefined) {
    return false;
  }
  const p = selectedMarkerPoint[key as keyof DisplayPoint];
  return !!(p || typeof p === 'string');
};

const EventRow: React.FC<EventRowProps> = ({
  name,
  time,
  encodedIconUrl,
  point,
  isSelectedEvent,
  isSimulatedEvent,
  handlePointSelection,
  scrollTargetDivNext,
  scrollTargetDivPrevious
}) => {
  const { eventData } = point;
  const isFSIEvent = hasProperty(point, 'type') && point.type === EventClass.FSI;

  const hasDescription =
    hasProperty(point, 'description') && point.description && point.description.length > 0;
  const descriptionValue = hasProperty(point, 'displayName')
    ? point.displayName
    : point.description;

  const hasThresholdsAndValues =
    isFSIEvent &&
    hasProperty(point, 'thresholdDisplayString') &&
    hasProperty(point, 'valueDisplayString');

  const hasThresholdsAndValuesAndSeverity =
    isFSIEvent &&
    hasProperty(point, 'thresholdDisplayString') &&
    hasProperty(point, 'valueDisplayString') &&
    hasProperty(point, 'durationDisplayString') &&
    hasProperty(point, 'peakBreachValueDisplayString') &&
    hasProperty(point, 'severityDisplayString');

  return (
    <div
      id={
        scrollTargetDivPrevious
          ? 'scrollTargetDivPrevious'
          : scrollTargetDivNext
          ? 'scrollTargetDivNext'
          : undefined
      }
      className={`eventTimelineRow ${isSelectedEvent ? 'eventTimelineRowSelected' : ''}`}
      ref={scrollTargetDivPrevious || scrollTargetDivNext}
    >
      <div className="time" onClick={() => handlePointSelection(point)}>
        {time}
      </div>
      <TimelineItem
        data-testid={`timeline-item-${point.id}`}
        className="timeline-item"
        color="gray"
        dot={
          name === 'Start of Track' || name === 'Latest Point' ? null : (
            <img
              data-testid={`event-icon-${point.id}`}
              className="event-icon"
              alt="event-icon"
              src={encodedIconUrl}
              onClick={() => handlePointSelection(point)}
            />
          )
        }
      >
        <div className="textContainer">
          <div className="name" onClick={() => handlePointSelection(point)}>
            {name}
          </div>
          {!isSimulatedEvent && hasDescription && !isFSIEvent && name !== descriptionValue && (
            <div className="additional">{descriptionValue}</div>
          )}
          {!isSimulatedEvent &&
            eventData?.map((e: { key: string; value: string }) => {
              return (
                <div key={e.key} className="additional">
                  {e.key}: {e.value}
                </div>
              );
            })}
          {!isSimulatedEvent && hasThresholdsAndValues && !hasThresholdsAndValuesAndSeverity && (
            <>
              <div className="additional">Value: {point.valueDisplayString}</div>
              <div className="additional">Threshold: {point.thresholdDisplayString}</div>
            </>
          )}
          {!isSimulatedEvent && hasThresholdsAndValuesAndSeverity && (
            <>
              <div className="additional">
                Peak Exceedance: {point.peakBreachValueDisplayString}
              </div>
              <div className="additional">Severity: {point.severityDisplayString}</div>
              <div className="additional">Threshold: {point.thresholdDisplayString}</div>
              <div className="additional">Exceedance Duration: {point.durationDisplayString}</div>
            </>
          )}
        </div>
      </TimelineItem>
    </div>
  );
};

EventRow.propTypes = {
  name: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  encodedIconUrl: PropTypes.string.isRequired,
  point: PropTypes.any.isRequired,
  isSelectedEvent: PropTypes.bool.isRequired,
  isSimulatedEvent: PropTypes.bool.isRequired,
  handlePointSelection: PropTypes.func.isRequired,
  scrollTargetDivNext: PropTypes.any,
  scrollTargetDivPrevious: PropTypes.any
};

export default EventRow;
