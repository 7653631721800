import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

export const GeofenceActionTableCell = ({ value, onClick, text }) => {
  const onDelete = useCallback(
    e => {
      e.stopPropagation();
      onClick(value);
    },
    [onClick, value]
  );

  return (
    <Button type="link" onClick={onDelete}>
      {text}
    </Button>
  );
};

GeofenceActionTableCell.propTypes = {
  text: PropTypes.string,
  value: PropTypes.string,
  onClick: PropTypes.func
};

export default GeofenceActionTableCell;
