import React from 'react';
import { Radio } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LAST_POINT, TRACK_ONLY, TRACK_WITH_EVENTS } from '../../GoogleMap/constants';
import { getInstance } from '../../../../../common/api/spidertracks-sdk';
import { UserPreferenceKey } from '../../../../../common/api/spidertracks-sdk/types/UserData';
import { setPointGroupingOption } from '../../../../../redux/reducers/mapReducer/actions/map';
import { gaEventHelper } from '@spidertracks/common';
import { getPointGroupingOption } from '../../../../../redux/selectors/mapData';

const gaEventSender = gaEventHelper('MapControls - Show Tracks');
const RadioGroup = Radio.Group;
const radioGroupData = [
  {
    id: 1,
    name: 'Latest Point',
    analytics: {
      eventAction: 'Show Latest Point ',
      eventLabel: 'pointGroupingOption'
    },
    value: LAST_POINT
  },
  {
    id: 2,
    name: 'Track',
    analytics: {
      eventAction: 'Show Track ',
      eventLabel: 'pointGroupingOption'
    },
    value: TRACK_ONLY
  },
  {
    id: 3,
    name: 'All Events',
    analytics: {
      eventAction: 'Show Points + Events ',
      eventLabel: 'pointGroupingOption'
    },
    value: TRACK_WITH_EVENTS
  }
];

export const PointGroupingOptionsContainer = ({ pointGroupingOption, setPointGroupingOption }) => {
  const sendGAEvent = (eventAction, eventLabel) => {
    gaEventSender(eventAction, eventLabel);
  };

  const onChange = e => {
    const { value } = e.target;
    const gaAnalytics = radioGroupData.find(element => element.value === value).analytics;

    setPointGroupingOption(value);
    getInstance().saveUserPreference(UserPreferenceKey.GROUPING_OPTION, {
      pointGroupingOption: value
    });
    sendGAEvent(gaAnalytics.eventAction, gaAnalytics.eventLabel);
  };

  return (
    <span role="button" tabIndex={0}>
      <RadioGroup
        className="pointGroupingOption-radio"
        value={pointGroupingOption}
        onChange={onChange}
      >
        {radioGroupData.map(radio => {
          return (
            <div key={radio.id} className="pointGroupingOptions-wrapper ">
              <Radio value={radio.value}>{radio.name}</Radio>
            </div>
          );
        })}
      </RadioGroup>
    </span>
  );
};

PointGroupingOptionsContainer.propTypes = {
  pointGroupingOption: PropTypes.string,
  setPointGroupingOption: PropTypes.func
};

const mapStateToProps = state => ({
  pointGroupingOption: getPointGroupingOption(state)
});

const mapDispatchToProps = {
  setPointGroupingOption
};

export default connect(mapStateToProps, mapDispatchToProps)(PointGroupingOptionsContainer);
