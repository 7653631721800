import React from 'react';
import { Tooltip } from 'antd';
import { MenuIcon } from '../MenuIcon';
import DownloadIcon from '../../../../../assets/download.svg';
import { GOOGLE_MAP_CONTAINER } from '../../GoogleMap/constants';
import { DownloadModalContext } from '../../../../../context/DownloadModalContext';

const DownloadTracksButton = () => {
  return (
    <DownloadModalContext.Consumer>
      {({ setModalVisible }) => {
        return (
          <div className="map-control">
            <Tooltip
              placement="left"
              title="Download"
              onClick={() => {
                setModalVisible(true);
              }}
              getPopupContainer={() => document.getElementById(GOOGLE_MAP_CONTAINER)}
            >
              <MenuIcon>
                <DownloadIcon />
              </MenuIcon>
            </Tooltip>
          </div>
        );
      }}
    </DownloadModalContext.Consumer>
  );
};

export default DownloadTracksButton;
