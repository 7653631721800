import { HAZARD_DISPLAY_PROPS, SIGAIRMET_TYPES, LAT_BOUNDS } from './constants';

export interface PolygonCoordinate {
  lat: number;
  lng: number;
}

export interface SigAirmetData {
  icao: {
    id: string;
    [key: string]: unknown;
  };
  type: string;
  hazard: {
    type: string;
    severity?: string;
    [key: string]: unknown;
  };
  region: {
    id: string;
    [key: string]: unknown;
  };
  sequence: string;
  issuetime: string;
  valid_begin: string;
  valid_end: string;
  geometry?: {
    type: string;
    coordinates: number[][][];
  };
  raw_text: string;
}

export const getPolygonCoordinates = (sigAirmet: SigAirmetData) => {
  if (sigAirmet.geometry == undefined) {
    return [];
  }

  const coordinates = sigAirmet.geometry?.coordinates[0];

  const polygonCoordinates = [];
  for (const coordinate of coordinates) {
    let lat = coordinate[1];
    if (lat < LAT_BOUNDS.min) {
      lat = LAT_BOUNDS.min;
    } else if (lat > LAT_BOUNDS.max) {
      lat = LAT_BOUNDS.max;
    }
    const lng = coordinate[0];

    polygonCoordinates.push({ lat, lng });
  }

  return polygonCoordinates;
};

export const getPolygonCenter = (polygonCoordinates: PolygonCoordinate[]) => {
  const polygon = new google.maps.Polygon({
    paths: polygonCoordinates
  });
  var bounds = new google.maps.LatLngBounds();
  for (let i = 0; i < polygonCoordinates.length; i++) {
    const coordinates = polygonCoordinates[i];
    bounds.extend(new google.maps.LatLng(coordinates.lat, coordinates.lng));
  }
  const center = bounds.getCenter();

  const centerInsidePolygon = google.maps.geometry.poly.containsLocation(center, polygon);
  if (!centerInsidePolygon) {
    return getAdjustedPolygonCenter(bounds, polygon);
  }

  return center;
};

const getAdjustedPolygonCenter = (
  polygonBounds: google.maps.LatLngBounds,
  polygon: google.maps.Polygon
) => {
  const maxSearchSteps = 10;

  const northWest = new google.maps.LatLng(
    polygonBounds.getNorthEast().lat(),
    polygonBounds.getSouthWest().lng()
  );
  const polygonHeight = google.maps.geometry.spherical.computeDistanceBetween(
    northWest,
    polygonBounds.getSouthWest()
  );
  const heightIncrement = polygonHeight / maxSearchSteps;

  const polygonWidth = google.maps.geometry.spherical.computeDistanceBetween(
    northWest,
    polygonBounds.getNorthEast()
  );
  const widthIncrement = polygonWidth / maxSearchSteps;

  const originalCenter = polygonBounds.getCenter();
  let adjustedCenter = originalCenter;
  for (let i = 0; i <= maxSearchSteps; i++) {
    adjustedCenter = google.maps.geometry.spherical.computeOffset(
      originalCenter,
      heightIncrement * i,
      0
    );
    if (google.maps.geometry.poly.containsLocation(adjustedCenter, polygon)) {
      break;
    }

    adjustedCenter = google.maps.geometry.spherical.computeOffset(
      originalCenter,
      widthIncrement * i,
      90
    );
    if (google.maps.geometry.poly.containsLocation(adjustedCenter, polygon)) {
      break;
    }

    adjustedCenter = google.maps.geometry.spherical.computeOffset(
      originalCenter,
      heightIncrement * i,
      180
    );
    if (google.maps.geometry.poly.containsLocation(adjustedCenter, polygon)) {
      break;
    }

    adjustedCenter = google.maps.geometry.spherical.computeOffset(
      originalCenter,
      widthIncrement * i,
      270
    );
    if (google.maps.geometry.poly.containsLocation(adjustedCenter, polygon)) {
      break;
    }
  }

  return adjustedCenter;
};

export const getFilteredSigAirmetData = (sigAirmets: SigAirmetData[]) => {
  return sigAirmets.filter(
    data =>
      data.geometry &&
      data.geometry?.type === 'Polygon' &&
      data.hazard.type &&
      HAZARD_DISPLAY_PROPS.hasOwnProperty(data.hazard.type) &&
      SIGAIRMET_TYPES.includes(data.type.toUpperCase())
  );
};
