import React from 'react';
import PropTypes from 'prop-types';

import TimelineRow from './FlightEventTimelineRow';

import './styles/scss/styles.scss';

const FlightEventTimeline = ({ timelineEvents }) => {
  return (
    <div className="event-timeline">
      {timelineEvents.map((event, index, events) => {
        // Handle the vertical line for the first and last icon
        let classNames = '';
        if (index === 0 && events.length > 1) {
          classNames = 'vertical-line-start';
        } else if (index === events.length - 1 && events.length > 1) {
          classNames = 'vertical-line-finish';
        }

        return (
          <TimelineRow
            key={index}
            encodedIconUrl={event.iconUrl}
            name={event.name}
            time={event.time}
            classNames={classNames}
          />
        );
      })}
    </div>
  );
};

FlightEventTimeline.propTypes = {
  timelineEvents: PropTypes.array
};

export default FlightEventTimeline;
