import React from 'react';
import styled from 'styled-components';
import { ParameterGroupContainer } from './ParameterGroupContainer';
import { getUserData } from '../../../../redux/selectors/userData';
import { useSelector } from 'react-redux';

const ParameterGroupsWrapper = styled.div`
  padding-top: 2em;
  padding-left: 0.5em;
  display: flex;
  flex-direction: column;
`;

export const ChartParameters = () => {
  const userData = useSelector(getUserData);

  return (
    <ParameterGroupsWrapper className={'parameters-container'}>
      <ParameterGroupContainer
        chartAxis={'LEFT'}
        userData={userData}
        defaultParameterGroup="Altitude"
      />
      <ParameterGroupContainer
        chartAxis={'RIGHT'}
        userData={userData}
        defaultParameterGroup="Speed"
      />
    </ParameterGroupsWrapper>
  );
};
