import React from 'react';
import PropTypes from 'prop-types';
import { Delete } from '@material-ui/icons';
import { Button } from 'antd';
import { boldFont } from '../../common/styles';

export const EventsConfigTableHeader = ({ selectedEventRules = [], onDeleteClick }) => {
  return (
    <table
      style={{
        width: '100%',
        height: '3.5em'
      }}
    >
      <thead>
        <tr>
          <th style={{ width: '100px' }}>
            {!!selectedEventRules.length && (
              <div style={boldFont}>{selectedEventRules.length} Selected</div>
            )}
          </th>
          <th style={{ width: '100px' }}>
            <div style={{ verticalAlign: 'middle', display: 'inline-flex' }}></div>
          </th>
          <th style={{ textAlign: 'end', maxWidth: '100px' }}>
            {!!selectedEventRules.length && (
              <Button
                style={{ padding: '0' }}
                type="link"
                disabled={!selectedEventRules.length}
                onClick={onDeleteClick}
              >
                <span
                  style={{
                    verticalAlign: 'middle',
                    display: 'inline-flex',
                    ...boldFont
                  }}
                >
                  <Delete />
                  <span className="delete-row-items" style={{ paddingTop: '2px' }}>
                    Delete
                  </span>
                </span>
              </Button>
            )}
          </th>
        </tr>
      </thead>
    </table>
  );
};

EventsConfigTableHeader.displayName = 'EventsConfigTableHeader';

EventsConfigTableHeader.propTypes = {
  selectedEventRules: PropTypes.array,
  onDeleteClick: PropTypes.func
};
