// filter states and track states and aircraft statuses
export const AZ = 'ALPHABETICAL';
export const ACTIVE = 'ACTIVE';
export const LATEST = 'LATEST';
export const SOS = 'SOS';
export const INACTIVE = 'INACTIVE';
export const SPIDERWATCH = 'SPIDERWATCH';
export const NORMAL = 'NORMAL';
export const DELETED = 'DELETED';

export const GREEN = '#BAD928';
export const RED = '#DE0B0D';
export const BLUE = '#00A8D5';
export const __BLUE = '#00A8D5';

export interface AircraftListFilter {
  id: number;
  text: string;
  value: 'ALPHABETICAL' | 'LATEST' | 'ACTIVE';
}

export const FILTERS: AircraftListFilter[] = [
  {
    id: 1,
    text: 'A-Z',
    value: AZ
  },
  {
    id: 2,
    text: 'Latest',
    value: LATEST
  },
  {
    id: 3,
    text: 'Active',
    value: ACTIVE
  }
];

export const COLUMN_ONE = {
  dataIndex: 'aircraft'
};

export default {
  AZ,
  ACTIVE,
  LATEST,
  SOS,
  INACTIVE,
  SPIDERWATCH,
  NORMAL,
  DELETED,
  FILTERS,
  COLUMN_ONE
};
