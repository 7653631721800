import React from 'react';
import { Icon } from 'antd';

export const CloseIcon = () => {
  return (
    <div>
      <Icon type="close" style={{ fontSize: '14px' }} />
    </div>
  );
};
