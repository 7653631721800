import React, { useEffect } from 'react';
import { Form, Drawer } from 'antd';
import PropTypes from 'prop-types';
import { FormComponentProps } from 'antd/lib/form';
import { NotificationForm, NotificationConfiguration } from './NotificationForm';

import { EventRule } from './EventRuleSelector';
import { User } from '../../common/form/UserSelector';
import { AircraftDetails } from '../../../types/aircraft';
import { useLocation, useHistory } from 'react-router';

interface NotificationDrawerProps extends FormComponentProps {
  initialData: NotificationConfiguration | null;
  organisationId: string;
  aircraft: AircraftDetails[];
  visibility: boolean;
  onClose: () => void;
  createNotification: (config: NotificationConfiguration) => Promise<void>;
  updateNotification: (config: NotificationConfiguration) => Promise<void>;
  deleteNotification: (notificationConfigurationId: string) => Promise<void>;
  users: User[];
  geofenceEventRules: EventRule[];
  exceedenceEventRules: EventRule[];
}

const NotificationDrawer = ({
  initialData,
  aircraft,
  visibility,
  onClose,
  createNotification,
  updateNotification,
  deleteNotification,
  users,
  form,
  geofenceEventRules,
  exceedenceEventRules
}: NotificationDrawerProps) => {
  const history = useHistory();
  useEffect(() => {
    // Closes drawer if user navigates away from the page
    const historyUnlisten = history.listen(() => {
      onClose();
    });

    return () => {
      historyUnlisten();
    };
  }, []);

  return (
    <Drawer
      title={`${initialData ? 'Edit' : 'Add'}`}
      placement="right"
      onClose={onClose}
      visible={visibility}
      width={400}
    >
      <NotificationForm
        initialData={initialData}
        form={form}
        users={users}
        geofenceEventRules={geofenceEventRules}
        exceedenceEventRules={exceedenceEventRules}
        onClose={onClose}
        createNotification={createNotification}
        updateNotification={updateNotification}
        deleteNotification={deleteNotification}
        aircraft={aircraft}
      />
    </Drawer>
  );
};

NotificationDrawer.propTypes = {
  initialData: PropTypes.any,
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  aircraft: PropTypes.array.isRequired,
  createNotification: PropTypes.func.isRequired,
  updateNotification: PropTypes.func.isRequired,
  deleteNotification: PropTypes.func.isRequired,
  geofenceEventRules: PropTypes.array.isRequired,
  exceedenceEventRules: PropTypes.array.isRequired
};

export default Form.create()(NotificationDrawer);
