import { SolidTooltip } from '@spidertracks/components';
import { CheckboxOptionType } from 'antd/lib/checkbox';
import React from 'react';
import {
  EventParameter,
  EventParameterItem,
  EventRule,
  EventRuleBase,
  EventType
} from '../../../common/api/spidertracks-sdk/private/services/EventRulesService';
import { AircraftBase } from '../../../types/aircraft';
import {
  EventRuleType,
  InsightRule,
  InsightRuleCondition,
  InsightRuleThresholdProperty
} from '../../../types/insightRules';

export type KeyedEventParameter = Record<string, EventParameter[]>;
export const transformParameters = (eventParameters: EventParameterItem[]) =>
  eventParameters.reduce<KeyedEventParameter>(
    (acc, param) => ({ ...acc, [param.eventType]: param.parameters }),
    {}
  );

export function getOperationForCondition(op: InsightRuleCondition): string {
  switch (op) {
    case InsightRuleCondition.lowAltitudeAgl:
    case InsightRuleCondition.lowAltitudeAmsl:
    case InsightRuleCondition.pitchDown:
    case InsightRuleCondition.lowSpeed:
      return 'less than';
    default:
      return 'greater than';
  }
}

function getPropertyForCondition(condition: InsightRuleCondition): InsightRuleThresholdProperty {
  switch (condition) {
    case InsightRuleCondition.highAltitudeAgl:
    case InsightRuleCondition.lowAltitudeAgl:
      return InsightRuleThresholdProperty.aglMetres;
    case InsightRuleCondition.highAltitudeAmsl:
    case InsightRuleCondition.lowAltitudeAmsl:
      return InsightRuleThresholdProperty.amslMetres;
    case InsightRuleCondition.roll:
      return InsightRuleThresholdProperty.aircraftRollRadians;
    case InsightRuleCondition.pitchUp:
    case InsightRuleCondition.pitchDown:
      return InsightRuleThresholdProperty.aircraftPitchRadians;
    case InsightRuleCondition.lowSpeed:
      return InsightRuleThresholdProperty.groundSpeed;
    case InsightRuleCondition.rateOfClimb:
    case InsightRuleCondition.rateOfDescent:
      return InsightRuleThresholdProperty.verticalSpeed;
    case InsightRuleCondition.gForce:
      return InsightRuleThresholdProperty.gForce;
    case InsightRuleCondition.highSpeed:
    case InsightRuleCondition.rpm:
    case InsightRuleCondition.temperature:
      return InsightRuleThresholdProperty.primaryConditionValue;
    default:
      throw new Error(`Unknown condition received: ${condition}`);
  }
}

export const getThresholdForInsightRule = (rule: InsightRule, condition: InsightRuleCondition) => {
  const threshold = rule.thresholds.find(
    t => t.thresholdProperty === getPropertyForCondition(condition)
  )!;

  if (threshold.severityEnabled) {
    return `<span style="color:green">${threshold.thresholdValueLow}</span>/<span style="color:orange">${threshold.thresholdValueMedium}</span>/<span style="color:red">${threshold.thresholdValueHigh}</span>`;
  }

  return threshold.thresholdValue.toString();
};

export function getInsightEventThresholdPropertyFromPropertyName(
  key: string
): InsightRuleThresholdProperty {
  switch (key) {
    case 'roll':
      return InsightRuleThresholdProperty.aircraftRollRadians;
    case 'pitch':
      return InsightRuleThresholdProperty.aircraftPitchRadians;
    case 'gps_gspeed':
      return InsightRuleThresholdProperty.groundSpeed;
    case 'v_speed':
      return InsightRuleThresholdProperty.verticalSpeed;
    case 'agl':
      return InsightRuleThresholdProperty.aglMetres;
    case 'altitude':
      return InsightRuleThresholdProperty.amslMetres;
    case 'gForce':
      return InsightRuleThresholdProperty.gForce;
    case 'primaryConditionValue':
      return InsightRuleThresholdProperty.primaryConditionValue;
    case 'rateOfDescent':
      return InsightRuleThresholdProperty.verticalSpeed;
    default:
      throw new Error(`Unknown threshold property received : ${key}`);
  }
}

export const getDescriptionForInsightRule = (rule: InsightRule) => {
  let description: string = getDisplayConditionName(rule.primaryCondition);
  const operation = getOperationForCondition(rule.primaryCondition);

  description += ` ${operation} ${getThresholdForInsightRule(rule, rule.primaryCondition)}`;
  if (rule.secondaryCondition) {
    const operation = getOperationForCondition(rule.secondaryCondition);
    description += ` & ${getDisplayConditionName(
      rule.secondaryCondition
    )} ${operation} ${getThresholdForInsightRule(rule, rule.secondaryCondition)}`;
  }

  return description;
};

function getDisplayConditionName(condition: InsightRuleCondition): string {
  switch (condition) {
    case InsightRuleCondition.highAltitudeAgl:
    case InsightRuleCondition.lowAltitudeAgl:
      return 'Altitude AGL';
    case InsightRuleCondition.highAltitudeAmsl:
    case InsightRuleCondition.lowAltitudeAmsl:
      return 'Altitude AMSL';
    case InsightRuleCondition.roll:
      return 'Roll';
    case InsightRuleCondition.pitchUp:
    case InsightRuleCondition.pitchDown:
      return 'Pitch';
    case InsightRuleCondition.lowSpeed:
      return 'Speed';
    case InsightRuleCondition.rateOfClimb:
    case InsightRuleCondition.rateOfDescent:
      return 'Rate of Descent';
    case InsightRuleCondition.gForce:
      return 'G-Force';
    case InsightRuleCondition.highSpeed:
      return 'Speed';
    case InsightRuleCondition.rpm:
      return 'RPM';
    case InsightRuleCondition.temperature:
      return 'Temperature';
    default:
      throw new Error(`Unknown condition received: ${condition}`);
  }
}

const eventTypeMap = new Map<
  EventRuleType,
  [InsightRuleCondition, InsightRuleCondition | undefined]
>([
  [EventRuleType.EXCESSIVE_G_FORCE, [InsightRuleCondition.gForce, undefined]],
  [EventRuleType.EXCESSIVE_PITCH_DOWN, [InsightRuleCondition.pitchDown, undefined]],
  [
    EventRuleType.EXCESSIVE_PITCH_DOWN_AT_LOW_ALTITUDE_AGL,
    [InsightRuleCondition.pitchDown, InsightRuleCondition.lowAltitudeAgl]
  ],
  [EventRuleType.EXCESSIVE_PITCH_UP, [InsightRuleCondition.pitchUp, undefined]],
  [
    EventRuleType.EXCESSIVE_PITCH_UP_AT_LOW_SPEED,
    [InsightRuleCondition.pitchUp, InsightRuleCondition.lowSpeed]
  ],
  [
    EventRuleType.EXCESSIVE_PITCH_UP_AT_LOW_ALTITUDE_AGL,
    [InsightRuleCondition.pitchUp, InsightRuleCondition.lowAltitudeAgl]
  ],
  [EventRuleType.EXCESSIVE_RATE_OF_CLIMB, [InsightRuleCondition.rateOfClimb, undefined]],
  [EventRuleType.EXCESSIVE_ROLL, [InsightRuleCondition.roll, undefined]],
  [
    EventRuleType.EXCESSIVE_ROLL_AT_LOW_ALTITUDE_AGL,
    [InsightRuleCondition.roll, InsightRuleCondition.lowAltitudeAgl]
  ],
  [
    EventRuleType.EXCESSIVE_ROLL_AT_LOW_ALTITUDE_AMSL,
    [InsightRuleCondition.roll, InsightRuleCondition.lowAltitudeAmsl]
  ],
  [EventRuleType.HIGH_RATE_OF_DESCENT, [InsightRuleCondition.rateOfDescent, undefined]],
  [
    EventRuleType.HIGH_RATE_OF_DESCENT_AT_LOW_ALTITUDE_AGL,
    [InsightRuleCondition.rateOfDescent, InsightRuleCondition.lowAltitudeAgl]
  ],
  [
    EventRuleType.HIGH_RATE_OF_DESCENT_AT_LOW_ALTITUDE_AMSL,
    [InsightRuleCondition.rateOfDescent, InsightRuleCondition.lowAltitudeAmsl]
  ],
  [EventRuleType.MAXIMUM_ALTITUDE_AGL, [InsightRuleCondition.highAltitudeAgl, undefined]],
  [EventRuleType.MAXIMUM_ALTITUDE_AMSL, [InsightRuleCondition.highAltitudeAmsl, undefined]],
  [EventRuleType.EXCESSIVE_TEMPERATURE, [InsightRuleCondition.temperature, undefined]],
  [EventRuleType.EXCESSIVE_RPM, [InsightRuleCondition.rpm, undefined]],
  [EventRuleType.EXCESSIVE_SPEED, [InsightRuleCondition.highSpeed, undefined]]
]);

const conditionMap = new Map<
  InsightRuleCondition,
  Map<InsightRuleCondition | undefined, EventRuleType>
>([]);
for (const [eventType, [primaryCondition, secondaryCondition]] of eventTypeMap) {
  if (!conditionMap.has(primaryCondition)) {
    conditionMap.set(primaryCondition, new Map());
  }
  conditionMap.get(primaryCondition)!.set(secondaryCondition, eventType);
}

export function getEventRuleTypeFromConditions(event: {
  primaryCondition: InsightRuleCondition;
  secondaryCondition?: InsightRuleCondition;
}): EventRuleType {
  const conditions = {
    primaryCondition: event.primaryCondition,
    secondaryCondition: event.secondaryCondition
  };

  const secondaryCondition = conditions.secondaryCondition;
  const primaryCondition = conditions.primaryCondition;
  if (conditionMap.has(primaryCondition)) {
    const secondaryMap = conditionMap.get(primaryCondition);
    if (secondaryMap && secondaryMap.has(secondaryCondition)) {
      return secondaryMap.get(secondaryCondition)!;
    }
  }

  throw new Error(`No event type for conditions: ${JSON.stringify(conditions)}`);
}

export type EventConfigTableDataItem = EventRuleBase & {
  parameters: any;
  description: string;
};
export type EventConfigTableData = EventConfigTableDataItem[];

export type TableData = InsightRule & {
  description: string;
};

export const getTableData = (rules: InsightRule[]): TableData[] => {
  return rules.map(el => {
    const description = getDescriptionForInsightRule(el);
    return {
      ...el,
      description
    };
  });
};

export const getMessage = (minDisplayValue: string | number, maxDisplayValue: string | number) =>
  `Must be between ${minDisplayValue} and ${maxDisplayValue}`;

export const checkValidProps = (minDisplayValue: number, maxDisplayValue: number, type: string) => {
  return (
    type === 'numeric' && typeof minDisplayValue === 'number' && typeof maxDisplayValue === 'number'
  );
};

export const getCustomValidationRules = ({
  minDisplayValue,
  maxDisplayValue
}: Pick<EventParameter, 'minDisplayValue' | 'maxDisplayValue'>) => [
  {
    type: 'number',
    message: getMessage(minDisplayValue, maxDisplayValue),
    min: minDisplayValue,
    max: maxDisplayValue,
    required: true
  }
];

const defaultValidationRules = [
  {
    pattern: /^[-]?([1-9]\d*\.?|0\.)([1-9]|\d[1-9]|\d\d[1-9]|\d\d\d[1-9])?$/,
    message: 'Please enter valid values',
    required: true
  }
];

export const getValidationRules = ({ minDisplayValue, maxDisplayValue, type }: EventParameter) =>
  checkValidProps(minDisplayValue, maxDisplayValue, type)
    ? getCustomValidationRules({ minDisplayValue, maxDisplayValue })
    : defaultValidationRules;

export const validateParameter = (eventParameter: EventParameter) =>
  ['displayName', 'op'].every(p => p in eventParameter);

const getOperation = (op: string) => (op === 'gt' ? 'greater than' : 'less than');

export const getThresholdInputLabel = (eventParameter: EventParameter) => {
  if (!validateParameter(eventParameter)) {
    return `[Data Error: Please Contact Support]`;
  }
  return `${eventParameter.displayName} is ${getOperation(eventParameter.op)}`;
};

export type ExtendedEventType = EventType & {
  ruleAlreadyConfigured?: boolean;
  description?: string;
};

export const sortDescriptionsByAZ = (a: ExtendedEventType, b: ExtendedEventType): number => {
  const descriptionA = a.description?.toUpperCase() ?? '';
  const descriptionB = b.description?.toUpperCase() ?? '';

  if (descriptionA < descriptionB) {
    return -1;
  }
  if (descriptionA > descriptionB) {
    return 1;
  }
  return 0;
};

export const sortRuleNamesByAZ = (a: ExtendedEventType, b: ExtendedEventType): number => {
  const ruleNameA = a.ruleName ?? '';
  const ruleNameB = b.ruleName ?? '';
  return ruleNameA.localeCompare(ruleNameB, undefined, { sensitivity: 'base' });
};

export const sortEventTypesByAZ = (a: ExtendedEventType, b: ExtendedEventType): number => {
  const eventTypeLabelA = a.eventTypeDisplayName.toUpperCase();
  const eventTypeLabelB = b.eventTypeDisplayName.toUpperCase();

  if (eventTypeLabelA < eventTypeLabelB) {
    return -1;
  }
  if (eventTypeLabelA > eventTypeLabelB) {
    return 1;
  }
  return 0;
};

export const sortEventTypesByEnabled = (a: ExtendedEventType, b: ExtendedEventType): number => {
  if (b.ruleAlreadyConfigured === true && a.ruleAlreadyConfigured === false) {
    return -1;
  }
  if (b.ruleAlreadyConfigured === false && a.ruleAlreadyConfigured === true) {
    return 1;
  }
  return 0;
};

export const sortEventTypes = (eventTypes: ExtendedEventType[]): ExtendedEventType[] => {
  return eventTypes.sort(sortEventTypesByAZ);
};

export const byRegistration = <T extends AircraftBase>(a: T, b: T) =>
  a.registration < b.registration ? -1 : a.registration > b.registration ? 1 : 0;

export const checkboxOptionTransformer = (
  valueTransformer: (aircraft: {
    id: string;
    registration: string;
    unassigned?: boolean;
  }) => string = aircraft => aircraft.id
) => (aircraft: {
  id: string;
  registration: string;
  unassigned?: boolean;
}): CheckboxOptionType => ({
  label: aircraft.registration,
  value: valueTransformer(aircraft)
});

export const findRuleForEventType = (eventType: string) => (rules: EventRule[]) => (aircraft: {
  id: string;
}) => {
  const eventTypeRules = rules.filter(r => r.eventType === eventType);
  return (
    eventTypeRules.find(r => r.allAircraft) ||
    eventTypeRules.find(rule => rule.aircraftIds && rule.aircraftIds.includes(aircraft.id))
  );
};

export const buildAircraftOptions = (
  aircraft: { id: string; registration: string }[],
  selectedRule?: InsightRule
) => {
  const selected = [];
  const remaining = [];

  for (let i = 0; i < aircraft.length; i++) {
    const aircraftId = aircraft[i].id;
    if (
      selectedRule &&
      (selectedRule.aircraftIds.includes(aircraftId) || selectedRule.allAircraft)
    ) {
      selected.push(aircraft[i]);
    } else {
      remaining.push(aircraft[i]);
    }
  }

  const result = [
    ...selected.sort(byRegistration).map(checkboxOptionTransformer()),
    ...remaining.sort(byRegistration).map(checkboxOptionTransformer())
  ];
  console.log('buildAircraftOptions', aircraft, result);
  return result;
};
