import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { blue } from '@ant-design/colors/src/index.js';
import { dropdownColumnSpans } from './EventTypeDropdown';

export const EnabledSelectOption = ({ name, isNew }) => {
  return (
    <Row>
      <Col
        style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
        span={isNew ? dropdownColumnSpans.title : dropdownColumnSpans.full}
      >
        {name}
      </Col>
      {isNew && (
        <Col style={{ color: blue.primary, fontStyle: 'italic' }} span={dropdownColumnSpans.icon}>
          new
        </Col>
      )}
    </Row>
  );
};

EnabledSelectOption.propTypes = {
  name: PropTypes.string,
  isNew: PropTypes.bool
};

EnabledSelectOption.displayName = 'EnabledSelectOption';
