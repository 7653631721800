import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { getDateFormat, getDistanceUnit, getTimezone } from 'redux/selectors/userData';
import styled from 'styled-components';
import FlightReportMultipleEventContainer from '../../components/Flying/Map/FlightReportEvent/FlightReportMultipleEventContainer';
import { GOOGLE_MAP_CONTAINER } from '../../components/Flying/Map/GoogleMap/constants';
import MapContainer from '../../components/Flying/Map/MapContainer';
import MapControls from '../../components/Flying/Map/MapControls';
import EventsPointInfoPanelContainer from '../../components/Flying/Map/PointInfoPanel/EventsPointInfoPanelContainer';
import { EventClass } from '../../constants';
import { MapProvider } from '../../context/MapContext';
import {
  getSelectedTrackId,
  getSelectedTracks,
  getTracksData
} from '../../redux/selectors/aircraftData';
import { getFlightReportDisplayPoints } from '../../redux/selectors/eventData';
import { EventsMapWrapper } from './EventsMapWrapper';
import './styles/scss/styles.scss';
import { DownloadModalContext } from '../../context/DownloadModalContext';
import { DownloadTracks } from '../../components/common/modal/DownloadTracks';

const StandardContainer = styled.div`
  padding: 10px;
  height: 100%;
`;

const TopContainer = styled.div`
  margin-bottom: 10px;
  height: 100%;
`;

function getQueryParam(name) {
  return new URLSearchParams(window.location.search).get(name);
}

export const HistoryFlightReport = ({
  tracks,
  timezone,
  dateFormat,
  distanceUnit,
  points,
  selectedTrackId
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const mapContext = {
    events: { displayEventClasses: [EventClass.GEOFENCE, EventClass.FSI] },
    strictPointGrouping: { displayFullTrack: true }
  };

  let paramsSerialNumber = useParams().serialNumber;
  let paramsBootcount = useParams().bootcount;
  let paramsTrackId = useParams().trackId;
  let queryTrackIds = getQueryParam('trackIds');
  const trackIds = paramsTrackId ? paramsTrackId.split(',') : (queryTrackIds ?? '').split(',');

  return (
    <StandardContainer>
      <DownloadTracks
        visible={modalVisible}
        trackIds={trackIds}
        serialNumber={paramsSerialNumber}
        bootCount={paramsBootcount || parseInt(paramsBootcount)}
        downloadLimit={250}
        requestClose={() => setModalVisible(false)}
      />
      <div className="flight-report d-flex flex-direction-col">
        <TopContainer id="history-flight-report-top-container" className="d-flex flex-1">
          <FlightReportMultipleEventContainer
            tracks={tracks}
            displayPoints={points}
            timezone={timezone}
            dateFormat={dateFormat}
            distanceUnit={distanceUnit}
            selectedTrackId={selectedTrackId}
          />

          <div id={GOOGLE_MAP_CONTAINER} className="map shadow d-flex flex-1">
            <MapProvider context={mapContext}>
              <MapContainer>
                <EventsMapWrapper>
                  {!modalVisible && <EventsPointInfoPanelContainer hidePanelOffset={true} />}
                  <DownloadModalContext.Provider value={{ modalVisible, setModalVisible }}>
                    <MapControls
                      downloadTracksEnabled={true}
                      favoritesEnabled={false}
                      pointGroupingEnabled={false}
                      weatherEnabled={false}
                    />
                  </DownloadModalContext.Provider>
                </EventsMapWrapper>
              </MapContainer>
            </MapProvider>
          </div>
        </TopContainer>
      </div>
    </StandardContainer>
  );
};

HistoryFlightReport.propTypes = {
  timezone: PropTypes.string,
  dateFormat: PropTypes.string,
  distanceUnit: PropTypes.string,
  tracks: PropTypes.array,
  points: PropTypes.object,
  selectedTrack: PropTypes.object,
  selectedTrackId: PropTypes.string
};

const mapStateToProps = state => {
  const selectedTracks = getSelectedTracks(state);
  const allTracks = getTracksData(state);
  const tracks = selectedTracks.map(track => allTracks[track.trackId]);

  return {
    tracks: tracks,
    timezone: getTimezone(state),
    dateFormat: getDateFormat(state),
    distanceUnit: getDistanceUnit(state),
    points: getFlightReportDisplayPoints(state),
    selectedTrackId: getSelectedTrackId(state)
  };
};

export default connect(mapStateToProps)(HistoryFlightReport);
