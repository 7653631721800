import { createSlice } from '@reduxjs/toolkit';

const initialState = { aircraftList: false };

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }) => {
      state.aircraftList = payload;
      return state;
    }
  }
});

// actions
export const { setIsLoading } = loadingSlice.actions;

export const getIsLoading = state => state.loading.aircraftList;

export default loadingSlice;
