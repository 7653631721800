import React, { Fragment } from 'react';
import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useGoogleMap } from '@react-google-maps/api';
import { getTextualDataForWeatherLayer } from '../utils/weather/weatherOverlay';
import MetarMarkers from './WeatherMarkers/MetarMarkers';
import TafMarkers from './WeatherMarkers/TafMarkers';
import NotamMarkers from './WeatherMarkers/NotamMarkers';
import { SigAirmetPolygons } from './WeatherMarkers/SigAirmetPolygons';
import { notification } from 'antd';
import {
  offlineError,
  weatherUnknownError,
  loadingMessage as loadingMessageWithoutDelay,
  loadingMessageWithDelay
} from '../../../../helpers/globalNotifications';
import { debounce } from '../../../../helpers/debounce';

const tooMuchZoomNotificationKey = 'too-much-zoom';

export const TextualOverlayMapView = ({ weatherLayer }) => {
  const [textualData, setTextualData] = useState([]);
  const boundsChangedListener = useRef(null);
  const mapInstance = useGoogleMap();

  useEffect(() => {
    let finishedLoading;
    const getData = async (bounds, loadingMessage) => {
      const zoom = mapInstance.getZoom();
      try {
        if (zoom >= 9 || !weatherLayer.incrementalLoadSupported) {
          finishedLoading = loadingMessage(weatherLayer.productCode, weatherLayer.name);
          notification.close(tooMuchZoomNotificationKey);
          let data = {};
          data[weatherLayer.productCode] = await getTextualDataForWeatherLayer(
            weatherLayer,
            bounds
          );
          setTextualData(data);
          finishedLoading();
        } else {
          notification.info({
            key: tooMuchZoomNotificationKey,
            message: `Please zoom in to view ${weatherLayer.name}`
          });
        }
      } catch (weatherError) {
        if (typeof finishedLoading === 'function') {
          finishedLoading();
        }

        if (!window.navigator.onLine) {
          offlineError('offlineError');
        } else {
          weatherUnknownError();
        }
      }
    };

    getData(mapInstance.getBounds(), loadingMessageWithoutDelay);

    if (weatherLayer.incrementalLoadSupported) {
      boundsChangedListener.current = mapInstance.addListener(
        'bounds_changed',
        debounce(() => getData(mapInstance.getBounds(), loadingMessageWithDelay), 500)
      );
    }

    return () => {
      if (weatherLayer.incrementalLoadSupported) {
        boundsChangedListener.current.remove();
      }
    };
  }, [weatherLayer, mapInstance]);

  return (
    <Fragment>
      {weatherLayer.productCode === 'metar' && (
        <MetarMarkers metarData={textualData[weatherLayer.productCode]} />
      )}
      {weatherLayer.productCode === 'taf' && (
        <TafMarkers tafData={textualData[weatherLayer.productCode]} />
      )}
      {weatherLayer.productCode === 'notam' && (
        <NotamMarkers notamData={textualData[weatherLayer.productCode]} />
      )}
      {weatherLayer.productCode === 'sigairmet' && (
        <SigAirmetPolygons sigAirmetData={textualData[weatherLayer.productCode]} />
      )}
    </Fragment>
  );
};

TextualOverlayMapView.propTypes = {
  weatherLayer: PropTypes.object
};

TextualOverlayMapView.displayName = 'TextualOverlayMapView';

export default TextualOverlayMapView;
