export enum SpiderType {
  SX = 'SX',
  S1 = 'S1',
  S2 = 'S2',
  S3 = 'S3',
  S3_LITE = 'S3_LITE',
  S4 = 'S4',
  S5 = 'S5',
  S6 = 'S6',
  S7 = 'S7',
  S8 = 'S8',
  DEV_KIT_SPIDER = 'DEV_KIT_SPIDER',
  FLIGHTCELL_DZM = 'FLIGHTCELL_DZM',
  UNSET = 'UNSET'
}
export interface Spider {
  imeiNumber: string;
  serialNumber: string;
  spiderType: SpiderType;
  vehicleId: string;
}

export interface SpiderPlan {
  id: string;
  name: string;
}

export interface SpiderDetails {
  id: string;
  updateRadius: number;
  imei: string;
  serialNumber: string;
  updateTime: number;
  plan: SpiderPlan;
}
