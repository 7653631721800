import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col, Input } from 'antd';

const formItemStyle = { marginBottom: '1px' };

const TagFormInput = ({
  name,
  label,
  getFieldDecorator,
  validationRules = [{ required: false }],
  placeholder,
  handleFieldChange,
  disabled = false,
  initialValue
}) => (
  <Form.Item label={label} style={formItemStyle}>
    <Row>
      <Col span={16}>
        {getFieldDecorator(name, {
          rules: validationRules,
          initialValue: initialValue && initialValue !== '-' ? initialValue : undefined
        })(
          <Input
            // autoComplete="off"
            // placeholder={placeholder}
            style={{ width: '300px', height: '40px' }}
            onChange={handleFieldChange}
            disabled={disabled}
          />
        )}
      </Col>
    </Row>
  </Form.Item>
);

TagFormInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
  getFieldDecorator: PropTypes.func.isRequired,
  validationRules: PropTypes.array,
  placeholder: PropTypes.string,
  handleFieldChange: PropTypes.func,
  disabled: PropTypes.bool,
  initialValue: PropTypes.string
};

export default TagFormInput;
