import { useState, useCallback, useEffect } from 'react';
import { getInstance } from '../common/api/spidertracks-sdk';
import { flightReplayStatusError } from '../helpers';
import { Track } from '../types/Track';

export interface Aircraft {
  icon: string;
  iconColour: string;
  id: string;
  make: string;
  model: string;
  organisationId: string;
  registration: string;
  type: string;
  year: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useAircraftData = (tracks: any) => {
  const SpidertracksSDK = getInstance();
  const [aircraftData, setAircraftsData] = useState<Aircraft>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const getAircraftData = useCallback(
    tracks => {
      const init = async () => {
        try {
          setLoading(true);
          const getAircraft = (aircraftId: string) =>
            SpidertracksSDK.getAircraftService().get(aircraftId);

          const getAircraftId = (track: Track) => track.aircraft && track.aircraft.id;

          const uniqueAircraftRequests = new Set(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            tracks.reduce((acc: any[], track: Track) => {
              if (track.aircraft === null) {
                console.error(`Aircraft data null on Track: ${track.trackId}`);
                return acc;
              }
              return [...acc, getAircraft(getAircraftId(track))];
            }, [])
          );

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          Promise.all(uniqueAircraftRequests).then((aircraftResponses: any) => {
            const newAircraftData = aircraftResponses.reduce(
              (aircraftsData: Aircraft[], aircraft: Aircraft) => ({
                ...aircraftsData,
                [aircraft.id]: aircraft
              }),
              {}
            );
            const updatedAircraftData = {
              ...newAircraftData
            };
            setAircraftsData(updatedAircraftData);
          });
          setLoading(false);
        } catch (e) {
          flightReplayStatusError();
          setLoading(false);
          setError(() => e);
          console.log(e);
        }
      };
      init();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tracks]
  );

  useEffect(() => {
    getAircraftData(tracks);
  }, [getAircraftData, tracks]);
  return [aircraftData, loading, error];
};
