import { aircraftMarkerColors } from '../markers/markerColorCodes';

interface PolylineOptions {
  strokeOpacity: number;
  strokeWeight: number;
  strokeColor: string;
  zIndex: number;
}

export interface AircraftTrackLineOptions {
  innerLineOptions: PolylineOptions;
  outerLineOptions?: PolylineOptions;
}

interface Preferences {
  aircraftIconColour?: string;
  isTrackActive: boolean;
  isTrackSelected: boolean;
  zIndex: number;
  trackLineBold: boolean;
  trackLineGrayUnderline: boolean;
  trackLineMatchAircraftColour: boolean;
}

const GRAY_HEX_CODE = '#BFBFBF';
const DEFAULT_TRACK_LINE_COLOUR_NOT_ACTIVE_NOT_SELECTED = 'red';
const DEFAULT_TRACK_LINE_COLOUR_SELECTED = 'blue';
const DEFAULT_TRACK_LINE_COLOUR_ACTIVE_NOT_SELECTED = 'limegreen';
const BORDER_LINE_WIDTH_NORMAL = 3;
const BORDER_LINE_WIDTH_BOLD = 6;

const getStrokeColour = (props: Preferences) => {
  if (props.trackLineMatchAircraftColour) {
    const trackLineColour = props.aircraftIconColour
      ? props.aircraftIconColour.toLowerCase()
      : 'default';

    return aircraftMarkerColors[trackLineColour];
  }

  if (props.isTrackSelected) {
    return DEFAULT_TRACK_LINE_COLOUR_SELECTED;
  }

  if (props.isTrackActive) {
    return DEFAULT_TRACK_LINE_COLOUR_ACTIVE_NOT_SELECTED;
  }

  return DEFAULT_TRACK_LINE_COLOUR_NOT_ACTIVE_NOT_SELECTED;
};

export const getTrackLineOptions = (props: Preferences): AircraftTrackLineOptions => {
  const trackLineOptions: AircraftTrackLineOptions = {
    innerLineOptions: {
      strokeOpacity: 1,
      strokeWeight: props.trackLineBold ? 2 : 1,
      strokeColor: getStrokeColour(props),
      zIndex: props.zIndex
    }
  };

  if (props.isTrackActive) {
    trackLineOptions.innerLineOptions.strokeWeight = props.trackLineBold ? 4 : 2;
  }
  if (props.isTrackSelected) {
    trackLineOptions.innerLineOptions.strokeWeight = props.trackLineBold ? 6 : 3;
  }

  if (props.trackLineGrayUnderline) {
    const borderWidth = props.trackLineBold ? BORDER_LINE_WIDTH_BOLD : BORDER_LINE_WIDTH_NORMAL;
    if (borderWidth > trackLineOptions.innerLineOptions.strokeWeight) {
      trackLineOptions.outerLineOptions = {
        strokeOpacity: 1,
        strokeWeight: borderWidth,
        strokeColor: GRAY_HEX_CODE,
        zIndex: props.zIndex
      };
    }
  }

  return trackLineOptions;
};
