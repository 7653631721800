import React, { Fragment, useEffect } from 'react';
import { node } from 'prop-types';
import { isAuthenticated } from '@spidertracks/common';
import NavigationFrame from '../common/containers/NavigationFrame';
import PrivateDataLoader from '../common/containers/PrivateDataLoader';
import PublicDataLoader from '../common/containers/PublicDataLoader';
import SpiderTxtNotificationAudio from '../common/containers/SpiderTxtNotificationAudio';
import ZendeskContainer from '../components/Zendesk';

export const MainLayout = ({ children }) => {
  useEffect(() => {
    document.title = `Spidertracks`;
  }, []);
  return (
    <Fragment>
      {isAuthenticated() && <PrivateDataLoader />}
      {!isAuthenticated() && <PublicDataLoader />}
      {isAuthenticated() && <SpiderTxtNotificationAudio />}
      {isAuthenticated() && <ZendeskContainer />}
      <NavigationFrame>{children}</NavigationFrame>
    </Fragment>
  );
};

MainLayout.propTypes = { children: node };
MainLayout.defaultProps = { children: {} };
