import {
  unableToSaveError,
  spiderAddedSuccess,
  spiderAssignedSuccess,
  partialRegistrationError
} from '../../../helpers/globalNotifications';
import { getInstance } from '../../../common/api/spidertracks-sdk';
import { DEFAULT_SX_PLAN_NAME } from './addAircraftSpiderFlows';

export const registerThenAssignSpiderFlow = async (
  serialNumber,
  currentAircraftSpider,
  setCurrentAircraftSpider,
  setSavedSerialNumber,
  refreshDeviceTable,
  organisationId
) => {
  const SpidertracksSDK = getInstance();

  const { aircraftId, registration } = currentAircraftSpider;
  let response;
  try {
    response = await SpidertracksSDK.getSpiderSettingsService().registerSpider({
      serialNumber,
      planName: DEFAULT_SX_PLAN_NAME,
      organisationId
    });
    // assign the aircraft to the spider (whether it already )
    await SpidertracksSDK.getSpiderSettingsService().assignAircraftToSpider({
      serialNumber,
      aircraftId
    });

    setCurrentAircraftSpider({
      ...currentAircraftSpider,
      serialNumber
    });

    setSavedSerialNumber(serialNumber);
    refreshDeviceTable();
    if (response.status === 400608) {
      partialRegistrationError();
    } else {
      spiderAssignedSuccess(registration);
    }
  } catch (e) {
    console.error(e);
    unableToSaveError();
  }
};

export const assignAircraftToSpiderFlow = async (
  serialNumber,
  currentAircraftSpider,
  setCurrentAircraftSpider,
  setSavedSerialNumber,
  refreshDeviceTable
) => {
  const SpidertracksSDK = getInstance();

  const { aircraftId, registration } = currentAircraftSpider;
  try {
    await SpidertracksSDK.getSpiderSettingsService().assignAircraftToSpider({
      serialNumber,
      aircraftId
    });
    setCurrentAircraftSpider({
      ...currentAircraftSpider,
      serialNumber
    });
    spiderAssignedSuccess(registration);
    setSavedSerialNumber(serialNumber);
    refreshDeviceTable();
  } catch (e) {
    console.error(e);
    unableToSaveError();
  }
};

export const registerSpiderFlow = async (
  serialNumber,
  setSavedSerialNumber,
  refreshDeviceTable,
  organisationId
) => {
  const SpidertracksSDK = getInstance();

  let response;
  try {
    response = await SpidertracksSDK.getSpiderSettingsService().registerSpider({
      serialNumber,
      planName: DEFAULT_SX_PLAN_NAME,
      organisationId
    });
    if (response.status === 400608) {
      partialRegistrationError();
    } else {
      spiderAddedSuccess();
    }
    setSavedSerialNumber(serialNumber);
    refreshDeviceTable();
  } catch (e) {
    console.error(e);
    unableToSaveError();
  }
};
