import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NotamMarker from './NotamMarker';
import { getDateTimeFormat, getTimezone } from '../../../../../redux/selectors/userData';

export const NotamMarkers = ({ notamData = [], dateFormat, timezone }) => {
  return (
    <Fragment>
      {notamData.map(notam => (
        <NotamMarker
          key={notam.station.id}
          notam={notam}
          dateFormat={dateFormat}
          timezone={timezone}
        />
      ))}
    </Fragment>
  );
};

NotamMarkers.propTypes = {
  notamData: PropTypes.array,
  dateFormat: PropTypes.string,
  timezone: PropTypes.string
};

const mapStateToProps = state => {
  return {
    dateFormat: getDateTimeFormat(state),
    timezone: getTimezone(state)
  };
};
export default connect(mapStateToProps)(NotamMarkers);
