import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';

const WeatherOptions = props => {
  const { productCode, name } = props;

  return <Radio value={productCode}>{name}</Radio>;
};

WeatherOptions.propTypes = {
  productCode: PropTypes.string,
  name: PropTypes.string
};

export default WeatherOptions;
