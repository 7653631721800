import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MetarMarker from './MetarMarker';
import { getDateTimeFormat, getTimezone } from '../../../../../redux/selectors/userData';

export const MetarMarkers = ({ metarData = [], dateFormat, timezone }) => {
  return (
    <Fragment>
      {metarData.map(metar => (
        <MetarMarker
          key={metar.raw_metar}
          metar={metar}
          dateFormat={dateFormat}
          timezone={timezone}
        />
      ))}
    </Fragment>
  );
};

MetarMarkers.propTypes = {
  metarData: PropTypes.array,
  dateFormat: PropTypes.string,
  timezone: PropTypes.string
};

const mapStateToProps = state => {
  return {
    dateFormat: getDateTimeFormat(state),
    timezone: getTimezone(state)
  };
};

export default connect(mapStateToProps)(MetarMarkers);
