import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Polyline } from '@react-google-maps/api';

export const CustomizedTrackLines = ({ coordinates, trackLineOptions }) => {
  return (
    <Fragment>
      {trackLineOptions.outerLineOptions && (
        <Polyline options={trackLineOptions.outerLineOptions} path={coordinates} />
      )}
      <Polyline options={trackLineOptions.innerLineOptions} path={coordinates} />
    </Fragment>
  );
};

CustomizedTrackLines.propTypes = {
  coordinates: PropTypes.array,
  trackLineOptions: PropTypes.object
};

export default CustomizedTrackLines;
