import React, { memo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';
import ForecastDetails from './ForecastDetails';
import { getDateTimeFormat, getTimezone } from '../../../../../../redux/selectors/userData';
import {
  formatStandardDateStrings,
  fromISO8601,
  dateFormatter
} from '../../../../../../helpers/formatTime';
import InfoWindowRow from '../../InfoWindowRow';

export const Forecasts = ({ forecasts, dateFormat, timezone }) => {
  return (
    <div className="text-left">
      <Collapse accordion bordered={false} defaultActiveKey={[0]}>
        {forecasts.map(forecast => {
          const formatFn = dateFormatter(dateFormat);
          const [validBeginUserPref, validBeginUTC] = forecast.valid_begin
            ? formatStandardDateStrings(formatFn, fromISO8601(forecast.valid_begin), timezone)
            : ['', ''];
          const [validEndUserPref, validEndUTC] = forecast.valid_end
            ? formatStandardDateStrings(formatFn, fromISO8601(forecast.valid_end), timezone)
            : ['', ''];

          const header = validBeginUserPref + ' - ' + validEndUserPref;

          return (
            <Collapse.Panel header={header} key={forecast.id}>
              <InfoWindowRow title="Start" content={validBeginUserPref} />
              <InfoWindowRow content={validBeginUTC} />
              <InfoWindowRow title="End" content={validEndUserPref} />
              <InfoWindowRow content={validEndUTC} />
              <ForecastDetails forecast={forecast} />
            </Collapse.Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

Forecasts.propTypes = {
  forecasts: PropTypes.array.isRequired,
  dateFormat: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired
};

export const mapStateToProps = state => {
  return {
    dateFormat: getDateTimeFormat(state),
    timezone: getTimezone(state)
  };
};

export default connect(mapStateToProps)(memo(Forecasts));
