import React from 'react';
import Tag from './Tag';

const style = { overflow: 'scroll', height: '100%' };

const TagContainer = () => (
  <div className="px-5 py-4" style={style}>
    <Tag />
  </div>
);

export default TagContainer;
