import React from 'react';
import PropTypes from 'prop-types';
import { FilterContainer, FilterMenuItem } from './styles/js/styled';
import './styles/scss/styles.scss';

const FilterMenu = props => {
  const { filter, filters, selectedFilter, config } = props;

  const selectItem = e => {
    filter(e);
    config.confirm();
  };

  const list = filters.map(item => (
    <FilterMenuItem key={item.id} href="#" data-filter={item.value} onClick={selectItem}>
      {item.text}
      {selectedFilter === item.value && <span className="tick" />}
    </FilterMenuItem>
  ));
  return <FilterContainer>{list}</FilterContainer>;
};

FilterMenu.defaultProps = {
  filter: () => {},
  filters: [],
  selectedFilter: null
};

FilterMenu.propTypes = {
  filter: PropTypes.func,
  filters: PropTypes.instanceOf(Array),
  selectedFilter: PropTypes.string,
  config: PropTypes.object.isRequired, // eslint-disable-line
};

export default FilterMenu;
