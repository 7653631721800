import { Chunk, Chunks, FlightDataFlags } from '../../types/FlightData';
import { CompleteFlightDataServiceResponse, getFlightData } from './flightDataStore';
import { PrivateTrackData } from '../../common/api/spidertracks-sdk/types/TrackData';

const CHUNK_DURATION_MS = 15 * 60 * 1000;

/**
 * This function is responsible for fetching flight data for a given chunk/block of time in the track.
 * If the data returned by the API is incomplete, we fetch it again 'till we got it all.
 *
 * @param selectedTrack PrivateTrackData
 * @param chunk Chunk
 * @param flags FlightDataFlags
 * @param orgId string
 * @returns CompleteFlightDataServiceResponse
 */
export async function fetchChunkData(
  selectedTrackId: number,
  chunk: Chunk,
  flags: FlightDataFlags,
  orgId: string
): Promise<CompleteFlightDataServiceResponse> {
  return (await getFlightData(
    flags,
    selectedTrackId,
    orgId,
    chunk.fromTimestamp,
    chunk.toTimestamp
  )) as CompleteFlightDataServiceResponse;
}

/**
 * This function is responsible for splitting the track into chunks that will be used in the 3D Player.
 * A chunk is just a time range, determined by the CHUNK_DURATION_MS constant. It can also be seen as a block of time in the track.
 *
 * The returned obj is essentially an indexed collection of chunks, where each chunk represents a block of time in the track.
 *
 * @param track PrivateTrackData
 * @returns Chunks
 */
export function chunkTrack(track: PrivateTrackData): Chunks {
  const { departedTime: trackStartTime, endTime: trackEndTime } = track;
  const chunks: Chunks = {
    [0]: {
      fromTimestamp: trackStartTime,
      toTimestamp: Math.min(trackStartTime + CHUNK_DURATION_MS, trackEndTime)
    }
  };

  let chunkFrom = trackStartTime + CHUNK_DURATION_MS;
  while (chunkFrom < trackEndTime) {
    const chunkTo = Math.min(chunkFrom + CHUNK_DURATION_MS, trackEndTime);
    chunks[chunkFrom - trackStartTime] = {
      fromTimestamp: chunkFrom,
      toTimestamp: chunkTo
    };
    chunkFrom = chunkTo;
  }
  return chunks;
}
