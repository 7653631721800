import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const date = moment();

const initialState = {
  dates: {
    dashboardFrom: date
      .clone()
      .add(-30, 'days')
      .format('YYYY-MM-DD'),
    dashboardTo: date.format('YYYY-MM-DD')
  },
  selectedAircraft: [],
  selectedEventTypes: []
};

const insightsSlice = createSlice({
  name: 'insights',
  initialState,
  reducers: {
    setDates: (state, { payload }) => {
      state.dates = payload;
      return state;
    },
    resetDates: state => {
      state.dates = {
        dashboardFrom: null,
        dashboardTo: null
      };
      return state;
    },
    setSelectedAircraft: (state, { payload }) => {
      state.selectedAircraft = payload;
      return state;
    },
    setSelectedEventTypes: (state, { payload }) => {
      state.selectedEventTypes = payload;
      return state;
    }
  }
});

// actions
export const {
  setDates,
  setSelectedAircraft,
  setSelectedEventTypes,
  resetDates
} = insightsSlice.actions;
// selector
export const getDates = state => state.insights.dates;
export const getSelectedAircraft = state => state.insights.selectedAircraft;
export const getSelectedEventTypes = state => state.insights.selectedEventTypes;

export default insightsSlice;
