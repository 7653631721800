import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getInstance } from 'common/api/spidertracks-sdk';
import { fetchAircraft } from '../../redux/slice/aircraft';
import { fetchLatestTracks } from '../../redux/slice/tracks';
import { setIsLoading } from '../../redux/slice/loading';
import PublicMapContainer from './PublicMapContainer';
import { getSelectedTrack, isLastPointInTrack } from '../../redux/selectors/aircraftData';
import { getSelectedMarkerPoint } from '../../redux/selectors/mapData';
import { selectLatestPointInSelectedTrack } from '../../redux/reducers/aircraftReducer/thunk';

export const PublicFlyingPage = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const ref = useRef();
  const selectedTrack = useSelector(getSelectedTrack);
  const selectedMarkerPoint = useSelector(getSelectedMarkerPoint);

  useEffect(() => {
    const SpidertracksSDK = getInstance();
    SpidertracksSDK.addEventListener('unknown_organisation', evt => {
      console.log('%cUnknown Org!', 'font-size: 72px');
      console.log(evt);
    });

    const init = async () => {
      dispatch(setIsLoading(true));
      await dispatch(fetchAircraft(params.organisationSlug));
      await dispatch(fetchLatestTracks(params.organisationSlug));
      dispatch(setIsLoading(false));
    };
    init();

    const intervalId = setInterval(() => {
      Promise.all([
        dispatch(fetchAircraft(params.organisationSlug)),
        dispatch(fetchLatestTracks(params.organisationSlug))
      ]);
    }, 30000);
    return () => clearInterval(intervalId);
  }, [params.organisationSlug, dispatch, fetchAircraft, fetchLatestTracks]);

  useEffect(() => {
    if (
      selectedTrack &&
      selectedMarkerPoint &&
      ref.current &&
      ref.current.selectedTrack &&
      ref.current.selectedTrack.trackId === selectedTrack.trackId &&
      isLastPointInTrack(ref.current.selectedTrack, selectedMarkerPoint)
    ) {
      dispatch(selectLatestPointInSelectedTrack());
    }
    ref.current = {
      selectedTrack
    };
  }, [selectedTrack]);

  return <PublicMapContainer />;
};

export default PublicFlyingPage;
