import { Slider } from 'antd';
import React, { FC, Fragment } from 'react';
import { DisplayPoint } from '../../../../common/api/spidertracks-sdk/types/TrackData';
import { debounce } from '../../../../helpers/debounce';
import PointInfoPanelButtons from './PointInfoPanelButtons';

interface PanelSliderProps {
  points: DisplayPoint[];
  selectedDisplayPoint: DisplayPoint;
  setPoint(i: number): void;
}

const PanelSlider: FC<PanelSliderProps> = ({ points, selectedDisplayPoint, setPoint }) => {
  const selectedPointIndex = points.findIndex(p => p.id === selectedDisplayPoint.id);
  const isFirstPointInTrack = selectedPointIndex === 0;
  const isLastPointInTrack = selectedPointIndex === points.length - 1;
  const setFirstPoint = () => setPoint(0);
  const setLastPoint = () => setPoint(points.length - 1);
  const setPreviousPoint = () => {
    if (selectedPointIndex > 0) {
      setPoint(selectedPointIndex - 1);
    }
  };
  const setNextPoint = () => {
    if (selectedPointIndex < points.length - 1) {
      setPoint(selectedPointIndex + 1);
    }
  };

  return (
    <Fragment>
      <Slider
        min={0}
        max={points.length - 1}
        value={selectedPointIndex}
        onChange={debounce(setPoint, 50)}
        tipFormatter={null}
      />
      <PointInfoPanelButtons
        isFirstPointInTrack={isFirstPointInTrack}
        isLastPointInTrack={isLastPointInTrack}
        setNextPoint={setNextPoint}
        setPreviousPoint={setPreviousPoint}
        setFirstPoint={setFirstPoint}
        setLastPoint={setLastPoint}
      />
    </Fragment>
  );
};

export default PanelSlider;
