import React from 'react';
import PropTypes from 'prop-types';

const MapLayersIcon = props => {
  const { color } = props;
  return (
    <path
      style={{ fill: color }}
      d="M100,5.15l100,50l-100,50l-100-50L100,5.15z M100,123.33L27.88,87.27L0,101.21l100,50l100-50l-27.88-13.94L100,123.33z M100,166.97l-72.12-36.06L0,144.85l100,50l100-50l-27.88-13.94L100,166.97z"
    />
  );
};

MapLayersIcon.defaultProps = {
  color: 'grey'
};

MapLayersIcon.propTypes = {
  color: PropTypes.string
};

export default MapLayersIcon;
