import { InsightRuleCondition } from '../../../types/insightRules';

export function getPrimaryConditionSourceOptions(eventType: string) {
  switch (eventType) {
    case 'excessive_temperature':
      return [
        {
          key: 'engine1Cht1DegreesC',
          label: getPrimaryConditionSourceLabel('engine1Cht1DegreesC')
        },
        { key: 'engine1Egt1DegreesC', label: getPrimaryConditionSourceLabel('engine1Egt1DegreesC') }
      ];
    case 'excessive_rpm':
      return [{ key: 'engine1Rpm', label: getPrimaryConditionSourceLabel('engine1Rpm') }];
    case 'excessive_speed':
      return [
        {
          key: 'indicatedAirSpeedMetresPerSecond',
          label: getPrimaryConditionSourceLabel('indicatedAirSpeedMetresPerSecond')
        },
        {
          key: 'groundSpeedMetresPerSecond',
          label: getPrimaryConditionSourceLabel('groundSpeedMetresPerSecond')
        }
      ];
    default:
      return undefined;
  }
}

export function getConditionSourceOptions(condition: InsightRuleCondition) {
  switch (condition) {
    case InsightRuleCondition.temperature:
      return [
        {
          key: 'engine1Cht1DegreesC',
          label: getPrimaryConditionSourceLabel('engine1Cht1DegreesC')
        },
        { key: 'engine1Egt1DegreesC', label: getPrimaryConditionSourceLabel('engine1Egt1DegreesC') }
      ];
    case InsightRuleCondition.rpm:
      return [{ key: 'engine1Rpm', label: getPrimaryConditionSourceLabel('engine1Rpm') }];
    case InsightRuleCondition.highSpeed:
      return [
        {
          key: 'indicatedAirSpeedMetresPerSecond',
          label: getPrimaryConditionSourceLabel('indicatedAirSpeedMetresPerSecond')
        },
        {
          key: 'groundSpeedMetresPerSecond',
          label: getPrimaryConditionSourceLabel('groundSpeedMetresPerSecond')
        }
      ];
    default:
      return undefined;
  }
}

export function getPrimaryConditionSourceLabel(key: string) {
  switch (key) {
    case 'engine1Cht1DegreesC':
      return 'Engine 1 Cylinder Head Temperature';
    case 'engine1Egt1DegreesC':
      return 'Engine 1 Exhaust Gas Temperature';
    case 'engine1Rpm':
      return 'Engine 1';
    case 'indicatedAirSpeedMetresPerSecond':
      return 'Air Speed';
    case 'groundSpeedMetresPerSecond':
      return 'Ground Speed';
    default:
      return '';
  }
}
