import styled from 'styled-components';

interface PointInfoPanelParentProps {
  aircraftListVisible: boolean;
}

const PointInfoPanelParent = styled.div<PointInfoPanelParentProps>`
  float: left;

  & > div > .ant-notification.ant-notification-bottomLeft {
    transition: all 0.5s ease;
    ${({ aircraftListVisible }) => aircraftListVisible && `left: 261px !important;`}
  }
`;

export default PointInfoPanelParent;
