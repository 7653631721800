/* eslint-disable @typescript-eslint/explicit-function-return-type */
import moment, * as Moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';

momentDurationFormatSetup(Moment);

export type DateFormatterFn = (momentObject: Moment.Moment) => string;

export const ensureUTC = (momentObject: Moment.Moment) => {
  if (!momentObject.isUTC()) {
    throw new Error('Not a UTC enabled date');
  }
  return momentObject;
};

export const formatSecondsToHoursDecimal = (seconds: number, decimalPlaces: number = 1): string => {
  const hoursValue = seconds / 3600;
  const formattedHours = parseFloat(hoursValue.toFixed(decimalPlaces));

  return `${formattedHours}`;
};

export const dateFormatter = (dateFormat: string): DateFormatterFn => {
  return (momentObject: Moment.Moment) => momentObject.format(dateFormat);
};

export const fromISO8601 = (isoString: string) => {
  return moment(isoString, moment.ISO_8601, true).utc();
};

export const initializeDuration = () => moment.duration(0);

export const humanizeTime = (duration: number) => {
  let humanReadableDuration;
  if (duration === 0) {
    humanReadableDuration = '0 min';
  } else if (duration < 60000) {
    humanReadableDuration = '1 min';
  } else {
    humanReadableDuration = moment.duration(duration).format('d [days] h [hrs] m [min]');
  }
  return humanReadableDuration;
};

export const getDuration = (startTime: number, endTime: number) => {
  const start = moment(startTime);
  const end = moment(endTime);

  const difference = end.diff(start);

  return {
    formattedHoursDecimal: formatSecondsToHoursDecimal(difference / 1000, 2),
    formatted: humanizeTime(difference),
    unformatted: moment.duration(difference)
  };
};

export const fromTimestamp = (timestamp: number): moment.Moment => {
  return moment(timestamp).utc();
};

export const toTimestamp = (time: moment.Moment): number => {
  return moment.utc(time).valueOf();
};

export const addSeconds = (momentObject: moment.Moment, seconds: number) => {
  return ensureUTC(momentObject).add(seconds, 'seconds');
};

export const formatStandardDateStrings = (
  formatFn: DateFormatterFn,
  momentObject: moment.Moment,
  timezone: string
) => {
  const formattedUtcMoment = formatFn(ensureUTC(momentObject));
  const formattedUserTimezonelMoment = formatFn(ensureUTC(momentObject).tz(timezone));

  return [formattedUserTimezonelMoment, formattedUtcMoment];
};
