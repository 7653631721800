import { formatCoordinates } from '../../helpers/formatCoordinates';
import { convertMetresTo } from '../../components/Flying/Map/utils/helper';
import { EventClass } from '../../constants';
import { decodeGeoHash } from '../../util/geoHash';
import { getEventDisplayValues } from '../../components/Flying/Map/utils/eventDisplayHelper';
import { formatSpeedFromMetersPerSeconds } from '../../helpers';
import { BUTTON_MODES } from '../../components/Flying/Map/utils/drawing/marker';

export const FSI_SEVERITY_MAP = {
  NOT_SPECIFIED: {
    buttonMode: BUTTON_MODES.EVENTS.INSIGHTS_SEVERITY_NOT_SPECIFIED,
    displayString: 'Not Specified'
  },
  LOW: {
    buttonMode: BUTTON_MODES.EVENTS.INSIGHTS_SEVERITY_LOW,
    displayString: 'Low'
  },
  MEDIUM: {
    buttonMode: BUTTON_MODES.EVENTS.INSIGHTS_SEVERITY_MEDIUM,
    displayString: 'Medium'
  },
  HIGH: {
    buttonMode: BUTTON_MODES.EVENTS.INSIGHTS_SEVERITY_HIGH,
    displayString: 'High'
  }
};
export const getFSIEventProperties = (event, latLongFormat, altitudeUnit, speedUnit) => {
  const { latitude, longitude } = decodeGeoHash(event.geohash);
  const { altitude, groundSpeed, heading } = event.eventDetails;
  const eventDisplayValues = getEventDisplayValues(event) || {};

  return {
    latitude,
    longitude,
    coordinates: formatCoordinates({ latitude, longitude }, latLongFormat),
    altitude: convertMetresTo(altitude, altitudeUnit, 0)[0],
    altitudeUnit,
    speed: Math.ceil(formatSpeedFromMetersPerSeconds(groundSpeed, speedUnit)),
    speedUnit,
    heading: Math.round(heading),
    ...eventDisplayValues,
    type: EventClass.FSI
  };
};

export const getFSIEventWithSeverityButtonMode = event => {
  return FSI_SEVERITY_MAP[event.eventDetails.severity]?.buttonMode || BUTTON_MODES.EVENTS.DEFAULT;
};
