import React from 'react';
import PropTypes from 'prop-types';

export const EditDisabled = ({ show, message }) =>
  show ? (
    <div style={{ textAlign: 'left', paddingTop: '0px', color: '#A9A9A9' }}>
      <React.Fragment>
        <p>{message}</p>
      </React.Fragment>
    </div>
  ) : (
    <React.Fragment />
  );

EditDisabled.propTypes = {
  show: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired
};

export default EditDisabled;
