import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import Icon from '../Icon';
import CenterMapIcon from './CenterMapIcon';
import { GOOGLE_MAP_CONTAINER } from '../../GoogleMap/constants';
import { MenuIcon } from '../MenuIcon';

const CenterMap = ({ centerMap }) => (
  <Tooltip
    placement="left"
    title="Center Map"
    onClick={centerMap}
    getPopupContainer={() => document.getElementById(GOOGLE_MAP_CONTAINER)}
  >
    <MenuIcon>
      <Icon height={18} width={18} type={CenterMapIcon} />
    </MenuIcon>
  </Tooltip>
);

CenterMap.propTypes = {
  centerMap: PropTypes.func
};

export default CenterMap;
