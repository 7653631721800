import React from 'react';
import { AirplanemodeInactive } from '@material-ui/icons';
import { EmptyListWrapper } from './styles/js/styled';

const EmptyList = () => (
  <EmptyListWrapper>
    <AirplanemodeInactive />
    <p>No aircraft</p>
  </EmptyListWrapper>
);

export default EmptyList;
