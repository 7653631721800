import React, { FC, Fragment } from 'react';
import styled from 'styled-components';
import { CopyOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

type PointDetailProps = {
  name: string;
  value: string;
  copySupported?: boolean;
};

const PointPropertyName = styled.div`
  display: flex;
  font-size: 0.75rem;
  font-weight: bold;
  color: #b1b1b1;
`;

const PointPropertyValue = styled.div`
  font-size: 0.8rem;
  color: #272727;
`;

const CopyWrapper = styled.div`
  padding-left: 5px;
`;

export const PointDetail: FC<PointDetailProps> = ({ name, value, copySupported }) => {
  return (
    <Fragment>
      <PointPropertyName>
        {name}
        {copySupported && (
          <CopyWrapper>
            <Tooltip title="Copy to clipboard">
              <CopyOutlined
                style={{ color: '#272727' }}
                onClick={() => {
                  navigator.clipboard.writeText(value);
                }}
              />
            </Tooltip>
          </CopyWrapper>
        )}
      </PointPropertyName>
      <PointPropertyValue>{value}</PointPropertyValue>
    </Fragment>
  );
};
