import constants, { AircraftListFilter } from '../components/Flying/AircraftList/constants';
import { Aircraft } from '../redux/types';

type SubType<Base, Condition> = {
  [Key in keyof Base]: Base[Key] extends Condition ? Key : never;
}[keyof Base];

export const sortAircraftBy = (field: SubType<Aircraft, string | number>) => (
  a: Aircraft,
  b: Aircraft
) => {
  switch (typeof a[field]) {
    case 'string':
      return `${a[field] as string}`
        .toLowerCase()
        .localeCompare(`${b[field] as string}`.toLowerCase());
    case 'number':
      return (a[field] as number) - (b[field] as number);
    default:
      console.error('Cannot sort by provided type');
      return 0;
  }
};

export const sortAircraftListBy = (aircraftList: Aircraft[], filter: AircraftListFilter['value']) =>
  filter === constants.AZ
    ? aircraftList.sort(sortAircraftBy('registration'))
    : filter === constants.LATEST
    ? aircraftList.sort(sortAircraftBy('lastPointTimestamp')).reverse()
    : aircraftList;
