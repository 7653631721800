import React, { memo } from 'react';
import PropTypes from 'prop-types';
import InfoWindowRow from '../../InfoWindowRow';

export function getVisibilityDetails(visibility) {
  return visibility.value + visibility.units;
}

export const VisibilityDetails = ({ visibility }) => {
  return <InfoWindowRow title="Visibility" content={getVisibilityDetails(visibility)} />;
};

VisibilityDetails.propTypes = {
  visibility: PropTypes.object.isRequired
};

VisibilityDetails.displayName = 'VisibilityDetails';

export default memo(VisibilityDetails);
