import { defaultFeatureConfig } from './useFeatureConfig.default';
export const featureConfigLocalStoragePath = 'featureConfig';

export function useFeatureConfig() {
  const item = window.localStorage.getItem(featureConfigLocalStoragePath);
  try {
    if (item === null) {
      return defaultFeatureConfig();
    }

    return JSON.parse(item);
  } catch (e) {
    // Parse Error - return default var
    return defaultFeatureConfig();
  }
}
