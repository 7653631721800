import * as moment from 'moment/moment';
import { PrivateTrackData } from '../../../common/api/spidertracks-sdk/types/TrackData';

export function formatUnixEpoch(from: number) {
  return moment
    .unix(from)
    .utc()
    .format('YYYY-MM-DD\\THH:mm:ss.SSS\\Z');
}

export function hexToRGB(hexColor: string, alpha: number = 255): rgba {
  // Remove the leading '#' if it's there
  hexColor = hexColor.replace(/^#/, '');

  // Parse the red, green, and blue components
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  return [r, g, b, alpha];
}

export function availabilityFromTimestamps(startTS: number, endTS: number) {
  const begin = formatUnixEpoch(startTS);
  const end = formatUnixEpoch(endTS);
  return `${begin}/${end}`;
}

export function availability(selectedTrack: PrivateTrackData) {
  return availabilityFromTimestamps(
    selectedTrack.departedTime / 1000,
    selectedTrack.endTime / 1000
  );
}

export function document(selectedTrack?: PrivateTrackData) {
  if (selectedTrack) {
    const begin = formatUnixEpoch(selectedTrack.departedTime / 1000);
    return {
      id: 'document',
      name: 'CZML Path',
      version: '1.0',
      clock: {
        interval: availability(selectedTrack),
        currentTime: begin,
        multiplier: 1
      }
    };
  } else {
    return {
      id: 'document',
      name: 'CZML Path',
      version: '1.0'
    };
  }
}

export type rgba = [number, number, number, number];
