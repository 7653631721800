import { createSlice } from '@reduxjs/toolkit';
import { getInstance } from '../../common/api/spidertracks-sdk';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import deepEqual from 'deep-equal';
import { setIsLoading } from './loading';

momentDurationFormatSetup(moment);

const initialState = { all: {}, latest: [], selected: [], selectedTrackId: '' };

export const getLastTwoPoints = points => {
  const pointsSortedByTimestamp = [...points].sort((a, b) => b.timestamp - a.timestamp);
  return [pointsSortedByTimestamp[0], pointsSortedByTimestamp[1]];
};

export const getIsActive = points => {
  if (!points) {
    return false;
  }
  const [latestPoint, secondLatestPoint] = getLastTwoPoints(points);
  if (!latestPoint) {
    return false;
  }
  if (!secondLatestPoint) {
    return moment().diff(moment(latestPoint.timestamp), 'minute') <= 15;
  }

  const delta = moment(latestPoint.timestamp).diff(moment(secondLatestPoint.timestamp), 'minute');
  return moment().diff(moment(latestPoint.timestamp), 'minute') <= delta + 5;
};

export const arePointUnitsSame = (pointFromStore, pointFromApi) => {
  if (pointFromStore && pointFromApi) {
    return (
      pointFromStore.speedUnit === pointFromApi.speedUnit &&
      pointFromStore.altitudeUnit === pointFromApi.altitudeUnit
    );
  }

  return false;
};

export const tracksSlice = createSlice({
  name: 'tracks',
  initialState,
  reducers: {
    updateAllTracks: (state, { payload }) => {
      for (const track of payload) {
        // if this is a new track (i.e. the trackId is not in state.all)
        if (!(track.trackId in state.all)) {
          // if the track was not associated to an aircraft (aircraft is null), do not add the track to the state
          if (track.aircraft === null) {
            track.aircraft = {
              id: '',
              registration: 'Registration not available',
              status: 'UNAVAILABLE'
            };
          }
          // add the track to state.all
          state.all[track.trackId] = track;

          const isActive = getIsActive(track.points);
          if (track.aircraft) {
            state.all[track.trackId].aircraft.isActive = isActive;
          }
        }
        // if the track is already in the list
        else {
          const isActive = getIsActive(track.points);
          if (track.aircraft) {
            state.all[track.trackId].aircraft.isActive = isActive;
          }

          // if the track number of points is different
          if (track.points.length !== state.all[track.trackId].points.length) {
            state.all[track.trackId].points = track.points;
          }

          // if the user preference has changed for units
          if (!arePointUnitsSame(track.points[0], state.all[track.trackId].points[0])) {
            state.all[track.trackId].points = track.points;
          }

          // if the track state changed update the track in the store
          if (track.state !== state.all[track.trackId].state) {
            state.all[track.trackId].state = track.state;
          }

          // if the track aircraft status is deleted update it in the store
          if (
            track.aircraft &&
            track.aircraft.status !== state.all[track.trackId].aircraft.status
          ) {
            state.all[track.trackId].aircraft.status = track.aircraft.status;
          }
        }
      }
    },
    setLatestTracks: (state, { payload }) => {
      window.performance.mark('setLatestTracks_start');
      for (const track of payload) {
        // if this is a new track (i.e. the trackId is not in state.all)
        if (!(track.trackId in state.all)) {
          // if the track was not associated to an aircraft (aircraft is null), do not add the track to the state
          if (track.aircraft === null) {
            track.aircraft = {
              id: '',
              registration: 'Registration not available',
              status: 'UNAVAILABLE'
            };
          }
          // add the track to state.all
          state.all[track.trackId] = track;

          const isActive = getIsActive(track.points);
          if (track.aircraft) {
            state.all[track.trackId].aircraft.isActive = isActive;
          }
        }
        // if the track is already in the list
        else {
          const isActive = getIsActive(track.points);
          if (track.aircraft) {
            state.all[track.trackId].aircraft.isActive = isActive;
          }

          // if the track number of points is different
          if (track.points.length !== state.all[track.trackId].points.length) {
            state.all[track.trackId].points = track.points;
          }

          // if the user preference has changed for units
          if (!arePointUnitsSame(track.points[0], state.all[track.trackId].points[0])) {
            state.all[track.trackId].points = track.points;
          }

          // if the track state changed update the track in the store
          if (track.state !== state.all[track.trackId].state) {
            state.all[track.trackId].state = track.state;
          }

          // if the track aircraft status is deleted update it in the store
          if (
            track.aircraft &&
            track.aircraft.status !== state.all[track.trackId].aircraft.status
          ) {
            state.all[track.trackId].aircraft.status = track.aircraft.status;
          }

          if (
            track.aircraft &&
            !deepEqual(
              track.aircraft.activeDeviceConnection,
              state.all[track.trackId].aircraft.activeDeviceConnection
            )
          ) {
            state.all[track.trackId].aircraft.activeDeviceConnection =
              track.aircraft.activeDeviceConnection;
          }
        }
        // push all latest ids into latest
        if (!state.latest.includes(track.trackId)) {
          state.latest.push(track.trackId);
        }
      }
      //remove track from latest if its no longer latest
      const latestTrackIds = payload.map(track => track.trackId);
      for (const trackIdKey in state.latest) {
        if (!latestTrackIds.includes(state.latest[trackIdKey])) {
          state.latest.splice(trackIdKey, 1);
        }
      }
      window.performance.mark('setLatestTracks_end');
      window.performance.measure('setLatestTracks', 'setLatestTracks_start', 'setLatestTracks_end');
    },
    clearAllTracks: state => {
      state.all = {};
      state.latest = [];
      state.selected = [];
      state.selectedTrackId = undefined;
    },
    setSelectedTracks: (state, { payload }) => {
      state.selected = [...payload];
    },
    setSelectedTrackId: (state, { payload }) => {
      state.selectedTrackId = payload;
    },
    clearSelectedTracks: state => {
      state.selected = [];
      state.selectedTrackId = undefined;
    },
    setTrackPoints: (state, { payload }) => {
      state.all[payload.trackId].points = payload.points;
    }
  }
});

// actions
export const {
  setLatestTracks,
  clearAllTracks,
  setSelectedTracks,
  setSelectedTrackId,
  clearSelectedTracks,
  setTrackPoints,
  updateAllTracks
} = tracksSlice.actions;

// asynchronous thunk action
export const fetchLatestTracks = organisationSlug => async dispatch => {
  const SpidertracksSDK = getInstance();
  const latestTrack = await SpidertracksSDK.getLatestTracks(organisationSlug);

  dispatch(setLatestTracks(latestTrack));
  dispatch(setIsLoading(false));
};

export const fetchLatestPublicTracksByAircraftIds = aircraftIds => async dispatch => {
  const SpidertracksSDK = getInstance();

  const latestTrack = await SpidertracksSDK.getLatestTracksService().getLatestTracksByAircraftIds(
    aircraftIds
  );

  dispatch(setLatestTracks(latestTrack.items));
};

export default tracksSlice;
