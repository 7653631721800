import { useMemo } from 'react';
import moment, { Moment } from 'moment';
import { useHistory } from 'react-router-dom';

export type HistoryPageQueryParams = Partial<{
  aircraftFilteredValues: string[];
  distanceFilteredValues: boolean[];
  durationFilteredValues: boolean[];
  endTime: Moment;
  page: number;
  sosFilteredValues: string[];
  startTime: Moment;
}>;

interface PageFilter {
  excludeIf?: (x: any) => boolean;
  name: string;
  paramName: string;
  parse: (x: any) => any;
  stringify: (x: any) => string;
}

const historyPageFilters: PageFilter[] = [
  {
    name: 'aircraftIds',
    paramName: 'aircraftFilteredValues',
    parse: (x: string) => x.split(','),
    stringify: (x: string[]) => x.join()
  },
  {
    excludeIf: (x: boolean | boolean[]) => x === false || (Array.isArray(x) && x.length === 0),
    name: 'distance',
    paramName: 'distanceFilteredValues',
    parse: (x: string) => x === 'true',
    // NOTE: apparently [true].toString() is 'true'. Annoying, but it works.
    // The arrays are due to the way AntD handles checkboxes. Obviously, this
    // is only suitable for filters with a single option.
    stringify: (x: boolean[]) => x.toString()
  },
  {
    excludeIf: (x: boolean | boolean[]) => x === false || (Array.isArray(x) && x.length === 0),
    name: 'duration',
    paramName: 'durationFilteredValues',
    parse: (x: string) => x === 'true',
    stringify: (x: boolean[]) => x.toString()
  },
  {
    excludeIf: (x: Moment | null) => x === null,
    name: 'end',
    paramName: 'endTime',
    parse: (x: string) => moment.unix(Number(x)),
    stringify: (x: Moment) =>
      x
        .endOf('day')
        .unix()
        .toString()
  },
  {
    excludeIf: (x: number) => x < 2,
    name: 'page',
    paramName: 'page',
    parse: (x: string) => Number(x),
    stringify: (x: any) => x.toString()
  },
  {
    name: 'sos',
    paramName: 'sosFilteredValues',
    parse: (x: string) => x.split(','),
    stringify: (x: string[]) => x.join()
  },
  {
    excludeIf: (x: Moment | null) => x === null,
    name: 'start',
    paramName: 'startTime',
    parse: (x: string) => moment.unix(Number(x)),
    stringify: (x: Moment) => {
      const ts = x.startOf('day').unix();
      return `${ts >= 0 ? ts : 0}`;
    }
  }
];

const updateFilters = (filters: HistoryPageQueryParams, existingQuery: string) => {
  const search = new URLSearchParams(existingQuery);
  for (const filter of historyPageFilters) {
    const f = filters[filter.paramName as keyof HistoryPageQueryParams];
    if (f !== undefined) {
      // Remove filter from search entirely if exclude conditions met
      if (filter.excludeIf && filter.excludeIf(f)) {
        search.delete(filter.name);
        continue;
      }

      if (Array.isArray(f) && f.length === 0) {
        // Empty array, remove it from search
        search.delete(filter.name);
        continue;
      }

      search.set(filter.name, filter.stringify(f));
    }
  }

  return search;
};

export const useHistoryParams = () => {
  const history = useHistory();

  const params = useMemo(() => {
    const search = new URLSearchParams(history.location.search);

    const searchParams: HistoryPageQueryParams = {};
    for (const filter of historyPageFilters) {
      const f = search.get(filter.name);
      if (f) {
        searchParams[filter.paramName as keyof HistoryPageQueryParams] = filter.parse(f);
      }
    }

    return searchParams;
  }, [history.location.search]);

  const updateParams = (filters: HistoryPageQueryParams) => {
    const search = updateFilters(filters, history.location.search);
    history.push({ search: search.toString() });
  };

  return {
    ...params,
    updateParams
  };
};
