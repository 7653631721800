import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { Form, Row, Col, Select } from 'antd';
import { convertToMetres, convertEventType, displayEventType } from '../../Flying/Map/utils/helper';
import './styles/scss/styles.scss';
import GeofenceMapContainer from './GeofenceMapContainer';
import { geofenceAddFormError, geofenceAddFormSuccess } from '../../../helpers/globalNotifications';
import {
  AltitudeContextOptions,
  AltitudeReferenceOptions,
  AltitudeLimitInput,
  EventTypeOptions,
  GeofenceNameInput
} from './form';
import { ConfirmationButtons } from '../../common/form';
import { useFeatureFlag } from '../../../hooks';

const { Option } = Select;

export const GeofenceForm = ({
  form,
  onClose,
  save,
  altitudeUnit,
  viewOnly = false,
  selectedGeofence
}) => {
  const { organisationId } = useParams();
  const { getFieldDecorator, validateFields, setFieldsValue } = form;
  const [isSaving, setIsSaving] = useState(false);
  const [isLimitEnabled, setIsLimitEnabled] = useState(false);
  const [isAltitudeReferenceEnabled, setAltitudeReferenceEnabled] = useState(false);
  const [displayUnit, setDisplayUnit] = useState();
  const isGeofenceAGLSelectorFlagEnabled = useFeatureFlag('geofence-agl-selector', [
    organisationId
  ]);

  const saveGeofence = async e => {
    e.preventDefault();

    try {
      await validateFields(async (err, values) => {
        if (!err) {
          setIsSaving(true);
          const geofenceFormData = values.shape;
          geofenceFormData.properties = {
            name: values.name,
            ...(values.altitudeContext !== 'any' && {
              altitudeLimit: convertToMetres(values.altitudeLimit, altitudeUnit)[0],
              altitudeReference: values.altitudeReference
            }),
            altitudeContext: values.altitudeContext,
            triggeringEvent: convertEventType(values.eventType)
          };

          try {
            await save(geofenceFormData);
            geofenceAddFormSuccess();
            onClose();
          } catch (e) {
            console.error(e);
            geofenceAddFormError();
            setIsSaving(false);
          }
        }
      });
    } catch (e) {
      // Catch intentionally left empty
    }
  };

  const onAltitudeChange = e => {
    if (e === 'above' || e === 'below') {
      setIsLimitEnabled(true);
      setAltitudeReferenceEnabled(true);
    } else {
      setIsLimitEnabled(false);
      setAltitudeReferenceEnabled(false);
    }
  };

  const options = dropdownOptions => {
    return dropdownOptions.map(option => {
      return (
        <Option key={option.displayedValue} value={option.actualValue}>
          {option.displayedValue}
        </Option>
      );
    });
  };

  useEffect(() => {
    if (altitudeUnit === 'ft') {
      setDisplayUnit('Feet');
    } else if (altitudeUnit === 'm') {
      setDisplayUnit('Metres');
    } else {
      setDisplayUnit(altitudeUnit);
    }
  }, [altitudeUnit]);

  const geofenceName = !!selectedGeofence ? selectedGeofence.properties.name : '';
  const altitudeContext = !!selectedGeofence ? selectedGeofence.properties.altitudeContext : '';
  const altitudeReference = !!selectedGeofence
    ? selectedGeofence.properties.altitudeReference
      ? selectedGeofence.properties.altitudeReference
      : 'amsl'
    : 'amsl';
  const altitudeLimit = !!selectedGeofence ? selectedGeofence.properties.altitudeLimit : '';
  const eventType =
    !!selectedGeofence && displayEventType(selectedGeofence.properties.triggeringEvent);

  return (
    <Form onSubmit={saveGeofence} layout="vertical" hideRequiredMark id="geofence-form">
      <Row gutter={{ xs: 8, sm: 40 }}>
        <Col xs={24} md={6}>
          <GeofenceNameInput
            name={geofenceName}
            disabled={viewOnly}
            getFieldDecorator={getFieldDecorator}
            setFieldsValue={setFieldsValue}
          />
        </Col>
        <Col xs={24} md={13}>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <AltitudeContextOptions
              label={isGeofenceAGLSelectorFlagEnabled ? 'Altitude' : 'Altitude AMSL'}
              altitudeContext={altitudeContext}
              getFieldDecorator={getFieldDecorator}
              setFieldsValue={setFieldsValue}
              onAltitudeChange={onAltitudeChange}
              disabled={viewOnly}
              options={options}
            />
            <AltitudeLimitInput
              altitudeLimit={altitudeLimit}
              disabled={!isLimitEnabled || viewOnly}
              displayUnit={displayUnit}
              isLimitEnabled={isLimitEnabled}
              getFieldDecorator={getFieldDecorator}
              setFieldsValue={setFieldsValue}
              altitudeUnit={altitudeUnit}
            />
            {isGeofenceAGLSelectorFlagEnabled && (
              <AltitudeReferenceOptions
                altitudeReference={altitudeReference}
                disabled={!isAltitudeReferenceEnabled || viewOnly}
                getFieldDecorator={getFieldDecorator}
                setFieldsValue={setFieldsValue}
                options={options}
              />
            )}
            <div style={{ paddingLeft: 75 }}></div>{' '}
            {/** <div></div> added to create white space between Altitude settings and Event Type */}
          </div>
        </Col>
        <Col xs={24} md={5}>
          <EventTypeOptions
            disabled={viewOnly}
            getFieldDecorator={getFieldDecorator}
            options={options}
            eventType={eventType}
            setFieldsValue={setFieldsValue}
          />
        </Col>
      </Row>
      <Form.Item>
        {!viewOnly && <p className="my-2">Use the drawing tools below to define a geofence area</p>}
        {getFieldDecorator('shape', {
          rules: [{ required: true, message: 'Please complete drawing out a geofence in the map' }]
        })(
          <GeofenceMapContainer
            className="drawing-container"
            drawingControl={!viewOnly}
            selectedGeofence={selectedGeofence}
          />
        )}
      </Form.Item>
      {!viewOnly && <ConfirmationButtons isSaving={isSaving} onClose={onClose} />}
    </Form>
  );
};

GeofenceForm.propTypes = {
  form: PropTypes.object,
  onClose: PropTypes.func,
  save: PropTypes.func.isRequired,
  altitudeUnit: PropTypes.string.isRequired,
  viewOnly: PropTypes.bool.isRequired,
  selectedGeofence: PropTypes.any
};

export default Form.create()(GeofenceForm);
