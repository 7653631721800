import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ExceedenceNotificationTableRow, GeofenceNotificationTableRow } from './NotificationsTable';
import { NotificationsTabsContainer } from './NotificationsTabsContainer';

import NotificationDrawer from './NotificationDrawer';
import { PageHeader } from 'antd';
import { getInstance } from '../../../common/api/spidertracks-sdk';
import Button from 'antd/lib/button';
import { Role } from '../../../common/api/spidertracks-sdk/types/Member';
import {
  getAircraftRegistrationVerboseLabel,
  getExceedenceLabel,
  getGeofenceLabel,
  getRuleLabel,
  getUsersLabel
} from '@spidertracks/components';
import { AircraftDetails } from '../../../types/aircraft';
import { NotificationConfiguration } from './NotificationForm';
import { User } from '../../common/form/UserSelector';
import {
  showErrorNotification,
  showSuccessNotification
} from '../../../helpers/globalNotifications';
import { EventRule } from './EventRuleSelector';
import { getDescription, transformParameters } from '../events/utils';
import {
  mapExcludedSeveritiesToSendNotificationForSeverity,
  getSendNotifcationForSeverityLabel
} from './ExcludedSeveritiesDropdown';
const style = { overflow: 'scroll', height: '100%' };

interface OrganisationId {
  organisationId: string;
}

export const NotificationsContainer = () => {
  const SpidertracksSDK = getInstance();
  const { organisationId } = useParams<OrganisationId>();
  const [geofenceNotificationTableRows, setGeofenceNotificationTableRows] = useState<
    GeofenceNotificationTableRow[]
  >();
  const [exceedenceNotificationTableRows, setExceedenceNotificationTableRows] = useState<
    ExceedenceNotificationTableRow[]
  >();
  const [notificationConfigurations, setNotificationConfigurations] = useState<
    NotificationConfiguration[]
  >();
  const [
    selectedNotificationConfiguration,
    setSelectedNotificationConfiguration
  ] = useState<NotificationConfiguration | null>(null);

  const [drawerVisibility, setDrawerVisibility] = useState<boolean>(false);
  const [orgDataLoading, setOrgDataLoading] = useState(true);
  const [notificationDataLoading, setNotificationDataLoading] = useState(true);
  const [userActionLoading, setUserActionLoading] = useState(false);

  const [users, setUsers] = useState<User[]>([]);
  const [aircraft, setAircraft] = useState<AircraftDetails[]>([]);
  const [geofenceEventRules, setGeofenceEventRules] = useState<EventRule[]>([]);
  const [exceedenceEventRules, setExceedenceEventRules] = useState<EventRule[]>([]);

  const fetchGeofences = async (): Promise<EventRule[]> => {
    const geofences = await SpidertracksSDK.getGeofencesService().getGeofences(organisationId);
    return geofences.features.map(g => {
      return { id: g.properties.id, label: g.properties.name };
    });
  };

  const fetchEventRules = async (): Promise<EventRule[]> => {
    const service = SpidertracksSDK.getEventRulesService();
    const [eventRules, eventParameters] = await Promise.all([
      service.getAllEventRules(organisationId),
      service.getAllEventParameters('fsi')
    ]);

    const transformedParameters = transformParameters(eventParameters);

    const filteredEventRules: EventRule[] = eventRules
      .filter(rule => rule.eventClass === 'fsi')
      .map(rule => {
        const parameters = transformedParameters[rule.eventType].map(p => {
          // TODO remove eventType dependency
          return {
            ...p,
            eventTypeDisplayName: rule.ruleName,
            displayValue: rule.severityDisplayThresholds
              ? rule.severityDisplayThresholds[p.name]
              : rule.displayThresholds[p.name]
          };
        });
        const description = getDescription(parameters, rule);

        return {
          id: rule.id,
          label: rule.ruleName,
          toolTipLabel: rule.ruleName,
          description: description,
          eventType: rule.eventType
        };
      });

    return filteredEventRules;
  };

  const eventClasses = [
    {
      name: 'geofence',
      label: 'Geofences',
      fetchOptions: fetchGeofences
    },
    {
      name: 'fsi',
      label: 'Exceedances (by name)',
      fetchOptions: fetchEventRules
    }
  ];

  const openAddNotificationConfigDrawer = () => {
    setDrawerVisibility(true);
    setSelectedNotificationConfiguration(null);
  };

  const onDrawerClose = () => {
    setDrawerVisibility(false);
  };

  useEffect(() => {
    const init = async () => {
      try {
        const membersData = await SpidertracksSDK.getMembers(organisationId);
        const membersAsUsers: User[] = membersData
          .map(member => ({
            id: member.id,
            name: `${member.firstName} ${member.lastName}`,
            isAdmin: member.role === Role.ADMIN
          }))
          .sort((user1, user2) => user1.name.localeCompare(user2.name));
        setUsers(membersAsUsers);

        const orgAircraft = (
          await SpidertracksSDK.getOrgAircraft(organisationId)
        ).sort((aircraft1, aircraft2) =>
          aircraft1.registration.localeCompare(aircraft2.registration)
        );
        setAircraft(orgAircraft as AircraftDetails[]);
      } catch (err) {
        showErrorNotification('Error fetching organisation data');
      } finally {
        setOrgDataLoading(false);
      }
    };

    init();
  }, [organisationId]);

  useEffect(() => {
    const init = async () => {
      try {
        const notificationsData = await SpidertracksSDK.getNotificationConfigurationService().get(
          organisationId
        );

        const geofences = await fetchGeofences();
        setGeofenceEventRules(geofences);
        const geofenceNotificationTableData: GeofenceNotificationTableRow[] = notificationsData
          .filter(entry => {
            return entry.eventClass === 'geofence';
          })
          .map(entry => {
            const geofenceNotificationTableRow = {
              key: entry.id,
              category: entry.eventClass,
              people: getUsersLabel(
                entry.people.map(o => (typeof o === 'object' ? ('name' in o ? o.name : o) : o))
              ),
              peopleToolTip: users
                .filter(
                  v =>
                    entry.people.filter(
                      u => (typeof u === 'object' ? ('userId' in u ? u.userId : '') : '') === v.id
                    ).length > 0
                )
                .map(o => o.name)
                .join(', '),
              notificationType: entry.how,
              aircraft: getAircraftRegistrationVerboseLabel(
                entry.aircraft.map(aicraftId => {
                  const aircraftDetail = aircraft.find(a => a.id == aicraftId);
                  if (aircraftDetail) {
                    return aircraftDetail.registration;
                  }
                  return aicraftId;
                })
              ),
              aircraftToolTip: aircraft
                .filter(v => entry.aircraft.includes(v.id))
                .map(o => o.registration)
                .join(', '),
              geofence: getGeofenceLabel(
                entry.eventRules.map(geofenceId => {
                  if (geofenceId == '*') {
                    return geofenceId;
                  }
                  const geofenceDetail = geofences.find(g => g.id === geofenceId);
                  if (geofenceDetail) {
                    return geofenceDetail.label;
                  }
                  return '(Deleted geofence)';
                })
              ),
              geofenceToolTip: geofences
                .filter(e => entry.eventRules.includes(e.id))
                .map(o => o.label)
                .join(', ')
            } as GeofenceNotificationTableRow;

            return geofenceNotificationTableRow;
          });
        setGeofenceNotificationTableRows(geofenceNotificationTableData);

        const eventRules = await fetchEventRules();
        setExceedenceEventRules(eventRules);
        const exceedencesNotificationTableData: ExceedenceNotificationTableRow[] = notificationsData
          .filter(entry => {
            return entry.eventClass === 'fsi';
          }) // Assumes all fsi events have eventRuleIds property
          .map(entry => {
            return {
              key: entry.id,
              category: entry.eventClass,
              people: getUsersLabel(
                entry.people.map(o => (typeof o === 'object' ? ('name' in o ? o.name : o) : o))
              ),
              peopleToolTip: users
                .filter(
                  v =>
                    entry.people.filter(
                      u => (typeof u === 'object' ? ('userId' in u ? u.userId : '') : '') === v.id
                    ).length > 0
                )
                .map(o => o.name)
                .join(', '),
              notificationType: entry.how,
              exceedence: entry.eventRuleIds!.includes('*')
                ? 'All Exceedances'
                : getExceedenceLabel(
                    entry.eventRuleIds!.map(ruleId => {
                      const rule = eventRules.find(e => e.id === ruleId);
                      if (rule == undefined) {
                        return '(Deleted Exceedance)';
                      }
                      return rule.label;
                    })
                  ),
              exceedenceToolTip: entry.eventRuleIds!.includes('*')
                ? eventRules.map(o => (o.toolTipLabel ? o.toolTipLabel : o.label)).join(', ')
                : eventRules
                    .filter(rule => entry.eventRuleIds!.includes(rule.id))
                    .map(o => (o.toolTipLabel ? o.toolTipLabel : o.label))
                    .join(', '),
              exceedenceRule: getRuleLabel(
                entry.eventRuleIds!.includes('*')
                  ? eventRules
                      .filter(rule => rule.description != undefined)
                      .map(rule => rule.description!)
                  : eventRules
                      .filter(rule => entry.eventRuleIds!.includes(rule.id))
                      .map(rule => rule.description!)
              ),
              exceedenceRuleToolTip: entry.eventRuleIds!.includes('*')
                ? eventRules
                    .filter(rule => rule.description != undefined)
                    .map(rule => rule.description)
                    .join(', ')
                : eventRules
                    .filter(rule => entry.eventRuleIds!.includes(rule.id))
                    .map(rule => rule.description)
                    .join(', '),
              severity: getSendNotifcationForSeverityLabel(
                mapExcludedSeveritiesToSendNotificationForSeverity(entry.excludedSeverities)
              ),
              severityToolTip: getSendNotifcationForSeverityLabel(
                mapExcludedSeveritiesToSendNotificationForSeverity(entry.excludedSeverities)
              )
            } as ExceedenceNotificationTableRow;
          });
        setExceedenceNotificationTableRows(exceedencesNotificationTableData);

        const notificationConfigurations = notificationsData.map(entry => {
          return {
            ...entry,
            eventClass: eventClasses.find(eventClass => eventClass.label === entry.eventClass)
              ?.name,
            users: entry.people.map(o =>
              typeof o === 'object' ? ('userId' in o ? o.userId : o) : o
            ),
            notifyMethod: entry.how.split(', ').map(method => method.toLowerCase())
          } as NotificationConfiguration;
        });
        setNotificationConfigurations(notificationConfigurations);
      } catch (err) {
        console.log(err);
        showErrorNotification('Error fetching notification configurations');
      } finally {
        setNotificationDataLoading(false);
      }
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userActionLoading, orgDataLoading]);

  const createNotification = async (config: NotificationConfiguration) => {
    try {
      setUserActionLoading(true);
      await SpidertracksSDK.getNotificationConfigurationService().save(organisationId, {
        aircraft: config.aircraft,
        recipients: config.users,
        eventClass: config.eventClass,
        eventRules: config.eventRules,
        eventRuleIds: config.eventRuleIds,
        notificationTypes: config.notifyMethod,
        excludedSeverities: config.excludedSeverities
      });
      showSuccessNotification('Notification configuration was added successfully');
    } catch (err) {
      showErrorNotification('Error saving notification configuration');
    } finally {
      setUserActionLoading(false);
    }
  };

  const updateNotification = async (config: NotificationConfiguration) => {
    const errorMessage = 'Error updating notification configuration';
    if (!config.id) {
      showErrorNotification(errorMessage);
      return;
    }

    try {
      setUserActionLoading(true);
      await SpidertracksSDK.getNotificationConfigurationService().update(
        organisationId,
        {
          id: config.id,
          aircraft: config.aircraft,
          recipients: config.users,
          eventClass: config.eventClass,
          eventRules: config.eventRules,
          eventRuleIds: config.eventRuleIds,
          notificationTypes: config.notifyMethod,
          excludedSeverities: config.excludedSeverities
        },
        config.id
      );
      showSuccessNotification('Notification configuration was updated successfully');
    } catch (err) {
      showErrorNotification(errorMessage);
    } finally {
      setUserActionLoading(false);
    }
  };

  const deleteNotification = async (notificationConfigurationId: string) => {
    const errorMessage = 'Error deleting notification configuration';
    if (!notificationConfigurationId) {
      showErrorNotification(errorMessage);
      return;
    }

    try {
      setUserActionLoading(true);
      await SpidertracksSDK.getNotificationConfigurationService().delete(
        organisationId,
        notificationConfigurationId
      );
      showSuccessNotification('Notification configuration deleted successfully');
    } catch (err) {
      showErrorNotification(errorMessage);
    } finally {
      setUserActionLoading(false);
    }
  };

  const openEditNotificationConfigDrawer = (
    record: GeofenceNotificationTableRow | ExceedenceNotificationTableRow
  ) => ({
    onClick: () => {
      setDrawerVisibility(true);
      setSelectedNotificationConfiguration(
        notificationConfigurations?.find(rule => rule.id === record.key) ?? null
      );
    }
  });

  return (
    <React.Fragment>
      <div className="px-5 py-4" style={style}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <PageHeader
            className="site-page-header"
            style={{ paddingLeft: '0px' }}
            title="Notifications"
            subTitle="View and manage event notifications"
          />
          <Button
            size="large"
            onClick={openAddNotificationConfigDrawer}
            type="primary"
            ghost
            style={{ padding: '0 50px' }}
            disabled={notificationDataLoading || orgDataLoading}
          >
            Add
          </Button>
        </div>
        <NotificationsTabsContainer
          geofenceNotificationTableRows={geofenceNotificationTableRows}
          exceedenceNotificationTableRows={exceedenceNotificationTableRows}
          loading={notificationDataLoading || orgDataLoading}
          onRow={openEditNotificationConfigDrawer}
          organisationId={organisationId}
        />
        {drawerVisibility && (
          <NotificationDrawer
            initialData={selectedNotificationConfiguration}
            visibility={drawerVisibility}
            onClose={onDrawerClose}
            users={users}
            aircraft={aircraft}
            createNotification={createNotification}
            updateNotification={updateNotification}
            deleteNotification={deleteNotification}
            geofenceEventRules={geofenceEventRules}
            exceedenceEventRules={exceedenceEventRules}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default NotificationsContainer;
