import * as airac from 'airac-cc';

export const vectorTile = (normalizedCoord, skyVectorView, zoom) => {
  const { identifier } = airac.Cycle.fromDate(new Date());

  if (!normalizedCoord) {
    return null;
  }
  return `https://t.skyvector.com/68b6ba18/${skyVectorView}/${identifier}/${zoom}/${normalizedCoord.x}/${normalizedCoord.y}.jpg`;
};

export default vectorTile;
