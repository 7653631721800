import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { getInstance } from 'common/api/spidertracks-sdk';
import { gaEventHelper } from '@spidertracks/common';
import { ConfirmationButtons } from '../../../../../common/form';

const gaEventSender = gaEventHelper('MapControls - Favourite Menu');

export const AddFavourite = ({ onClose }) => {
  const [name, setName] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const focusInput = component => {
    if (component) {
      component.focus();
    }
  };

  const addFavourite = useCallback(
    async e => {
      e.preventDefault();
      const mapCenter = window.gmap.getCenter();
      const SpidertracksSDK = getInstance();
      setIsSaving(true);
      await SpidertracksSDK.getFavouritesService().save({
        name,
        // eslint-disable-next-line @typescript-eslint/camelcase
        zoom_level: window.gmap.getZoom(),
        location: {
          lat: mapCenter.lat(),
          lng: mapCenter.lng()
        }
      });
      gaEventSender('Save', 'Favourites Save');
      setIsSaving(false);
      onClose();
    },
    [name]
  );

  return (
    <div className="p-2">
      <Form onSubmit={addFavourite} layout="vertical">
        <p>
          Centre the map on the location that you wish to save and enter a name for the favourite
          location.
        </p>
        <p>
          You will be able to quickly load this location by selecting it from the favourites menu
          once saved.
        </p>
        <Form.Item label="Favourite Name">
          <Input
            placeholder="Favourite Name"
            autofocus="true"
            ref={focusInput}
            onChange={event => setName(event.target.value)}
          />
        </Form.Item>
        <ConfirmationButtons onClose={onClose} disabled={name.length < 1} isSaving={isSaving} />
      </Form>
    </div>
  );
};

AddFavourite.propTypes = {
  onClose: PropTypes.func
};
