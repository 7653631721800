interface Coordinates {
  latitude: number;
  longitude: number;
}

interface FormattedCoordinates {
  value: string[];
  format: string;
}

enum CoordinatesUnit {
  DMS = 'degreesminutesseconds',
  DDM = 'degreesdecimalminutes',
  DECIMAL = 'decimal'
}

export const formatCoordinates = (
  { latitude, longitude }: Coordinates,
  format: string = CoordinatesUnit.DECIMAL
): FormattedCoordinates => {
  const round = (num: number) => Math.round(num * 1000000) / 1000000; // 6 decimal places

  let value;
  switch (format) {
    case CoordinatesUnit.DMS:
      value = toDMS({ latitude, longitude });
      break;
    case CoordinatesUnit.DDM:
      value = toDDM({ latitude, longitude });
      break;
    case CoordinatesUnit.DECIMAL:
    default:
      value = [`${round(latitude)}`, `${round(longitude)}`];
  }
  return {
    value,
    format
  };
};

const northSouth = (coordinate: number) => (Math.sign(coordinate) === -1 ? 'S' : 'N');
const eastWest = (coordinate: number) => (Math.sign(coordinate) === -1 ? 'W' : 'E');

function toDDM(coordinates: Coordinates) {
  function convertToMinutes(coordinate: number) {
    const absolute = Math.abs(coordinate);
    const degrees = Math.floor(absolute);
    const minutesNotTruncated = (absolute - degrees) * 60;
    const decimalMinutes = parseFloat(minutesNotTruncated.toFixed(4));

    return [degrees, decimalMinutes];
  }

  const [latDegree, latDecimal] = convertToMinutes(coordinates.latitude);
  const [lngDegree, lngDecimal] = convertToMinutes(coordinates.longitude);
  const ySymbol = northSouth(coordinates.latitude);
  const xSymbol = eastWest(coordinates.longitude);
  return [`${ySymbol}${latDegree}° ${latDecimal}’`, `${xSymbol}${lngDegree}° ${lngDecimal}’`];
}

function toDMS(coordinates: Coordinates) {
  function convertToMinutesSeconds(coordinate: number) {
    const absolute = Math.abs(coordinate);
    const degrees = Math.floor(absolute);
    const minutesNotTruncated = (absolute - degrees) * 60;
    const minutes = Math.floor(minutesNotTruncated);
    const seconds = ((minutesNotTruncated - minutes) * 60).toFixed(2);

    return [degrees, minutes, seconds];
  }
  const [latDegree, latMinutes, latSeconds] = convertToMinutesSeconds(coordinates.latitude);
  const [lngDegree, lngMinutes, lngSeconds] = convertToMinutesSeconds(coordinates.longitude);
  const ySymbol = northSouth(coordinates.latitude);
  const xSymbol = eastWest(coordinates.longitude);

  return [
    `${ySymbol}${latDegree}° ${latMinutes}’ ${latSeconds}”`,
    `${xSymbol}${lngDegree}° ${lngMinutes}’ ${lngSeconds}”`
  ];
}
