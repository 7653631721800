import React from 'react';

export const HistoryTableFilter = () => (
  <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.75 12H6.25C6.11193 12 6 12.1119 6 12.25V13.75C6 13.8881 6.11193 14 6.25 14H11.75C11.8881 14 12 13.8881 12 13.75V12.25C12 12.1119 11.8881 12 11.75 12Z"
      fill="#1890FF"
    />
    <path
      d="M14.75 6H3.25C3.11193 6 3 6.11193 3 6.25V7.75C3 7.88807 3.11193 8 3.25 8H14.75C14.8881 8 15 7.88807 15 7.75V6.25C15 6.11193 14.8881 6 14.75 6Z"
      fill="#1890FF"
    />
    <path
      d="M17.75 0H0.25C0.111929 0 0 0.111929 0 0.25V1.75C0 1.88807 0.111929 2 0.25 2H17.75C17.8881 2 18 1.88807 18 1.75V0.25C18 0.111929 17.8881 0 17.75 0Z"
      fill="#1890FF"
    />
  </svg>
);

export const DownloadFlight = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.87695 12.9102C9.89157 12.9288 9.91024 12.9439 9.93156 12.9543C9.95288 12.9647 9.97629 12.9701 10 12.9701C10.0237 12.9701 10.0471 12.9647 10.0684 12.9543C10.0898 12.9439 10.1084 12.9288 10.123 12.9102L12.3105 10.1426C12.3906 10.041 12.3184 9.89062 12.1875 9.89062H10.7402V3.28125C10.7402 3.19531 10.6699 3.125 10.584 3.125H9.41211C9.32617 3.125 9.25586 3.19531 9.25586 3.28125V9.88867H7.8125C7.68164 9.88867 7.60938 10.0391 7.68945 10.1406L9.87695 12.9102ZM17.1484 12.2266H15.9766C15.8906 12.2266 15.8203 12.2969 15.8203 12.3828V15.3906H4.17969V12.3828C4.17969 12.2969 4.10938 12.2266 4.02344 12.2266H2.85156C2.76562 12.2266 2.69531 12.2969 2.69531 12.3828V16.25C2.69531 16.5957 2.97461 16.875 3.32031 16.875H16.6797C17.0254 16.875 17.3047 16.5957 17.3047 16.25V12.3828C17.3047 12.2969 17.2344 12.2266 17.1484 12.2266Z"
      fill="white"
    />
  </svg>
);
