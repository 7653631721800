import styled from 'styled-components';

/** MapSlider * */
export default styled.div`
  position: relative;
  float: left;
  left: 0;
  z-index: 1;
  transition: all 0.5s ease;
  height: 100%;
`;
