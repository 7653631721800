import CryptoJS from 'crypto-js';
import * as constants from '../../GoogleMap/constants';

export const signature = () => {
  const ts = Math.round(new Date().getTime() / 1000);
  const stringToSign = `${constants.BARON.KEY}:${ts}`;
  const hmac = CryptoJS.HmacSHA1(stringToSign, constants.BARON.SECRET);
  let sig = CryptoJS.enc.Base64.stringify(hmac);
  sig = sig.replace(/\+/g, '-').replace(/\//g, '_');
  return `ts=${ts}&sig=${sig}`;
};

export default {
  signature
};
