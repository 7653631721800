interface KeyDisplayConfig {
  name: string;
  unit: string;
  displayUnit: string;
}

interface EventDisplayConfig {
  eventType: string;
  thresholdConfig: {
    keys: KeyDisplayConfig[];
    displayFormatString: string;
  };
  valueConfig: {
    keys: KeyDisplayConfig[];
    displayFormatString: string;
  };
  displayName: string;
}

const ExcessivePitchDown: EventDisplayConfig = {
  eventType: 'excessive_pitch_down',
  thresholdConfig: {
    keys: [
      {
        name: 'pitch',
        unit: 'radians',
        displayUnit: 'degrees'
      }
    ],
    displayFormatString: '{pitch}°'
  },
  valueConfig: {
    keys: [
      {
        name: 'pitch',
        unit: 'radians',
        displayUnit: 'degrees'
      }
    ],
    displayFormatString: '{pitch}°'
  },
  displayName: 'Excessive Pitch Down'
};

const ExcessivePitchDownAtLowAltitudeAGL: EventDisplayConfig = {
  eventType: 'excessive_pitch_down_at_low_altitude_agl',
  thresholdConfig: {
    keys: [
      {
        name: 'pitch',
        unit: 'radians',
        displayUnit: 'degrees'
      },
      {
        name: 'agl',
        unit: 'm',
        displayUnit: 'ft'
      }
    ],
    displayFormatString: '{pitch}° below {agl} ft'
  },
  valueConfig: {
    keys: [
      {
        name: 'pitch',
        unit: 'radians',
        displayUnit: 'degrees'
      },
      {
        name: 'agl',
        unit: 'm',
        displayUnit: 'ft'
      }
    ],
    displayFormatString: '{pitch}° at {agl} ft'
  },
  displayName: 'Excessive Pitch Down At Low AGL'
};

const ExcessivePitchUp: EventDisplayConfig = {
  eventType: 'excessive_pitch_up',
  thresholdConfig: {
    keys: [
      {
        name: 'pitch',
        unit: 'radians',
        displayUnit: 'degrees'
      }
    ],
    displayFormatString: '{pitch}°'
  },
  valueConfig: {
    keys: [
      {
        name: 'pitch',
        unit: 'radians',
        displayUnit: 'degrees'
      }
    ],
    displayFormatString: '{pitch}°'
  },
  displayName: 'Excessive Pitch Up'
};

const ExcessivePitchUpAtLowAltitudeAGL: EventDisplayConfig = {
  eventType: 'excessive_pitch_up_at_low_altitude_agl',
  thresholdConfig: {
    keys: [
      {
        name: 'pitch',
        unit: 'radians',
        displayUnit: 'degrees'
      },
      {
        name: 'agl',
        unit: 'm',
        displayUnit: 'ft'
      }
    ],
    displayFormatString: '{pitch}° below {agl} ft'
  },
  valueConfig: {
    keys: [
      {
        name: 'pitch',
        unit: 'radians',
        displayUnit: 'degrees'
      },
      {
        name: 'agl',
        unit: 'm',
        displayUnit: 'ft'
      }
    ],
    displayFormatString: '{pitch}° at {agl} ft'
  },
  displayName: 'Excessive Pitch Up At Low AGL'
};

const ExcessivePitchUpAtLowSpeed: EventDisplayConfig = {
  eventType: 'excessive_pitch_up_at_low_speed',
  thresholdConfig: {
    keys: [
      {
        name: 'pitch',
        unit: 'radians',
        displayUnit: 'degrees'
      },
      {
        name: 'gps_gspeed',
        unit: 'm/s',
        displayUnit: 'kt'
      }
    ],
    displayFormatString: '{pitch}° below {gps_gspeed} kt'
  },
  valueConfig: {
    keys: [
      {
        name: 'pitch',
        unit: 'radians',
        displayUnit: 'degrees'
      },
      {
        name: 'gpsGspeed',
        unit: 'm/s',
        displayUnit: 'kt'
      }
    ],
    displayFormatString: '{pitch}° at {gpsGspeed} kt'
  },
  displayName: 'Excessive Pitch Up At Low Speed'
};

const ExcessiveRateOfClimb: EventDisplayConfig = {
  eventType: 'excessive_rate_of_climb',
  thresholdConfig: {
    keys: [
      {
        name: 'v_speed',
        unit: 'm/s',
        displayUnit: 'ft/min'
      }
    ],
    displayFormatString: '{v_speed} ft/min'
  },
  valueConfig: {
    keys: [
      {
        name: 'vSpeed',
        unit: 'm/s',
        displayUnit: 'ft/min'
      }
    ],
    displayFormatString: '{vSpeed} ft/min'
  },
  displayName: 'Excessive RoC'
};

const ExcessiveRoll: EventDisplayConfig = {
  eventType: 'excessive_roll',
  thresholdConfig: {
    keys: [
      {
        name: 'roll',
        unit: 'radians',
        displayUnit: 'degrees'
      }
    ],
    displayFormatString: '{roll}°'
  },
  valueConfig: {
    keys: [
      {
        name: 'roll',
        unit: 'radians',
        displayUnit: 'degrees'
      }
    ],
    displayFormatString: '{roll}°'
  },
  displayName: 'Excessive Roll'
};

const ExcessiveRollAtLowAltitude: EventDisplayConfig = {
  eventType: 'excessive_roll_at_low_altitude',
  thresholdConfig: {
    keys: [
      {
        name: 'roll',
        unit: 'radians',
        displayUnit: 'degrees'
      },
      {
        name: 'altitude',
        unit: 'm',
        displayUnit: 'ft'
      }
    ],
    displayFormatString: '{roll}° below {altitude} ft'
  },
  valueConfig: {
    keys: [
      {
        name: 'roll',
        unit: 'radians',
        displayUnit: 'degrees'
      },
      {
        name: 'altitude',
        unit: 'm',
        displayUnit: 'ft'
      }
    ],
    displayFormatString: '{roll}° at {altitude} ft'
  },
  displayName: 'Excessive Roll At Low AMSL'
};

const ExcessiveRollAtLowAltitudeAGL: EventDisplayConfig = {
  eventType: 'excessive_roll_at_low_altitude_agl',
  thresholdConfig: {
    keys: [
      {
        name: 'roll',
        unit: 'radians',
        displayUnit: 'degrees'
      },
      {
        name: 'agl',
        unit: 'm',
        displayUnit: 'ft'
      }
    ],
    displayFormatString: '{roll}° below {agl} ft'
  },
  valueConfig: {
    keys: [
      {
        name: 'roll',
        unit: 'radians',
        displayUnit: 'degrees'
      },
      {
        name: 'agl',
        unit: 'm',
        displayUnit: 'ft'
      }
    ],
    displayFormatString: '{roll}° at {agl} ft'
  },
  displayName: 'Excessive Roll At Low AGL'
};

const HighRateOfDescent: EventDisplayConfig = {
  eventType: 'high_rate_of_descent',
  thresholdConfig: {
    keys: [
      {
        name: 'v_speed',
        unit: 'm/s',
        displayUnit: 'ft/min'
      }
    ],
    displayFormatString: '{v_speed} ft/min'
  },
  valueConfig: {
    keys: [
      {
        name: 'vSpeed',
        unit: 'm/s',
        displayUnit: 'ft/min'
      }
    ],
    displayFormatString: '{vSpeed} ft/min'
  },
  displayName: 'High RoD'
};

const HighRateOfDescentAtLowAltitude: EventDisplayConfig = {
  eventType: 'high_rate_of_descent_at_low_altitude',
  thresholdConfig: {
    keys: [
      {
        name: 'v_speed',
        unit: 'm/s',
        displayUnit: 'ft/min'
      },
      {
        name: 'altitude',
        unit: 'm',
        displayUnit: 'ft'
      }
    ],
    displayFormatString: '{v_speed} ft/min below {altitude} ft'
  },
  valueConfig: {
    keys: [
      {
        name: 'vSpeed',
        unit: 'm/s',
        displayUnit: 'ft/min'
      },
      {
        name: 'altitude',
        unit: 'm',
        displayUnit: 'ft'
      }
    ],
    displayFormatString: '{vSpeed} ft/min at {altitude} ft'
  },
  displayName: 'High RoD At Low AMSL'
};

const HighRateOfDescentAtLowAltitudeAGL: EventDisplayConfig = {
  eventType: 'high_rate_of_descent_at_low_altitude_agl',
  thresholdConfig: {
    keys: [
      {
        name: 'v_speed',
        unit: 'm/s',
        displayUnit: 'ft/min'
      },
      {
        name: 'agl',
        unit: 'm',
        displayUnit: 'ft'
      }
    ],
    displayFormatString: '{v_speed} ft/min below {agl} ft'
  },
  valueConfig: {
    keys: [
      {
        name: 'vSpeed',
        unit: 'm/s',
        displayUnit: 'ft/min'
      },
      {
        name: 'agl',
        unit: 'm',
        displayUnit: 'ft'
      }
    ],
    displayFormatString: '{vSpeed} ft/min at {agl} ft'
  },
  displayName: 'High RoD At Low AGL'
};

const MaximumAltitudeAGL: EventDisplayConfig = {
  eventType: 'maximum_altitude_agl',
  thresholdConfig: {
    keys: [
      {
        name: 'agl',
        unit: 'm',
        displayUnit: 'ft'
      }
    ],
    displayFormatString: '{agl} ft'
  },
  valueConfig: {
    keys: [
      {
        name: 'agl',
        unit: 'm',
        displayUnit: 'ft'
      }
    ],
    displayFormatString: '{agl} ft'
  },
  displayName: 'Maximum Altitude AGL'
};

const MaximumAltitudeAMSL: EventDisplayConfig = {
  eventType: 'maximum_altitude_amsl',
  thresholdConfig: {
    keys: [
      {
        name: 'altitude',
        unit: 'm',
        displayUnit: 'ft'
      }
    ],
    displayFormatString: '{altitude} ft'
  },
  valueConfig: {
    keys: [
      {
        name: 'altitude',
        unit: 'm',
        displayUnit: 'ft'
      }
    ],
    displayFormatString: '{altitude} ft'
  },
  displayName: 'Maximum Altitude AMSL'
};

const ExcessiveGForce: EventDisplayConfig = {
  eventType: 'excessive_g_force',
  thresholdConfig: {
    keys: [
      {
        name: 'gForce',
        unit: 'g',
        displayUnit: 'g'
      }
    ],
    displayFormatString: '{gForce} Gs'
  },
  valueConfig: {
    keys: [
      {
        name: 'gForce',
        unit: 'g',
        displayUnit: 'g'
      }
    ],
    displayFormatString: '{gForce} Gs'
  },
  displayName: 'Excessive G force'
};

const ExcessiveTemperature: EventDisplayConfig = {
  eventType: 'excessive_temperature',
  thresholdConfig: {
    keys: [
      {
        name: 'temperature',
        unit: '°C',
        displayUnit: '°C'
      }
    ],
    displayFormatString: '{temperature} °C'
  },
  valueConfig: {
    keys: [
      {
        name: 'temperature',
        unit: '°C',
        displayUnit: '°C'
      }
    ],
    displayFormatString: '{temperature} °C'
  },
  displayName: 'Excessive Temperature'
};

const ExcessiveRPM: EventDisplayConfig = {
  eventType: 'excessive_rpm',
  thresholdConfig: {
    keys: [
      {
        name: 'rpm',
        unit: 'rpm',
        displayUnit: 'rpm'
      }
    ],
    displayFormatString: '{rpm} rpm'
  },
  valueConfig: {
    keys: [
      {
        name: 'rpm',
        unit: 'rpm',
        displayUnit: 'rpm'
      }
    ],
    displayFormatString: '{rpm} rpm'
  },
  displayName: 'Excessive RPM'
};

const ExcessiveSpeed: EventDisplayConfig = {
  eventType: 'excessive_speed',
  thresholdConfig: {
    keys: [
      {
        name: 'highSpeed',
        unit: 'm/s',
        displayUnit: 'kt'
      }
    ],
    displayFormatString: '{highSpeed} kt'
  },
  valueConfig: {
    keys: [
      {
        name: 'highSpeed',
        unit: 'm/s',
        displayUnit: 'kt'
      }
    ],
    displayFormatString: '{highSpeed} kt'
  },
  displayName: 'Excessive Speed'
};

const eventDisplayConfigs: EventDisplayConfig[] = [
  ExcessivePitchDown,
  ExcessivePitchDownAtLowAltitudeAGL,
  ExcessivePitchUp,
  ExcessivePitchUpAtLowAltitudeAGL,
  ExcessivePitchUpAtLowSpeed,
  ExcessiveRateOfClimb,
  ExcessiveRoll,
  ExcessiveRollAtLowAltitude,
  ExcessiveRollAtLowAltitudeAGL,
  HighRateOfDescent,
  HighRateOfDescentAtLowAltitude,
  HighRateOfDescentAtLowAltitudeAGL,
  MaximumAltitudeAGL,
  MaximumAltitudeAMSL,
  ExcessiveGForce,
  ExcessiveTemperature,
  ExcessiveRPM,
  ExcessiveSpeed
];

export const getEventDisplayConfig = (eventType: string): EventDisplayConfig | undefined => {
  return eventDisplayConfigs.find(displayConfig => displayConfig.eventType === eventType);
};
