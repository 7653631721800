import React from 'react';
import { Divider } from '../../common/styled/Divider';
import { Parameter, ParameterInput } from './ParameterInput';

interface ComponentProps {
  selectedParameter: {
    parameters: Parameter[];
  };
  severityThresholdsEnabled: boolean;
  getFieldDecorator: () => {};
  setFieldsValue: () => {};
}

export const ParameterWithThresholds = ({
                                          selectedParameter,
                                          getFieldDecorator,
                                          setFieldsValue,
                                          severityThresholdsEnabled
                                        }: ComponentProps) => {
  const parameters = selectedParameter.parameters;

  const parameterWithThresholds = parameters.map((parameter, i) => {
    const showDivider = selectedParameter.parameters.length > 1 && i % 2 === 0;
    const showSeverity = severityThresholdsEnabled && i === 0; // Show severity for the first parameter, if the feature flag is enabled. This can be improved later.

    return (
      <React.Fragment key={`param#${i}`}>
        <ParameterInput
          parameter={parameter}
          showSeverity={showSeverity}
          severityThresholdsEnabled={severityThresholdsEnabled}
          setFieldsValue={setFieldsValue}
          getFieldDecorator={getFieldDecorator}
        />
        {showDivider && (
          <Divider style={{ paddingBottom: '2rem' }}>
            <span style={{ color: '#9F9F9F' }}>AND</span>
          </Divider>
        )}
      </React.Fragment>
    );
  });

  return <React.Fragment>{parameterWithThresholds}</React.Fragment>;
};

ParameterWithThresholds.displayName = 'ParameterWithThresholds';
