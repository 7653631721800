import {useEffect, useState} from 'react';
import {getInstance} from '../common/api/spidertracks-sdk';
import {Meta} from '../common/api/spidertracks-sdk/types/TracksAPIResponse';
import {historyTableDataError} from '../helpers';

export const useTrackHistory = (
  filters: URLSearchParams,
  immediate: boolean
): [(p: URLSearchParams) => void, any[], Meta | undefined, boolean, boolean] => {
  const SpidertracksSDK = getInstance();
  const [tracks, setTracks] = useState<any[]>([]);
  const [meta, setMeta] = useState<Meta | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);

  const fetchTrackHistory = (filters: URLSearchParams) => {
    const init = async () => {
      try {
        setLoading(true);

        const trackHistory = await SpidertracksSDK.getHistoryTableTracks(filters);
        setMeta(trackHistory.meta);
        if (trackHistory.items.length) {
          setTracks(trackHistory.items);
        } else {
          setTracks([]);
        }
      } catch (e) {
        historyTableDataError();
        console.log(e);
        console.log('Error fetching track history');
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    init();
  };

  useEffect(() => {
    if (immediate) {
      fetchTrackHistory(filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [fetchTrackHistory, tracks, meta, loading, error];
};
