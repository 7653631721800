import { ServiceAbstract } from '../../ServiceAbstract';
import { Amplify } from '@spidertracks/common';
import { PrivateTrackData } from '../../types/TrackData';

export class LatestTracksService extends ServiceAbstract {
  public async getLatestTracksByAircraftIds(aircraftIds?: string): Promise<PrivateTrackData> {
    return Amplify.API.get(
      'unauthenticated',
      `current/tracks/public/latest?aircraftIds=${aircraftIds}`
    );
  }
}
