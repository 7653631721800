import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';

export const DropdownTooltip = ({ title, description }) => {
  return (
    <React.Fragment>
      <Typography.Text strong={true} style={{ color: '#fff' }}>
        {title}
      </Typography.Text>
      <Typography.Paragraph style={{ color: '#fff' }}>{description}</Typography.Paragraph>
    </React.Fragment>
  );
};

DropdownTooltip.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};
