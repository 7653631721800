import { PublicAPI } from './public';
import { PrivateAPI } from './private';
import { SDK } from './SDK';

let sdk: SDK | undefined;

export const getInstance = (): SDK => {
  if (sdk) {
    return sdk;
  } else {
    throw new Error('SDK not initialized');
  }
};

export const initializeInstance = ({
  isPublic = false,
  baseUrl
}: {
  isPublic: boolean;
  baseUrl: string;
}): SDK => {
  sdk = isPublic ? new PublicAPI(baseUrl) : new PrivateAPI(baseUrl);
  return sdk;
};

export const destroyInstance = () => {
  sdk = undefined;
};
