import React, { useMemo, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Select } from 'antd';
import { getInstance } from 'common/api/spidertracks-sdk';
import { Create, Member, OwnerAdmin } from './options';
const { OptGroup } = Select;

const dropDownTitle = {
  paddingLeft: '1vw',
  fontWeight: 'bold',
  fontSize: 'large'
};

export const isOwnerAdmin = org => (org.role === 'OWNER' || org.role === 'ADMIN' ? true : false);
export const isMember = org => org.role === 'MEMBER';
export const byOrgName = (a, b) => (a.org.orgName > b.org.orgName ? 1 : -1);

export const OrgDropDown = () => {
  const [memberOrgs, setMemberOrgData] = useState([]);
  const [ownerAdminOrgs, setOwnerAdminOrgData] = useState([]);

  const fetchOrgData = async () => {
    const SpidertracksSDK = getInstance();
    const userData = await SpidertracksSDK.getUsers();
    let ownerAdminOrgs = userData.orgs.filter(isOwnerAdmin).sort(byOrgName);
    let memberOrgs = userData.orgs.filter(isMember);

    setMemberOrgData(memberOrgs);
    setOwnerAdminOrgData(ownerAdminOrgs);
  };

  useEffect(() => {
    fetchOrgData();
  }, []);

  const { organisationId } = useParams();

  const selectedOrg = () => {
    let orgName =
      ownerAdminOrgs.length < 1
        ? null
        : ownerAdminOrgs.find(org => org.org.id === organisationId).org.orgName;
    return orgName;
  };

  const orgName = useMemo(selectedOrg, [ownerAdminOrgs, organisationId]);

  return (
    <div style={{ margin: '5px 5px 5px 21px', minWidth: 'fit-content' }}>
      <Select
        value={orgName}
        style={{ width: '100%' }}
        dropdownStyle={dropDownTitle}
        defaultOpen={false}
      >
        <OptGroup label="Admin/Owner" key="owneradmin">
          {OwnerAdmin({ ownerAdminOrgs })}
        </OptGroup>
        <OptGroup label="Member" key="member">
          {Member({ memberOrgs })}
        </OptGroup>
        <OptGroup label="Create Organisation" key="create">
          {Create({ key: 'create' })}
        </OptGroup>
      </Select>
    </div>
  );
};

export default OrgDropDown;
