import { Amplify } from '@spidertracks/common';

import { ServiceAbstract } from '../../ServiceAbstract';
import { EventExplorerEvent } from '../../types/EventExplorerEvent';

import { EventExplorerDashboardFilters } from '../../../../../components/EventsExplorer/Dashboard/EventsExplorerDashboard';

export interface EventsQueryParams extends EventExplorerDashboardFilters {
  offset: number;
  limit?: number;
}

export interface EventsExplorerEventsApiResponse {
  events: EventExplorerEvent[];
  total?: number;
  error?: Error;
}

export class EventsExplorerService extends ServiceAbstract {
  private arrayToString = (array: string[]): string => {
    return (array || [])
      .filter(Boolean)
      .filter(value => value.trim() !== '')
      .join(',');
  };

  public async getEvents(queryParams: EventsQueryParams): Promise<EventsExplorerEventsApiResponse> {
    try {
      const queryStringParameters: Record<string, number | string> = {
        startIncluding: queryParams.startTimestamp,
        endExcluding: queryParams.endTimestamp,
        organisationIds: this.arrayToString(queryParams.organisationIds),
        aircraft: queryParams.aircraft.join(','),
        offset: queryParams.offset
      };

      if (queryParams.eventSubtypes.length) {
        queryStringParameters.eventSubtypes = this.arrayToString(queryParams.eventSubtypes);
      }
      if (queryParams.eventSeverities.length) {
        queryStringParameters.eventSeverities = this.arrayToString(queryParams.eventSeverities);
      }
      if (queryParams.limit) {
        queryStringParameters.limit = queryParams.limit;
      }

      return await Amplify.API.get('api.spidertracks.io-authenticated', `events-explorer/events`, {
        queryStringParameters
      });
    } catch (error) {
      return {
        events: [],
        error
      };
    }
  }
}
