import { Dispatch } from 'react';
import { getInstance } from '../common/api/spidertracks-sdk';

// 1. Obtain a token from our SSO lambda.
// 2. Exchange this token for a Sisense session cookie.
// 3. Check the JSON response from their `/api/auth/isauth` route.
// 4. If unsuccessful, throw to the calling code.
export const getSisenseSession = async () => {
  const sisenseTokenResponse = await getInstance()
    .getSisenseService()
    .getSisenseToken();

  // The following line is only to be used temporarily to test the Sisense SSO login performance.
  // This is warn since the selenium driver used in monitoring is not picking up console.log messages.
  console.warn(`Sisense session token response: ${JSON.stringify(sisenseTokenResponse)}`);

  const response = await fetch(`${sisenseTokenResponse.url}&return_to=/api/auth/isauth`, {
    credentials: 'include'
  });
  const { isAuthenticated } = await response.json();
  if (!isAuthenticated) {
    throw Error('Sisense denied authentication for this user.');
  }
};

// Create a <script> element using our Sisense endpoint (probably spidertracks.sisense.com).
export const getSisenseJS = (): HTMLScriptElement => {
  const endpoint = window?.env?.sisense?.endpoint;
  if (!endpoint) {
    throw Error('Cannot create SisenseJS script element: missing endpoint definition.');
  }

  const script = document.createElement('script');
  script.id = 'sisensejs';
  script.src = `${endpoint}/js/sisense.js`;
  script.type = 'text/javascript';
  return script;
};

// Check the Sisense endpoint `/api/auth/isauth` for the current session status.
// Always return boolean, leaning `false` if anything goes wrong.
export const hasSisenseSession = async (): Promise<boolean> => {
  const endpoint = window?.env?.sisense?.endpoint;
  if (!endpoint) {
    throw Error('Cannot authenticate with Sisense: missing endpoint definition.');
  }

  try {
    const response = await fetch(`${endpoint}/api/auth/isauth`, { credentials: 'include' });
    const { isAuthenticated } = await response.json();
    return isAuthenticated;
  } catch (e) {
    return false;
  }
};

// Insert the <script> for SisenseJS. This should only ever be done if we have a current session
// (otherwise, it'll cause a hard redirect as Sisense.JS tries to request resources from Sisense and
// gets 302'd to our `/sisense/sso-login` route).
export const insertSisenseScriptElement = (sendReadySignal: Dispatch<boolean>) => {
  const sisenseScriptElement = document.getElementById('sisensejs');
  if (!sisenseScriptElement) {
    const script = getSisenseJS();
    script.onload = () => sendReadySignal(true);
    document.body.appendChild(script);
    return;
  }

  sendReadySignal(true);
};
