import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col, Select } from 'antd';

const formItemStyle = { marginBottom: '1px' };

const { Option } = Select;

const TagFormDropdown = ({
  name,
  label,
  dropdownOptions,
  getFieldDecorator,
  placeholder,
  validationRules = [{ required: false }],
  handleFieldChange,
  disabled = false,
  initialValue
}) => {
  const options = dropdownOptions.map(option => {
    return (
      <Option key={option.displayedValue} value={option.actualValue}>
        {option.displayedValue}
      </Option>
    );
  });

  return (
    <Form.Item name={name} label={label} style={formItemStyle}>
      <Row>
        <Col span={16}>
          {getFieldDecorator(name, {
            rules: validationRules,
            initialValue: initialValue ? initialValue : undefined
          })(
            <Select
              showSearch
              size="large"
              style={{ width: '300px' }}
              placeholder={placeholder}
              onChange={handleFieldChange}
              disabled={disabled}
            >
              {options}
            </Select>
          )}
        </Col>
      </Row>
    </Form.Item>
  );
};

TagFormDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
  dropdownOptions: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  validationRules: PropTypes.array,
  handleFieldChange: PropTypes.func,
  disabled: PropTypes.bool,
  initialValue: PropTypes.string
};

export default TagFormDropdown;
