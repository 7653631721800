import { PointGroupingOption } from '../../../../components/Flying/Map/GoogleMap/constants';
import { Organisation } from './Organisation';

export enum UserAltitudeUnit {
  FEET = 'ft',
  METERS = 'm'
}
export interface UserData {
  altitudeUnit: UserAltitudeUnit;
  city: string;
  countryCode: string;
  createdDate: string;
  dateFormat: string;
  displayName: string;
  distanceUnit: string; // 'Nautical Miles';
  email: string;
  firstName: string;
  flyingPagePromptShown: boolean;
  gender: string | null;
  hideMapRadiusShading: boolean;
  id: string;
  lastName: string;
  latLongFormat: string; // 'degreesminutesseconds';
  orgs: Organisation[];
  phones: UserPhone[];
  speedUnit: string; // 'knots';
  spiderTxtEnabled: boolean;
  spiderTxtId: number;
  states: {
    groupTrackPoints: boolean;
    mapLayer: string; // 'MAP';
    showAllPoints: boolean;
    pointGroupingOption: PointGroupingOption;
    sidebarTab: string; // 'ALPHABETICAL';
    sidebarVisible: boolean;
    tcVersion: string;
    trackLineBold: boolean;
    trackLineGrayUnderline: boolean;
    trackLineMatchAircraftColour: boolean;
  };
  timeOffset: number;
  timezone: string;
}

interface UserPhone {
  countryCode: string;
  countryId: string;
  fullNumber: string;
  number: string;
  type: 'MOBILE';
}

export enum UserPreferenceKey {
  CLUSTERING_OPTION,
  SHOW_TRACKS,
  MAP_LAYER,
  AIRCRAFT_SORT,
  KML_LAYER,
  GROUPING_OPTION
}

export interface UserPreference {
  [UserPreferenceKey.CLUSTERING_OPTION]: {
    groupTrackPoints: boolean;
  };
  [UserPreferenceKey.SHOW_TRACKS]: {
    showAllPoints: boolean;
  };
  [UserPreferenceKey.GROUPING_OPTION]: {
    pointGroupingOption: string;
  };
  [UserPreferenceKey.MAP_LAYER]: {
    mapLayer: string;
  };
  [UserPreferenceKey.AIRCRAFT_SORT]: {
    sortType: string;
  };
  [UserPreferenceKey.KML_LAYER]: {
    selectedKml: string;
    isActive: boolean;
  };
}
