import PropTypes from 'prop-types';
import React from 'react';
import { Route, Switch } from 'react-router';
import PreselectMultipleTracks from '../../components/common/navigation/PreselectMultipleTracks';
import PreselectTrack from '../../components/common/navigation/PreselectTrack';
import HistoryContainer from '../../components/History/HistoryContainer';
import HistoryFlightReport from '../flying/HistoryFlightReport';
import HistoryFlyingPage from '../flying/HistoryFlyingPage';
import FlightExplorer from '../flight-explorer';
import { useLocation } from 'react-router-dom';

const HistoryRoot = ({ match: { path } }) => {
  const queryParams = new URLSearchParams(useLocation().search);
  const is3dFRAvailable = queryParams.get('3dfr') !== null;

  const flags = {
    useNewFlightDataService: true
  };

  return (
    <Switch>
      <Route path={`${path}/flight-report-multiview`}>
        <PreselectMultipleTracks>
          <HistoryFlightReport />
        </PreselectMultipleTracks>
      </Route>
      <Route path={`${path}/:trackId/flight-explorer`}>
        <PreselectTrack>
          <FlightExplorer flags={flags} is3dFRAvailable={is3dFRAvailable} />
        </PreselectTrack>
      </Route>
      <Route path={`${path}/:trackId/flight-report`}>
        <PreselectTrack>
          <FlightExplorer flags={flags} is3dFRAvailable={is3dFRAvailable} />
        </PreselectTrack>
      </Route>
      <Route path={`${path}/:serialNumber/:bootcount/flight-explorer`}>
        <PreselectTrack>
          <FlightExplorer flags={flags} is3dFRAvailable={is3dFRAvailable} />
        </PreselectTrack>
      </Route>
      <Route path={`${path}/:serialNumber/:bootcount/flight-report`}>
        <PreselectTrack>
          <FlightExplorer flags={flags} is3dFRAvailable={is3dFRAvailable} />
        </PreselectTrack>
      </Route>
      <Route path={`${path}/:trackId`} component={HistoryFlyingPage} />
      <Route exact path={path} component={HistoryContainer} />
    </Switch>
  );
};

HistoryRoot.propTypes = {
  match: PropTypes.object
};

export default HistoryRoot;
