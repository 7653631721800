import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import moment from 'moment';
import { formatStandardDateStrings, dateFormatter } from '../../../helpers/formatTime';
import { getDateFormat, getTimezone } from '../../../redux/selectors/userData';
import { connect } from 'react-redux';

export const SpiderStatus = ({ hide, loading, serialNumber, status, dateFormat, timezone }) => {
  let activationDateInLocalTime, _;
  if (status.iridiumActivationDate) {
    const formatFn = dateFormatter(dateFormat);
    [activationDateInLocalTime, _] = formatStandardDateStrings(
      formatFn,
      moment(status.iridiumActivationDate).utc(),
      timezone
    );
  }
  return (
    hide && (
      <div style={{ textAlign: 'left', paddingTop: '25px', color: '#A9A9A9' }}>
        {loading ? (
          <span
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '40%'
            }}
          >
            <Spin size="small" tip="Loading Status..." />
          </span>
        ) : (
          <React.Fragment>
            <p>{`Serial Number: ${serialNumber}`}</p>
            <p>{!!status.type.length && `Spider Type: ${status.type}`}</p>
            <p>{!!status.firmwareVersion.length && `Spider Firmware: ${status.firmwareVersion}`}</p>
            <p>{!!status.imei.length && `IMEI: ${status.imei}`}</p>
            <p>{!!status.cellular.length && `Cellular Status: ${status.cellular}`}</p>
            <p>{!!status.iridium && `Iridium Status: ${status.iridium}`}</p>
            <p>
              {!!status.iridiumActivationDate && `Activation Date: ${activationDateInLocalTime}`}
            </p>
            <p>{!!status.plan && `Plan: ${status.plan}`}</p>
          </React.Fragment>
        )}
      </div>
    )
  );
};

SpiderStatus.propTypes = {
  hide: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  serialNumber: PropTypes.string.isRequired,
  status: PropTypes.object.isRequired,
  dateFormat: PropTypes.string,
  timezone: PropTypes.string
};

SpiderStatus.displayName = 'SpiderStatus';

const mapStateToProps = state => {
  return {
    dateFormat: getDateFormat(state),
    timezone: getTimezone(state)
  };
};

export default connect(mapStateToProps)(SpiderStatus);
