import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import Moment from 'moment';

import { getDateFormat } from '../../../redux/selectors/userData';

const { RangePicker } = DatePicker;

export const DateFilter = ({
  className,
  handleChange,
  calendarFieldsValues,
  dateFormat,
  ranges,
  timezone,
  disabled
}) => {
  return (
    <RangePicker
      className={className}
      size="large"
      ranges={ranges}
      format={dateFormat}
      onChange={(dates, rawDates) => {
        let processedDates = dates;
        if (timezone && dates.length) {
          processedDates = [Moment.tz(rawDates[0], timezone), Moment.tz(rawDates[1], timezone)];
        }
        handleChange(processedDates, rawDates);
      }}
      value={calendarFieldsValues}
      disabled={disabled}
    />
  );
};

DateFilter.propTypes = {
  className: PropTypes.string,
  handleChange: PropTypes.func,
  calendarFieldsValues: PropTypes.array,
  dateFormat: PropTypes.string,
  ranges: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.instanceOf(Moment))),
  timezone: PropTypes.string,
  disabled: PropTypes.bool
};

DateFilter.displayName = 'DateFilter';

const mapStateToProps = state => ({
  dateFormat: getDateFormat(state)
});

export default connect(mapStateToProps)(DateFilter);
