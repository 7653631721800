import React, { useEffect, useState } from 'react';
import { Provider as SisenseProvider } from '../../context/SisenseContextSafetyEvents';
import { SafetyInsightsDashboard } from './SafetyInsightsDashboard';
import { SafetyInsightsHeader } from './SafetyInsightsHeader';
import { RequireOrgAdmin } from '../common/navigation/RequireOrgAdmin';
import { hasSisenseSession, getSisenseSession } from '../../helpers/sisense';

export const SafetyInsights = () => {
  return (
    <div className="px-5">
      <SafetyInsightsHeader />
      <SafetyInsightsDashboard />
    </div>
  );
};

const SafetyInsightsRoot = () => {
  const [sisenseLoggedIn, setSisenseLoggedIn] = useState(false);

  useEffect(() => {
    const doSisenseLogin = async () => {
      const isLoggedIn = await hasSisenseSession();
      if (!isLoggedIn) {
        await getSisenseSession();
      }
      setSisenseLoggedIn(true);
    };

    doSisenseLogin();
  }, []);

  return sisenseLoggedIn ? (
    <RequireOrgAdmin>
      <SisenseProvider>
        <SafetyInsights />
      </SisenseProvider>
    </RequireOrgAdmin>
  ) : (
    <React.Fragment />
  );
};

export default SafetyInsightsRoot;
