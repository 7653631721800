import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Radio } from 'antd';
import { gaEventHelper } from '@spidertracks/common';
import './styles/scss/weatheroptions.scss';
import WeatherOptions from '../WeatherOptions';
import { weatherLayer } from '../../../../../redux/reducers/mapReducer/actions/map';
import weatherData from './weatherdata';
import { getSelectedWeatherLayer } from '../../../../../redux/selectors/mapData';

const gaEventSender = gaEventHelper('MapControls - Weather Overlay');

const RadioGroup = Radio.Group;

export class WeatherSelectionMenuContainer extends PureComponent {
  onChange = e => {
    const selectedWeatherLayer = e.target.value;

    const { clickCallback } = this.props;

    this.props.weatherLayer(selectedWeatherLayer);

    clickCallback();
    this.sendGAEvent(e.target.value);
  };

  clearWeather = () => {
    this.props.weatherLayer(undefined);

    this.props.clickCallback();
  };

  sendGAEvent(layerProductCode) {
    let eventLabel = 'Weather Overlay (' + layerProductCode + ')';
    gaEventSender(layerProductCode, eventLabel);
  }

  render() {
    const { currentSelectedWeatherLayer = '' } = this.props;

    return (
      <span role="button" tabIndex={0}>
        <RadioGroup
          onChange={this.onChange}
          value={currentSelectedWeatherLayer}
          className="weather-view-radio"
        >
          <div className="clear-wrapper">
            <span className="clear-weather" onClick={this.clearWeather} role="presentation">
              Clear
            </span>
          </div>
          <div className="weather-options-wrapper">
            {weatherData.options.map((object, index) => (
              <WeatherOptions
                key={`weather-menu-${index}`}
                name={object.name}
                productCode={object.productCode}
              />
            ))}
          </div>
        </RadioGroup>
      </span>
    );
  }
}

const mapStateToProps = state => ({
  currentSelectedWeatherLayer: getSelectedWeatherLayer(state)
});

const mapDispatchToProps = {
  weatherLayer
};

WeatherSelectionMenuContainer.propTypes = {
  currentSelectedWeatherLayer: PropTypes.string,
  weatherLayer: PropTypes.func,
  clickCallback: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(WeatherSelectionMenuContainer);
