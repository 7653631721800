import React from 'react';
import { FilterConfig } from '../../../redux/types';

export const HistoryTableFilterTooltipContent: React.FC<FilterConfig> = (
  filterConfig: FilterConfig
) => {
  const sosRows: JSX.Element[] = [];
  if (filterConfig.sos) {
    filterConfig.sos.forEach((sos, index) => {
      sosRows.push(
        <span key={index}>
          SOS: {sos}
          <br />
        </span>
      );
    });
  }

  return (
    <div>
      <span>Filtered by:<br /></span>
      {filterConfig.aircraft && <span>Aircraft: {filterConfig.aircraft.length}<br/></span>}
      {filterConfig.aircraftMoved && <span>Aircraft moved<br/></span>}
      {filterConfig.sos && sosRows}
      {filterConfig.spiderTimeGreaterThanOneMinute && <span>Spider Time &gt; 1min</span>}
    </div>
  );
};
