import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router';
import OrganisationSettingsPage from './OrganisationSettingsPage';
import { useMostRecentOrgSettingPage } from '../../hooks/useMostRecentOrgSettingPage';
import { useMostRecentOrganisation } from '../../hooks/useMostRecentOrganisation';

export const OrganisationSettingsRoot = ({ match: { path } }) => {
  const orgSettingPage = useMostRecentOrgSettingPage();
  useMostRecentOrganisation();

  return (
    <Fragment>
      <Switch>
        <Route path={`${path}/:settingsPage+`} component={OrganisationSettingsPage} />;
        <Redirect exact from={`${path}`} to={`${path}/${orgSettingPage}`} />;
      </Switch>
    </Fragment>
  );
};

OrganisationSettingsRoot.propTypes = {
  match: PropTypes.object
};

export default OrganisationSettingsRoot;
