import React, { useContext, useEffect, useState, memo } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useGoogleMap } from '@react-google-maps/api';
import AircraftTrackContainer from './AircraftTrackContainer';
import { getLatestTracksFromFilteredAircraftList } from '../../../../../redux/selectors/aircraftData';
import { getSelectedMarkerPoint } from '../../../../../redux/selectors/mapData';
import { getSelectedTracks, getSelectedTrack } from '../../../../../redux/selectors/aircraftData';
import fitMapToTrackBounds from '../../../../../helpers/fitMapToTrackBounds';
import panMapToPoint from '../../../../../helpers/panMapToPoint';
import { getSelectedFilter } from '../../../../../redux/selectors/mapData';
import { MapContext } from '../../../../../context/MapContext';
import { getUserData } from '../../../../../redux/selectors/userData';

export function AircraftTracksContainer({
  aircraftTracks,
  selectedMarkerPoint,
  selectedTracks,
  selectedTrack,
  trackDisplayOptions
}) {
  const onHistoryPage = useLocation().pathname.includes('/history');
  if (!onHistoryPage) {
    aircraftTracks = aircraftTracks.filter(
      aircraftTrack =>
        aircraftTrack.aircraft.status !== 'DELETED' &&
        aircraftTrack.aircraft.status !== 'UNAVAILABLE'
    );
  }
  const [fitBoundsNeedsToRun, setFitBoundsNeedsToRun] = useState(true);
  const map = useGoogleMap();
  const { events, strictPointGrouping } = useContext(MapContext);
  const isEvents = events.displayEventClasses.length;
  const selectedTrackId = selectedTrack?.trackId;
  const selectedMarkerId = selectedMarkerPoint?.id;
  let displayTracks = isEvents && onHistoryPage ? selectedTracks : aircraftTracks;
  if (selectedTrack && isEvents && onHistoryPage) {
    displayTracks = displayTracks.filter(track => track.trackId === selectedTrack.trackId);
  }

  useEffect(() => {
    if (selectedTrackId) {
      fitMapToTrackBounds([selectedTrack], map);
      setFitBoundsNeedsToRun(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTrackId, map]);

  useEffect(() => {
    if (fitBoundsNeedsToRun && displayTracks.length && !selectedTrack) {
      fitMapToTrackBounds(displayTracks, map);
      setFitBoundsNeedsToRun(false);
    }
  }, [displayTracks, selectedTrack, map, fitBoundsNeedsToRun]);

  useEffect(() => {
    if (selectedMarkerId) {
      panMapToPoint(selectedMarkerPoint, map);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMarkerId, map]);

  const totalTrackCount = displayTracks.length;
  return displayTracks.map((aircraftTrack, index) => (
    <AircraftTrackContainer
      key={aircraftTrack.trackId}
      trackId={aircraftTrack.trackId}
      aircraftTrack={aircraftTrack}
      selectedMarkerPoint={selectedMarkerPoint}
      displayEventClasses={events.displayEventClasses}
      strictPointGrouping={strictPointGrouping}
      onHistoryPage={onHistoryPage}
      zIndex={totalTrackCount - index + 10}
      trackDisplayOptions={trackDisplayOptions}
    />
  ));
}

AircraftTracksContainer.propTypes = {
  aircraftTracks: PropTypes.array,
  selectedMarkerPoint: PropTypes.object,
  selectedTrack: PropTypes.object,
  trackDisplayOptions: PropTypes.object
};

const mapStateToProps = state => {
  const selectedFilter = getSelectedFilter(state);
  const userData = getUserData(state);
  let trackDisplayOptions;
  if (userData.states) {
    trackDisplayOptions = {
      trackLineBold: userData.states.trackLineBold,
      trackLineMatchAircraftColour: userData.states.trackLineMatchAircraftColour,
      trackLineGrayUnderline: userData.states.trackLineGrayUnderline
    };
  }

  return {
    aircraftTracks: getLatestTracksFromFilteredAircraftList(state, selectedFilter),
    selectedTrack: getSelectedTrack(state),
    selectedMarkerPoint: getSelectedMarkerPoint(state),
    selectedTracks: getSelectedTracks(state),
    trackDisplayOptions
  };
};

export default connect(mapStateToProps)(memo(AircraftTracksContainer));
