import React, { useState } from 'react';
import { Route, Redirect } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useGlobalConfig } from '../../hooks/useGlobalConfig';
import useEventListener from '../../hooks/useEventListener';
import { populateUserData } from '../../redux/reducers/navigationReducer/actions/thunk';
import { getUserOrgData } from '../../redux/selectors/userData';
import { Spin, Row, Col } from 'antd';

export const CreateOrganisationPage = ({ populateUserData }) => {
  const { STL_GO_ORIGIN } = useGlobalConfig();
  const [orgId, setOrgId] = useState(null);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const goToken = window.localStorage.getItem('hash').replace('Basic ', '');

  const callback = e => {
    try {
      if (e.origin.includes('spidertracks')) {
        if (e.data.includes('create-org-modal-closed')) {
          setShouldRedirect(true);
        } else {
          const id = e.data.split('org-created: ')[1];
          setOrgId(id);
          populateUserData();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEventListener('message', callback);

  return (
    <div style={{ height: '100%', width: '100%', float: 'right' }}>
      {orgId && (
        <Route>
          <Redirect to={`/organisation/${orgId}/settings`} />
        </Route>
      )}
      {shouldRedirect && (
        <Route>
          <Redirect to={`/`} />
        </Route>
      )}
      {isLoading ? (
        <Row type="flex" justify="space-around" align="middle" style={{ height: '100vh' }}>
          <Col>
            <Spin size="large" />
          </Col>
        </Row>
      ) : null}
      <iframe
        id="createOrganisationIframe"
        src={`${STL_GO_ORIGIN}/settings?go_token=${encodeURIComponent(
          goToken
        )}&hide-sidebar=t&create-org=t`}
        title={'Create Organisaion'}
        width="100%"
        onLoad={() => setIsLoading(false)}
        style={{
          border: 0,
          height: '100%',
          position: 'absolute',
          Zndex: 9,
          top: 0,
          left: 0
        }}
      />
    </div>
  );
};

CreateOrganisationPage.propTypes = {
  populateUserData: PropTypes.func
};

CreateOrganisationPage.displayName = 'CreateOrganisationPage';

const mapStateToProps = state => ({
  userData: getUserOrgData(state)
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      populateUserData
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrganisationPage);
