import { Aircraft } from '../redux/types';
import constants, { AircraftListFilter } from '../components/Flying/AircraftList/constants';

const filterActivePredicate = (aircraft: Aircraft) => aircraft.isActive;

export const aircraftListFilter = (
  aircraftList: Aircraft[],
  filter: AircraftListFilter['value']
) => {
  switch (filter) {
    case constants.ACTIVE:
      return aircraftList.filter(filterActivePredicate);
    default:
      return aircraftList;
  }
};

export default aircraftListFilter;
