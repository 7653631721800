import React, { memo } from 'react';
import PropTypes from 'prop-types';
import InfoWindowRow from '../../InfoWindowRow';

const changeIndicatorDescMap = new Map([
  ['TEMPO', 'Temporary'],
  ['BECMG', 'Gradual'],
  ['FM', 'Rapid'],
  ['INTER', 'Intermittent']
]);

export function getChangeIndicatorDetails(changeIndicator) {
  return changeIndicatorDescMap.get(changeIndicator.value) || changeIndicator.value;
}

export const ChangeIndicatorDetails = ({ changeIndicator }) => {
  return <InfoWindowRow title="Change" content={getChangeIndicatorDetails(changeIndicator)} />;
};

ChangeIndicatorDetails.propTypes = {
  changeIndicator: PropTypes.object.isRequired
};

export default memo(ChangeIndicatorDetails);
