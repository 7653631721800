import React from 'react';
import { AircraftProperties } from '.';

export const AircraftPropertiesTooltipContent: React.FC<AircraftProperties> = (
  aircraftProperties: AircraftProperties
) => {
  const getAircraftType = (type: string | undefined) => {
    if (type === undefined) {
      return '[empty]';
    }

    switch (type) {
      case 'FIXED_WING':
        return 'Fixed Wing';
      case 'ROTARY_WING':
        return 'Rotary Wing';
      default:
        return '[unknown]';
    }
  };

  return (
    <div>
      <span>
        Organisation: {aircraftProperties.orgName}
        <br />
      </span>
      <span>
        Status: {aircraftProperties.active ? 'Active' : 'Inactive'}
        <br />
      </span>
      <span>
        Type: {getAircraftType(aircraftProperties.type)}
        <br />
      </span>
      <span>
        {/*//LATER: would have preferred to use `??` here & below but something is setting these to emptyString.  Lack of proper types makes finding where hard.*/}
        Make: {aircraftProperties.make || '[empty]'}
        <br />
      </span>
      <span>
        Model: {aircraftProperties.model || '[empty]'}
        <br />
      </span>
      <span>
        Device: {aircraftProperties.device || '[empty]'}
        <br />
      </span>
      <span>
        Serial Number: {aircraftProperties.serialNumber?.toUpperCase() ?? '[empty]'}
        <br />
      </span>
    </div>
  );
};
