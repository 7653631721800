import PropTypes from 'prop-types';
import React, { Fragment, memo, useCallback } from 'react';
import { connect } from 'react-redux';
import { selectedMarkerPoint as selectedMarkerPointAction } from '../../../../../redux/reducers/mapReducer/actions/map';
import {
  getAircraftWithLatestTrackById,
  getLatLng,
  getSelectedTrackById,
  getTrackById,
  getTrackPointsWithoutEvents,
  isTrackSelected
} from '../../../../../redux/selectors/aircraftData';
import { getPointGroupingOption } from '../../../../../redux/selectors/mapData';
import {
  setSelectedTrackId,
  setSelectedTracks as setSelectedTracksAction
} from '../../../../../redux/slice/tracks';
import { setSelectedDisplayPoint } from '../../../../../redux/slice/events';
import AircraftTrackLine from './AircraftTrackLine';
import AircraftTrackPoints from './AircraftTrackPoints/AircraftTrackPoints';
import {
  getFlightReportDisplayPoints,
  getFlyingPageDisplayPoints
} from '../../../../../redux/selectors/eventData';
import { TRACK_WITH_EVENTS } from '../constants';
import { useFeatureFlag } from '../../../../../hooks';
import { getTrackLineOptions } from './getTrackLineOptions';

export function AircraftTrackContainer({
  aircraftTrack,
  displayEventClasses,
  isTrackSelected,
  points,
  setSelectedMarkerPoint,
  setSelectedTrackId,
  showTrackLine,
  trackId,
  setSelectedTracks,
  onHistoryPage,
  showEvents,
  aircraft,
  zIndex,
  trackDisplayOptions
}) {
  const onMarkerClick = useCallback(
    point => () => {
      !onHistoryPage && setSelectedTracks(aircraftTrack);
      setSelectedTrackId(trackId);
      setSelectedMarkerPoint(point);
    },
    [
      aircraftTrack,
      trackId,
      onHistoryPage,
      setSelectedTrackId,
      setSelectedTracks,
      setSelectedMarkerPoint
    ]
  );
  const customizedTrackPolylineEnabled = useFeatureFlag('customized-track-polyline', [
    aircraftTrack?.orgId
  ]);
  const updatedZIndex = isTrackSelected ? 900 : zIndex;
  const props = {
    aircraftIconColour: aircraft?.aircraftIconColour,
    isTrackActive: aircraftTrack?.isActive,
    isTrackSelected,
    zIndex: updatedZIndex,
    ...trackDisplayOptions
  };
  const trackLineOptions = getTrackLineOptions(props);

  return (
    <Fragment>
      {(showTrackLine || isTrackSelected) && (
        <AircraftTrackLine
          coordinates={points.map(getLatLng)}
          isTrackSelected={isTrackSelected}
          customizedTrackPolylineEnabled={customizedTrackPolylineEnabled}
          trackLineOptions={trackLineOptions}
        />
      )}

      <AircraftTrackPoints
        aircraftTrack={aircraftTrack}
        trackLineColour={trackLineOptions.innerLineOptions.strokeColor}
        displayEventClasses={displayEventClasses}
        onMarkerClick={onMarkerClick}
        points={points}
        onHistoryPage={onHistoryPage}
        showEvents={showEvents}
        isTrackSelected={isTrackSelected}
        zIndex={updatedZIndex}
      />
    </Fragment>
  );
}

AircraftTrackContainer.propTypes = {
  points: PropTypes.array.isRequired,
  aircraftTrack: PropTypes.object,
  setSelectedTrackId: PropTypes.func,
  setSelectedMarkerPoint: PropTypes.func,
  trackId: PropTypes.string,
  isTrackSelected: PropTypes.bool,
  showTrackLine: PropTypes.bool,
  displayEventClasses: PropTypes.array,
  setSelectedTracks: PropTypes.func,
  onHistoryPage: PropTypes.bool,
  showEvents: PropTypes.bool,
  aircraft: PropTypes.object,
  zIndex: PropTypes.number,
  trackDisplayOptions: PropTypes.object
};

const mapStateToProps = (
  state,
  { trackId, displayEventClasses, strictPointGrouping, onHistoryPage }
) => {
  const aircraftTrack =
    displayEventClasses.length && onHistoryPage
      ? getSelectedTrackById(state, trackId)
      : getTrackById(state, trackId);

  const showEvents =
    getPointGroupingOption(state) === TRACK_WITH_EVENTS || strictPointGrouping.displayFullTrack;

  const getDisplayPoints = onHistoryPage
    ? getFlightReportDisplayPoints
    : getFlyingPageDisplayPoints;

  const aircraft = getAircraftWithLatestTrackById(state, aircraftTrack?.aircraft?.id);

  const points =
    displayEventClasses.length && showEvents
      ? getDisplayPoints(state)[trackId]
      : getTrackPointsWithoutEvents(aircraftTrack);
  const pointsWithAircraftTrackState = points.map(point => ({
    ...point,
    aircraftTrackState: aircraftTrack.state
  }));

  return {
    aircraftTrack,
    points: pointsWithAircraftTrackState,
    isTrackSelected: isTrackSelected(state, aircraftTrack),
    showTrackLine:
      getPointGroupingOption(state) !== 'LAST_POINT' || strictPointGrouping?.displayFullTrack,
    onHistoryPage,
    showEvents,
    aircraft: aircraft ? aircraft[0] : undefined
  };
};

const mapDispatchToProps = (dispatch, { displayEventClasses, onHistoryPage }) => {
  return {
    setSelectedTrackId: trackId => dispatch(setSelectedTrackId(trackId)),
    setSelectedTracks: point => dispatch(setSelectedTracksAction([point.trackId])),
    // displayPoint is only used on the history flight report page
    setSelectedMarkerPoint: point =>
      displayEventClasses.length && onHistoryPage
        ? dispatch(setSelectedDisplayPoint(point))
        : dispatch(selectedMarkerPointAction(point))
  };
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(AircraftTrackContainer));
