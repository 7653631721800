import React, { useEffect, useState } from 'react';
import { Provider as SisenseProvider } from '../../context/SisenseContext';
import { InsightsDashboard } from './InsightsDashboard';
import { InsightsHeader } from './InsightsHeader';
import { RequireOrgAdmin } from '../common/navigation/RequireOrgAdmin';
import { hasSisenseSession, getSisenseSession } from '../../helpers/sisense';

export const Insights = () => {
  return (
    <div className="px-5">
      <InsightsHeader />
      <InsightsDashboard />
    </div>
  );
};

const InsightsRoot = () => {
  const [sisenseLoggedIn, setSisenseLoggedIn] = useState(false);

  useEffect(() => {
    const doSisenseLogin = async () => {
      const isLoggedIn = await hasSisenseSession();
      if (!isLoggedIn) {
        await getSisenseSession();
      }
      setSisenseLoggedIn(true);
    };

    doSisenseLogin();
  }, []);

  return sisenseLoggedIn ? (
    <RequireOrgAdmin>
      <SisenseProvider>
        <Insights />
      </SisenseProvider>
    </RequireOrgAdmin>
  ) : (
    <React.Fragment />
  );
};

export default InsightsRoot;
