import React, { Fragment, useEffect, useState } from 'react';
import { getPolygonCoordinates, getPolygonCenter, SigAirmetData, PolygonCoordinate } from './utils';
import { HAZARD_DISPLAY_PROPS } from './constants';
import { Marker, Polygon } from '@react-google-maps/api';

interface SigAirmetPolygonData {
  polygon: {
    coordinates: PolygonCoordinate[];
    options: google.maps.PolygonOptions;
  };
  marker: {
    label: google.maps.MarkerLabel;
    position: google.maps.LatLng;
  };
}

interface ComponentProps {
  sigAirmet: SigAirmetData;
}

export const SigAirmetPolygon: React.FC<ComponentProps> = ({ sigAirmet }) => {
  const [polygonDisplayData, setPolygonDisplayData] = useState<SigAirmetPolygonData>();

  useEffect(() => {
    const hazardDisplayProps = HAZARD_DISPLAY_PROPS[sigAirmet.hazard.type];
    if (hazardDisplayProps === undefined) {
      return;
    }

    const polygonCoordinates = getPolygonCoordinates(sigAirmet);
    const polygonCenter = getPolygonCenter(polygonCoordinates);
    setPolygonDisplayData({
      polygon: {
        coordinates: polygonCoordinates,
        options: {
          strokeOpacity: 0.8,
          strokeWeight: 3,
          strokePosition: google.maps.StrokePosition.INSIDE,
          strokeColor: hazardDisplayProps.polygon.color,
          fillColor: hazardDisplayProps.polygon.color,
          fillOpacity: 0.1
        }
      },
      marker: {
        label: {
          text: hazardDisplayProps.marker.label,
          color: hazardDisplayProps.marker.color,
          fontSize: '10px',
          fontWeight: 'bold'
        },
        position: polygonCenter
      }
    });
  }, []);

  return (
    <Fragment>
      {polygonDisplayData && (
        <Fragment>
          <Polygon
            paths={polygonDisplayData.polygon.coordinates}
            options={polygonDisplayData.polygon.options}
          />
          <Polygon
            paths={polygonDisplayData.polygon.coordinates}
            options={{
              strokeOpacity: 1,
              strokeWeight: 1,
              strokeColor: 'black',
              fillOpacity: 0
            }}
          />
          <Marker
            position={polygonDisplayData.marker.position}
            icon={{ path: google.maps.SymbolPath.CIRCLE, scale: 0 }}
            label={polygonDisplayData.marker.label}
          />
        </Fragment>
      )}
    </Fragment>
  );
};
