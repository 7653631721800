import React from 'react';
import PropTypes from 'prop-types';

const MapView = props => {
  const { color } = props;
  return (
    <path
      style={{
        fill: color
      }}
      d="M3.88,8c-0.67,0.03-1.41,0.27-2,0.62C0.7,9.36,0,10.61,0,12v152c0,1.52,0.89,2.94,2.25,3.62L48,190.5v-161L5.75,8.38 C5.12,8.06,4.55,7.97,3.88,8z M196.12,8c-0.69-0.02-1.38,0.16-2,0.5L156,29.25v161.12l41.88-22.88c1.28-0.7,2.12-2.03,2.12-3.5V12 c0-1.41-0.8-2.78-2-3.5C197.39,8.14,196.81,8.02,196.12,8z M96,9.5l-40,20v161l40-20V9.5z M104,9.5v160.75l44,20.25V29.75L104,9.5z"
    />
  );
};

MapView.defaultProps = {
  color: 'grey'
};

MapView.propTypes = {
  color: PropTypes.string
};

export default MapView;
