import styled from 'styled-components';
import { Flight } from '@material-ui/icons';

export const ViewFlightIcon = styled.span`
  color: #1890ff;
  margin-right: 25px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const FlightIcon = styled(Flight)`
  &&& {
    font-size: 14px;
  }
`;

export const ViewFlightLabel = styled.span`
  color: #1890ff;
  margin-left: 5px;
  font-family: arial;
  font-weight: 500;
`;
